import React, { state, useEffect, useState, Component } from "react";
import Dropzone from "react-dropzone";
import Button from "../2/Controls/Button";
// import * as constants from '../../Constatnts'
import * as constatnts from "../../Constatnts";

import axios from "axios";
import Spinner from "../Common/Spinner";

class ImportProduct extends Component {
  constructor() {
    super();
    this.state = {
      stores: [],
      selectedProductImportType: "",
      selectedStoreID: "",
      selectedStoreName: "",
      importID: "",
      logs: [],
      loading: false,
      completed: false,
      importStatus: "Uploading file...",
      showModal: true,
      importFile: null,
      emailID: "",
      rows: [],
      overWriteExistingCustomerData: false,
      importFolder: "/ImportProduct/",
      columnsToCheck: [
        "SKU",
        "BarCode",
        "Brand",
        "Category",
        "SubCategory",
        "Name",
        "Description",
        "HSNSACCode",
        "ChargeTaxOnProduct",
        "MRP",
        "SupplyPrice",
        "RetailPrice",
        "Markup",
        "DiscountPercentage",
        "DiscountAmount",
        "TaxInclusive",
        "TaxInclusivePurchase",
        "TaxGroup",
        "InventoryType",
        "MinQty",
        "MaxQty",
        "ReorderQty",
        "BatchTrackingBatchNoRequired",
        "BatchTrackingMRPRequired",
        "BatchTrackingMfgDateRequired",
        "BatchTrackingExpDateRequired",
        "BatchTrackingDateFormat",
        "OpeningStock",
      ],
      maxRecords: 2000,
    };
    this.onCSVUpload = this.onCSVUpload.bind(this);
    this.onFileRemove = this.onFileRemove.bind(this);
    this.onUploadClick = this.onUploadClick.bind(this);
    this.fileReader = this.fileReader.bind(this);
    this.pollUploadStatus = this.pollUploadStatus.bind(this);
    this.showStatus = this.showStatus.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.renderPincodeImportOptions = this.renderPincodeImportOptions.bind(
      this
    );
  }

  async componentDidMount() {
    let stores = await this.props.getUserStores(
      this.props.user.user.RegistrationID,
      this.props.user.auth.uid
    );

    let selectedStoreID = "";
    let selectedStoreName = "";
    if (this.props.config.WhiteLabel.Code === "PINCODE") {
      selectedStoreID = this.props.user.store.key;
      selectedStoreName = this.props.user.store.StoreName;
    }
    this.setState({
      emailID: this.props.user.auth.email,
      stores: stores,
      selectedStoreID: selectedStoreID,
      selectedStoreName: selectedStoreName,
    });
  }

  processProductLogs = () => {
    return this.state.logs.map((element) => ({
      ExcelRowNo: element.ExcelRowNo,
      SKU: element.SKU,
      BarCode: element.BarCode,
      Brand: element.Brand,
      Category: element.Category,
      SubCategory: element.SubCategory,
      Name: element.Name,
      HSNSACCode: element.HSNSACCode,
      ChargeTaxOnProduct: element.ChargeTaxOnProduct,
      MRP: element.MRP,
      SupplyPrice: element.SupplyPrice,
      RetailPrice: element.RetailPrice,
      Markup: element.Markup,
      DiscountPercentage: element.DiscountPercentage,
      DiscountAmount: element.DiscountAmount,
      TaxInclusive: element.TaxInclusive,
      TaxGroup: element.TaxGroup,
      Status: element.Status,
      Error: element.Error,
    }));
  };
  downloadCSV = (data, fileName) => {
    const csvData = constatnts.objToCSV(data);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  onExportClick() {
    let data = [];
    let fileName = "";
    // console.log("this.props.importType",this.props.importType);
    // console.log("this.state.selectedProductImportType",this.state.selectedProductImportType);
    if (this.props.importType === "customer" && this.state.completed) {
      for (let index = 0; index < this.state.logs.length; index++) {
        const element = this.state.logs[index];

        data.push({
          ExcelRowNo: element.ExcelRowNo,
          CustomerCode: element.CustomerCode,
          FirstName: element.FirstName,
          LastName: element.LastName,
          Gender: element.Gender,
          PhoneNo: element.PhoneNo,
          EmailID: element.EmailID,
          Notes: element.Notes,
          DOB: element.DOB,
          AddressType: element.AddressType,
          AddressLine1: element.AddressLine1,
          AddressLine2: element.AddressLine2,
          City: element.City,
          State: element.State,
          PostalCode: element.PostalCode,
          Country: element.Country,
          CustomField1: element.CustomField1,
          CustomField2: element.CustomField2,
          CustomField3: element.CustomField3,
          CustomField4: element.CustomField4,
          CustomField5: element.CustomField5,
          CustomField6: element.CustomField6,
          Membership: element.Membership,
          WalletOpeningBalance: element.WalletOpeningBalance,
          OutstandingAmount: element.OutstandingAmount,
          Status: element.Status,
          Error: element.Error,
        });
      }
      fileName = "Customers.csv";
    } else if (
      this.props.importType === "product" &&
      this.props.config.WhiteLabel.Code === "PINCODE" &&
      this.state.completed
    ) {
      for (let index = 0; index < this.state.logs.length; index++) {
        const log = this.state.logs[index];
        data.push({
          ExcelRowNo: log.ExcelRowNo,
          BarCode: log.BarCode,
          MRP: log.MRP,
          RetailPrice: log.RetailPrice,
          OutOfStock: log.OutOfStock,
          ShowOnline: log.ShowOnline,
          InStock: log.InStock,
          Status: log.Status,
          Message: log.Message,
          ActionOn: new Date(log.ActionOn.seconds * 1000)
            .toString()
            .substring(0, 24),
        });
      }
      fileName = "ImportLog.csv";
    } else if (
      this.props.importType === "product" &&
      (this.state.selectedProductImportType === "1" ||
        this.state.selectedProductImportType === "3") &&
      this.state.completed
    ) {
      for (let index = 0; index < this.state.logs.length; index++) {
        const element = this.state.logs[index];
        data.push({
          ExcelRowNo: element.ExcelRowNo,
          SKU: element.SKU,
          BarCode: element.BarCode,
          Brand: element.Brand,
          Category: element.Category,
          SubCategory: element.SubCategory,
          Name: element.Name,
          HSNSACCode: element.HSNSACCode,
          ChargeTaxOnProduct: element.ChargeTaxOnProduct,
          MRP: element.MRP,
          SupplyPrice: element.SupplyPrice,
          RetailPrice: element.RetailPrice,
          Markup: element.Markup,
          DiscountPercentage: element.DiscountPercentage,
          DiscountAmount: element.DiscountAmount,
          TaxInclusive: element.TaxInclusive,
          TaxGroup: element.TaxGroup,
          PriceBasedTax: element.PriceBasedTax,
          PriceRangeFrom1: element.PriceRangeFrom1,
          PriceRangeTo1: element.PriceRangeTo1,
          TaxGroup2: element.TaxGroup2,
          ProductType: element.ProductType,
          AllowDiscount: element.AllowDiscount,
          AllowPriceEdit: element.AllowPriceEdit,
          AllowTaxChange: element.AllowTaxChange,
          WalletTopUpAmount: element.WalletTopUpAmount,
          WalletTopUpSameAsRetailPrice: element.WalletTopUpSameAsRetailPrice,
          Status: element.Status,
          Error: element.Error,
        });
      }
      fileName = "ProductImport.csv";
    } else if (
      this.props.importType === "product" &&
      // this.state.selectedProductImportType === "2" &&
      this.state.completed
    ) {
      // for (let index = 0; index < this.state.logs.length; index++) {
      //   const element = this.state.logs[index];

      //   data.push({
      //     ExcelRowNo: element.ExcelRowNo,
      //     ProductID: element.ProductID,
      //     MRP: element.MRP,
      //     SupplyPrice: element.SupplyPrice,
      //     RetailPrice: element.RetailPrice,
      //     Markup: element.Markup,
      //     DiscountPercentage: element.DiscountPercentage,
      //     DiscountAmount: element.DiscountAmount,
      //     Status: element.Status,
      //     Error: element.Error,
      //   });
      // }
      data = this.processProductLogs();
      fileName = "ProductImport.csv";
    } else if (
      this.props.importType === "product" &&
      this.state.selectedProductImportType === "3" &&
      this.state.completed
    ) {
      for (let index = 0; index < this.state.logs.length; index++) {
        const element = this.state.logs[index];
        data.push({
          ExcelRowNo: element.ExcelRowNo,
          ProductID: element.ProductID,
          MRP: element.MRP,
          SupplyPrice: element.SupplyPrice,
          RetailPrice: element.RetailPrice,
          Markup: element.Markup,
          DiscountPercentage: element.DiscountPercentage,
          DiscountAmount: element.DiscountAmount,
          Status: element.Status,
          Error: element.Error,
        });
      }
      fileName = "ProductImport.csv";
    } else if (this.props.importType === "bom" && this.state.completed) {
      for (let index = 0; index < this.state.logs.length; index++) {
        const element = this.state.logs[index];
        data.push({
          ExcelRowNo: element.ExcelRowNo,
          BOMName: element.BOMName,
          BOMProductSKU: element.BOMProductSKU,
          BOMProductName: element.BOMProductName,
          RawMaterialSKU: element.RawMaterialSKU,
          RawMaterialName: element.RawMaterialName,
          RawMaterialQty: element.RawMaterialQty,
          Status: element.Status,
          Error: element.Error,
        });
      }
      fileName = "BOMImport.csv";
    } else if (
      this.props.importType === "loyaltyCustomer" &&
      this.state.completed
    ) {
      for (let index = 0; index < this.state.logs.length; index++) {
        const element = this.state.logs[index];

        data.push({
          ExcelRowNo: element.ExcelRowNo,
          CustomerCode: element.CustomerCode,
          CustomerFirstName: element.CustomerFirstName,
          CustomerLastName: element.CustomerLastName,
          CustomerPhoneNo: element.CustomerPhoneNo,
          CustomerEmailID: element.CustomerEmailID,
          LoyaltyType: element.LoyaltyType,
          LoyaltyPoints: element.LoyaltyPoints,
          Status: element.Status,
          Error: element.Error,
        });
      }
      fileName = "LoyaltyCustomers.csv";
    }
    // const csvData = constatnts.objToCSV(data);
    this.setState({ exportCalled: false });
    this.downloadCSV(data, fileName);

    // const csvBlob = new Blob([csvData], { type: "text/csv" });
    // const url = window.URL.createObjectURL(csvBlob);
    // const a = document.createElement("a");
    // a.setAttribute("hidden", "");
    // a.setAttribute("href", url);
    // a.setAttribute("download", fileName);
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  onCSVUpload() {
    this.setState({ loading: true });
  }

  onFileRemove() {
    this.setState({ importFile: null });
  }

  async onUploadClick() {
    if (!this.state.importFile) {
      alert("Please select file to import");
      return;
    }
    //read csv to chck no of rows
    //read csv to check column name
    //upload csv
    //create doc

    let rows = this.state.rows;
    // let maxRecords = this.state.maxRecords;
    // if (rows.length > maxRecords) {
    //   alert("No of rows more than " + maxRecords);
    //   return;
    // }

    if (this.props.type === "store") {
      let stores = await this.props
        .getStores(
          this.props.user.user.RegistrationID,
          0,
          1000,
          "active",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        )
        .then((result) => {
          return result.searchResult;
        });
      let storeCount = stores.filter((f) => !f.IsDeleted).length;
      storeCount = Number(storeCount) + Number(rows.length);
      let noOfStoresAllowed = 0;
      if (
        this.props.registration.registration.hasOwnProperty("NoOfStoresAllowed")
      ) {
        noOfStoresAllowed = this.props.registration.registration
          .NoOfStoresAllowed;
        // console.log("noOfStoresAllowed", noOfStoresAllowed);
      }
      if (storeCount > noOfStoresAllowed) {
        alert(
          "Additional store cannot be added as limit has exceeded. You can only have " +
            noOfStoresAllowed +
            " active stores"
        );
        return;
      }
    }

    let columns = rows[0].split(",");
    console.log("columns", columns);
    let columnsToCheck = this.state.columnsToCheck;
    let f = this.state.importFolder;
    console.log("columnsToCheck", columnsToCheck);
    let error = "";
    for (var i = 0; i < columnsToCheck.length; i++) {
      let columnFound = false;
      //console.log('columnsToCheck[i]',columnsToCheck[i])
      for (var j = 0; j < columns.length; j++) {
        if (columnsToCheck[i] === columns[j]) {
          //console.log('colun found - columns[j]', columns[j])
          columnFound = true;
          break;
        }
      }
      if (!columnFound) {
        error = error + columnsToCheck[i] + " missing.";
        //console.log(error)
      }
    }
    if (error !== "") {
      alert("Invalid file format." + error);
      return;
    }
    this.setState({ loading: true });
    // console.log('this.props.importType',this.props.importType)
    // console.log('f',f)
    let uploadPath =
      this.props.registration.registration.StorageFolder +
      f +
      this.props.user.user.RegistrationID +
      "_" +
      Math.floor(Math.random() * 100000 + 1);
    // console.log('uploadPath',uploadPath)
    this.setState({ loading: true });
    try {
      this.props
        .uploadFileToServer(this.state.importFile, uploadPath)
        .then(async (downloadURL) => {
          console.log("downloadURL", downloadURL);
          const formData = new FormData();
          formData.append("file", this.state.importFile);
          formData.append(
            "filePath",
            uploadPath + "/" + this.state.importFile.name
          );
          formData.append("storageFolder", uploadPath);
          formData.append(
            "RegistrationID",
            this.props.user.user.RegistrationID
          );
          formData.append("StoreID", this.props.user.store.key);
          formData.append("ActionBy", this.props.user.user.Name);
          formData.append("ActionByEmailID", this.props.user.user.EmailID);
          formData.append("ActionByUID", this.props.user.user.UID);
          // formData.append("importType", props.importType);
          formData.append("importType", "product");
          formData.append("emailID", this.state.emailID);
          formData.append("productImportType", "5");
          formData.append(
            "forStoreID",
            this.state.selectedProductImportType === "3"
              ? ""
              : this.state.selectedStoreID
          );
          formData.append("forStore", this.state.selectedStoreName);
          formData.append(
            "overWriteExistingCustomerData",
            this.state.overWriteExistingCustomerData
          );

          const response = await axios.post(
            "https://backend-o3dyulj4pa-el.a.run.app/v1/uploadFileToServer",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
              },
            }
          );
          this.setState({ importID: response.data.id });
          this.pollUploadStatus();

          // this.props
          //   .importCSV(
          //     uploadPath + "/" + this.state.importFile.name,
          //     this.props.importType,
          //     this.state.importFile.name,
          //     this.state.emailID,
          //     // this.props.importType === "product" ? this.state.selectedProductImportType : "",
          //     productImportType,
          //     this.state.selectedProductImportType === "3"
          //       ? ""
          //       : this.state.selectedStoreID,
          //     this.state.selectedStoreName,
          //     this.state.overWriteExistingCustomerData
          //   )
          //   .then((importID) => {
          //     console.log("importID", importID);
          //     this.setState({ importID: importID });
          //     this.pollUploadStatus();
          //   });
        });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  pollUploadStatus() {
    this.setState({ importStatus: "Processing file.." });
    this.timer = setInterval(() => this.showStatus(), 30000);
  }

  showStatus() {
    console.log("Polling Import Status...this.state.importID");
    this.props.getImportDetails(this.state.importID).then((data) => {
      if (data.Status.toLowerCase() === "completed") {
        this.setState({
          loading: false,
          completed: true,
          downloadLink: data.DownloadLink,
          importStatus: "Import completed",
        });
        //get logs for download
        this.props.onImportCompleted();
        this.props.getImportLogs(this.state.importID).then((logs) => {
          this.setState({ logs: logs });
          clearInterval(this.timer);
          this.timer = null;
        });
      } else {
        this.setState({ importStatus: "Processing file.." });
      }
    });
  }

  onFileSelect(acceptedFiles, rejectedFiles) {
    // console.log('acceptedFiles',acceptedFiles)
    // return
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].size / 1024 > 6000) {
        alert("File size should not be more than 6 MB");
        return;
      }
      let ext = acceptedFiles[0].name.substring(
        acceptedFiles[0].name.length - 3,
        acceptedFiles[0].name.length
      );
      if (ext !== "csv") {
        alert("Invalid file format");
        return;
      }
      this.setState({ importFile: acceptedFiles[0] });
      const reader = new FileReader();
      reader.readAsText(this.state.importFile);
      reader.onload = this.fileReader;
    } else {
      alert("No file selected");
    }
  }

  fileReader(e) {
    //console.log('fileReader')
    let rows = e.target.result.split("\r");
    //  console.log('fileReader rows', rows)
    this.setState({ rows: rows });
  }

  renderPincodeImportOptions() {
    return (
      <React.Fragment>
        {!this.state.loading && !this.state.completed && (
          <React.Fragment>
            <div className="info">
              <div className="info-header">Info</div>
              <div className="info-text">
                - Maximum of {this.state.maxRecords} rows allowed per file
                upload.
              </div>
              <div className="info-text">
                - Click here to download import template.{" "}
                <a
                  href={
                    "https://backend-o3dyulj4pa-el.a.run.app/v1/importExcelFile"
                  }
                >
                  Import Template
                </a>
              </div>
            </div>

            <div>
              {!this.state.importFile && (
                <Dropzone onDrop={(files) => this.onFileSelect(files)}>
                  <div>
                    Drop csv file you would like to upload or click to select
                    files to upload.
                  </div>
                </Dropzone>
              )}

              {this.state.importFile && (
                <div className="form-group">
                  <ul className="mailbox-attachments clearfix">
                    <li>
                      <span className="mailbox-attachment-icon has-img"></span>
                      <div className="mailbox-attachment-info">
                        <span className="mailbox-attachment-name">
                          {this.state.importFile.size.length > 20
                            ? this.state.importFile.size.substr(0, 20) + "..."
                            : this.state.importFile.name}
                        </span>
                        <span className="mailbox-attachment-size">
                          {Math.round(this.state.importFile.size / 1024)} KB
                          <a
                            onClick={this.onFileRemove}
                            className="btn btn-default btn-xs pull-right"
                          >
                            <i className="fa fa-trash"></i>
                          </a>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div>
              <div className="form-group">
                <label>Store:</label>
                <select
                  className="form-control"
                  value={this.state.selectedStoreID}
                  onChange={(e) => {
                    this.setState({
                      selectedStoreID: e.target.value,
                      selectedStoreName:
                        e.target.options[e.target.selectedIndex].text,
                    });
                  }}
                >
                  <option value="">All</option>
                  {this.state.stores
                    .filter((e) => !e.IsDeleted)
                    .map((store) => (
                      <option value={store.key}>
                        {store.StoreCode} {store.StoreName}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="import-header">
          <div>{"Imort Product (s)"}</div>
        </div>
        <div className="import-content">
          {this.renderPincodeImportOptions()}

          {this.state.loading && !this.state.completed && (
            <React.Fragment>
              <div>
                <h3>{this.state.importStatus}</h3>
              </div>
              {this.state.loading && (
                <div>
                  <Spinner hideOverlay={true} text="Importing data ..." />
                </div>
              )}
              <div>
                Import processing is under process. You can choose to close this
                window. Import completion status can be viewed in Import Log.
              </div>
            </React.Fragment>
          )}

          {this.state.completed && (
            <div>
              <h3>Import completed</h3>
              <button
                className="btn btn-default btn-flat btn-md pull-left"
                onClick={this.onExportClick}
              >
                Download import processing file{" "}
                <i className="glyphicon glyphicon-download-alt "></i>
              </button>
            </div>
          )}
        </div>

        {!this.state.loading && !this.state.completed && (
          <div className="import-footer">
            <div className="left">
              <Button
                type="secondary"
                text="Cancel"
                onClick={this.props.onClose}
              />
            </div>
            <div className="right">
              <Button
                type="primary"
                text="Upload"
                onClick={this.onUploadClick}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ImportProduct;
