import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import ReactHtmlParser from "react-html-parser";
import Input from "../2/Controls/Input";
import "./InvoiceDetail.css";

class TransactionReport extends Component {
  constructor() {
    super();
    this.state = {
      invoices: [],
      stores: [],
      storeList: [],
      searchText: "",
      searchTransactionNo: "",
      searchTransactiontype: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      searchCustomerDetail: "",
      searchCashRegister: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      paginationStatus: "No records to display",
      exportCalled: false,
      loading: true,
      customerCustomField1DisplayName: "CustomerCustomField1",
      customerCustomField2DisplayName: "CustomerCustomField2",
      customerCustomField3DisplayName: "CustomerCustomField3",
      customerCustomField4DisplayName: "CustomerCustomField4",
      customerCustomField5DisplayName: "CustomerCustomField5",
      customerCustomField6DisplayName: "CustomerCustomField6",
    };
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.loadTransactionData = this.loadTransactionData.bind(this);
  }

  componentDidMount() {
    // this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });

    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Customer") {
        let gstinCustomFieldNo = 0;
        if (customField.CustomField1Name.trim().toLowerCase() === "gstin") {
          gstinCustomFieldNo = 1;
        } else if (
          customField.CustomField2Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 2;
        } else if (
          customField.CustomField3Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 3;
        } else if (
          customField.CustomField4Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 4;
        } else if (
          customField.CustomField5Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 5;
        } else if (
          customField.CustomField6Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 6;
        }

        let customerCustomField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "CustomerCustomField1"
            : customField.CustomField1Name.trim();
        let customerCustomField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "CustomerCustomField2"
            : customField.CustomField2Name.trim();
        let customerCustomField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "CustomerCustomField3"
            : customField.CustomField3Name.trim();
        let customerCustomField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "CustomerCustomField4"
            : customField.CustomField4Name.trim();
        let customerCustomField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "CustomerCustomField5"
            : customField.CustomField5Name.trim();
        let customerCustomField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "CustomerCustomField6"
            : customField.CustomField6Name.trim();
        this.setState({
          customerCustomField1DisplayName: customerCustomField1DisplayName,
          customerCustomField2DisplayName: customerCustomField2DisplayName,
          customerCustomField3DisplayName: customerCustomField3DisplayName,
          customerCustomField4DisplayName: customerCustomField4DisplayName,
          customerCustomField5DisplayName: customerCustomField5DisplayName,
          customerCustomField6DisplayName: customerCustomField6DisplayName,
          gstinCustomFieldNo: gstinCustomFieldNo,
        });
      }
    });

    this.renderData(
      storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionNo,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchCashRegister
    );
  }

  getGSTINCustomField() {
    let gstinCustomFieldNo = 0;
    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Customer") {
        if (customField.CustomField1Name.trim().toLowerCase() === "gstin") {
          gstinCustomFieldNo = 1;
        } else if (
          customField.CustomField2Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 2;
        } else if (
          customField.CustomField3Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 3;
        } else if (
          customField.CustomField4Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 4;
        } else if (
          customField.CustomField5Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 5;
        } else if (
          customField.CustomField6Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 6;
        }
        this.setState({ gstinCustomFieldNo: gstinCustomFieldNo });
      }
    });
    return gstinCustomFieldNo;
  }

  async renderData(
    storeList,
    from,
    size,
    searchTransactionNo,
    searchTransactiontype,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCustomerDetail,
    searchCashRegister
  ) {
    this.setState({ loading: true });

    let invoices = await this.loadTransactionData(
      storeList,
      from,
      size,
      searchTransactionNo,
      searchTransactiontype,
      searchTransactionFromDate,
      searchTransactionToDate,
      searchCustomerDetail,
      searchCashRegister
    );

    var col = [];
    for (var i = 0; i < invoices.length; i++) {
      for (var key in invoices[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }
    // console.log("col", col);
    var result = "<table class='table table-bordered'><thead><tr>";
    for (var i = 0; i < col.length; i++) {
      result += "<th>";
      result += col[i];
      result += "</th>";
    }
    result += "</tr></thead>";

    // ADD JSON DATA TO THE TABLE AS ROWS.
    result += "<tbody>";
    for (var i = 0; i < invoices.length; i++) {
      result += "<tr>";
      for (var j = 0; j < col.length; j++) {
        result += "<td>";
        result += invoices[i][col[j]] === undefined ? 0 : invoices[i][col[j]];
        result += "</td>";
      }
      result += "</tr>";
    }
    result += "</tbody>";
    result += "</table>";
    this.setState({ invoices: invoices, finalTable: result, loading: false });
  }

  async loadTransactionData(
    storeList,
    from,
    size,
    searchTransactionNo,
    searchTransactiontype,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCustomerDetail,
    searchCashRegister
  ) {
    this.setState({ loading: true });
    let gstinCustomFieldNo = this.getGSTINCustomField();
    let result = await this.props.getBillWiseDetailReport(
      this.props.user.user.RegistrationID,
      storeList,
      from,
      size,
      searchTransactionNo,
      searchTransactiontype,
      searchTransactionFromDate,
      searchTransactionToDate,
      searchCustomerDetail,
      searchCashRegister
    );

    result.searchResult.map((l) => {
      if (l.hasOwnProperty("ReceivedPaymentTransactions")) {
        let receivedPayment = 0;
        for (let i = 0; i < l.ReceivedPaymentTransactions.length; i++) {
          let r = l.ReceivedPaymentTransactions[i];
          if (r.hasOwnProperty("IsConvert") && r.IsConvert) {
          } else {
            receivedPayment = receivedPayment + Number(r.ReceiveAmount);
          }
        }
        if (receivedPayment > 0) {
          l.BillPaidAmount = l.BillPaidAmount + receivedPayment;
          l.BalanceAmount = l.BalanceAmount - receivedPayment;
        }
      }
      l.Selected = false;
    });

    // console.log("result.searchResult", result.searchResult);
    let invoices = [];
    for (let i = 0; i < result.searchResult.length; i++) {
      const data = result.searchResult[i];
      let invoice = {
        StoreName: data.Store.StoreCode + data.Store.StoreName,
        CashRegister:
          data.CashRegister.CashRegisterCode +
          " " +
          data.CashRegister.CashRegisterName,
      };

      invoice["TransactionNo"] = data.TransactionNo;
      invoice["TransactionType"] = data.TransactionType.TransactionType;
      invoice["TransactionDate"] = new Date(data.TransactionDate)
        .toISOString()
        .split("T")[0];
      invoice.Cancelled = "No";
      if (data.CancellationReason) {
        invoice.Cancelled = "Yes";
      }
      if (this.props.registration.registration.EnableONDC) {
        invoice["OnlineOrderNo"] = data.ExternalTransactionNo;
      }

      invoice["CustomerCode"] = data.Customer ? data.Customer.CustomerCode : "";
      invoice["CustomerName"] = data.Customer
        ? data.Customer.FirstName + " " + data.Customer.LastName
        : "";
      invoice["CustomerMobileNo"] =
        data.Customer && !this.props.registration.registration.EnableONDC
          ? data.Customer.PhoneNo
          : "";
      invoice["CustomerEmailID"] = data.Customer ? data.Customer.EmailID : "";
      invoice["CustomerLineAddressType"] = data.DeliveryCustomerAddress
        ? data.DeliveryCustomerAddress.AddressType
        : "";
      invoice["CustomerLineAddress1"] = data.DeliveryCustomerAddress
        ? data.DeliveryCustomerAddress.Address1
        : "";
      invoice["CustomerLineAddress2"] = data.DeliveryCustomerAddress
        ? data.DeliveryCustomerAddress.Address2
        : "";
      invoice["CustomerAddressCity"] = data.DeliveryCustomerAddress
        ? data.DeliveryCustomerAddress.City
        : "";
      invoice["CustomerAddressState"] = data.DeliveryCustomerAddress
        ? data.DeliveryCustomerAddress.State
        : "";
      invoice["CustomerAddressPostalCode"] = data.DeliveryCustomerAddress
        ? data.DeliveryCustomerAddress.PostalCode
        : "";
      invoice["CustomerAddressLatLon"] =
        data.DeliveryCustomerAddress && data.DeliveryCustomerAddress.Location
          ? data.DeliveryCustomerAddress.Location.lat +
            "," +
            data.DeliveryCustomerAddress.Location.lon
          : "";
      invoice["CustomerGSTIN"] = "";
      if (gstinCustomFieldNo === 1) {
        invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField1")
          ? data.CustomerCustomField1
          : "";
      } else if (gstinCustomFieldNo === 2) {
        invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField2")
          ? data.CustomerCustomField2
          : "";
      } else if (gstinCustomFieldNo === 3) {
        invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField3")
          ? data.CustomerCustomField3
          : "";
      } else if (gstinCustomFieldNo === 4) {
        invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField4")
          ? data.CustomerCustomField4
          : "";
      } else if (gstinCustomFieldNo === 5) {
        invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField5")
          ? data.CustomerCustomField5
          : "";
      } else if (gstinCustomFieldNo === 6) {
        invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField6")
          ? data.CustomerCustomField6
          : "";
      }
      invoice["BillQty"] = data.BillQty;
      invoice["BillDiscount"] = data.BillDiscount;
      invoice["BillSubTotal"] = data.CustomerCode;
      invoice["BillTaxAmount"] = data.BillTaxAmount;
      invoice["BillAmount"] = data.BillAmount;
      invoice["BillPaidAmount"] = data.BillPaidAmount;
      invoice["BalanceAmount"] = data.BalanceAmount;
      if (this.props.registration.registration.EnablePosBillingForPincode) {
        invoice["DeliveryStatus"] = data.DeliveryStatus;
        invoice["TransactionStatus"] = data.TransactionStatus;
      }

      this.props.config.PaymentTypes.filter(
        (f) => f.ShowInSales && !f.IsDeleted
      ).map((m) => {
        invoice[m.PaymentType] = 0;
      });

      data.Payments.map((p) => {
        invoice[p.PaymentMode] = p.Amount;
      });
      invoices.push(invoice);
    }
    this.setState({
      totalItemsCount: result.totalItemsCount,
      loading: false,
    });

    return invoices;
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      storeList: storeList,
      storeID: this.props.user.store.key,
      recordFrom: 0,
      searchTransactionNo: "",
      searchTransactiontype: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      searchCustomerDetail: "",
      searchCashRegister: "",
    });

    this.renderData(
      storeList,
      0,
      this.state.itemsCountPerPage,
      "",
      "",
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0],
      "",
      ""
    );
  }

  async onExportClick() {
    let invoices = await this.loadTransactionData(
      this.state.storeList,
      0,
      10000,
      this.state.searchTransactionNo,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchCashRegister
    );

    const csvData = constatnts.objToCSV(invoices);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "TransactionReport.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({ searchFrom: 0, loading: true });
    this.renderData(
      this.state.storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionNo,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      "",
      ""
    );
  }

  onStoreChange(value) {
    let storeList = [];
    //console.log("key", e.target.value);
    if (value != "") {
      storeList.push({ key: value });
    } else {
      for (let i = 0; i < this.state.stores.length; i++) {
        const store = this.state.stores[i];
        storeList.push({ key: store.key });
      }
    }
    //console.log("storeList", JSON.stringify(storeList));
    this.setState({ storeList: storeList, storeID: value });
  }

  handlePageChange(pageNumber) {
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.renderData(
      this.state.storeList,
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchTransactionNo,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      "",
      ""
    );
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    this.setState({ paginationStatus: paginationStatus });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        Transaction Report
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <Input
                        type="select"
                        label="Store"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        <option value="">All Stores</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </Input>
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="date"
                        label="Transaction From"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionFromDate: value,
                          })
                        }
                        value={this.state.searchTransactionFromDate}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="date"
                        label="Transaction To"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionToDate: value,
                          })
                        }
                        value={this.state.searchTransactionToDate}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="select"
                        label="Transaction Type"
                        value={this.state.searchTransactiontype}
                        onChange={(value) =>
                          this.setState({
                            searchTransactiontype: value,
                          })
                        }
                      >
                        <option value="">All</option>
                        {this.props.config.TransactionTypes.filter(
                          (e) => e.Module === "sales" && !e.IsDeleted
                        ).map((e) => (
                          <option value={e.key}>{e.TransactionType}</option>
                        ))}
                      </Input>
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="text"
                        label="Transaction No"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionNo: value,
                          })
                        }
                        value={this.state.searchTransactionNo}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="text"
                        label="Customer Details"
                        onChange={(value) =>
                          this.setState({
                            searchCustomerDetail: value,
                          })
                        }
                        value={this.state.searchCustomerDetail}
                        placeholder="Customer Name, Code, Phone Number, Email"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      {ReactHtmlParser(this.state.finalTable)}
                    </div>
                  )}
                  {this.state.totalItemsCount > 0 && (
                    <div className="col-md-12">
                      <div className="pull-left" style={{ marginTop: "25px" }}>
                        {this.state.paginationStatus}
                      </div>
                      <div className="pull-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={2}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(TransactionReport);
