import React from "react";
import { withRouter } from "react-router-dom";
import firebase, { database, auth } from "../../firebase";
import CSVReader from "react-csv-reader";
import * as constatnts from "../../Constatnts";
import Input from "../2/Controls/Input";
import axios from "axios";

// import { user } from "firebase-functions/v1/auth";

class OnBoarding extends React.Component {
  constructor() {
    super();
    this.state = {
      registrationID: "",
      productCount: 0,
      deletedCount: 0,
      processing: false,
      fromDate: new Date().toISOString().split("T")[0],
      toDate: new Date().toISOString().split("T")[0],
    };
    this.getCanceledShadowFaxOrder = this.getCanceledShadowFaxOrder.bind(this);
    this.getPowerUser = this.getPowerUser.bind(this);
    this.getSalesDetails = this.getSalesDetails.bind(this);
    this.generateTXTFile = this.generateTXTFile.bind(this);
    this.sleep = this.sleep.bind(this);
    this.onClick = this.onClick.bind(this);
    this.getTransaction = this.getTransaction.bind(this);
    this.getPrintTemplates = this.getPrintTemplates.bind(this);
    this.enbalePayBill = this.enbalePayBill.bind(this);
    this.getCatalogue = this.getCatalogue.bind(this);
    this.increaseBulkexpiryDate = this.increaseBulkexpiryDate.bind(this);
    // this.updateOMNITag = this.updateOMNITag.bind(this);
    this.identifySubCategoriesToBeDeleted = this.identifySubCategoriesToBeDeleted.bind(
      this
    );
    this.updatePrintTemplates = this.updatePrintTemplates.bind(this);
    this.migrateInvoices = this.migrateInvoices.bind(this);
    this.convertStoreSpecificPricesToNormal = this.convertStoreSpecificPricesToNormal.bind(
      this
    );
    this.updateProductcategoriesAndSubCatgorieNames = this.updateProductcategoriesAndSubCatgorieNames.bind(
      this
    );
    this.pushProductsToElastic = this.pushProductsToElastic.bind(this);
    this.migrateCustomers = this.migrateCustomers.bind(this);
    this.exportCatalogueFromRedux = this.exportCatalogueFromRedux.bind(this);
    this.migrateInStockQty = this.migrateInStockQty.bind(this);
    this.updateCategorySubCategory = this.updateCategorySubCategory.bind(this);
    this.getApsanData = this.getApsanData.bind(this);
    this.deleteProducts = this.deleteProducts.bind(this);
    this.deleteInStockQty = this.deleteInStockQty.bind(this);
    this.resetTransactionRunningNo = this.resetTransactionRunningNo.bind(this);
    this.pushInvoices = this.pushInvoices.bind(this);
    this.deleteChildOrphanVariants = this.deleteChildOrphanVariants.bind(this);
    this.correctCatalogue = this.correctCatalogue.bind(this);
    this.deleteCategories = this.deleteCategories.bind(this);
    this.getPinCodeOrders = this.getPinCodeOrders.bind(this);
    this.getSearchData = this.getSearchData.bind(this);
    this.updateSKU = this.updateSKU.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.donwloadProducts = this.donwloadProducts.bind(this);
    this.processEnterpriseRegistration = this.processEnterpriseRegistration.bind(
      this
    );
    this.onUpdatePincodeHashKey = this.onUpdatePincodeHashKey.bind(this);
    this.disableCustomerPickUpForAllStores = this.disableCustomerPickUpForAllStores.bind(
      this
    );
    this.processM10Catalog = this.processM10Catalog.bind(this);
    this.updateDeliveryCharges = this.updateDeliveryCharges.bind(this);
    this.enbalePincodeDelivery = this.enbalePincodeDelivery.bind(this);
    this.changeDeliveryType = this.changeDeliveryType.bind(this);
    this.lockUnlockStores = this.lockUnlockStores.bind(this);
    this.getDeliveredShadowFaxOrder = this.getDeliveredShadowFaxOrder.bind(
      this
    );
    this.onEnableDisableCustomerPickup = this.onEnableDisableCustomerPickup.bind(
      this
    );
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  async updateProducts() {
    let products = await database
      .collection("Products")
      .where("RegistrationID", "==", this.state.registrationID)
      .where("IsDeleted", "==", false)
      .get()
      .then(async (querySnapshot) => {
        let list = [];
        querySnapshot.forEach(async (doc) => {
          // if(doc.data().IsDeleted){
          let tmp = doc.data();
          tmp.key = doc.id;
          list.push(tmp);
          // }
        });
        return list;
      });
    console.log("products.length", products.length);

    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      if (product.hasOwnProperty("StoreSpecificPrices")) {
        product.StoreSpecificPrices.map((m) => {
          m.OMNIEnabled = true;
          m.OutOfStock = false;
        });
      }
      await database
        .collection("Products")
        .doc(products[i].key)
        .update({
          StoreSpecificPrices: product.StoreSpecificPrices,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        });
      console.log(i);
    }
  }

  getSalesDetails() {
    // "gte": "2022-06-13",
    // // "lte": "2022-06-20",
    // "gte": "2023-03-31",
    //                 "lte": "2023-04-24"
    let searchParameters = {
      size: 10000,
      query: {
        bool: {
          must: [
            {
              match: {
                "Store.key": this.props.user.store.key, //"fJCLPDS2qeJ0QBjBGWwF"
              },
            },
            {
              range: {
                TransactionDate: {
                  gte: "2023-06-02",
                  lte: "2023-06-25",
                  format: "yyyy-MM-dd",
                },
              },
            },
          ],
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "asc",
          },
        },
      ],
    };

    console.log("Parameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let invoice = { key: data._id, ...data._source };
        invoices.push(invoice);
      });
      console.log("invoices", invoices);
      return invoices;
    });
  }

  async generateTXTFile() {
    let lineBreak = "\r\n";
    // let tenanantID = "PRDLEAS00010123";//PRDLEAS00010122";//soultree
    let tenanantID = "PFCLEAS00020123"; //Nail One
    let cashRegisterCode = "01";
    let lastReceiptNo = ""; //this.state.lastReceiptNo;
    let tranFileNo = 184; //153;//101//this.state.tranFileNo;// "1"
    let date = ""; //this.state.generateTXTFileDate;// "20220305" ;//(new Date()).getFullYear() + "" 20220217
    let hrs = "11:30:00";
    let userID = "MANAGER"; //this.state.generateTXTFileUserID;//"TESTUSER"
    let saleDate = ""; //date;

    let invoices = await this.getSalesDetails();
    // return;
    let previousDate = "";
    let str = "";
    for (let i = 0; i < invoices.length; i++) {
      let invoice = invoices[i];
      let year = new Date(invoice.TransactionDate).getFullYear();
      let month = new Date(invoice.TransactionDate).getMonth() + 1;
      if (month.toString().length === 1) {
        month = "0" + month;
      }
      let day = new Date(invoice.TransactionDate).getDate();
      if (day.toString().length === 1) {
        day = "0" + day;
      }
      date = year + "" + month + "" + day;
      saleDate = date;
      if (date !== previousDate) {
        if (previousDate !== "") {
          lastReceiptNo = invoices[i - 1].TransactionNo;
          console.log("save file");
          str =
            str +
            "1|CLOSED|" +
            tenanantID +
            "|" +
            cashRegisterCode +
            "|" +
            lastReceiptNo +
            "|" +
            tranFileNo +
            "|" +
            previousDate +
            "|" +
            hrs +
            "|" +
            userID +
            "|" +
            previousDate +
            lineBreak;
          console.log(str);
          let fileName =
            "t" +
            tenanantID +
            "_" +
            cashRegisterCode +
            "_" +
            tranFileNo +
            "_" +
            previousDate +
            "1130.txt";
          console.log("FileName", fileName);

          const csvBlob = new Blob([str], { type: "text/plain" });
          const url = window.URL.createObjectURL(csvBlob);
          const a = document.createElement("a");
          a.setAttribute("hidden", "");
          a.setAttribute("href", url);
          a.setAttribute("download", fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          this.sleep(2000);
          // console.log('set last transaction no', lastReceiptNo);
          tranFileNo += 1;
          console.log("increment tranFileNo", tranFileNo);
        }
        console.log("start new file");
        str =
          "1|OPENED|" +
          tenanantID +
          "|" +
          cashRegisterCode +
          "|" +
          lastReceiptNo +
          "|" +
          tranFileNo +
          "|" +
          date +
          "|" +
          hrs +
          "|" +
          userID +
          "|" +
          saleDate +
          lineBreak;
        previousDate = date;
      }

      let transacitonNo = invoice.TransactionNo;
      let shiftNo = "1";
      let manualReceipt = "";
      let refundReceipt = "";
      let reasonCode = "";
      let salesManCode = "";
      let tableNo = ""; //only f&b
      let custCount = ""; //only f&b
      let trainingMode = "N";
      let tranStatus = "SALE";
      str =
        str +
        "101|" +
        transacitonNo +
        "|" +
        shiftNo +
        "|" +
        date +
        "|" +
        hrs +
        "|" +
        userID +
        "|" +
        manualReceipt +
        "|" +
        refundReceipt +
        "|" +
        reasonCode +
        "|" +
        salesManCode +
        "|" +
        tableNo +
        "|" +
        custCount +
        "|" +
        trainingMode +
        "|" +
        tranStatus +
        lineBreak;
      invoice.LineItems.map((l, index) => {
        // console.log(l);
        let itemCode = l.Product.SKU.substring(0, 31);
        let itemQty = l.Qty;
        let retailPrice = l.RetailPrice;
        let itemFlag = ""; //null
        let taxCode = l.TaxGroup === "" ? "GST 0%" : l.TaxGroup;
        let discountCode = "";
        let disocuntAmt = l.DiscountAmount;
        let itemDept = invoice.Store.StoreCode;
        let itemCateogry = l.Product.Category.substring(0, 8);
        let labelKeys = "";
        let itemComm = "N";
        let itemAmount = l.Amount;
        let discountBy = l.DiscountPer;
        let discountSign = "%";
        let itemTax = l.TotalTax;
        let pluCode = "";
        str =
          str +
          "111|" +
          itemCode +
          "|" +
          itemQty +
          "|" +
          retailPrice +
          "|" +
          retailPrice +
          "|" +
          itemFlag +
          "|" +
          taxCode +
          "|" +
          discountCode +
          "|" +
          disocuntAmt +
          "|" +
          itemDept +
          "|" +
          itemCateogry +
          "|" +
          labelKeys +
          "|" +
          itemComm +
          "|" +
          itemAmount +
          "|" +
          discountBy +
          "|" +
          discountSign +
          "|" +
          itemTax +
          "|" +
          pluCode +
          lineBreak;

        if (l.TaxGroup === "") {
          console.log("no tax group", l);
        }
      });
      //amount
      let cess = "";
      let charges = "";
      let taxType = "I"; //inclusive I/E
      let exemptGST = "N";
      let discountCode = "";
      let otherChrg = "";
      let dicosuntPer = "";
      let roundingAmt = invoice.RoundOff;
      str =
        str +
        "121|" +
        invoice.BillSubTotal +
        "|" +
        invoice.BillDiscount +
        "|" +
        cess +
        "|" +
        charges +
        "|" +
        invoice.BillTaxAmount +
        "|" +
        taxType +
        "|" +
        exemptGST +
        "|" +
        discountCode +
        "|" +
        otherChrg +
        "|" +
        dicosuntPer +
        "|" +
        roundingAmt +
        lineBreak;
      ///payment mode
      invoice.Payments.map((m, index) => {
        str =
          str +
          "131|T|" +
          m.PaymentMode.substring(0, 8) +
          "|INR|1|" +
          m.Amount +
          "|||" +
          m.Amount +
          lineBreak;
      });
    }
    if (str !== "") {
      lastReceiptNo = invoices[invoices.length - 1].TransactionNo;
      console.log("save file");
      str =
        str +
        "1|CLOSED|" +
        tenanantID +
        "|" +
        cashRegisterCode +
        "|" +
        lastReceiptNo +
        "|" +
        tranFileNo +
        "|" +
        date +
        "|" +
        hrs +
        "|" +
        userID +
        "|" +
        saleDate +
        lineBreak;
      console.log(str);
      // console.log('FileName', 't' + tenanantID + "_" + cashRegisterCode + "_" + tranFileNo + "_" + date + "1130.txt")
      let fileName =
        "t" +
        tenanantID +
        "_" +
        cashRegisterCode +
        "_" +
        tranFileNo +
        "_" +
        date +
        "1130.txt";
      console.log("FileName", fileName);

      const csvBlob = new Blob([str], { type: "text/plain" });
      const url = window.URL.createObjectURL(csvBlob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  // async generateTXTFile1(){
  //     let products = this.props.config.Products
  //     for(let i=0;i<products.length;i++){
  //         await database.collection("Products").doc(products[i].key).update({AccessibleToSelectedStores:false});
  //         console.log(products[i].Name)
  //     }
  // }

  async deleteSalesTransaction() {
    // xaYetSMlcC9AjlLo7hXN storeid
    // registration id PakV0L4Z5SRD7VqC5hSt
    console.log("deleteSalesTransaction");
    // let newStartDate = new Date("2023-06-17");
    // let endDate = new Date("2023-06-18");
    // let sales = await database.collection("Sales").where("TransactionDate", "<", "").where("RegistrationID","==",this.props.user.registration.registration.key).get().then((querySnapshot) => {
    let sales = await database
      .collection("Sales")
      .where("TransactionDate", "<", "")
      .where(
        "RegistrationID",
        "==",
        this.props.user.registration.registration.key
      )
      .get()
      .then((querySnapshot) => {
        const sales = [];
        querySnapshot.forEach((doc) => {
          // if(doc.data().Store.key ==="xaYetSMlcC9AjlLo7hXN"){
          sales.push({
            key: doc.id,
            ...doc.data(),
          });
          // }
        });
        return sales;
      });
    console.log("sales", sales);
    // for(let i=0;i<sales.length;i++){
    //     await database.collection("Sales").doc(sales[i].key).update({BackEndUpdate: true, Version:"25-Jun-23"});
    //     console.log(i)
    // }
  }

  async getTransaction() {
    // xaYetSMlcC9AjlLo7hXN storeid
    // registration id PakV0L4Z5SRD7VqC5hSt
    console.log("getTransaction");
    let newStartDate = new Date("2023-07-01");
    let endDate = new Date("2023-08-01");
    // where("RegistrationID","==","PakV0L4Z5SRD7VqC5hSt")
    let sales = await database
      .collection("Sales")
      .where("RegistrationID", "==", "7xSeSzLv5xd9ihTyN24s")
      .where("TransactionDate", ">=", newStartDate)
      .where("TransactionDate", "<=", endDate)
      .get()
      .then((querySnapshot) => {
        const sales = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().Store.key === "0XiKdtoBqk5F4uvcjKsA") {
            sales.push({
              key: doc.id,
              ...doc.data(),
            });
          }
        });
        return sales;
      });
    console.log("sales", sales);
    for (let i = 0; i < sales.length; i++) {
      // sales[i].RegistrationID =sales[i].RegistrationID + "_deleted";
      sales[i].BackEndUpdate = true;
      // sales[i].Store.key = sales[i].Store.key + "_deleted";
      await database
        .collection("Sales")
        .doc(sales[i].key)
        .update(sales[i]);
      console.log(i);
    }
  }

  async pushInvoices() {
    console.log("pushInvoices started");
    console.log("RegistrationID", this.state.pushToElasticRegistrationID);
    console.log("From Date", this.state.pushToElasticFromDate);
    console.log("To Date", this.state.pushToElasticToDate);
    // .where("CashRegister.key", "==", "vDodRhjYQmQikOwv8vTH")
    // .where("TransactionDate", ">=", new Date("2024-01-02"))
    // .where("TransactionDate", "<=", new Date("2024-01-03"))
    // .limit(100)
    // .where("TransactionDate", ">=", new Date("2024-03-01"))
    // .where("TransactionDate", "<=", new Date("2024-03-25"))
    // let invoices = await database
    //   .collection("Sales")
    //   .where("RegistrationID", "==", "KPLVQpkYE34hsM9zhtjk")
    //   .get()
    //   .then((querySnapshot) => {
    //     // "RegistrationID","==",this.props.registration.registration.key
    //     // where("TransactionDate", ">=", new Date("2022-04-01")).where("TransactionDate", "<=", new Date("2022-04-30")).
    //     let invoices = [];
    //     let obj = null;
    //     querySnapshot.forEach((doc) => {
    //       // if(doc.data().Store.key ==="qwzrJfGGkxIjG1NI3USy"){
    //       obj = doc.data();
    //       obj.key = doc.id;
    //       invoices.push(obj);
    //       // }
    //     });
    //     return invoices;
    //   });
    // console.log("invoices", invoices);
    // let ctr = 0;
    // invoices.length

    let searchParameters = {
      from: 0,
      size: 10000,
      track_total_hits: true,
      _source: ["TransactionNo"],
      query: {
        bool: {
          must: [
            {
              match: { RegistrationID: this.state.pushToElasticRegistrationID },
            },
            {
              range: {
                TransactionDate: {
                  lte: this.state.pushToElasticToDate,
                  gte: this.state.pushToElasticFromDate,
                  format: "yyyy-MM-dd",
                },
              },
            },
          ],
        },
      },
      sort: [{ TransactionDate: { order: "desc" } }],
    };
    console.log("Parameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getSales");
    let result = await addMessage({ text: searchParameters });

    const invoices = [];
    let sanitizedMessage = result.data.text;
    console.log("invoice count", sanitizedMessage.hits.total.value);
    sanitizedMessage.hits.hits.map((data, index) => {
      let invoice = { key: data._id, ...data._source };
      invoices.push(invoice);
    });

    for (let index = 0; index < invoices.length; index++) {
      const obj = invoices[index];
      console.log("obj", obj);
      // ctr += 1;
      await database
        .collection("Sales")
        .doc(obj.key)
        .update({ BackEndUpdate: true, Version: new Date().toISOString() });
      console.log("update ctr", index);
    }
  }

  async increaseBulkexpiryDate() {
    let stores = await database
      .collection("Stores")
      .where("RegistrationID", "==", "3IG5IC8dghrAKTTBJ4zD")
      .get()
      .then((querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return list;
      });
    console.log("stores", stores);

    let ctr = 0;
    for (let index = 0; index < stores.length; index++) {
      ctr += 1;
      await database
        .collection("Stores")
        .doc(stores[index].key)
        .update({
          ExpiryDate: "2024-01-01",
          // , StoreNotFound: true, DealValue: 0, NoOfTransactionalSMS: 0, NoOfPromotionalSMS: 0
        });
      console.log("Counter", ctr);
    }
  }

  async onClick() {
    let products = this.props.config.Products;
    let productCategories = this.props.config.ProductCategories;
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      let category = productCategories.filter(
        (f) => f.key === product.CategoryID
      )[0];
      if (product.Category.trim() !== category.Name.trim()) {
        console.log("ctr", i);
        console.log("product", product);
        product.Category = category.Name;
        await this.props.updateProduct(product, product.key);
      }
    }
    // let invoices = await database.collection('Sales').where("RegistrationID","==","aevbWxVLO6740OK12umP").where("TransactionDate", ">=", new Date("2022-07-25")).where("TransactionDate", "<=", new Date("2022-07-26")).get().then((querySnapshot) => {
    //     let list = []
    //     querySnapshot.forEach(async (doc) => {
    //         let doc1 = doc.data();
    //         if(doc1.Store.key ==="k49gBmZo2pQfEK1WXAPl"){
    //             doc1.key = doc.id;
    //             console.log('doc.id',doc.id)
    //             await database.collection("Sales").doc(doc.id).update({ BackEndUpdate:true})
    //             list.push(doc1)
    //         }
    //     })
    //     return list;
    // })
    // console.log('invoices',invoices);
    // for(let i=0;i< deletedList.length;i++){
    //             await database.collection("Products").doc(deletedList[i].key).update({ RegistrationID: deletedList[i].RegistrationID + '_deleted'})
    //             console.log('deleted', deletedList[i]);
    //         }

    // let products = this.props.config.Products.filter(f=> f.InventoryType && f.InventoryType.toLowerCase() === "variants");
    // console.log('products', products.length);

    // let orphans = [];
    // for(let i=0;i< products.length;i++){
    //     await database.collection("Products").where("ParentProductID", "==", products[i].key).get().then(async (querySnapshot) => {
    //             querySnapshot.forEach(async (doc) => {
    //                 if(products[i].Variants.filter(f=> f.ProductID ===doc.id ).length === 0 ){
    //                     // console.log('doc', doc.data());
    //                     // console.log('doc.key', doc.id);
    //                     // console.log('products[i]',products[i].SKU)
    //                     // if(!doc.data().IsDeleted){
    //                         let orphan  = doc.data();
    //                         orphan.key = doc.id;
    //                         orphans.push(orphan);
    //                         // await database.collection("Products").doc(doc.id).update({ IsDeleted: true})

    //                     // }

    //                     // console.log('SKU', doc.data().SKU);
    //                 }
    //             })
    //     })
    // }
    // console.log('orphans', orphans)
    // for(let l =0; l< orphans.length;l++){
    //     await database.collection("InventoryInStock").where("ProductID", "==", orphans[l].key).get().then(async (querySnapshot) => {
    //         querySnapshot.forEach(async (instock) => {
    //             console.log('instock', instock.data());
    //             await database.collection("InventoryInStock").doc(instock.id).update({ StoreID: instock.data().StoreID + '_deleted', LocationID: instock.data().LocationID + '_deleted'})
    //         })
    //     })
    // }

    // for(let l =0; l< orphans.length;l++){
    //     await database.collection("Products").doc(orphans[l].key).update({ IsDeleted:true})

    // }
    // let invoices = await database.collection('Sales').where("RegistrationID","==","7xSeSzLv5xd9ihTyN24s").where("TransactionDate", ">=", new Date("2022-03-01")).where("TransactionDate", "<=", new Date("2022-03-31")).get().then((querySnapshot) => {

    // this.props.config.Products.map(m=>{
    //     if(m.AccessibleToSelectedStores){
    //         console.log('AccessibleToSelectedStores', m)
    //     }
    //     if(m.StoreSpecificPricing){
    //         console.log('StoreSpecificPricing', m.StoreSpecificPricing)
    //     }
    // })

    // this.getTransaction()
    // let ledger = await
    // database.collection("InventoryInStock").where("StoreID", "==", this.props.user.store.key).get().then(async (querySnapshot) => {
    //     querySnapshot.forEach(async (doc) => {
    //         if(this.props.config.Products.filter(f=> f.key === doc.data().ProductID).length===0){
    //             console.log('deleted product', doc.data())
    //             await database.collection("InventoryInStock").doc(doc.id).update({ StoreID: this.props.user.store.key + '_deleted' })

    //         }else
    //         {
    //             let product = this.props.config.Products.filter(f=> f.key === doc.data().ProductID)[0];
    //             if(product.ProductTypeID === 3){
    //                 console.log('service', product);
    //                 await database.collection("InventoryInStock").doc(doc.id).update({ StoreID: this.props.user.store.key + '_deleted' })

    //             }
    //         }
    //         // ledger = {
    //         //     key: doc.id
    //         // }
    //     })
    // })

    // let deletedList = [];
    // deletedList = await database.collection("Products").where("RegistrationID", "==", this.props.user.user.RegistrationID).get().then(async (querySnapshot) => {
    //     let list = [];
    //     querySnapshot.forEach(async (doc) => {
    //         let product = doc.data();
    //         if(product.IsDeleted){
    //             product.key = doc.id;
    //             list.push(product);
    //         }
    //     })
    //     return list
    // })
    // console.log(deletedList)
    // for(let i=0;i< deletedList.length;i++){
    //     await database.collection("Products").doc(deletedList[i].key).update({ RegistrationID: deletedList[i].RegistrationID + '_deleted'})
    //     console.log('deleted', deletedList[i]);
    // }

    // let deletedList = [];
    // deletedList = await
    // database.collection("InventoryInStock").where("StoreID", "==", this.props.user.store.key).get().then(async (querySnapshot) => {
    //     let list = [];
    //     querySnapshot.forEach(async (doc) => {
    //         let instock = doc.data();
    //         if(this.props.config.Products.filter(f=> f.key ===instock.ProductID ).length === 0){
    //             instock.key = doc.id;
    //             await database.collection("InventoryInStock").doc(doc.id).update({ StoreID: this.props.user.store.key + '_deleted', LocationID: instock.LocationID  + '_deleted'})
    //             console.log('deleted instock', instock)
    //             list.push(instock);
    //         }
    //     })
    //     // return list
    // })
    // console.log('deletedList', deletedList)

    // let products = this.props.config.Products;
    // let categories = this.props.config.ProductCategories;
    // let subCategories = this.props.config.ProductSubCategories;
    // console.log('products', products.length);
    // console.log('categories', categories.length);
    // console.log('subCategories', subCategories.length);
    // for(let i=0;i<products.length;i++){
    //     console.log('ctr', i);
    //     await database.collection("Products").doc(products[i].key).update({ BackEndUpdate: true, Version:"1.0"})

    // if(categories.filter(f=> f.key === products[i].CategoryID && f.Name !==  products[i].Category).length > 1 ){
    //     console.log('category name not updated',products[i]);
    // }
    // if(subCategories.filter(f=> f.key === products[i].SubCategoryID && f.Name !==  products[i].SubCategory).length > 1 ){
    //     console.log('sub category name not updated',products[i]);
    // }
    // }
  }

  getPrintTemplates() {
    database
      .collection("PrintConfigs")
      .where("RegistrationID", "==", this.props.user.store.RegistrationID)
      .get()
      .then(async (querySnapshot) => {
        // database.collection("PrintConfigs").where("StoreID", "==", this.props.user.store.key).get().then(async (querySnapshot) => {

        let prints = [];
        querySnapshot.forEach(async (doc) => {
          let print = doc.data();
          if (print.PrintDisplayName === "Transfer Out A4") {
            // && new Date(doc.data().ActionOn.seconds *1000).toISOString().split("T")[0] !=="2022-02-11"
            // console.log('created on date',new Date(doc.data().ActionOn.seconds *1000));
            print.key = doc.id;
            // console.log('print',print);
            prints.push(print);
          }
          // if(this.props.config.Products.filter(f=> f.key ===instock.ProductID ).length === 0){
          //     instock.key = doc.id;
          //     await database.collection("InventoryInStock").doc(doc.id).update({ StoreID: this.props.user.store.key + '_deleted', LocationID: instock.LocationID  + '_deleted'})
          //     console.log('deleted instock', instock)
          //     list.push(instock);
          // }
        });
        console.log(prints);
        // return list
      });
  }

  getCatalogue() {
    const csvData = constatnts.objToCSV(this.props.config.Products);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Catalogue.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // async updateOMNITag(){
  //     this.props.config.ProductSubCategories.map(async productCategory=>{
  //         if(productCategory.ParentID!==""){
  //             let omniTag = '', category = '', subCategory = ''
  //             category = productCategory.Parent.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/[' ']/g,'_');
  //             subCategory = productCategory.Name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/[' ']/g,'_');
  //             omniTag = category + '_' + subCategory
  //             productCategory.OMNITag = omniTag;
  //             // console.log(JSON.stringify(productCategory));
  //             await this.props.saveProductCategory(productCategory.key, productCategory)
  //             console.log(productCategory)
  //         }

  //     })
  // }

  // async identifySubCategoriesToBeDeleted(){
  //     let ctr = 0;

  //     this.props.config.ProductSubCategories.map(async productCategory=>{
  //         if(productCategory.ParentID!=="" && !productCategory.IsDeleted){
  //             if(this.props.config.Products.filter(f=> f.SubCategoryID === productCategory.key).length===0){
  //                 console.log('to be deleted', productCategory);
  //                 ctr = ctr+1;
  //                 console.log('ctr',ctr);
  //                 await this.props.deleteProductCategory(productCategory.key);
  //             }
  //         }
  //     })
  //     console.log('final',ctr)
  // }

  async identifySubCategoriesToBeDeleted() {
    let ctr = 0;

    this.props.config.ProductCategories.map(async (productCategory) => {
      if (productCategory.ParentID === "" && !productCategory.IsDeleted) {
        if (
          this.props.config.Products.filter(
            (f) => f.CategoryID === productCategory.key
          ).length === 0
        ) {
          console.log("to be deleted", productCategory);
          ctr = ctr + 1;
          console.log("ctr", ctr);
          await this.props.deleteProductCategory(productCategory.key);
        }
      }
    });
    console.log("final", ctr);
  }

  async updatePrintTemplates() {
    let stores = await database
      .collection("Stores")
      .where("RegistrationID", "==", "Y1mBnL02vfcKtZKda3x7")
      .get()
      .then((querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return list;
      });
    console.log("stores", stores);

    let ctr = 0;
    let trip1 = null;
    let trip2 = null;
    let trip3 = null;

    if (
      this.props.config.PrintConfigs.filter(
        (f) =>
          f.TransactionTypeID === "omDapHNi0C1ziopLnds1" &&
          !f.hasOwnProperty("IsDeleted")
      ).length > 0
    ) {
      trip1 = this.props.config.PrintConfigs.filter(
        (f) =>
          f.TransactionTypeID === "omDapHNi0C1ziopLnds1" &&
          !f.hasOwnProperty("IsDeleted")
      )[0];
    }
    if (
      this.props.config.PrintConfigs.filter(
        (f) =>
          f.TransactionTypeID === "XFU75jXfjwFJL2ebTRyy" &&
          !f.hasOwnProperty("IsDeleted")
      ).length > 0
    ) {
      trip2 = this.props.config.PrintConfigs.filter(
        (f) =>
          f.TransactionTypeID === "XFU75jXfjwFJL2ebTRyy" &&
          !f.hasOwnProperty("IsDeleted")
      )[0];
    }
    if (
      this.props.config.PrintConfigs.filter(
        (f) =>
          f.TransactionTypeID === "xFLf1wdAfGtJzNTQOWJo" &&
          !f.hasOwnProperty("IsDeleted")
      ).length > 0
    ) {
      trip3 = this.props.config.PrintConfigs.filter(
        (f) =>
          f.TransactionTypeID === "xFLf1wdAfGtJzNTQOWJo" &&
          !f.hasOwnProperty("IsDeleted")
      )[0];
    }
    console.log("trip1", trip1);
    console.log("trip2", trip2);
    console.log("trip3", trip3);
    // return;
    // stores.length
    for (let index = 0; index < 10; index++) {
      ctr += 1;
      let store = stores[index];
      if (store.key !== "0D9BCU8gvqElwHavov85") {
        console.log("store found", store);
        let printConfigs = await database
          .collection("PrintConfigs")
          .where("StoreID", "==", store.key)
          .get()
          .then(async (querySnapshot) => {
            const list = [];
            querySnapshot.forEach((doc) => {
              list.push({
                key: doc.id,
                ...doc.data(),
              });
            });
            return list;
          });
        if (
          printConfigs.filter(
            (f) =>
              f.TransactionTypeID === "omDapHNi0C1ziopLnds1" &&
              !f.hasOwnProperty("IsDeleted")
          ).length > 0
        ) {
          // trip1= this.props.config.PrintConfigs.filter(f=> f.TransactionTypeID === "omDapHNi0C1ziopLnds1")[0]
          console.log("update printcofig for trip 1");
          // trip1.StoreID=store.key;
          let trip1toUpdate = printConfigs.filter(
            (f) =>
              f.TransactionTypeID === "omDapHNi0C1ziopLnds1" &&
              !f.hasOwnProperty("IsDeleted")
          )[0];
          trip1toUpdate.PrintTemplate = trip1.PrintTemplate;
          // console.log('trip1key',trip1key)
          await this.props.savePrintConfig(trip1toUpdate, trip1toUpdate.key);
        } else {
          console.log("new printcofig for trip 1");
          let trip1toUpdate = { ...trip1 }; //trip1.StoreID=store.key;
          trip1toUpdate.StoreID = store.key;
          console.log("trip1toUpdate", trip1toUpdate);
          await this.props.savePrintConfig(trip1toUpdate, "");
        }
        if (
          printConfigs.filter(
            (f) =>
              f.TransactionTypeID === "XFU75jXfjwFJL2ebTRyy" &&
              !f.hasOwnProperty("IsDeleted")
          ).length > 0
        ) {
          // trip2= this.props.config.PrintConfigs.filter(f=> f.TransactionTypeID === "XFU75jXfjwFJL2ebTRyy")[0]
          console.log("update printcofig for trip 2");
          // trip2.StoreID=store.key;
          let trip2Update = printConfigs.filter(
            (f) =>
              f.TransactionTypeID === "XFU75jXfjwFJL2ebTRyy" &&
              !f.hasOwnProperty("IsDeleted")
          )[0];
          // console.log('trip2key',trip2key)
          await this.props.savePrintConfig(trip2Update, trip2Update.key);
        } else {
          console.log("new printcofig for trip 2");
          let trip2Update = { ...trip2 }; //.StoreID=store.key;
          trip2Update.StoreID = store.key;
          await this.props.savePrintConfig(trip2Update, "");
        }
        if (
          printConfigs.filter(
            (f) =>
              f.TransactionTypeID === "xFLf1wdAfGtJzNTQOWJo" &&
              !f.hasOwnProperty("IsDeleted")
          ).length > 0
        ) {
          // trip3= this.props.config.PrintConfigs.filter(f=> f.TransactionTypeID === "xFLf1wdAfGtJzNTQOWJo")[0]
          console.log("update printcofig for trip 3");
          // trip3.StoreID=store.key;
          let trip3Update = printConfigs.filter(
            (f) =>
              f.TransactionTypeID === "xFLf1wdAfGtJzNTQOWJo" &&
              !f.hasOwnProperty("IsDeleted")
          )[0];
          // console.log('trip3key',trip3key)
          await this.props.savePrintConfig(trip3Update, trip3Update.key);
        } else {
          console.log("new printcofig for trip 3");
          // trip3.StoreID=store.key;
          let trip3Update = { ...trip3 };
          trip3Update.StoreID = store.key;
          await this.props.savePrintConfig(trip3Update, "");
        }
      } else {
        ///
        console.log("current logged in store");
      }
      // await database.collection("Stores").doc(stores[index].key).update({ ExpiryDate: "2023-01-01"
      // , StoreNotFound: true, DealValue: 0, NoOfTransactionalSMS: 0, NoOfPromotionalSMS: 0
    }
    // console.log("Counter", ctr);
  }

  async migrateInvoices() {
    //31

    let searchParameters = {
      from: 0,
      size: 1000,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: "ww9IM6wYveoT8qV3CoOL" } },
            {
              range: {
                TransactionDate: {
                  lte: "2023-12-31",
                  gte: "2020-01-01",
                  format: "yyyy-MM-dd",
                },
              },
            },
          ],
          must_not: [],
          should: [{ term: { "Store.key.keyword": "ZGfoPhb0yDc8dcjCDnFW" } }],
          filter: { bool: { must: [], should: [] } },
          minimum_should_match: 1,
        },
      },
      sort: [{ TransactionDate: { order: "desc" } }],
    };
    console.log("Parameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getSales");
    let invoices = await addMessage({ text: searchParameters }).then(function(
      result
    ) {
      const tmp = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let invoice = { key: data._id, ...data._source };
        invoice.TransactionDate = new Date(invoice.TransactionDate);
        invoice.ActionOn = new Date(invoice.ActionOn);
        tmp.push(invoice);
      });
      // console.log('invoices',invoices)
      return tmp;
    });

    console.log("invoices", invoices);
    let ctr = 0;
    for (let index = 0; index < invoices.length; index++) {
      const obj = invoices[index];
      ctr += 1;
      obj.RegistrationID = "ieTFutBU180FeuCsRXPw";
      obj.Store.key = "cjvQtTXIi7VELJdwISFb";
      obj.BackEndUpdate = true;
      await database
        .collection("Sales")
        .doc(obj.key)
        .update({ ...obj });
      console.log("update done ctr", ctr);
    }
  }

  async migrateCustomers() {
    let searchParameters = {
      from: 0,
      size: 1000,
      track_total_hits: true,
      query: {
        bool: {
          must: [{ match: { StoreID: "ZGfoPhb0yDc8dcjCDnFW" } }],
          must_not: [{ match: { IsDeleted: "true" } }],
          should: [],
          filter: { bool: { must: [], should: [] } },
        },
      },
      sort: [{ ActionOn: { order: "desc" } }],
    };

    console.log("Parameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    let customers = await addMessage({ text: searchParameters }).then(function(
      result
    ) {
      const tmp = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let customer = { key: data._id, ...data._source };
        // invoice.TransactionDate = new Date(invoice.TransactionDate);
        // invoice.ActionOn= new Date(invoice.ActionOn)
        tmp.push(customer);
      });
      // console.log('invoices',invoices)
      return tmp;
    });

    // customers.length
    console.log("customers", customers);

    // let ctr=0;
    for (let index = 0; index < customers.length; index++) {
      const obj = customers[index];
      await database
        .collection("Customers")
        .doc(obj.key)
        .update({
          RegistrationID: "ieTFutBU180FeuCsRXPw",
          StoreID: "cjvQtTXIi7VELJdwISFb",
        });
      console.log("customer done index", index);
    }
  }
  async convertStoreSpecificPricesToNormal() {
    console.log("start");
    let products = await database
      .collection("Products")
      .where("RegistrationID", "==", this.state.registrationID)
      .get()
      .then(async (querySnapshot) => {
        let list = [];
        querySnapshot.forEach(async (doc) => {
          // if (doc.data().IsDeleted) {
          let product = doc.data();
          product.key = doc.id;
          list.push(product);
          // }
        });
        return list;
      });
    console.log(products);
    // return;
    // let products = this.props.config.Products;
    let ctr = 0;
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      if (product.AccessibleToSelectedStores) {
        if (
          product.StoreSpecificPrices &&
          product.StoreSpecificPrices.length > 0
        ) {
          ctr = ctr + 1;
          console.log(ctr);

          product.RetailPrice = product.StoreSpecificPrices[0].RetailPrice;
          product.MRP = product.StoreSpecificPrices[0].MRP;
          // product.MSP = product.StoreSpecificPrices[0].MSP;
          product.SupplyPrice = product.StoreSpecificPrices[0].SupplyPrice;
          product.DiscountAmount =
            product.StoreSpecificPrices[0].DiscountAmount;
          product.DiscountPercentage =
            product.StoreSpecificPrices[0].DiscountPercentage;
          product.AccessibleToSelectedStores = false;
          console.log("AccessibleToSelectedStores", product);
          await database
            .collection("Products")
            .doc(products[i].key)
            .update({ ...product });
          // return;
        }
      }
      // console.log(product);
      // console.log(i)
      // await database.collection("Products").doc(products[i].key).update({Version:"1.1"});
      // return;
    }
    console.log("end");
  }

  async updateProductcategoriesAndSubCatgorieNames() {
    let products = this.props.config.Products;
    let ctr = 0;
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      let updateCategory = false;
      let updateSubCategory = false;
      let category = this.props.config.ProductCategories.filter(
        (f) => f.key === product.CategoryID
      );
      let subCategory = this.props.config.ProductSubCategories.filter(
        (f) => f.key === product.SubCategoryID
      );

      if (
        category &&
        category.length > 0 &&
        product.Category.trim() !== category[0].Name.trim()
      ) {
        updateCategory = true;
        product.Category = category[0].Name.trim();
      }
      if (
        subCategory &&
        subCategory.length > 0 &&
        product.SubCategory.trim() !== subCategory[0].Name.trim()
      ) {
        updateSubCategory = true;
        product.SubCategory = subCategory[0].Name.trim();
      }
      if (updateCategory || updateSubCategory) {
        ctr = ctr + 1;
        console.log(ctr);
        console.log("update category or sub cateogy text", product);
        console.log("updateCategory", updateCategory);
        console.log("updateSubCategory", updateSubCategory);
        await database
          .collection("Products")
          .doc(products[i].key)
          .update({ ...product });
      }
    }
  }

  async onFileLoaded(collection) {
    for (let i = 1; i < collection.length; i++) {
      let productID = collection[i][0];
      console.log("i", i);
      console.log("productID", productID);
      await database
        .collection("Products")
        .doc(productID)
        .update({
          PinCodeProductType: "Seller Code",
        });
    }
    // console.log("collection", collection);
    // let products = await database
    //   .collection("Products")
    //   .where("RegistrationID", "==", this.props.user.user.RegistrationID)
    //   .where("IsDeleted", "==", true)
    //   .get()
    //   .then(async (querySnapshot) => {
    //     let result = [];

    //     querySnapshot.forEach(async (doc) => {
    //       let obj = doc.data();
    //       obj.key = doc.id;
    //       result.push(obj);
    //     });
    //     return result;
    //   });
    // console.log("products", products);

    // let ctr = 1;
    // for(let i=1;i<collection.length;i++){
    //     let sku = collection[i][0];
    //     // console.log('sku',sku);
    //     if(products.filter(f=> f.SKU == sku ).length > 0){
    //         let product = products.filter(f=> f.SKU == sku )[0];
    //         console.log('product',product)
    //         console.log('ctr',ctr)
    //         ctr= ctr+1;
    //         await database.collection("Products").doc(product.key).update({
    //             IsDeleted:false
    //         })
    //     }

    // }
  }

  async pushProductsToElastic() {
    let products = await database
      .collection("Products")
      .where("RegistrationID", "==", this.props.user.user.RegistrationID)
      .get()
      .then(async (querySnapshot) => {
        let list = [];
        querySnapshot.forEach(async (doc) => {
          if (doc.data().IsDeleted) {
            let product = doc.data();
            product.key = doc.id;
            list.push(product);
          }
        });
        return list;
      });
    console.log(products);
    for (let i = 0; i < products.length; i++) {
      await database
        .collection("Products")
        .doc(products[i].key)
        .update({ BackEndUpdate: true, Version: "1.1" });
      console.log(i);
      // console.log('products', deletedList[i]);
    }
  }

  exportCatalogueFromRedux() {
    let products = this.props.config.Products;
    const csvData = constatnts.objToCSV(products);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Invoices.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async migrateInStockQty() {
    let fromStroreID = "QGCYBeolH5nA6KBFtitR";
    let toStoreID = "Txl4AXiaT2qWN24VUdWW";
    // "Txl4AXiaT2qWN24VUdWW"
    let toStoreName = "APSANS NEW";
    let toStoreCode = "AP2";
    let toStoreLocation = "01 Default Location";
    let toStoreLocationID = "oKRl8n1oVLqD8UHKP8vU";
    // "oKRl8n1oVLqD8UHKP8vU"

    let batches = await database
      .collection("Batches")
      .where("StoreID", "==", fromStroreID)
      .limit(1000)
      .get()
      .then(async (querySnapshot) => {
        let list = [];
        querySnapshot.forEach(async (doc) => {
          // if(doc.data().IsDeleted){
          let tmp = doc.data();
          tmp.key = doc.id;
          list.push(tmp);
          // }
        });
        return list;
      });
    console.log("batches", batches);
    //
    for (let i = 0; i < batches.length; i++) {
      await database
        .collection("Batches")
        .doc(batches[i].key)
        .update({
          StoreID: toStoreID,
          StoreCode: toStoreCode,
          StoreName: toStoreName,
          StoreLocationID: toStoreLocationID,
          StoreLocation: toStoreLocation,
        });
      console.log(i);
      // console.log('products', deletedList[i]);
    }

    // let inStockQtys = await database.collection("InventoryInStock").where("StoreID", "==", fromStroreID).limit(1000).get().then(async (querySnapshot) => {
    //     let list = [];
    //     querySnapshot.forEach(async (doc) => {
    //         // if(doc.data().IsDeleted){
    //             let tmp = doc.data();
    //             tmp.key = doc.id;
    //             list.push(tmp);
    //         // }

    //     })
    //     return list
    // })
    // console.log('inStockQtys',inStockQtys);
    // for(let i=0;i< inStockQtys.length;i++){
    //     await database.collection("InventoryInStock").doc(inStockQtys[i].key).update({
    //         StoreID:toStoreID,
    //         StoreCode:toStoreCode,
    //         StoreName:toStoreName,
    //         LocationID:toStoreLocationID,
    //         Location:toStoreLocation
    //     })
    //     console.log(i);
    //     // console.log('products', deletedList[i]);
    // }
  }

  async updateCategorySubCategory() {
    let products = await database
      .collection("Products")
      .where("RegistrationID", "==", this.props.user.user.RegistrationID)
      .get()
      .then((querySnapshot) => {
        let list = [];
        querySnapshot.forEach(async (doc) => {
          let doc1 = doc.data();
          doc1.key = doc.id;
          // console.log('doc.id',doc.id)
          list.push(doc1);
        });
        return list;
      });
    console.log("products", products);
    let ctr = 0;
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      let category = product.Category;
      let subcategory = product.SubCategory;
      let difference = false;
      if (
        this.props.config.ProductCategories.filter(
          (f) => f.key === product.CategoryID && f.Name !== product.Category
        ).length > 0
      ) {
        category = this.props.config.ProductCategories.filter(
          (f) => f.key === product.CategoryID
        )[0].Name;
        console.log("correct category", category);
        difference = true;
      }
      if (
        this.props.config.ProductSubCategories.filter(
          (f) =>
            f.key === product.SubCategoryID && f.Name !== product.SubCategory
        ).length > 0
      ) {
        subcategory = this.props.config.ProductSubCategories.filter(
          (f) => f.key === product.SubCategoryID
        )[0].Name;
        console.log("correct sub category", subcategory);
        difference = true;
      }
      if (difference) {
        ctr = ctr + 1;
        await database
          .collection("Products")
          .doc(product.key)
          .update({ Category: category, SubCategory: subcategory });
        console.log("update", product);
        console.log("ctr", ctr);
      }
    }
    // await database.collection("Sales").doc(doc.id).update({ BackEndUpdate:true})
  }

  async getApsanData() {
    //get products
    let products = this.props.config.Products;
    // let

    let inStockQty = [];
    for (let i = 0; i < 100; i++) {
      console.log("ctr", i);
      let result = await database
        .collection("InventoryInStockAuditTrail")
        .where("LocationID", "==", "b9bYl7PL9aqTcgoV20l8")
        .where("ProductID", "==", products[i].key)
        .where("ActionOn", "<", new Date("01-Jan-2023"))
        .where("ActionOn", ">", new Date("30-Sep-2022"))
        .limit(1)
        .orderBy("ActionOn", "desc")
        .get()
        .then((querySnapshot) => {
          const itemLedgers = [];
          querySnapshot.forEach((doc) => {
            itemLedgers.push({
              ActionOn: new Date(doc.data().ActionOn.seconds * 1000)
                .toString()
                .substring(0, 24),
              Brand: doc.data().Brand,
              ProductSKU: doc.data().ProductSKU,
              Location: doc.data().Location,
              Category: doc.data().Category,
              SubCategory: doc.data().SubCategory,
              BarCode: doc.data().BarCode,
              ProductName: doc.data().ProductName,
              RetailPrice: doc.data().RetailPrice,
              TransactionType: doc.data().TransactionType,
              TransactionQty: doc.data().TransactionQty,
              TransactionNo: doc.data().TransactionNo,
              Action: doc.data().Action,
              InStockQty: doc.data().InStockQty,
            });
          });
          return itemLedgers;
        });
      if (result && result.length > 0) {
        let retailPrice = Number(products[i].RetailPrice);
        let supplyPrice = Number(products[i].SupplyPrice);
        let qty = Number(result[0].InStockQty);

        inStockQty.push({
          SKU: products[i].SKU,
          Name: products[i].Name,
          RetailPrice: retailPrice,
          SupplyPrice: supplyPrice,
          InStockQty: qty,
          PurchaseValue: supplyPrice * qty,
          SaleValue: retailPrice * qty,
          LastTransactionDate: result[0].ActionOn,
        });
      } else {
        console.log("not found", products[i].SKU);
      }
      //   console.log("result",result);
    }

    console.log("inStockQty", inStockQty);
    const csvData = constatnts.objToCSV(inStockQty);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "apsan_inStockQty.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async deleteInStockQty() {
    // let batches = await database.collection("Batches").where("StoreID", "==", this.props.user.store.key).limit(1000).get().then(async (querySnapshot) => {
    //     let list = [];
    //     querySnapshot.forEach(async (doc) => {
    //         // if(doc.data().IsDeleted){
    //             let tmp = doc.data();
    //             tmp.key = doc.id;
    //             list.push(tmp);
    //         // }

    //     })
    //     return list
    // })
    // console.log("batches",batches);

    // for(let i=0; i<batches.length;i++){
    //     await database.collection("Batches").doc(batches[i].key).update({
    //         StoreID:batches[i].StoreID + "_deleted",
    //         StoreLocationID:batches[i].StoreLocationID + "_deleted",
    //     })
    //     console.log(i)
    // }

    let instockList = await database
      .collection("InventoryInStock")
      .where("StoreID", "==", this.props.user.store.key)
      .get()
      .then(async (querySnapshot) => {
        let list = [];
        querySnapshot.forEach((doc) => {
          let instock = doc.data();
          instock.key = doc.id;
          list.push(instock);
        });
        return list;
      });
    console.log("instockList", instockList);
    for (let i = 0; i < instockList.length; i++) {
      await database
        .collection("InventoryInStock")
        .doc(instockList[i].key)
        .update({
          StoreID: this.props.user.store.key + "_deleted",
          LocationID: instockList[i].LocationID + "_deleted",
        });
      console.log(i);
    }
  }

  async deleteChildOrphanVariants() {
    let childProducts = this.props.config.Products.filter(
      (f) => f.ParentProductID && f.ParentProductID !== ""
    );
    console.log("childProducts", childProducts);
    let orphanChild = [];
    for (let i = 0; i < childProducts.length; i++) {
      if (
        this.props.config.Products.filter(
          (f) => f.key === childProducts[i].ParentProductID
        ).length === 0
      ) {
        orphanChild.push(childProducts[i]);
      }
    }
    console.log("orphanChild", orphanChild);
    for (let i = 0; i < orphanChild.length; i++) {
      await database
        .collection("Products")
        .doc(orphanChild[i].key)
        .update({ IsDeleted: true });
      console.log("deleted ", i);
    }
  }

  // let orphans = [];
  //         for(let i=0;i< products.length;i++){
  //             await database.collection("Products").where("ParentProductID", "==", products[i].key).get().then(async (querySnapshot) => {
  //                     querySnapshot.forEach(async (doc) => {
  //                         if(products[i].Variants.filter(f=> f.ProductID ===doc.id ).length === 0 ){
  //                             // console.log('doc', doc.data());
  //                             // console.log('doc.key', doc.id);
  //                             // console.log('products[i]',products[i].SKU)
  //                             // if(!doc.data().IsDeleted){
  //                                 let orphan  = doc.data();
  //                                 orphan.key = doc.id;
  //                                 orphans.push(orphan);
  //                                 // await database.collection("Products").doc(doc.id).update({ IsDeleted: true})

  //                             // }

  //                             // console.log('SKU', doc.data().SKU);
  //                         }
  //                     })
  //             })
  //         }
  //         console.log('orphans', orphans)
  //         for(let l =0; l< orphans.length;l++){
  //             await database.collection("InventoryInStock").where("ProductID", "==", orphans[l].key).get().then(async (querySnapshot) => {
  //                 querySnapshot.forEach(async (instock) => {
  //                     console.log('instock', instock.data());
  //                     await database.collection("InventoryInStock").doc(instock.id).update({ StoreID: instock.data().StoreID + '_deleted', LocationID: instock.data().LocationID + '_deleted'})
  //                 })
  //             })
  //         }

  // s

  async correctCatalogue() {
    let products = await database
      .collection("Products")
      .where("RegistrationID", "==", this.props.user.user.RegistrationID)
      .get()
      .then((querySnapshot) => {
        let list = [];
        querySnapshot.forEach(async (doc) => {
          let doc1 = doc.data();
          doc1.key = doc.id;
          // console.log('doc.id',doc.id)
          if (!doc1.IsDeleted) {
            list.push(doc1);
          }
        });
        return list;
      });
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      if (
        this.props.config.ProductCategories.filter(
          (f) => f.key === product.CategoryID && f.Name !== product.Category
        ).length > 0
      ) {
        let categoryName = this.props.config.ProductCategories.filter(
          (f) => f.key === product.CategoryID
        )[0].Name;
        console.log("category name mismatch", product.Category);
        console.log("should be ", categoryName);
        await database
          .collection("Products")
          .doc(product.key)
          .update({ Category: categoryName });
      }
    }
    // console.log('products',products);
    // let categories = this.props.config.ProductCategories
    // for(let c=0;c<categories.length;c++){
    //     if(!categories[0].IsDeleted &&  products.filter(f=>f.CategoryID === categories[c].key).length===0){
    //         console.log("category not mapped to any product", categories[c])
    //     }
    // }
    // let subcategories = this.props.config.ProductSubCategories
    // let subcategories = await database.collection('ProductCategories').where("RegistrationID","==",this.props.user.user.RegistrationID).get().then((querySnapshot) => {
    //     let list = []
    //     querySnapshot.forEach(async (doc) => {
    //         let doc1 = doc.data();
    //             doc1.key = doc.id;
    //             // console.log('doc.id',doc.id)
    //             if(!doc1.IsDeleted && doc1.ParentID!==""){
    //                 list.push(doc1)
    //             }
    //     })
    //     return list;
    // })

    // console.log("subcategories",subcategories);

    // let deleteCounter = 0;
    // for(let c=0;c<subcategories.length;c++){
    //     if(!subcategories[c].IsDeleted &&  products.filter(f=>f.SubCategoryID === subcategories[c].key).length===0){
    //         deleteCounter = deleteCounter+ 1;
    //         console.log("subcategories not mapped to any product", subcategories[c]);
    //         await database.collection("ProductCategories").doc(subcategories[c].key).update({ IsDeleted:true, Action:"DELETE" });
    //     }
    // }
    // console.log("deleteCounter",deleteCounter);
    // let ctr = 0;
    // for(let i=0;i<products.length;i++){
    //     let product = products[i];
    //     if(product.SubCategoryID !=="" && this.props.config.ProductSubCategories.filter(f=>f.key ===product.SubCategoryID && f.ParentID === product.CategoryID).length===0){
    //         console.log("product with incorrect subcategory", product);
    //         await database.collection("Products").doc(product.key).update({ SubCategoryID:"",SubCategory:"" });
    //     }
    // if(product.SubCategoryID ==="" && product.SubCategory !==""){
    //     console.log("product with incorrect subcategory", product);
    // }
    // let category = product.Category;
    // let subcategory = product.SubCategory;
    // let difference =false;
    // if(this.props.config.ProductCategories.filter(f=>f.key ===product.CategoryID && f.Name !==product.Category).length>0){
    //     category = this.props.config.ProductCategories.filter(f=>f.key ===product.CategoryID)[0].Name;
    //     console.log("correct category", category);
    //     difference= true;
    // }
    // if(this.props.config.ProductSubCategories.filter(f=>f.key ===product.SubCategoryID && f.Name !==product.SubCategory).length>0){
    //     subcategory = this.props.config.ProductSubCategories.filter(f=>f.key ===product.SubCategoryID)[0].Name;
    //     console.log("correct sub category", subcategory);
    //     difference= true;
    // }
    // if(difference){
    //     ctr= ctr+ 1;
    //     await database.collection("Products").doc(product.key).update({ Category:category,SubCategory:subcategory });
    //     console.log("update", product);
    //     console.log("ctr", ctr);
    // }
    // }
    // await database.collection("Sales").doc(doc.id).update({ BackEndUpdate:true})
  }

  async deleteCategories() {
    let categories = this.props.config.ProductCategories;
    for (let i = 0; i < categories.length; i++) {
      await database
        .collection("ProductCategories")
        .doc(categories[i].key)
        .update({ IsDeleted: true });
      console.log("deleted", i);
    }
  }

  async downloadStores() {
    let addMessage = firebase.functions().httpsCallable("getRegistrations");

    let registrations = await addMessage({
      text: {
        size: 10000,
        query: {
          match_all: {},
        },
        _source: ["EnableONDC", "BusinessName", "EmailID", "ContactNo"],
      },
    }).then(function(result) {
      const list = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let record = { key: data._id, ...data._source };
        list.push(record);
      });
      // console.log("list", list);
      return list;
    });

    // let registrations = await database
    //   .collection("Registration")
    //   .get()
    //   .then((querySnapshot) => {
    //     const registrations = [];
    //     querySnapshot.forEach((doc) => {
    //       registrations.push({
    //         key: doc.id,
    //         ...doc.data(),
    //       });
    //     });
    //     return registrations;
    //   });
    console.log("registrations", registrations);

    let storesMessage = firebase.functions().httpsCallable("getStores");
    let stores = await storesMessage({
      text: {
        size: 10000,
        query: {
          match_all: {},
        },
      },
    }).then(function(result) {
      const list = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let record = { key: data._id, ...data._source };
        list.push(record);
      });
      // console.log("list", list);
      return list;
    });
    console.log("stores", stores);
    // let stores = await database
    //   .collection("Stores")
    //   .get()
    //   .then((querySnapshot) => {
    //     const list = [];
    //     querySnapshot.forEach((doc) => {
    let list = [];
    for (let i = 0; i < stores.length; i++) {
      let doc = stores[i];
      console.log("i", i);
      let registration = null;
      let registrationID = "";
      let enbaleONDC = false;
      let registrationBusinessName = "";
      let registrationEmailID = "";
      let registrationMobileNo = "";
      if (
        registrations.filter((f) => f.key === doc.RegistrationID).length > 0
      ) {
        registration = registrations.filter(
          (f) => f.key === doc.RegistrationID
        )[0];
        registrationID = registration.key;
        enbaleONDC = registration.hasOwnProperty("EnableONDC")
          ? registration.EnableONDC
          : false;
        registrationBusinessName = registration.BusinessName;
        registrationEmailID = registration.EmailID;
        registrationMobileNo = registration.ContactNo;
      }

      let lat = "";
      let lon = "";
      if (doc.Location) {
        if (doc.Location.lat) {
          lat = doc.Location.lat;
        }
        if (doc.Location.lon) {
          lon = doc.Location.lon;
        }
      }
      let storeImage = "";
      if (doc.Images && doc.Images.length > 0) {
        storeImage = doc.Images[0].ImagePath;
      }
      list.push({
        key: doc.key,
        StoreCode: doc.StoreCode,
        StoreName: doc.StoreName,
        Address1: doc.Address1,
        Address2: doc.Address2,
        City: doc.City,
        State: doc.State,
        PostalCode: doc.PostalCode,
        PhoneNo: doc.PhoneNo,
        EmailID: doc.EmailID,
        StartDate: doc.StartDate ? doc.StartDate : "",
        ExpiryDate: doc.ExpiryDate ? doc.ExpiryDate : "",
        Latitude: lat,
        Longitude: lon,
        PincodeHashKey: doc.PincodeHashKey ? doc.PincodeHashKey : "",
        Domain: doc.StoreDomainID ? doc.StoreDomainID : "",
        StoreType: doc.StoreTypeID ? doc.StoreTypeID : "",
        Delivery: doc.DeliveryType ? doc.DeliveryType : "",
        DeliveryRadius: doc.DeliveryRadius ? doc.DeliveryRadius : "",
        TimeToShip: doc.hasOwnProperty("ShipTime") ? doc.ShipTime : 0,
        TimeToShipType: doc.hasOwnProperty("ShipTimeType")
          ? doc.ShipTimeType
          : "",
        TimeToDeliver: doc.hasOwnProperty("DeliveryTime")
          ? doc.DeliveryTime
          : 0,
        TimeToDeliverType: doc.hasOwnProperty("DeliveryTimeType")
          ? doc.DeliveryTimeType
          : "",
        StoreImage: storeImage,
        IsDeleted: doc.IsDeleted,
        RegistrationID: registrationID,
        EnbaleONDC: enbaleONDC,
        RegistrationBusinessName: registrationBusinessName,
        RegistrationEmailID: registrationEmailID,
        RegistrationMobileNo: registrationMobileNo,
        StoreLock: doc.hasOwnProperty("LockStoreConfig")
          ? doc.LockStoreConfig
          : false,
        CustomerPickUp: doc.hasOwnProperty("CustomerPickUp")
          ? doc.CustomerPickUp
          : false,
        AutoAcceptOnlineOrders: doc.hasOwnProperty("AutoAcceptOnlineOrders")
          ? doc.AutoAcceptOnlineOrders
          : false,
      });
    }
    //   });
    //   return list;
    // });

    const csvData = constatnts.objToCSV(list);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "stores_list.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async getPinCodeOrders() {
    console.log("getPinCodeOrders");
    console.log("this.state.fromDate", this.state.fromDate);
    console.log("this.state.toDate", this.state.toDate);

    // const [year, month, day] = this.state.fromDate.split("-");
    // const fromDate = `${year}-${month}-${day}`;
    // console.log("fromDate", fromDate); // Output: "2023-12-31" (assuming today's date is used)

    // [year, month, day] = this.state.toDate.split("-");
    // const toDate = `${year}-${month}-${day}`;
    // console.log("toDate", toDate); // Output: "2023-12-31" (assuming today's date is used)

    // let searchQuery = {
    //   from: 0,
    //   size: 10000,
    //   track_total_hits: true,
    //   _source: [
    //     "ExternalTransactionID",
    //     "TransactionDate",
    //     "Store",
    //     "DeliveryPartnerOrderAmount",
    //     "BillAmount",
    //     "DeliveryStatus",
    //     "DeliveryBy",
    //   ],
    //   query: {
    //     bool: {
    //       must: [
    //         {
    //           range: {
    //             TransactionDate: {
    //               lte: this.state.toDate,
    //               gte: this.state.fromDate,
    //               format: "yyyy-MM-dd",
    //             },
    //           },
    //         },
    //         {
    //           exists: {
    //             field: "ExternalTransactionID",
    //           },
    //         },
    //       ],
    //     },
    //   },
    //   sort: [
    //     {
    //       TransactionDate: {
    //         order: "asc",
    //       },
    //     },
    //   ],
    // };

    // Define the date-time string
    const fromDateStr = this.state.fromDate + "T00:00:00";
    // Create a Date object
    const fromDate = new Date(fromDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisFrom = fromDate.getTime();
    console.log(epochMillisFrom);

    // Define the date-time string
    const toDateStr = this.state.toDate + "T23:59:59";
    // Create a Date object
    const toDate = new Date(toDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisTo = toDate.getTime();
    console.log(epochMillisTo);

    let searchQuery = {
      from: 0,
      size: 10000,
      track_total_hits: true,
      _source: [
        "ExternalTransactionID",
        "TransactionDate",
        "Store",
        "DeliveryPartnerOrderAmount",
        "BillAmount",
        "DeliveryStatus",
        "DeliveryBy",
      ],
      query: {
        bool: {
          must: [
            {
              range: {
                TransactionDate: {
                  lte: epochMillisTo,
                  gte: epochMillisFrom,
                  // format: "yyyy-MM-dd'T'HH:mm:ssZ",
                },
              },
            },
            {
              exists: {
                field: "ExternalTransactionID",
              },
            },
          ],
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "asc",
          },
        },
      ],
    };
    // {
    //   term: {
    //     "RequestSource.keyword": {
    //       value: "shopping-network.phonepe.com",
    //     },
    //   },
    // },
    console.log("searchQuery", JSON.stringify(searchQuery));
    let invoices = [];
    let addMessage = firebase.functions().httpsCallable("scrollData");
    let sanitizedMessage = await addMessage({
      text: searchQuery,
      collection: "sales1",
      scrollID: "",
    }).then(function(result) {
      return result.data.text;
    });
    sanitizedMessage.hits.hits.map((data, index) => {
      if (
        data._source.hasOwnProperty("ExternalTransactionID") &&
        data._source.ExternalTransactionID !== ""
      ) {
        invoices.push({ key: data._id, ...data._source });
      }
    });
    // console.log("Invoices", JSON.stringify(sanitizedMessage.hits.total.value))
    console.log("invoices", invoices);
    // return invoices;
    let total = sanitizedMessage.hits.total.value;
    console.log("total,", total);
    let scrollID = sanitizedMessage._scroll_id;
    // console.log("scrollID", scrollID);
    for (let index = 1; index < total / 10000; index++) {
      let addMessage = firebase.functions().httpsCallable("scrollData");
      let loopResult = await addMessage({
        text: { scroll: "1m", scroll_id: scrollID },
      });
      let loopDatas = loopResult.data.text.hits.hits;
      loopDatas.map((data, index) => {
        if (
          data._source.hasOwnProperty("ExternalTransactionID") &&
          data._source.ExternalTransactionID !== ""
        ) {
          invoices.push({ key: data._id, ...data._source });
        }
        // invoices.push({ key: data._id, ...data._source });
      });
    }

    let exportData = [];
    for (let i = 0; i < invoices.length; i++) {
      exportData.push({
        OrderNo: invoices[i].ExternalTransactionID,
        OrderDate: new Date(invoices[i].TransactionDate)
          .toString()
          .substring(0, 24),
        StoreID: invoices[i].Store.key,
        StoreName: invoices[i].Store.StoreName,
        DeliveryCharge: invoices[i].DeliveryPartnerOrderAmount
          ? invoices[i].DeliveryPartnerOrderAmount
          : 0,
        InvoiceAmount: invoices[i].BillAmount,
        DeliveryStatus: invoices[i].DeliveryStatus,
        DeliveryBy: invoices[i].DeliveryBy,
      });
    }
    const csvData = constatnts.objToCSV(exportData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "PincodeOrders.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async getSearchData() {
    let searchQuery = {
      size: 1000,
      query: {
        range: {
          action_on: {
            gte: "2024-01-12 07:00:00",
            lt: "2024-01-13 09:00:00",
          },
        },
      },
    };
    let addMessage = firebase.functions().httpsCallable("apiGetSearchLog");
    let searches = await addMessage({
      fromDate: "2024-01-12 10:30:00",
      toDate: "2024-01-13 12:00:00",
    }).then(function(result) {
      console.log("result", result);
      const searches = [];
      result.data.map((data, index) => {
        let action_on = new Date(data.action_on).toString();
        let message_id = data.message_id;
        let city = data.city;
        searches.push({
          action_on: action_on,
          message_id: message_id,
          city: city,
          hr: new Date(data.action_on).getHours(),
        });
      });
      console.log("searches", searches);
      return searches;
    });
    console.log("searches", searches);

    const csvData = constatnts.objToCSV(searches);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "searches.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async getPowerUser() {
    let users = await database
      .collection("User")
      .where("Role", "==", "Power User")
      .get()
      .then((querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
          // if(doc.data().Store.key ==="xaYetSMlcC9AjlLo7hXN"){
          users.push({
            key: doc.id,
            ...doc.data(),
          });
          // }
        });
        return users;
      });

    console.log("users", users);

    let registrations = [];
    for (let i = 0; i < users.length; i++) {
      // if (
      //   registrations.filter(
      //     (f) => f.RegistrationID === users[i].RegistrationID
      //   ).length > 0
      // ) {
      registrations.push(users[i].RegistrationID);
      // }
    }
    // getStore details for these registratioons
    let query = {
      size: 10000,
      query: {
        terms: {
          "RegistrationID.keyword": registrations,
        },
      },
    };
    let addMessage = firebase.functions().httpsCallable("getStores");
    let stores = await addMessage({ text: query }).then(function(result) {
      const stores = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let invoice = { key: data._id, ...data._source };
        stores.push(invoice);
      });
      console.log("stores", stores);
      return stores;
    });

    console.log("query", JSON.stringify(query));

    let storeUsers = [];
    for (let i = 0; i < users.length; i++) {
      let userStores = stores.filter(
        (f) => f.RegistrationID === users[i].RegistrationID
      );
      if (userStores.length > 0) {
        userStores.map((s) => {
          storeUsers.push({
            StoreID: s.key,
            StoreCode: s.StoreCode,
            StoreName: s.StoreName,
            UserName: users[i].Name,
            UserEmailID: users[i].EmailID,
            UserRole: users[i].Role,
            RegistrationID: users[i].RegistrationID,
            UserID: users[i].key,
          });
        });
      }
    }
    console.log("storeUsers", storeUsers);
    const csvData = constatnts.objToCSV(storeUsers);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "StorePowerUsers.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async getRegistrationToBeProcessed() {
    let registrations = await database
      .collection("Registration")
      .where("EnableONDC", "==", true)
      .get()
      .then((querySnapshot) => {
        const registrations = [];
        querySnapshot.forEach((doc) => {
          registrations.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return registrations;
      });
    console.log("registrations", registrations.length);

    for (let i = 0; i < registrations.length; i++) {
      await database.collection("RegistrationToBeProcessed").add({
        key: registrations[i].key,
        BusinessName: registrations[i].BusinessName,
        Status: 1,
      });
    }

    // let registrationIDS = [];
    // for (let i = 0; i < registrations.length; i++) {
    //   console.log(i);
    //   registrationIDS.push(registrations[i].key);
    // }

    // let searchQuery = {
    //   size: 10000,
    //   query: {
    //     bool: {
    //       must: [
    //         {
    //           terms: {
    //             "RegistrationID.keyword": registrationIDS,
    //           },
    //         },
    //         {
    //           match: {
    //             IsDeleted: false,
    //           },
    //         },
    //       ],
    //     },
    //   },
    // };
    // let addMessage = firebase.functions().httpsCallable("getStores");
    // let stores = await addMessage({ text: searchQuery }).then(function(result) {
    //   const stores = [];
    //   let sanitizedMessage = result.data.text;
    //   sanitizedMessage.hits.hits.map((data, index) => {
    //     let store = { key: data._id, ...data._source };
    //     stores.push(store);
    //   });
    //   // console.log("stores", stores);
    //   return stores;
    // });
    // console.log("stores", stores);

    // //check registrations that have single store
    // let singleStoreRegistrations = [];
    // for (let i = 0; i < registrations.length; i++) {
    //   if (
    //     stores.filter((f) => f.RegistrationID === registrations[i].key)
    //       .length === 1
    //   ) {
    //     singleStoreRegistrations.push(registrations[i]);
    //   }
    // }
    // console.log("singleStoreRegistrations", singleStoreRegistrations);
    // // singleStoreRegistrations.length
    // for (let i = 0; i < singleStoreRegistrations.length; i++) {
    //   await database.collection("RegistrationToBeProcessed").add({
    //     key: singleStoreRegistrations[i].key,
    //     BusinessName: singleStoreRegistrations[i].BusinessName,
    //     Status: 1,
    //   });
    // }
  }

  async updateSKU() {
    let products = this.props.config.Products.filter((f) => f.SKU === "");
    console.log("products", products.length);
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      console.log("ctr", i);
      console.log("product", product);
      await database
        .collection("Products")
        .doc(product.key)
        .update({ SKU: product.BarCode });
    }
  }

  async deleteProducts() {
    // console.log("deleteProducts started");
    // axios
    //   .post("https://backend-o3dyulj4pa-el.a.run.app/v1/backend/dc", {
    //     RegistrationID: this.state.registrationID,
    //   })
    //   .then((res) => {
    //     console.log("deleteCatalog api call done");
    //     // console.log('getBrandList', res)
    //     // return res.data;
    //   })
    //   .catch((err) => {
    //     console.log("deleteCatalog api call error");
    //     // console.log('Could not get businessname for provided domain name. Try again later.');
    //   });
    // console.log("deleteProducts end");
    // return;
    if (this.state.registrationID === "") {
      alert("Please specify registration id");
      return;
    }
    this.setState({ processing: true });
    let searchQuery = {
      size: 10000,
      track_total_hits: true,
      _source: ["SKU"],
      query: {
        bool: {
          must: [
            {
              match: {
                RegistrationID: this.state.registrationID,
              },
            },
            {
              match: {
                IsDeleted: false,
              },
            },
          ],
        },
      },
    };
    console.log("searchQuery", JSON.stringify(searchQuery));
    // return;
    let addMessage = firebase.functions().httpsCallable("getProducts");
    let products = await addMessage({ text: searchQuery }).then(function(
      result
    ) {
      const products = [];
      let sanitizedMessage = result.data.text;
      console.log("total product count", sanitizedMessage.hits.total.value);
      sanitizedMessage.hits.hits.map((data, index) => {
        let product = { key: data._id, ...data._source };
        products.push(product);
      });
      // console.log("products", products);
      return products;
    });

    // // let products = await database
    // //   .collection("Products")
    // //   .where("RegistrationID", "==", this.state.registrationID)
    // //   .where("IsDeleted", "==", false)
    // //   .get()
    // //   .then(async (querySnapshot) => {
    // //     let list = [];
    // //     querySnapshot.forEach(async (doc) => {
    // //       // if(doc.data().IsDeleted){
    // //       let tmp = doc.data();
    // //       tmp.key = doc.id;
    // //       list.push(tmp);
    // //       // }
    // //     });
    // //     return list;
    // //   });

    // console.log("products.length", products.length);
    // this.setState({ productCount: products.length });
    // // return;
    for (let i = 0; i < products.length; i++) {
      await database
        .collection("Products")
        .doc(products[i].key)
        .update({
          IsDeleted: true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        });
      console.log("deleted", i);
      this.setState({ deletedCount: i });
    }
    this.setState({ processing: false });
  }

  async getPinCodeOrders2() {
    console.log("this.state.fromDate", this.state.fromDate);
    console.log("this.state.toDate", this.state.toDate);

    // const [year, month, day] = this.state.fromDate.split("-");
    // const fromDate = `${year}-${month}-${day}`;
    // console.log("fromDate", fromDate); // Output: "2023-12-31" (assuming today's date is used)

    // [year, month, day] = this.state.toDate.split("-");
    // const toDate = `${year}-${month}-${day}`;
    // console.log("toDate", toDate); // Output: "2023-12-31" (assuming today's date is used)

    let searchQuery = {
      from: 0,
      size: 10000,
      track_total_hits: true,
      _source: [
        "ExternalTransactionID",
        "TransactionDate",
        "Store",
        "DeliveryPartnerOrderAmount",
        "BillAmount",
        "DeliveryStatus",
      ],
      query: {
        bool: {
          must: [
            {
              range: {
                TransactionDate: {
                  lte: this.state.toDate,
                  gte: this.state.fromDate,
                  format: "yyyy-MM-dd",
                },
              },
            },
          ],
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "asc",
          },
        },
      ],
    };

    // {
    //   term: {
    //     "RequestSource.keyword": {
    //       value: "shopping-network.phonepe.com",
    //     },
    //   },
    // },
    let invoices = [];
    let addMessage = firebase.functions().httpsCallable("getSales");
    let sanitizedMessage = await addMessage({
      text: searchQuery,
    }).then(function(result) {
      return result.data.text;
    });
    sanitizedMessage.hits.hits.map((data, index) => {
      invoices.push({ key: data._id, ...data._source });
    });
    // console.log("Invoices", JSON.stringify(sanitizedMessage.hits.total.value))
    console.log("invoices", invoices);
    // return invoices;
    let total = sanitizedMessage.hits.total.value;
    console.log("total,", total);
    for (let index = 1; index < total / 10000; index++) {
      searchQuery.from = 10000 * 1 + 1;
      console.log("index", index);
      console.log("searchQuery", JSON.stringify(searchQuery));
      let addMessage = firebase.functions().httpsCallable("getSales");
      let loopResult = await addMessage({
        text: searchQuery,
      });

      let loopDatas = loopResult.data.text.hits.hits;
      loopDatas.map((data, index) => {
        invoices.push({ key: data._id, ...data._source });
      });
    }

    let exportData = [];
    for (let i = 0; i < invoices.length; i++) {
      exportData.push({
        OrderNo: invoices[i].ExternalTransactionID,
        OrderDate: new Date(invoices[i].TransactionDate)
          .toString()
          .substring(0, 24),
        StoreID: invoices[i].Store.key,
        StoreName: invoices[i].Store.StoreName,
        DeliveryCharge: invoices[i].DeliveryPartnerOrderAmount
          ? invoices[i].DeliveryPartnerOrderAmount
          : 0,
        InvoiceAmount: invoices[i].BillAmount,
        DeliveryStatus: invoices[i].DeliveryStatus,
      });
    }
    const csvData = constatnts.objToCSV(exportData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "PincodeOrders.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async processEnterpriseRegistration() {
    // await database.collection("RegistrationToBeProcessed").add({
    //   key: this.state.registrationID,
    //   BusinessName: "",
    //   Status: 1,
    //   Enterprise: true,
    // });

    let registrations = await database
      .collection("Registration")
      .where("EnableONDC", "==", true)
      .get()
      .then((querySnapshot) => {
        const registrations = [];
        querySnapshot.forEach((doc) => {
          registrations.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return registrations;
      });
    console.log("registrations", registrations.length);
    for (let i = 0; i < registrations.length; i++) {
      console.log(i);
      // registrationIDS.push(registrations[i].key);
      await database.collection("RegistrationToBeProcessed").add({
        key: registrations[i].key,
        BusinessName: "",
        Status: 1,
        Enterprise: true,
      });
    }
  }

  async onEnablePincodeSKU(collection) {
    // EnablePincodeSKU
    console.log("onEnablePincodeSKU start");
    for (let i = 1; i < collection.length; i++) {
      console.log("i", i);
      let providerID = collection[i][0];
      console.log("providerID", providerID);
      let enablePincodeSKU = false;
      if (collection[i][1] && collection[i][1].toLowerCase() === "true") {
        enablePincodeSKU = true;
      }
      console.log("enablePincodeSKU", enablePincodeSKU);
      let store = await database
        .collection("Stores")
        .doc(providerID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("BOM found", doc.data());
            return doc.data();
          } else {
            return null;
          }
        });

      await database
        .collection("Registration")
        .doc(store.RegistrationID)
        .update({
          EnablePincodeSKU: enablePincodeSKU,
        });
      console.log("store.RegistrationID", store.RegistrationID);
    }
    console.log("enbalePayBill end");
  }

  async enbalePayBill(collection) {
    // collection.length
    console.log("enbalePayBill start");
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);

      let store = await database
        .collection("Stores")
        .doc(providerID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("BOM found", doc.data());
            return doc.data();
          } else {
            return null;
          }
        });

      await database
        .collection("Registration")
        .doc(store.RegistrationID)
        .update({
          EnablePincodePayBill: true,
        });
      console.log("store.RegistrationID", store.RegistrationID);
    }
    console.log("enbalePayBill end");
  }

  async onUpdatePincodeHashKey(collection) {
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);
      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          PincodeHashKey: collection[i][1],
        });
    }
  }

  async changeDeliveryType(collection) {
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);
      console.log("deliverycharge", collection[i][1]);
      console.log("PincodeMinOrderValueForFreeDelivery", collection[i][2]);
      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          DeliveryType: collection[i][1],
        });
    }
  }

  async updateDeliveryCharges(collection) {
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);
      console.log("deliverycharge", collection[i][1]);
      console.log("PincodeMinOrderValueForFreeDelivery", collection[i][2]);
      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          PincodeDeliveryCharge: Number(collection[i][1]),
          PincodeMinOrderValueForFreeDelivery: Number(collection[i][2]),
        });
    }
  }

  async donwloadProducts() {
    if (this.state.registrationID === "") {
      alert("Please specify registration id");
      return;
    }
    this.setState({ processing: true });
    let searchQuery = {
      size: 10000,
      query: {
        bool: {
          must: [
            {
              match: {
                RegistrationID: this.state.registrationID,
              },
            },
            {
              match: {
                IsDeleted: false,
              },
            },
          ],
        },
      },
    };
    console.log("searchQuery", JSON.stringify(searchQuery));
    // return;
    let addMessage = firebase.functions().httpsCallable("getProducts");
    let products = await addMessage({ text: searchQuery }).then(function(
      result
    ) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let product = { key: data._id, ...data._source };
        products.push(product);
      });
      // console.log("products", products);
      return products;
    });

    console.log("products.length", products.length);

    let exportList = [];
    for (let i = 0; i < products.length; i++) {
      if (products[i].StoreSpecificPrices.length > 0) {
        products[i].StoreSpecificPrices.map((m) => {
          exportList.push({
            ProductID: products[i].key,
            SKU: products[i].SKU,
            BarCode: products[i].BarCode,
            Name: products[i].Name,
            StoreID: m.StoreID,
            StoreName: m.StoreName,
            RetailPrice: m.RetailPrice,
            OMNIEnabled: !m.hasOwnProperty("OMNIEnabled")
              ? "Not found"
              : m.OMNIEnabled,
            OutOfStock: !m.hasOwnProperty("OutOfStock")
              ? "Not found"
              : m.OutOfStock,
            ProductOMNIEnabled: products[i].OMNIEnabled,
            ProductOutOfStock: products[i].OutOfStock,
          });
        });
      } else {
        exportList.push({
          ProductID: products[i].key,
          SKU: products[i].SKU,
          BarCode: products[i].BarCode,
          Name: products[i].Name,
          StoreID: "",
          StoreName: "",
          RetailPrice: "",
          OMNIEnabled: "",
          OutOfStock: "",
          ProductOMNIEnabled: products[i].OMNIEnabled,
          ProductOutOfStock: products[i].OutOfStock,
        });
      }
    }
    // console.log('exportList',exportList)
    const csvData = constatnts.objToCSV(exportList);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "productlist.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.setState({ processing: false });
  }

  async correctNotPresent() {
    let products = await database
      .collection("Products")
      .where("OMNIEnabled", "==", "not present")
      .get()
      .then((querySnapshot) => {
        const products = [];
        querySnapshot.forEach((doc) => {
          products.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return products;
      });
    console.log("products", products.length);
    for (let i = 0; i < products.length; i++) {
      products[i].StoreSpecificPrices.map((m) => {
        m.OMNIEnabled = true;
        m.OutOfStock = false;
      });
      await database
        .collection("Products")
        .doc(products[i].key)
        .update({
          OMNIEnabled: true,
          OutOfStock: false,
          StoreSpecificPrices: products[i].StoreSpecificPrices,
        });
      console.log(i);
    }
  }

  async disableCustomerPickUpForAllStores() {
    let registrations = await database
      .collection("Registration")
      .where("EnableONDC", "==", true)
      .get()
      .then((querySnapshot) => {
        const registrations = [];
        querySnapshot.forEach((doc) => {
          registrations.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return registrations;
      });
    console.log("registrations", registrations.length);

    let registrationIDS = [];
    for (let i = 0; i < registrations.length; i++) {
      // console.log(i);
      registrationIDS.push(registrations[i].key);
    }

    let searchQuery = {
      size: 10000,
      query: {
        bool: {
          must: [
            {
              terms: {
                "RegistrationID.keyword": registrationIDS,
              },
            },
            {
              match: {
                IsDeleted: false,
              },
            },
          ],
        },
      },
    };
    let addMessage = firebase.functions().httpsCallable("getStores");
    let stores = await addMessage({ text: searchQuery }).then(function(result) {
      const stores = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let store = { key: data._id, ...data._source };
        stores.push(store);
      });
      // console.log("stores", stores);
      return stores;
    });
    console.log("stores", stores);

    //get all stores

    // stores.length
    for (let i = 0; i < stores.length; i++) {
      console.log(i);
      console.log("storeid", stores[i].key);
      await database
        .collection("Stores")
        .doc(stores[i].key)
        .update({
          CustomerPickUp: false,
        });
    }
  }

  async updateFlagForMKRetail() {
    let searchQuery = {
      size: 10000,
      query: {
        bool: {
          must: [
            {
              match: {
                RegistrationID: "ToFNLPtQMmvdeoqFkkjF",
              },
            },
            {
              match: {
                "CustomField2.keyword": "not in master catalog",
              },
            },
            {
              match: {
                IsDeleted: false,
              },
            },
          ],
        },
      },
    };
    console.log("searchQuery", JSON.stringify(searchQuery));
    // return;
    let addMessage = firebase.functions().httpsCallable("getProducts");
    let products = await addMessage({ text: searchQuery }).then(function(
      result
    ) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let product = { key: data._id, ...data._source };
        products.push(product);
      });
      // console.log("products", products);
      return products;
    });
    console.log("products.length", products.length);
    for (let i = 0; i < products.length; i++) {
      console.log(i);
      await database
        .collection("Products")
        .doc(products[i].key)
        .update({
          CustomField2: "",
        });
    }
    // let products = await database
    //   .collection("Products")
    //   .where("RegistrationID", "==", "ToFNLPtQMmvdeoqFkkjF")
    //   .get()
    //   .then((querySnapshot) => {
    //     const products = [];
    //     querySnapshot.forEach((doc) => {
    //       products.push({
    //         key: doc.id,
    //         ...doc.data(),
    //       });
    //     });
    //     return products;
    //   });
    // console.log("products.length", products.length);
  }

  async processM10Catalog() {
    let products = await database
      .collection("Products")
      .where("RegistrationID", "==", this.state.registrationID)
      .where("IsDeleted", "==", false)
      .get()
      .then(async (querySnapshot) => {
        let list = [];
        querySnapshot.forEach(async (doc) => {
          // if(doc.data().IsDeleted){
          let tmp = doc.data();
          tmp.key = doc.id;
          list.push(tmp);
          // }
        });
        return list;
      });

    console.log("products.length", products.length);

    for (let i = 0; i < products.length; i++) {
      await database
        .collection("Products")
        .doc(products[i].key)
        .update({
          TaxInclusive: false,
          TaxPriceRange: [
            {
              FromPrice: 1,
              FromPriceErrMsg: "",
              TaxErrMsg: "",
              TaxGroup: "GST 5%",
              TaxID: "D9npemfbwovF75GXJv9o",
              ToPrice: 999,
              ToPriceErrMsg: "",
            },
            {
              FromPrice: 1000,
              FromPriceErrMsg: "",
              TaxErrMsg: "",
              TaxGroup: "GST 12%",
              TaxID: "CpzySVshxVUzKSZAL1ER",
              ToPrice: 5000,
              ToPriceErrMsg: "",
            },
          ],
        });
      console.log(i);
    }
  }

  async enbalePincodeDelivery(collection) {
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);

      let store = await database
        .collection("Stores")
        .doc(providerID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("BOM found", doc.data());
            return doc.data();
          } else {
            return null;
          }
        });

      await database
        .collection("Registration")
        .doc(store.RegistrationID)
        .update({
          EnablePincodeDelivery: true,
        });

      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          CustomerPickUp: true,
          DeliveryType: "pincode",
        });
    }
  }

  async getDeliveredShadowFaxOrder() {
    // console.log("getCanceledShadowFaxOrder");
    // console.log("this.state.fromDate", this.state.fromDate);
    // console.log("this.state.toDate", this.state.toDate);

    let result = await database
      .collection("ShadowFaxCallBackLog")
      .where("order_status", "==", "DELIVERED")
      // CANCELLED
      // .where("CreatedOn", ">=", this.state.fromDate)
      // .where("CreatedOn", "<=", this.state.toDate)
      .get()
      .then((querySnapshot) => {
        const result = [];
        querySnapshot.forEach((doc) => {
          result.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return result;
      });
    console.log("result", result);
    const csvData = constatnts.objToCSV(result);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "sfxdeliveredorder.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.setState({ processing: false });
  }

  async getCanceledShadowFaxOrder() {
    // console.log("getCanceledShadowFaxOrder");
    // console.log("this.state.fromDate", this.state.fromDate);
    // console.log("this.state.toDate", this.state.toDate);

    let result = await database
      .collection("ShadowFaxCallBackLog")
      .where("order_status", "==", "CANCELLED")
      // CANCELLED
      // .where("CreatedOn", ">=", this.state.fromDate)
      // .where("CreatedOn", "<=", this.state.toDate)
      .get()
      .then((querySnapshot) => {
        const result = [];
        querySnapshot.forEach((doc) => {
          result.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return result;
      });
    console.log("result", result);
    const csvData = constatnts.objToCSV(result);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "sfxcancelledorder.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.setState({ processing: false });
  }

  async lockUnlockStores(collection) {
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);
      let lock = false;
      if (collection[i][1] && collection[i][1].toLowerCase() === "true") {
        lock = true;
      }
      console.log("lock", lock);
      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          LockStoreConfig: lock,
        });
    }
  }

  async updateGeoJSON(collection) {
    for (let i = 1; i < collection.length; i++) {
      console.log("i", i);

      let providerID = collection[i][0];
      console.log("providerID", providerID);

      let deliveryRadius = Number(collection[i][1]) / 1000;
      console.log("deliveryRadius", deliveryRadius);

      let geoJSON = "";
      if (collection[i][2] === "polygon") {
        geoJSON =
          '{"type":"FeatureCollection","features":[{"type":"Feature","properties": {},"geometry": {"coordinates": [[' +
          collection[i][3] +
          ']],"type":"Polygon"}}]}';
      } else {
        // geoJSON ='{"type": "FeatureCollection", "features": [{"type":"Feature","properties": {}, "geometry": {"coordinates": [[ [' + [77.668794,12.925744],[77.670745,12.921055],[77.6754595,12.924577],[77.668794,12.925744]]], [[[77.641901,12.917111],[77.633031,12.912950],[77.644913,12.910446],[77.641901,12.917111]]]], "type": "MultiPolygon"}}]}
        // geoJSON= '{"type":"FeatureCollection","features": [{"type": "Feature", "properties": {}, "geometry": {"coordinates":' +  collection[i][1] [[ [[77.679474,12.913731],[77.673212,12.928429],[77.661135,12.924536],[77.679523,12.913735],[77.679474,12.913731]]], [[[77.641901,12.917111],[77.633031,12.912950],[77.644913,12.910446],[77.641901,12.917111]]]], "type": "MultiPolygon"}}]}
      }
      console.log("geoJSON", geoJSON);

      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          GeoJSON: geoJSON,
          DeliveryRadius: deliveryRadius,
        });
    }
  }

  async onEnableDisableCustomerPickup(collection) {
    for (let i = 1; i < collection.length; i++) {
      console.log("i", i);

      let providerID = collection[i][0];
      console.log("providerID", providerID);

      let customerPickUp = false;
      if (collection[i][1].toLowerCase() === "true") {
        customerPickUp = true;
      }

      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          CustomerPickUp: customerPickUp,
        });
    }
  }

  async onEnablePincodeOrderManagement(collection) {
    // collection.length
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);
      let enablePincodeOrderManagement = true;
      if (collection[i][1].toLowerCase() === "false") {
        enablePincodeOrderManagement = false;
      }
      let store = await database
        .collection("Stores")
        .doc(providerID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("BOM found", doc.data());
            return doc.data();
          } else {
            return null;
          }
        });

      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          EnablePincodeOrderManagement: enablePincodeOrderManagement,
        });

      await database
        .collection("Registration")
        .doc(store.RegistrationID)
        .update({
          EnablePincodeOrderManagement: enablePincodeOrderManagement,
        });
      console.log("store.RegistrationID", store.RegistrationID);
    }
  }

  async resetTransactionRunningNo() {
    let registrationID = this.state.resetTransactionRunningNoRegistrationID;
    if (registrationID.trim() === "") {
      alert("Please provide registration id");
      return;
    }
    let addMessage = firebase.functions().httpsCallable("getTransactionTypes");
    let transactionTypes = await addMessage({
      registrationID: registrationID,
    }).then(function(result) {
      // console.log(result.data)
      return result.data;
    });

    console.log("transactionTypes", transactionTypes);

    let crs = await database
      .collection("CashRegisters")
      .where("RegistrationID", "==", registrationID)
      .get()
      .then((querySnapshot) => {
        const crs = [];
        querySnapshot.forEach((doc) => {
          crs.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return crs;
      });
    console.log("crs", crs);

    for (let i = 0; i < crs.length; i++) {
      transactionTypes.map(async (m) => {
        let rec = await database
          .collection("TransactionRunningNo")
          .doc(crs[i].key + m.key)
          .get()
          .then((doc) => {
            let obj = null;
            if (doc.exists) {
              obj = doc.data();
              obj.key = doc.id;
            }
            return obj;
          });

        if (rec) {
          console.log(rec);
          await database
            .collection("TransactionRunningNo")
            .doc(rec.key)
            .update({ RunningNo: 0 });
        }
      });
    }
  }

  async getImport() {
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

    let crs = await database
      .collection("Import")
      .where("ProductImportType", "==", "4.1")
      .where("ActionOn", ">=", twoDaysAgo)
      .where("Status", "in", ["Pending", "WIP"])
      .orderBy("ActionOn", "asc")
      .get()
      .then((querySnapshot) => {
        const crs = [];
        querySnapshot.forEach((doc) => {
          crs.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return crs;
      });
    console.log("crs", crs);
  }

  async getPackedTransctions() {
    let query = {
      from: 0,
      size: 10000,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              term: {
                "DeliveryStatus.keyword": "Packed",
              },
            },
            {
              range: {
                DeliveryDate: {
                  lte: "2024-04-06",
                  gte: "2024-04-04",
                  format: "yyyy-MM-dd",
                },
              },
            },
          ],
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "asc",
          },
        },
      ],
    };

    let addMessage = firebase.functions().httpsCallable("getSales");
    let invoices = await addMessage({ text: query }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let invoice = { key: data._id, ...data._source };
        if (invoice.hasOwnProperty("OrderLineItems")) {
          invoices.push(invoice);
        }
      });

      return invoices;
    });
    console.log("invoices", invoices);
  }

  async apiProcessOpenCases(collection) {
    for (let i = 1; i < collection.length; i++) {
      console.log("i", i);

      let order_id = collection[i][0];
      console.log("order_id", order_id);
      await axios
        .post(
          "https://us-central1-ondc-posible-firestore-prod.cloudfunctions.net/apiProcessOpenCases",
          { order_id: order_id }
        )
        .then((res) => {
          // console.log('getBrandList', res)
          return res.data;
        })
        .catch((err) => {
          // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }
  }

  async getPincodeGloablCatalog() {
    let searchQuery = {
      from: 0,
      size: 10000,
      track_total_hits: true,
    };
    let invoices = [];
    let addMessage = firebase.functions().httpsCallable("scrollData");
    let sanitizedMessage = await addMessage({
      text: searchQuery,
      collection: "pincodecatalogueenriched",
      scrollID: "",
    }).then(function(result) {
      return result.data.text;
    });
    sanitizedMessage.hits.hits.map((data, index) => {
      invoices.push({ key: data._id, ...data._source });
    });
    // console.log("Invoices", JSON.stringify(sanitizedMessage.hits.total.value))
    console.log("invoices", invoices);
    // return invoices;
    let total = sanitizedMessage.hits.total.value;
    console.log("total,", total);
    let scrollID = sanitizedMessage._scroll_id;
    console.log("scrollID", scrollID);
    for (let index = 1; index < total / 10000; index++) {
      let addMessage = firebase.functions().httpsCallable("scrollData");
      let loopResult = await addMessage({
        text: { scroll: "1m", scroll_id: scrollID },
      });
      let loopDatas = loopResult.data.text.hits.hits;
      loopDatas.map((data, index) => {
        invoices.push({ key: data._id, ...data._source });
      });
      console.log("index", index);
    }

    let exportData = [];
    for (let i = 0; i < invoices.length; i++) {
      exportData.push({
        Type: invoices[i].Type,
        Code: invoices[i].Code,
        Name: invoices[i].Name,
        Category: invoices[i].Category,
        Subcategory: invoices[i].Subcategory,
        ProductType: invoices[i].ProductType,
        Brand: invoices[i].Brand,
        Model: invoices[i].Model,
        Quantity: invoices[i].Quantity,
        UOM: invoices[i].UOM,
        Packaging: invoices[i].Packaging,
        Bundling: invoices[i].Bundling,
        MRP: invoices[i].MRP,
        Image: invoices[i].Image,
      });
    }
    const csvData = constatnts.objToCSV(exportData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "PincodeGlobalCatalog.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async updatePincodeOrders(collection) {
    for (let i = 1; i < collection.length; i++) {
      console.log("i", i);

      let order_id = collection[i][0];
      console.log("order_id", order_id);
      await axios
        .post(
          "https://us-central1-ondc-posible-firestore-prod.cloudfunctions.net/apiUpdateOrderStats",
          { order_id: order_id }
        )
        .then((res) => {
          // console.log('getBrandList', res)
          return res.data;
        })
        .catch((err) => {
          // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }
  }

  async apiRetryOrders(collection) {
    for (let i = 1; i < collection.length; i++) {
      console.log("i", i);

      let confirm_document_id = collection[i][0];
      console.log("confirm_document_id", confirm_document_id);
      await axios
        .post(
          "https://us-central1-ondc-posible-firestore-prod.cloudfunctions.net/apiRetryOrders",
          { confirm_document_id: confirm_document_id }
        )
        .then((res) => {
          // console.log('getBrandList', res)
          return res.data;
        })
        .catch((err) => {
          // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }
  }

  async updateStorePostalCode(collection) {
    // collection.length
    console.log("updateStorePostalCode started");
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);

      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          PostalCode: collection[i][1],
        });
    }
    console.log("updateStorePostalCode ended");
  }

  async deleteStores(collection) {
    console.log("deleteStores started");
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);

      let store = await database
        .collection("Stores")
        .doc(providerID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Campaign found", doc.data());
            return doc.data();
          } else {
            console.log("No such store!");
          }
        });

      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          City: "",
          IsDeleted: true,
        });

      let registrationID = store.RegistrationID;

      let stores = await database
        .collection("Stores")
        .where("RegistrationID", "==", registrationID)
        .get()
        .then(async (querySnapshot) => {
          let list = [];
          querySnapshot.forEach(async (doc) => {
            let tmp = doc.data();
            tmp.key = doc.id;
            list.push(tmp);
          });
          return list;
        });
      console.log("stores.length", stores.length);

      if (stores.length === 1) {
        // console.log("More than one store", registrationID);
        //delete registration
        //delete user

        let registration = await database
          .collection("Registration")
          .doc(registrationID)
          .get()
          .then((doc) => {
            if (doc.exists) {
              // console.log("Campaign found", doc.data());
              return doc.data();
            } else {
              console.log("No such registrationID!");
            }
          });

        await database
          .collection("Registration")
          .doc(registrationID)
          .update({
            UniqueName: "",
            EnableONDC: false,
            IsDeleted: true,
          });
        console.log("user to be deleted", registration.EmailID);
        await axios
          .post(
            "https://us-central1-posible-app-prod.cloudfunctions.net/apiDeleteUser",
            { email: registration.EmailID }
          )
          .then((res) => {
            console.log("user deleted", res);
            // return res.data;
          })
          .catch((err) => {
            console.log("Error deleting user", err);
            // console.log('Could not get businessname for provided domain name. Try again later.');
          });
      } else {
        console.log("More than one store", registrationID);
      }
    }
  }

  async mapExternalStoreID(collection) {
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);
      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          ExternalStoreID: collection[i][1],
        });
    }
  }

  async enableInventoryCount(collection) {
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);
      let ShowProductsOnlineAvailabilityBasedOnStockInventoryCount = false;
      if (collection[i][1] && collection[i][1].toLowerCase() === "true") {
        ShowProductsOnlineAvailabilityBasedOnStockInventoryCount = true;
      }
      console.log(
        "ShowProductsOnlineAvailabilityBasedOnStockInventoryCount",
        ShowProductsOnlineAvailabilityBasedOnStockInventoryCount
      );
      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          ShowProductsOnlineAvailabilityBasedOnStockInventoryCount: ShowProductsOnlineAvailabilityBasedOnStockInventoryCount,
        });
    }
  }

  async nextDayDelivery(collection) {
    console.log("nextDayDelivery");
    for (let i = 1; i < collection.length; i++) {
      let providerID = collection[i][0];
      console.log("i", i);
      console.log("providerID", providerID);
      await database
        .collection("Stores")
        .doc(providerID)
        .update({
          NextDayDelivery: true,
        });
    }
  }

  async resendOrderStatusToPincode(collection) {
    console.log("apiUpdateOrderStats");
    for (let i = 1; i < collection.length; i++) {
      let order_id = collection[i][0];
      await axios
        .post(
          "https://us-central1-ondc-posible-firestore-prod.cloudfunctions.net/apiUpdateOrderStats",
          { order_id: order_id }
        )
        .then((res) => {
          // console.log('getBrandList', res)
          return res.data;
        })
        .catch((err) => {
          // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }
  }

  render() {
    return (
      <div>
        <div className="content-wrapper">
          <div className="container">
            {/* <button onClick={this.getPackedTransctions}>
              getPackedTransctions
            </button> */}

            {/* <button onClick={this.pushInvoices}>pushInvoices</button> */}
            {/* <button onClick={this.updateSKU}>updateSKU</button> */}

            {/* <button onClick={this.processEnterpriseRegistration}>
              processEnterpriseRegistration
            </button> */}
            {/* <button onClick={this.updateFlagForMKRetail}>
              updateFlagForMKRetail
            </button> */}
            {/* <button onClick={this.correctNotPresent}>
                  correctNotPresent
                </button> */}

            <div className="row">
              <div className="col-md-3">
                <button onClick={this.getPincodeGloablCatalog}>
                  Download master catalog
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <h2> Delete stores</h2>
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.deleteStores}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2> Enable Next Day Delivery</h2>
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.nextDayDelivery}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2> Update External Store ID</h2>
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.mapExternalStoreID}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2> Update Inventory Count Flag</h2>
                <div>ProviderID, Enable (True/False)</div>
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.enableInventoryCount}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2> Update Provider Postal Code</h2>
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.updateStorePostalCode}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2> Process Pincode Open Orders</h2>
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.apiProcessOpenCases}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2> Update Pincode Orders</h2>
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.updatePincodeOrders}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2> Retry Pincode Order Creation</h2>
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.apiRetryOrders}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <button onClick={this.getDeliveredShadowFaxOrder}>
                  Donwload Shadowfax Delivered Data
                </button>
              </div>
            </div>
            <br />
            <div className="row">
              <br></br>
              <button onClick={this.getImport}>Imports</button>
            </div>
            <div className="row">
              <div className="col-md-3">
                <h2>Push Transactions To Elastic</h2>
                <Input
                  type="text"
                  label="Registration ID"
                  onChange={(value) =>
                    this.setState({
                      pushToElasticRegistrationID: value,
                    })
                  }
                  value={this.state.pushToElasticRegistrationID}
                />
                <Input
                  label="From Date"
                  type="date"
                  value={this.state.pushToElasticFromDate}
                  onChange={(value) =>
                    this.setState({ pushToElasticFromDate: value })
                  }
                />
                <Input
                  label="To Date"
                  type="date"
                  value={this.state.pushToElasticToDate}
                  onChange={(value) =>
                    this.setState({ pushToElasticToDate: value })
                  }
                />
                <button onClick={this.pushInvoices}>
                  Push Data To Elastic
                </button>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <br />
                <br />
                <h2>Reset running now</h2>

                <Input
                  type="text"
                  label="Registration ID"
                  onChange={(value) =>
                    this.setState({
                      resetTransactionRunningNoRegistrationID: value,
                    })
                  }
                  value={this.state.resetTransactionRunningNoRegistrationID}
                />

                <button onClick={this.resetTransactionRunningNo}>
                  Reset Running No
                </button>
                <br />
                <br />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-3">
                <h2>Download stores</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <button onClick={this.downloadStores}>downloadStores</button>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2>Flush catalogue</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Input
                  type="text"
                  label="Registration ID"
                  value={this.state.registrationID}
                  onChange={(value) => this.setState({ registrationID: value })}
                />
                <br />
                <button onClick={this.convertStoreSpecificPricesToNormal}>
                  convertStoreSpecificPricesToNormal
                </button>

                <button onClick={this.processEnterpriseRegistration}>
                  processEnterpriseRegistration
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <button
                  onClick={this.deleteProducts}
                  disabled={this.state.processing}
                >
                  Flush catalogue
                </button>

                <div>Product Count: {this.state.productCount}</div>
                <div>Deleted Count: {this.state.deletedCount}</div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">
                <h2>Download Pincode Orders</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Input
                  label="From Date"
                  type="date"
                  value={this.state.fromDate}
                  onChange={(value) => this.setState({ fromDate: value })}
                />
              </div>
              <div className="col-md-3">
                <Input
                  label="To Date"
                  type="date"
                  value={this.state.toDate}
                  onChange={(value) => this.setState({ toDate: value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <button onClick={this.getPinCodeOrders}>
                  Download pincode orders
                </button>
                <br></br>
                <br></br>
                <button onClick={this.getCanceledShadowFaxOrder}>
                  Get Cancelled Shadowfax orders
                </button>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />

            <div className="row">
              <div className="col-md-3">Enable SKU</div>
            </div>
            <div className="row">
              <div className="col-md-3">ProviderID, Enable (True/False)</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.onEnablePincodeSKU}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">Enable Paybill</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.enbalePayBill}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <button onClick={this.getRegistrationToBeProcessed}>
              getRegistrationToBeProcessed
            </button>
            <br />
            <br />
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">Update Pincode Hash Key</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.onUpdatePincodeHashKey}
                  inputId="ObiWan"
                />
              </div>
            </div>
            {/* <br />
            <br />
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">Disable customer pickup for all</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <button onClick={this.disableCustomerPickUpForAllStores}>
                  disableCustomerPickUpForAllStores
                </button>
              </div>
            </div> */}
            {/* <button onClick={this.getRegistrationToBeProcessed}>
              getRegistrationToBeProcessed
            </button> */}

            {/* <button onClick={this.getSearchData}>getSearchData</button> */}
            {/* <button onClick={this.getPowerUser}>getPowerUser</button> */}
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">
                Update Delivery Charges<br></br>Columns:- Provider Id,Delivery
                Fee,Delivery charge below
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.updateDeliveryCharges}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">
                Change Delivery Type (self/shadowfax)
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">Columns: ProviderID, DeliveryType</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.changeDeliveryType}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">Enable Pincode Delivery</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.enbalePincodeDelivery}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">
                Enable Pincode Order Management <br></br>Columns - ProviderID,
                EnablePincodeOrderManagement=(true/false)
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.onEnablePincodeOrderManagement}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <br />

            <div className="row">
              <div className="col-md-3">
                lock / Unlock Stores <br></br>Columns - ProviderID, Lock.
                Lock=(true/false)
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.lockUnlockStores}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">Update GeoJSON and Delivery Radius</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.updateGeoJSON}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">Enable Disable Customer Pickup</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.onEnableDisableCustomerPickup}
                  inputId="ObiWan"
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-md-3">Resend Order Status To Pincode</div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.resendOrderStatusToPincode}
                  inputId="ObiWan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OnBoarding);
