import React, { Component } from "react";
import firebase from "../../firebase";
import * as constatnts from "../../Constatnts";

class ProductExport extends Component {
  constructor() {
    super();
    this.state = {};
    this.onBackClick = this.onBackClick.bind(this);
    this.exportProduct = this.exportProduct.bind(this);
  }

  async exportProduct() {
    let products = this.props.config.Products;
    let exportData = [];
    for (let i = 0; i < products.length; i++) {
      let product = products[i];

      if (
        product.hasOwnProperty("ParentProductID") &&
        product.ParentProductID !== ""
      ) {
        continue;
      }
      let variant1 = "";
      let variant1Value = "";
      let variant2 = "";
      let variant2Value = "";
      let variant3 = "";
      let variant3Value = "";
      let parentProductID = "";
      let parentSKU = "";
      if (
        product.hasOwnProperty("Attributes") &&
        product.Attributes.length > 0
      ) {
        variant1 = product.Attributes[0].Attribute;
        product.Attributes[0].AttributeValues.map((a) => {
          if (variant1Value === "") {
            variant1Value = a.value;
          } else {
            variant1Value = variant1Value + "," + a.value;
          }
        });

        if (product.Attributes.length > 1) {
          variant2 = product.Attributes[1].Attribute;
          product.Attributes[1].AttributeValues.map((a) => {
            if (variant2Value === "") {
              variant2Value = a.value;
            } else {
              variant2Value = variant2Value + "," + a.value;
            }
          });
        }
        if (product.Attributes.length > 2) {
          variant3 = product.Attributes[2].Attribute;
          product.Attributes[2].AttributeValues.map((a) => {
            if (variant3Value === "") {
              variant3Value = a.value;
            } else {
              variant3Value = variant3Value + "," + a.value;
            }
          });
        }
      }

      let image1 = "";
      let image2 = "";
      let image3 = "";
      let image4 = "";

      if (product.hasOwnProperty("Images") && product.Images.length > 0) {
        for (let i = 0; i < product.Images.length; i++) {
          if (i === 0) {
            image1 = product.Images[i].ImagePath;
          }
          if (i === 1) {
            image2 = product.Images[i].ImagePath;
          }
          if (i === 2) {
            image3 = product.Images[i].ImagePath;
          }
          if (i === 3) {
            image4 = product.Images[i].ImagePath;
          }
        }
      }

      let PriceRangeFrom1 = "";
      let PriceRangeTo1 = "";
      let TaxGroup1 = "";
      let PriceRangeFrom2 = "";
      let PriceRangeTo2 = "";
      let TaxGroup2 = "";

      if (
        product.hasOwnProperty("TaxPriceRange") &&
        product.TaxPriceRange.length > 0
      ) {
        TaxGroup1 = product.TaxPriceRange[0].TaxGroup;
        PriceRangeFrom1 = product.TaxPriceRange[0].FromPrice;
        PriceRangeTo1 = product.TaxPriceRange[0].ToPrice;

        if (product.TaxPriceRange.length > 1) {
          TaxGroup2 = product.TaxPriceRange[1].TaxGroup;
          PriceRangeFrom2 = product.TaxPriceRange[1].FromPrice;
          PriceRangeTo2 = product.TaxPriceRange[1].ToPrice;
        }
      }
      exportData.push({
        ProductType: product.ProductType,
        SKU: product.SKU,
        BarCode: product.BarCode,
        Brand: product.Brand,
        Category: product.Category,
        SubCategory: product.SubCategory,
        Name: product.Name,
        Description: product.Description,
        HSNSACCode: product.HSNSACCode,
        ChargeTaxOnProduct: product.ChargeTaxOnProduct,
        MRP: product.MRP,
        SupplyPrice: product.SupplyPrice,
        RetailPrice: product.RetailPrice,
        Markup: product.Markup,
        DiscountPercentage: product.DiscountPercentage,
        DiscountAmount: product.DiscountAmount,
        TaxInclusive: product.TaxInclusive,
        TaxInclusivePurchase: product.TaxInclusivePurchase,
        TaxGroup: product.TaxGroup,
        PriceBasedTax: product.PriceBasedTax,
        PriceRangeFrom1: PriceRangeFrom1,
        PriceRangeTo1: PriceRangeTo1,
        TaxGroup1: TaxGroup1,
        PriceRangeFrom2: PriceRangeFrom2,
        PriceRangeTo2: PriceRangeTo2,
        TaxGroup2: TaxGroup2,
        InventoryType: product.InventoryType,
        RepackProduce: product.RepackProduce,
        MinQty: product.MinQty,
        MaxQty: product.MaxQty,
        ReorderQty: product.ReorderQty,
        UniqueNoLabel: product.hasOwnProperty("UniqueNoLabel")
          ? product.UniqueNoLabel
          : "",
        NoOfUniqueNoPerItem: product.hasOwnProperty("NoOfUniqueNoPerItem")
          ? product.UniqueNoLabel
          : "",
        AllowDiscount: product.AllowDiscount,
        AllowPriceEdit: product.AllowPriceEdit,
        AllowTaxChange: product.AllowTaxChange,
        WalletTopUpAmount: product.WalletTopUpAmount,
        WalletTopUpSameAsRetailPrice: product.WalletTopUpSameAsRetailPrice,
        AllowUserToSpecifyRechargeAmountAtTimeOfBilling: product.hasOwnProperty(
          "AllowUserToSpecifyRechargeAmountAtTimeOfBilling"
        )
          ? product.AllowUserToSpecifyRechargeAmountAtTimeOfBilling
          : "",
        CustomField1: product.CustomField1,
        CustomField2: product.CustomField2,
        CustomField3: product.CustomField4,
        CustomField4: product.CustomField4,
        CustomField5: product.CustomField5,
        CustomField6: product.CustomField6,
        UOM: product.UOM,
        SecondaryUOM: "",
        UOMToSecondaryUOMConversion: "",
        SalesUOM: product.SalesUOM,
        PurchaseUOM: product.PurchaseUOM,
        PromptForQty: product.hasOwnProperty("PromptForQty")
          ? product.PromptForQty
          : "",
        Favourite: product.Favourite,
        ShowOnline: product.OMNIEnabled,
        BOM: product.BOM,
        BOMManual: product.BOMManual,
        CanBeUsedAsConsumable: product.CanBeUsedAsConsumable,
        AssociateMembership: product.AssociateMembership,
        Membership: product.Membership,
        BatchTrackingBatchNoRequired: product.BatchTrackingBatchNoRequired,
        BatchTrackingMRPRequired: product.BatchTrackingMRPRequired,
        BatchTrackingMfgDateRequired: product.BatchTrackingMfgDateRequired,
        BatchTrackingExpDateRequired: product.BatchTrackingExpDateRequired,
        BatchTrackingDateFormat: product.BatchTrackingDateFormat,
        ParentSKU: parentSKU,
        VariantOptionOneName: variant1,
        VariantOptionOneValue: variant1Value,
        VariantOptionTwoName: variant2,
        VariantOptionTwoValue: variant2Value,
        VariantOptionThreeName: variant3,
        VariantOptionThreeValue: variant3Value,
        Image1: image1,
        Image2: image2,
        Image3: image3,
        Image4: image4,
        ProductID: product.key,
        ParentProductID: parentProductID,
      });
      if (product.hasOwnProperty("Variants") && product.Variants.length > 0) {
        for (let v = 0; v < product.Variants.length; v++) {
          let variant = product.Variants[v];
          if (variant.ProductID && variant.ProductID !== "") {
            exportData.push({
              ProductType: product.ProductType,
              SKU: variant.SKU,
              BarCode: product.BarCode,
              Brand: product.Brand,
              Category: product.Category,
              SubCategory: product.SubCategory,
              Name: product.Name + " " + variant.Name,
              Description: product.Description,
              HSNSACCode: product.HSNSACCode,
              ChargeTaxOnProduct: product.ChargeTaxOnProduct,
              MRP: product.MRP,
              SupplyPrice: variant.SupplyPrice,
              RetailPrice: variant.RetailPrice,
              Markup: product.Markup,
              DiscountPercentage: variant.DiscountPercentage,
              DiscountAmount: variant.DiscountAmount,
              TaxInclusive: product.TaxInclusive,
              TaxInclusivePurchase: product.TaxInclusivePurchase,
              TaxGroup: product.TaxGroup,
              PriceBasedTax: product.PriceBasedTax,
              PriceRangeFrom1: PriceRangeFrom1,
              PriceRangeTo1: PriceRangeTo1,
              TaxGroup1: TaxGroup1,
              PriceRangeFrom2: PriceRangeFrom2,
              PriceRangeTo2: PriceRangeTo2,
              TaxGroup2: TaxGroup2,
              InventoryType: product.InventoryType,
              RepackProduce: product.RepackProduce,
              MinQty: product.MinQty,
              MaxQty: product.MaxQty,
              ReorderQty: product.ReorderQty,
              UniqueNoLabel: product.hasOwnProperty("UniqueNoLabel")
                ? product.UniqueNoLabel
                : "",
              NoOfUniqueNoPerItem: product.hasOwnProperty("NoOfUniqueNoPerItem")
                ? product.UniqueNoLabel
                : "",
              AllowDiscount: product.AllowDiscount,
              AllowPriceEdit: product.AllowPriceEdit,
              AllowTaxChange: product.AllowTaxChange,
              WalletTopUpAmount: product.WalletTopUpAmount,
              WalletTopUpSameAsRetailPrice:
                product.WalletTopUpSameAsRetailPrice,
              AllowUserToSpecifyRechargeAmountAtTimeOfBilling: product.hasOwnProperty(
                "AllowUserToSpecifyRechargeAmountAtTimeOfBilling"
              )
                ? product.AllowUserToSpecifyRechargeAmountAtTimeOfBilling
                : "",
              CustomField1: product.CustomField1,
              CustomField2: product.CustomField2,
              CustomField3: product.CustomField4,
              CustomField4: product.CustomField4,
              CustomField5: product.CustomField5,
              CustomField6: product.CustomField6,
              UOM: product.UOM,
              SecondaryUOM: "",
              UOMToSecondaryUOMConversion: "",
              SalesUOM: product.SalesUOM,
              PurchaseUOM: product.PurchaseUOM,
              PromptForQty: product.hasOwnProperty("PromptForQty")
                ? product.PromptForQty
                : "",
              Favourite: product.Favourite,
              ShowOnline: product.OMNIEnabled,
              BOM: product.BOM,
              BOMManual: product.BOMManual,
              CanBeUsedAsConsumable: product.CanBeUsedAsConsumable,
              AssociateMembership: product.AssociateMembership,
              Membership: product.Membership,
              BatchTrackingBatchNoRequired:
                product.BatchTrackingBatchNoRequired,
              BatchTrackingMRPRequired: product.BatchTrackingMRPRequired,
              BatchTrackingMfgDateRequired:
                product.BatchTrackingMfgDateRequired,
              BatchTrackingExpDateRequired:
                product.BatchTrackingExpDateRequired,
              BatchTrackingDateFormat: product.BatchTrackingDateFormat,
              ParentSKU: parentSKU,
              VariantOptionOneName: variant1,
              VariantOptionOneValue: variant.Attribute1,
              VariantOptionTwoName: variant2,
              VariantOptionTwoValue: variant.Attribute2,
              VariantOptionThreeName: variant3,
              VariantOptionThreeValue: variant.Attribute3,
              Image1: image1,
              Image2: image2,
              Image3: image3,
              Image4: image4,
              ProductID: variant.ProductID,
              ParentProductID: parentProductID,
            });
          }
        }
      }
    }
    const csvData = constatnts.objToCSV(exportData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "products.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  async exportProductold() {
    // let searchQuery = {
    //   size: 5000,
    //   track_total_hits: true,
    //   query: {
    //     bool: {
    //       must: [
    //         {
    //           match: {
    //             RegistrationID: this.props.registration.registration.key,
    //           },
    //         },
    //         {
    //           match: {
    //             IsDeleted: false,
    //           },
    //         },
    //       ],
    //     },
    //   },
    // };
    // let products = [];
    // let addMessage = firebase.functions().httpsCallable("scrollData");
    // let sanitizedMessage = await addMessage({
    //   text: searchQuery,
    //   collection: "products",
    //   scrollID: "",
    // }).then(function(result) {
    //   return result.data.text;
    // });
    // sanitizedMessage.hits.hits.map((data, index) => {
    //   products.push({ key: data._id, ...data._source });
    // });
    // // console.log("Invoices", JSON.stringify(sanitizedMessage.hits.total.value))
    // console.log("products", products);
    // // return invoices;
    // let total = sanitizedMessage.hits.total.value;
    // console.log("total,", total);
    // let scrollID = sanitizedMessage._scroll_id;
    // console.log("scrollID", scrollID);
    // for (let index = 1; index < total / 5000; index++) {
    //   let addMessage = firebase.functions().httpsCallable("scrollData");
    //   let loopResult = await addMessage({
    //     text: {
    //       scroll: "1m",
    //       scroll_id: scrollID,
    //     },
    //   });
    //   let loopDatas = loopResult.data.text.hits.hits;
    //   loopDatas.map((data, index) => {
    //     products.push({ key: data._id, ...data._source });
    //   });
    // }

    let products = this.props.config.Products;
    let exportData = [];
    for (let i = 0; i < products.length; i++) {
      let product = products[i];

      let variant1 = "";
      let variant1Value = "";
      let variant2 = "";
      let variant2Value = "";
      let variant3 = "";
      let variant3Value = "";
      let parentProductID = "";
      let parentSKU = "";
      if (
        product.hasOwnProperty("Attributes") &&
        product.Attributes.length > 0
      ) {
        variant1 = product.Attributes[0].Attribute;
        product.Attributes[0].AttributeValues.map((a) => {
          if (variant1Value === "") {
            variant1Value = a.value;
          } else {
            variant1Value = variant1Value + "," + a.value;
          }
        });

        if (product.Attributes.length > 1) {
          variant2 = product.Attributes[1].Attribute;
          product.Attributes[1].AttributeValues.map((a) => {
            if (variant2Value === "") {
              variant2Value = a.value;
            } else {
              variant2Value = variant2Value + "," + a.value;
            }
          });
        }
        if (product.Attributes.length > 2) {
          variant3 = product.Attributes[2].Attribute;
          product.Attributes[2].AttributeValues.map((a) => {
            if (variant3Value === "") {
              variant3Value = a.value;
            } else {
              variant3Value = variant3Value + "," + a.value;
            }
          });
        }
      }
      if (
        product.hasOwnProperty("ParentProductID") &&
        product.ParentProductID !== ""
      ) {
        parentProductID = product.ParentProductID;

        console.log("product", product);
        //get parent product
        if (products.filter((f) => f.key === parentProductID).length > 0) {
          let parentProduct = products.filter(
            (f) => f.key === parentProductID
          )[0];
          console.log("parentProduct", parentProduct);
          parentSKU = parentProduct.SKU;
          if (
            parentProduct.hasOwnProperty("Attributes") &&
            parentProduct.Attributes.length > 0
          ) {
            variant1 = parentProduct.Attributes[0].Attribute;

            if (parentProduct.Attributes.length > 1) {
              variant2 = parentProduct.Attributes[1].Attribute;
            }
            if (parentProduct.Attributes.length > 2) {
              variant3 = parentProduct.Attributes[2].Attribute;
            }
          }
        }
        variant1Value = product.Attribute1;
        variant2Value = product.Attribute2;
        variant3Value = product.Attribute3;
      }
      let image1 = "";
      let image2 = "";
      let image3 = "";
      let image4 = "";

      if (product.hasOwnProperty("Images") && product.Images.length > 0) {
        for (let i = 0; i < product.Images.length; i++) {
          if (i === 0) {
            image1 = product.Images[i].ImagePath;
          }
          if (i === 1) {
            image2 = product.Images[i].ImagePath;
          }
          if (i === 2) {
            image3 = product.Images[i].ImagePath;
          }
          if (i === 3) {
            image4 = product.Images[i].ImagePath;
          }
        }
      }

      let PriceRangeFrom1 = "";
      let PriceRangeTo1 = "";
      let TaxGroup1 = "";
      let PriceRangeFrom2 = "";
      let PriceRangeTo2 = "";
      let TaxGroup2 = "";

      if (
        product.hasOwnProperty("TaxPriceRange") &&
        product.TaxPriceRange.length > 0
      ) {
        TaxGroup1 = product.TaxPriceRange[0].TaxGroup;
        PriceRangeFrom1 = product.TaxPriceRange[0].FromPrice;
        PriceRangeTo1 = product.TaxPriceRange[0].ToPrice;

        if (product.TaxPriceRange.length > 1) {
          TaxGroup2 = product.TaxPriceRange[1].TaxGroup;
          PriceRangeFrom2 = product.TaxPriceRange[1].FromPrice;
          PriceRangeTo2 = product.TaxPriceRange[1].ToPrice;
        }
      }
      exportData.push({
        ProductType: product.ProductType,
        SKU: product.SKU,
        BarCode: product.BarCode,
        Brand: product.Brand,
        Category: product.Category,
        SubCategory: product.SubCategory,
        Name: product.Name,
        Description: product.Description,
        HSNSACCode: product.HSNSACCode,
        ChargeTaxOnProduct: product.ChargeTaxOnProduct,
        MRP: product.MRP,
        SupplyPrice: product.SupplyPrice,
        RetailPrice: product.RetailPrice,
        Markup: product.Markup,
        DiscountPercentage: product.DiscountPercentage,
        DiscountAmount: product.DiscountAmount,
        TaxInclusive: product.TaxInclusive,
        TaxInclusivePurchase: product.TaxInclusivePurchase,
        TaxGroup: product.TaxGroup,
        PriceBasedTax: product.PriceBasedTax,
        PriceRangeFrom1: PriceRangeFrom1,
        PriceRangeTo1: PriceRangeTo1,
        TaxGroup1: TaxGroup1,
        PriceRangeFrom2: PriceRangeFrom2,
        PriceRangeTo2: PriceRangeTo2,
        TaxGroup2: TaxGroup2,
        InventoryType: product.InventoryType,
        RepackProduce: product.RepackProduce,
        MinQty: product.MinQty,
        MaxQty: product.MaxQty,
        ReorderQty: product.ReorderQty,
        UniqueNoLabel: product.hasOwnProperty("UniqueNoLabel")
          ? product.UniqueNoLabel
          : "",
        NoOfUniqueNoPerItem: product.hasOwnProperty("NoOfUniqueNoPerItem")
          ? product.UniqueNoLabel
          : "",
        AllowDiscount: product.AllowDiscount,
        AllowPriceEdit: product.AllowPriceEdit,
        AllowTaxChange: product.AllowTaxChange,
        WalletTopUpAmount: product.WalletTopUpAmount,
        WalletTopUpSameAsRetailPrice: product.WalletTopUpSameAsRetailPrice,
        AllowUserToSpecifyRechargeAmountAtTimeOfBilling: product.hasOwnProperty(
          "AllowUserToSpecifyRechargeAmountAtTimeOfBilling"
        )
          ? product.AllowUserToSpecifyRechargeAmountAtTimeOfBilling
          : "",
        CustomField1: product.CustomField1,
        CustomField2: product.CustomField2,
        CustomField3: product.CustomField4,
        CustomField4: product.CustomField4,
        CustomField5: product.CustomField5,
        CustomField6: product.CustomField6,
        UOM: product.UOM,
        SecondaryUOM: "",
        UOMToSecondaryUOMConversion: "",
        SalesUOM: product.SalesUOM,
        PurchaseUOM: product.PurchaseUOM,
        PromptForQty: product.hasOwnProperty("PromptForQty")
          ? product.PromptForQty
          : "",
        Favourite: product.Favourite,
        ShowOnline: product.OMNIEnabled,
        BOM: product.BOM,
        BOMManual: product.BOMManual,
        CanBeUsedAsConsumable: product.CanBeUsedAsConsumable,
        AssociateMembership: product.AssociateMembership,
        Membership: product.Membership,
        BatchTrackingBatchNoRequired: product.BatchTrackingBatchNoRequired,
        BatchTrackingMRPRequired: product.BatchTrackingMRPRequired,
        BatchTrackingMfgDateRequired: product.BatchTrackingMfgDateRequired,
        BatchTrackingExpDateRequired: product.BatchTrackingExpDateRequired,
        BatchTrackingDateFormat: product.BatchTrackingDateFormat,
        ParentSKU: parentSKU,
        VariantOptionOneName: variant1,
        VariantOptionOneValue: variant1Value,
        VariantOptionTwoName: variant2,
        VariantOptionTwoValue: variant2Value,
        VariantOptionThreeName: variant3,
        VariantOptionThreeValue: variant3Value,
        Image1: image1,
        Image2: image2,
        Image3: image3,
        Image4: image4,
        ProductID: product.key,
        ParentProductID: parentProductID,
      });
    }
    const csvData = constatnts.objToCSV(exportData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "products.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  onBackClick() {
    this.props.history.push({ pathname: "/home/reports/allreport" });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>Product Export</font>
                    </div>
                    <div className="col-md-6">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={this.onBackClick}
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-3">
                      <button
                        className="btn btn-default btn-flat"
                        onClick={this.exportProduct}
                      >
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ProductExport;
