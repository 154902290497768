import React, { Component } from "react";
import "./ReportHeader.css";

class ReportHeader extends Component {
  render() {
    return (
      <div className="report-header">
        <div className="report-header-title">{this.props.title}</div>
        {this.props.showBack && (
          <div className="report-header-back">
            <button onClick={() => this.props.onBack()}>
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default ReportHeader;
