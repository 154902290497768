import React, { Component } from "react";
import "./ReportButton.css";

class ReportButton extends Component {
  render() {
    return (
      <>
        {this.props.type === "search" && (
          <button
            type="button"
            className="btn-md report-button-search pull-right"
            onClick={this.props.onClick}
          >
            Search
          </button>
        )}
        {this.props.type === "clear" && (
          <button
            type="button"
            onClick={this.props.onClick}
            className="btn-md report-button-clear pull-right"
          >
            Clear
          </button>
        )}
        {this.props.type === "export" && (
          <button
            className="btn-md report-button-export pull-right"
            onClick={this.props.onClick}
          >
            Export <i className="fa fa-download"></i>
          </button>
        )}
      </>
    );
  }
}

export default ReportButton;
