import React, { Component } from "react";
import Window from "../2/Controls/Window";
import ReportHeader from "./Common/ReportHeader";
import ReportBody from "./Common/ReportBody";
import Input from "../2/Controls/Input";
import ReportButton from "./Common/ReportButton";
import firebase from "../../firebase";
import Spinner from "../Common/Spinner";

class PincodeInstockReport extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      products: [],
    };
  }

  async componentDidMount() {
    let title =
      this.props.type === "1"
        ? "Items with one qty"
        : this.props.type === "0"
        ? "Items with zero qty"
        : this.props.type === "negative"
        ? "Items with negative qty"
        : "";
    this.setState({ title: title });

    await this.getInstockData(this.props.user.store.key, 0, 50, "", "", "");
  }

  async getInstockData(storeID, from, size, sku, barcode, productname) {
    let searchParameters = {
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              match: {
                "StoreID.keyword": storeID,
              },
            },
          ],
        },
      },
      size: size,
      from: from,
    };
    if (this.props.type === "1") {
      searchParameters.query.bool.must.push({
        match: {
          InStockQty: 1,
        },
      });
    } else if (this.props.type === "0") {
      searchParameters.query.bool.must.push({
        match: {
          InStockQty: 0,
        },
      });
    } else if (this.props.type === "negative") {
      searchParameters.query.bool.must.push({
        range: {
          InStockQty: {
            lt: 0,
          },
        },
      });
    }
    if (sku.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "ProductSKU.keyword": {
            value: sku + "*",
          },
        },
      });
    }
    if (barcode.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "BarCode.keyword": {
            value: sku + "*",
          },
        },
      });
    }
    if (productname.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "ProductName.keyword": {
            value: productname + "*",
          },
        },
      });
    }
    console.log("searchParameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getInventoryInStock");
    let result = await addMessage({ text: searchParameters });
    let sanitizedMessage = result.data.text;
    let inventoryInstocQtys = [];
    let productIDs = [];
    let totalRecordCount = sanitizedMessage.hits.total.value;
    for (let i = 0; i < sanitizedMessage.hits.hits.length; i++) {
      let obj = sanitizedMessage.hits.hits[i];
      inventoryInstocQtys.push({ key: obj._id, ...obj._source });
      productIDs.push(obj._source.ProductID);
    }
    console.log("inventoryInstocQtys", inventoryInstocQtys);

    let res1 = await this.props.getProductsByProductIDs(productIDs);
    let productList = res1.searchResult;
    console.log("productList", productList);

    let products = [];
    for (let index = 0; index < inventoryInstocQtys.length; index++) {
      const data = inventoryInstocQtys[index];
      let product = {
        key: data.key,
        StoreName: data.StoreName,
        StoreLocation: data.Location,
        Brand: data.Brand,
        Category: data.Category,
        SubCategory: data.SubCategory,
        ProductSKU: data.ProductSKU,
        BarCode: data.BarCode,
        ProductID: data.ProductID,
        ProductName: data.ProductName,
        MRP: 0,
        RetailPrice: Number(data.RetailPrice).toFixed(2),
        InStockQty: Number(data.InStockQty ? data.InStockQty : 0).toFixed(2),
      };

      if (productList.filter((p) => p.key === data.ProductID).length > 0) {
        let objProd = productList.filter((p) => p.key === data.ProductID)[0];
        product.Image =
          objProd.images && objProd.images.length > 0
            ? objProd.images[0].ImagePath
            : "";
        let mrp = objProd.mrp;
        let retailPrice = objProd.retailPrice;
        if (
          objProd.storeSpecificPrices.filter((f) => f.StoreID === storeID) > 0
        ) {
          let ssp = objProd.StoreSpecificPrices.filter(
            (f) => f.StoreID === storeID
          )[0];
          mrp = ssp.MRP;
          retailPrice = ssp.RetailPrice;
        }
        product.MRP = mrp;
        product.RetailPrice = retailPrice;
        product.Brand = objProd.brand;
        product.Category = objProd.category;
        product.SubCategory = objProd.sub_category;
        product.ProductName = objProd.product;
      }
      products.push(product);
    }
    console.log("products", products);

    this.setState({
      products: products,
      totalItemsCount: totalRecordCount,
      loading: false,
    });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <ReportHeader
            title={this.state.title}
            showBack={true}
            onBack={() => {
              console.log("back clicked");
            }}
          ></ReportHeader>
          <ReportBody>
            <div className="row">
              <div className="col-md-2">
                <Input
                  type="text"
                  label="SKU"
                  value={this.state.SKU}
                  onChange={(value) => this.setState({ SKU: value })}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="text"
                  label="Barcode"
                  value={this.state.barcode}
                  onChange={(value) => this.setState({ barcode: value })}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="text"
                  label="Product name"
                  value={this.state.productName}
                  onChange={(value) => this.setState({ productName: value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pull-right">
                {/* <ReportButton type="search" onClick={()=>}/> */}
                <ReportButton type="clear" />
                <ReportButton type="export" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.loading && <Spinner />}
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>SKU</th>
                      <th>Barcode</th>
                      <th>Name</th>
                      <th>MRP</th>
                      <th>Retail Price</th>
                      <th>In Stock Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products.map((m, index) => (
                      <tr key={"tdtr" + index}>
                        <td></td>
                        <td>{m.ProductSKU}</td>
                        <td>{m.BarCode}</td>
                        <td>{m.ProductName}</td>
                        <td>{m.MRP}</td>
                        <td>{m.RetailPrice}</td>
                        <td>{m.InStockQty}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ReportBody>
        </section>
      </div>
    );
  }
}

export default PincodeInstockReport;
