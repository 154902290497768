import React, { Component } from "react";
import "./InvoiceDetail.css";
import firebase from "../../firebase";

class PincodeInStockDashboard extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      instockZero: 0,
      instockOne: 0,
      instockNegative: 0,
      gcIssue: 0,
      outOfStock: 0,
      outOfStockDueToPartialCancellation: 0,
    };
    this.getInstockData = this.getInstockData.bind(this);
    this.getCatalogIssueData = this.getCatalogIssueData.bind(this);
    this.getManualOutOfStock = this.getManualOutOfStock.bind(this);
  }

  async getInstockData(storeID) {
    let searchParameters = {
      query: {
        bool: {
          must: [
            {
              match: {
                "StoreID.keyword": storeID,
              },
            },
          ],
        },
      },
      size: 0,
      aggs: {
        in_stock_counts: {
          filters: {
            filters: {
              instock_1: {
                term: {
                  InStockQty: 1,
                },
              },
              instock_0: {
                term: {
                  InStockQty: 0,
                },
              },
              instock_negative: {
                range: {
                  InStockQty: {
                    lt: 0,
                  },
                },
              },
            },
          },
        },
      },
    };

    let addMessage = firebase.functions().httpsCallable("getInventoryInStock");
    let result = await addMessage({ text: searchParameters });
    let instockZero = 0;
    let instockOne = 0;
    let instockNegative = 0;

    let sanitizedMessage = result.data.text;
    instockZero =
      sanitizedMessage.aggregations.in_stock_counts.buckets.instock_0.doc_count;
    instockOne =
      sanitizedMessage.aggregations.in_stock_counts.buckets.instock_1.doc_count;
    instockNegative =
      sanitizedMessage.aggregations.in_stock_counts.buckets.instock_negative
        .doc_count;

    this.setState({
      instockZero: instockZero,
      instockOne: instockOne,
      instockNegative: instockNegative,
      outOfStock: 0,
    });
  }

  async getCatalogIssueData(registrationID) {
    let searchParameters = {
      size: 0,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              match: {
                RegistrationID: registrationID,
              },
            },
            {
              match: {
                IsDeleted: false,
              },
            },
          ],
          filter: {
            bool: {
              must: [
                {
                  exists: {
                    field: "CustomField4",
                  },
                },
              ],
              must_not: [
                {
                  term: {
                    "CustomField4.keyword": "",
                  },
                },
              ],
            },
          },
        },
      },
    };

    let addMessage = firebase.functions().httpsCallable("getProducts");
    let result = await addMessage({ text: searchParameters });
    let gcIssue = 0;

    let sanitizedMessage = result.data.text;
    gcIssue = sanitizedMessage.hits.total.value;

    this.setState({
      gcIssue: gcIssue,
    });
  }

  async getManualOutOfStock(storeID) {
    let searchParameters = {
      size: 0,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              match: {
                "StoreSpecificPrices.StoreID.keyword": storeID,
              },
            },
            {
              match: {
                IsDeleted: false,
              },
            },
            {
              match: {
                "StoreSpecificPrices.OutOfStock": true,
              },
            },
          ],
        },
      },
    };

    let addMessage = firebase.functions().httpsCallable("getProducts");
    let result = await addMessage({ text: searchParameters });
    let outOfStock = 0;

    let sanitizedMessage = result.data.text;
    outOfStock = sanitizedMessage.hits.total.value;

    this.setState({
      outOfStock: outOfStock,
    });
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getInstockData(this.props.user.store.key);
    await this.getCatalogIssueData(this.props.registration.registration.key);
    await this.getManualOutOfStock(this.props.user.store.key);
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="dashboard-box">
        <div className="dashboard-box-header">
          <div className="dashboard-box-title left">Product</div>
        </div>
        <div className="dashboard-box-body">
          <div className="table-responsive scroll2">
            <table className="table  table-striped no-margin">
              <tbody>
                <tr>
                  <td>Items with 1 qty</td>
                  <td style={{ textAlign: "right" }}>
                    <a
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/home/reports/pincode/instockqty/1",
                        })
                      }
                    >
                      {this.state.instockOne}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Items with 0 qty</td>
                  <td style={{ textAlign: "right" }}>
                    <a
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/home/reports/pincode/instockqty/0",
                        })
                      }
                    >
                      {this.state.instockZero}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Items with -ve qty</td>
                  <td style={{ textAlign: "right" }}>
                    <a
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/home/reports/pincode/instockqty/negative",
                        })
                      }
                    >
                      {this.state.instockNegative}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Items with catalog issue</td>
                  <td style={{ textAlign: "right" }}>
                    <a>{this.state.gcIssue}</a>
                  </td>
                </tr>
                <tr>
                  <td>Items marked as show out of stock on pincode app </td>
                  <td style={{ textAlign: "right" }}>
                    <a>{this.state.outOfStock}</a>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    Items marked as show out of stock on pincode app due to
                    partial cancellation
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <a> {this.state.outOfStockDueToPartialCancellation}</a>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default PincodeInStockDashboard;
