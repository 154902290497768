import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Loader from "../Loader";
import * as constants from "../../Constatnts";

class PaymentModeTransactionType extends Component {
  constructor() {
    super();
    this.state = {
      payments: [],
      storeList: [],
      searchStore: "",
      searchCashRegister: "",
      searchTransactionType: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
    };

    this.loadPaymentWiseDashboardData = this.loadPaymentWiseDashboardData.bind(
      this
    );
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });
    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });

    this.loadPaymentWiseDashboardData(
      storeList,
      this.state.searchCashRegister,
      this.state.searchTransactionType,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  loadPaymentWiseDashboardData(
    storeList,
    searchCashRegister,
    searchTransactionType,
    searchTransactionFromDate,
    searchTransactionToDate
  ) {
    this.props
      .getPaymentModeTransactionTypeWiseSales(
        this.props.user.user.RegistrationID,
        storeList,
        searchTransactionType,
        searchTransactionFromDate,
        searchTransactionToDate,
        false
      )
      .then((result) => {
        let paymentData = [];

        for (let index = 0; index < result.searchResult.length; index++) {
          const payment = result.searchResult[index];

          var obj = {
            "Payment Mode": payment.name,
          };

          this.props.config.TransactionTypes.filter(
            (e) => e.PaymentModeChecked === true && !e.IsDeleted
          ).map((e) => {
            obj[e.TransactionType] = 0;
            for (
              let index1 = 0;
              index1 < payment.transactionTypes.length;
              index1++
            ) {
              if (e.TransactionType === payment.transactionTypes[index1].name) {
                obj[e.TransactionType] =
                  payment.transactionTypes[index1].total_tran_amount;
              }
            }
          });
          obj["Totals"] = payment.total_mode_amount;
          paymentData.push(obj);
        }
        var col = [];
        for (var i = 0; i < paymentData.length; i++) {
          for (var key in paymentData[i]) {
            if (col.indexOf(key) === -1) {
              col.push(key);
            }
          }
        }
        var result = "<table class='table table-bordered'><thead><tr>";
        for (var i = 0; i < col.length; i++) {
          result += "<th>";
          result += col[i];
          result += "</th>";
        }
        result += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        result += "<tbody>";
        for (var i = 0; i < paymentData.length; i++) {
          result += "<tr>";
          for (var j = 0; j < col.length; j++) {
            result += "<td>";
            result +=
              paymentData[i][col[j]] == undefined ? 0 : paymentData[i][col[j]];
            result += "</td>";
          }
          result += "</tr>";
        }

        // ADD Totals
        result += "<tr><td style='font-weight:bold'>Totals</td>";
        for (var j = 1; j < col.length; j++) {
          let total = 0;
          for (var i = 0; i < paymentData.length; i++) {
            total +=
              paymentData[i][col[j]] === undefined
                ? 0
                : Number(paymentData[i][col[j]]);
          }
          result += "<td style='font-weight:bold'>";
          result += total;
          result += "</td>";
        }
        result += "</tr>";
        result += "</tbody>";
        result += "</table>";
        this.setState({ payments: result });
      });
  }

  onSearchClick() {
    this.loadPaymentWiseDashboardData(
      this.state.storeList,
      this.state.searchCashRegister,
      this.state.searchTransactionType,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      storeList: storeList,
      storeID: this.props.user.store.key,
      searchCashRegister: "",
      searchTransactionType: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
    });
    this.loadPaymentWiseDashboardData(
      "",
      "",
      "",
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  onExportClick() {
    this.props
      .getPaymentModeTransactionTypeWiseSales(
        this.props.user.user.RegistrationID,
        this.state.storeList,
        this.state.searchTransactionType,
        this.state.searchTransactionFromDate,
        this.state.searchTransactionToDate,
        false
      )
      .then((result) => {
        let paymentData = [];

        for (let index = 0; index < result.searchResult.length; index++) {
          const payment = result.searchResult[index];

          var obj = {
            "Payment Mode": payment.name,
          };

          this.props.config.TransactionTypes.filter(
            (e) => e.PaymentModeChecked === true && !e.IsDeleted
          ).map((e) => {
            obj[e.TransactionType] = 0;
            for (
              let index1 = 0;
              index1 < payment.transactionTypes.length;
              index1++
            ) {
              if (e.TransactionType === payment.transactionTypes[index1].name) {
                obj[e.TransactionType] =
                  payment.transactionTypes[index1].total_tran_amount;
              }
            }
          });
          obj["Totals"] = payment.total_mode_amount;
          paymentData.push(obj);
        }
        const csvData = constants.objToCSV(paymentData);
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "PaymentModeTransactionType.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        Payment Mode Transaction Type Wise Report
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Transaction From</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionFromDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionFromDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Transaction To</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionToDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionToDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                        onClick={this.onSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export{" "}
                        <i className="glyphicon glyphicon-download-alt "></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      {ReactHtmlParser(this.state.payments)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default PaymentModeTransactionType;
