import React, { Component } from "react";
import Window from "../2/Controls/Window";
import ReportHeader from "./Common/ReportHeader";
import ReportBody from "./Common/ReportBody";
import Input from "../2/Controls/Input";
import ReportButton from "./Common/ReportButton";
import firebase from "../../firebase";
import Spinner from "../Common/Spinner";
import * as constatnts from "../../Constatnts";
class PincodeNotFoundInGC extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      products: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    await this.repotedProducts(this.props.user.store.key, 0, 10000, "", "", "");
  }

  async repotedProducts(storeID, from, size, sku, barcode, productname) {
    let searchParameters = {
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              match: {
                "StoreID.keyword": storeID,
              },
            },
            {
              range: {
                InStockQty: {
                  gte: 1,
                },
              },
            },
            {
              match: {
                "ProductCustomField2.keyword": "NGC",
              },
            },
          ],
        },
      },
      size: size,
      from: from,
    };

    if (sku.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "ProductSKU.keyword": {
            value: sku + "*",
          },
        },
      });
    }
    if (barcode.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "BarCode.keyword": {
            value: sku + "*",
          },
        },
      });
    }
    if (productname.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "ProductName.keyword": {
            value: productname + "*",
          },
        },
      });
    }
    console.log("searchParameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getInventoryInStock");
    let result = await addMessage({ text: searchParameters });
    let sanitizedMessage = result.data.text;

    let inventoryInstocQtys = [];
    let productIDs = [];
    let totalRecordCount = sanitizedMessage.hits.total.value;
    for (let i = 0; i < sanitizedMessage.hits.hits.length; i++) {
      let obj = sanitizedMessage.hits.hits[i];
      if (obj._source.BarCode.length > 8) {
        inventoryInstocQtys.push({ key: obj._id, ...obj._source });
        productIDs.push(obj._source.ProductID);
      }
    }
    console.log("inventoryInstocQtys", inventoryInstocQtys);

    let res1 = await this.props.getProductsByProductIDs(productIDs);
    let productList = res1.searchResult;
    console.log("productList", productList);

    let products = [];
    for (let index = 0; index < inventoryInstocQtys.length; index++) {
      const data = inventoryInstocQtys[index];
      let product = {
        key: data.key,
        StoreName: data.StoreName,
        StoreLocation: data.Location,
        Brand: data.Brand,
        Category: data.Category,
        SubCategory: data.SubCategory,
        ProductSKU: data.ProductSKU,
        BarCode: data.BarCode,
        ProductID: data.ProductID,
        ProductName: data.ProductName,
        MRP: 0,
        RetailPrice: 0,
        InStockQty: Number(data.InStockQty ? data.InStockQty : 0).toFixed(2),
      };

      if (productList.filter((p) => p.key === data.ProductID).length > 0) {
        let objProd = productList.filter((p) => p.key === data.ProductID)[0];
        //console.log("objProd",objProd)
        product.Image =
          objProd.images && objProd.images.length > 0
            ? objProd.images[0].ImagePath
            : "";
        let mrp = objProd.mrp;
        let retailPrice = objProd.retailprice;
        if (
          objProd.storeSpecificPrices.filter((f) => f.StoreID === storeID) > 0
        ) {
          let ssp = objProd.StoreSpecificPrices.filter(
            (f) => f.StoreID === storeID
          )[0];
          mrp = ssp.MRP;
          retailPrice = ssp.RetailPrice;
        }
        product.MRP = mrp;
        product.RetailPrice = retailPrice;
        product.Brand = objProd.brand;
        product.Category = objProd.category;
        product.SubCategory = objProd.sub_category;
        product.ProductName = objProd.product;
        product.CatalogueIssue = objProd.customField4;
        product.CatalogueIssueRemark = objProd.customField5;
      }
      products.push(product);
    }
    console.log("products", products);

    this.setState({
      products: products,
      totalItemsCount: totalRecordCount,
      loading: false,
    });
  }
  async exportProduct() {
    const productsData = this.state.products.map((item) => {
      const product = {
        Image: item.Image,
        SKU: item.ProductSKU,
        BarCode: item.BarCode,
        Name: item.ProductName,
        Category: item.Category,
        SubCategory: item.SubCategory,
        Brand: item.Brand,
        MRP: item.MRP,
        RetailPrice: item.RetailPrice,
        "In Stock Qty": item.InStockQty,
      };
      return product;
    });

    const csvData = constatnts.objToCSV(productsData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "notfoundinglobalcatalog.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <ReportHeader
            title={"Not found in Global Catalog"}
            showBack={true}
            onBack={() => {
              this.props.history.push({
                pathname: "/home/reports/allreport",
              });
            }}
          ></ReportHeader>
          <ReportBody>
            {/* <div className="row">
              <div className="col-md-2">
                <Input
                  type="text"
                  label="SKU"
                  value={this.state.SKU}
                  onChange={(value) => this.setState({ SKU: value })}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="text"
                  label="Barcode"
                  value={this.state.barcode}
                  onChange={(value) => this.setState({ barcode: value })}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="text"
                  label="Product name"
                  value={this.state.productName}
                  onChange={(value) => this.setState({ productName: value })}
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-6 pull-left">
                <p>Total Count:{this.state.products.length}</p>
              </div>
              <div className="col-md-6 pull-right">
                {/* <ReportButton type="search" onClick={()=>}/> */}
                {/* <ReportButton type="clear" /> */}
                <ReportButton
                  type="export"
                  onClick={() => this.exportProduct()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.loading && <Spinner />}
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>SKU</th>
                      <th>Barcode</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>SubCategory</th>
                      <th>Brand</th>
                      <th>MRP</th>
                      <th>Retail Price</th>
                      <th>In Stock Qty</th>

                      {/* <th>Catalogue Issue</th>
                      <th>Catlogue Issue Remark</th>
                       */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products.map((m, index) => (
                      <tr key={"tdtr" + index}>
                        <td>
                          <img className="list-page-image" src={m.Image} />
                        </td>
                        <td>{m.ProductSKU}</td>
                        <td>{m.BarCode}</td>
                        <td>{m.ProductName}</td>
                        <td>{m.Category}</td>
                        <td>{m.SubCategory}</td>
                        <td>{m.Brand}</td>

                        <td className={"number"}>{m.MRP}</td>
                        <td className={"number"}>{m.RetailPrice}</td>
                        <td className={"number"}>{m.InStockQty}</td>

                        {/* <td>{m.CatalogueIssue}</td>
                        <td>{m.CatalogueIssueRemark}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ReportBody>
        </section>
      </div>
    );
  }
}

export default PincodeNotFoundInGC;
