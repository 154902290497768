import React, { Component } from "react";
import ImageButton from "./Controls/ImageButton";
import Icon from "./Controls/Icon";
import * as actionTypes from "../../actionTypes";
import { isMobileApp } from "../../actions/Util";
import "./style.css";
import Avatar from "../Common/Avatar";
import Button from "../2/Controls/Button";
import { auth } from "../../firebase";
import Input from "./Controls/Input";
// import logo from '../../images/Posible_logo_new.png'

import Logo from "./Controls/Logo";

class Menu extends Component {
  constructor() {
    super();
    this.state = {
      menuCss: "menu-left",
      menus: [],
      selectedMenu: "",
      selectedSubMenu: "",
    };
    this.getVisibility = this.getVisibility.bind(this);
    this.onMenuClicked = this.onMenuClicked.bind(this);
    this.loadMenus = this.loadMenus.bind(this);
    this.onOpenMenu = this.onOpenMenu.bind(this);
    this.showModule = this.showModule.bind(this);
    this.onCloseMenu = this.onCloseMenu.bind(this);
    this.returnMenuItemClassName = this.returnMenuItemClassName.bind(this);
    this.renderUserWidget = this.renderUserWidget.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.loadMenus(this.props.config.TransactionTypes);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.config.TransactionTypes !== this.props.config.TransactionTypes
    ) {
      this.loadMenus(nextProps.config.TransactionTypes);
    }
  }

  loadPincodeMenu() {
    let menus = [];
    let showCatalogueMenu = true;
    if (
      this.props.registration.registration.hasOwnProperty(
        "EnablePincodeCatalogManagement"
      )
    ) {
      showCatalogueMenu = !this.props.registration.registration
        .EnablePincodeCatalogManagement;
    }
    if (isMobileApp()) {
      menus.push({
        Title: "Home",
        Value: "home",
        Icon: "store",
        URL: "/home",
      });
    }
    menus.push({
      Title: "Online Order(s)",
      Value: "deliverylist",
      Icon: "onlineorder",
      URL: "/home/sales/deliverylist",
      URLData: { type: "list" },
    });

    if (this.props.registration.registration.EnablePincodePayBill) {
      menus.push({
        Title: "Pay Bill",
        Value: "paybill",
        Icon: "pay",
        URL: "/home/paybill",
        URLData: { type: "list" },
      });
    }

    if (!this.props.registration.registration.PincodeIntegration) {
      menus.push({
        Title: "Customer Complaints",
        Value: "complaints",
        Icon: "complaints",
        URL: "/home/complaints",
        URLData: { type: "list" },
      });
    }

    if (showCatalogueMenu) {
      menus.push({
        Title: "Catalogue",
        Value: "catalogue",
        Icon: "catalogue",
      });
      if (
        this.props.user.user.Role === "Power User" ||
        this.props.user.user.Role === "Store Owner"
      ) {
        menus.push({
          Parent: "catalogue",
          Title: "Product",
          Value: "products",
          Icon: "product",
          URL: "/home/products",
          URLData: null,
        });

        if (
          this.props.registration.registration.hasOwnProperty("StoreType") &&
          this.props.registration.registration.StoreType === "Supermarket"
        ) {
          menus.push({
            Parent: "catalogue",
            Title: "Missing popular item",
            Value: "missingpopularitem",
            Icon: "product",
            URL: "/home/missingpopularproducts",
            URLData: null,
          });
        }

        menus.push({
          Parent: "catalogue",
          Title: "Item not found in Master Catalogue",
          Value: "itemnotfoundinmastercatalogue",
          Icon: "product",
          URL: "/home/itemnotfoundinmastercatalogue",
          URLData: null,
        });
      }

      menus.push({
        Parent: "catalogue",
        Title: "Product - Quick Edit",
        Value: "productquickedit",
        Icon: "list",
        URL: "/home/product/pincodequickedit/",
        URLData: null,
      });
    }

    //staff
    if (
      this.showModule("Attendance", false) ||
      this.showModule("Employees", false)
    ) {
      menus.push({ Title: "Staff", Value: "staff", Icon: "staff" });
    }

    if (this.showModule("Attendance", false)) {
      menus.push({
        Parent: "staff",
        Title: "Attendance",
        Value: "attendance",
        Icon: "attendance",
        URL: "/home/attendancelist",
        URLData: null,
      });
    }

    if (this.showModule("Employees", false)) {
      menus.push({
        Parent: "staff",
        Title: "Staff Details",
        Value: "staffdetails",
        Icon: "staff",
        URL: "/home/settings/employees/",
        URLData: null,
      });
    }

    if (
      this.showModule("Registration", false) ||
      this.showModule("Stores", false)
    ) {
      menus.push({ Title: "Setup", Value: "setup", Icon: "setup" });
      if (this.showModule("Registration", false)) {
        menus.push({
          Parent: "setup",
          Title: "Registration",
          Value: "registration",
          Icon: "registration",
          URL: "/home/settings/registration",
          URLData: null,
        });
      }
      if (this.showModule("Stores", false)) {
        menus.push({
          Parent: "setup",
          Title: "Stores",
          Value: "stores",
          Icon: "stores",
          URL: "/home/settings/stores",
          URLData: null,
        });
        if (this.showModule("CustomFields", false)) {
          menus.push({
            Parent: "setup",
            Title: "Custom Fields",
            Value: "customfields",
            Icon: "customfields",
            URL: "/home/settings/customfields",
            URLData: null,
          });
        }
        if (this.props.registration.registration.PincodeIntegration) {
          menus.push({
            Parent: "setup",
            Title: "Pincode Setting",
            Value: "pincodesettings",
            Icon: "setup",
            URL: "/home/settings/pincodeintegrationsettings",
            URLData: null,
          });
        }
      }
    }

    if (
      this.props.user.store &&
      this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
      this.props.user.store.NoOfDaysToExpire > 0 &&
      this.props.user.user.Role !== "Store Cashier" &&
      this.getVisibility("Reports", "read")
    ) {
      menus.push({ Title: "Reports", Value: "reports", Icon: "reports" });

      if (
        this.props.user.user.Role !== "Store Cashier" &&
        this.props.getVisibility("Reports", "read")
      ) {
        menus.push({
          Parent: "reports",
          Title: "Report Options",
          Value: "reportoptions",
          Icon: "reports",
          URL: "/home/reports/allreport",
          URLData: null,
        });
      }
    }
    this.setState({ menus: menus });
  }

  loadPincodeBillingMenus(transactionTypes) {
    let menus = [];
    let salesTransactionTypes = transactionTypes.filter(
      (f) => f.Module === "sales" && !f.IsDeleted
    );
    let inventoryTransactionTypes = transactionTypes.filter(
      (f) => f.Module === "inventory" && !f.IsDeleted
    );
    //sort as per sequence no
    salesTransactionTypes.sort(function(a, b) {
      let sequenceA = a.hasOwnProperty("SequenceNo") ? Number(a.SequenceNo) : 0;
      let sequenceb = b.hasOwnProperty("SequenceNo") ? Number(b.SequenceNo) : 0;
      return sequenceA - sequenceb;
    });
    inventoryTransactionTypes.sort(function(a, b) {
      let sequenceA = a.hasOwnProperty("SequenceNo") ? Number(a.SequenceNo) : 0;
      let sequenceb = b.hasOwnProperty("SequenceNo") ? Number(b.SequenceNo) : 0;
      return sequenceA - sequenceb;
    });

    menus.push({
      Title: "Online Order(s)",
      Value: "deliverylist",
      Icon: "onlineorder",
      URL: "/home/sales/deliverylist",
      URLData: { type: "list" },
    });

    if (isMobileApp()) {
      menus.push({
        Title: "Home",
        Value: "home",
        Icon: "store",
        URL: "/home",
        URLData: {},
      });
    }

    if (this.getVisibility("Sales", "read")) {
      menus.push({ Title: "Sales", Value: "sales", Icon: "sales" });

      //now load sub menu
      salesTransactionTypes
        .filter((e) => {
          let accessibleToSpecificStores = false;
          if (
            e.hasOwnProperty("AccessibleToSpecificStores") &&
            e.AccessibleToSpecificStores &&
            e.AccessibleToSpecificStoresList.filter(
              (f) => f.StoreID === this.props.user.store.key
            ).length > 0
          ) {
            accessibleToSpecificStores = true;
          } else if (
            e.hasOwnProperty("AccessibleToSpecificStores") &&
            !e.AccessibleToSpecificStores
          ) {
            accessibleToSpecificStores = true;
          } else if (!e.hasOwnProperty("AccessibleToSpecificStores")) {
            accessibleToSpecificStores = true;
          }
          if (
            e.Module === "sales" &&
            !e.IsDeleted &&
            accessibleToSpecificStores
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((i) => {
          let showModule = false;
          if (
            this.props.user.user.Role === "Power User" ||
            this.props.user.user.Role === "Store Owner" ||
            this.props.user.user.Role === "Store Manager" ||
            this.props.user.user.Role === "Store Cashier"
          ) {
            showModule = this.showModule(i.TransactionType, true);
          } else {
            if (
              this.props.user.acl.filter(
                (e) => e.id === i.TransactionType && e.perms.read
              ).length > 0
            ) {
              showModule = true;
            }
          }
          if (this.props.registration.registration.PincodeIntegration) {
            if (
              this.props.registration.registration.hasOwnProperty(
                "PinocdeOrderTransactionTypeID"
              ) &&
              this.props.registration.registration
                .PinocdeOrderTransactionTypeID === i.key
            ) {
              showModule = false;
            }
          }
          if (showModule) {
            menus.push({
              Parent: "sales",
              Title: "New " + i.TransactionType,
              Value: i.TransactionType,
              Icon: "new",
              URL: "/home/sales/sales",
              URLData: { transactionType: i, type: actionTypes.SALE_NEW },
              QuickAdd: true,
            });
          }
        });

      menus.push({
        Parent: "sales",
        Title: "Sales History",
        Value: "saleshistory",
        Icon: "list",
        URL: "/home/sales/saleslist",
        URLData: { type: "list" },
      });

      if (
        this.props.user.store.hasOwnProperty("CashRegisterClosing") &&
        this.showModule("CashRegistersClosing")
      ) {
        menus.push({
          Parent: "sales",
          Title: "Cash Register Closing",
          Value: "cashregisterclosinglist",
          Icon: "cashregister",
          URL: "/home/sales/cashregisterclosinglist/",
          URLData: { type: "list" },
        });
      }
    }

    if (
      this.props.registration.registration.hasOwnProperty("LoyaltyEnabled") &&
      this.props.registration.registration.LoyaltyEnabled &&
      this.getVisibility("LoyaltyDashboard", "read")
    ) {
      menus.push({ Title: "Loyalty", Value: "loyalty", Icon: "loyalty" });
      menus.push({
        Parent: "loyalty",
        Title: "Loyalty Type",
        Value: "loyaltytype",
        Icon: "list",
        URL: "/home/loyalty/loyaltytype",
        URLData: null,
      });
      menus.push({
        Parent: "loyalty",
        Title: "Setup",
        Value: "loyaltysetup",
        Icon: "setup",
        URL: "/home/loyalty/setup",
        URLData: null,
      });
    }

    if (this.getVisibility("Inventory", "read")) {
      menus.push({ Title: "Inventory", Value: "inventory", Icon: "inventory" });

      inventoryTransactionTypes
        .filter((e) => {
          // console.log(e)
          let accessibleToSpecificStores = false;
          if (
            e.hasOwnProperty("AccessibleToSpecificStores") &&
            e.AccessibleToSpecificStores &&
            e.AccessibleToSpecificStoresList.filter(
              (f) => f.StoreID === this.props.user.store.key
            ).length > 0
          ) {
            accessibleToSpecificStores = true;
          } else if (
            e.hasOwnProperty("AccessibleToSpecificStores") &&
            !e.AccessibleToSpecificStores
          ) {
            accessibleToSpecificStores = true;
          } else if (!e.hasOwnProperty("AccessibleToSpecificStores")) {
            accessibleToSpecificStores = true;
          }
          if (
            e.Module === "inventory" &&
            !e.IsDeleted &&
            e.TransactionType !== "Transfer In" &&
            accessibleToSpecificStores
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((i) => {
          let showModule = false;
          if (
            this.props.user.user.Role === "Power User" ||
            this.props.user.user.Role === "Store Owner" ||
            this.props.user.user.Role === "Store Manager" ||
            this.props.user.user.Role === "Store Cashier"
          ) {
            showModule = this.showModule(i.TransactionType, true);
          } else {
            if (
              this.props.user.acl.filter(
                (e) => e.id === i.TransactionType && e.perms.read
              ).length > 0
            ) {
              showModule = true;
            }
          }

          if (showModule) {
            menus.push({
              Parent: "inventory",
              Title: "New " + i.TransactionType,
              Value: i.TransactionType,
              Icon: "new",
              URL: "/home/inventory/inventory/",
              URLData: { transactionType: i, type: actionTypes.INVENTORY_NEW },
              QuickAdd: true,
            });
          }
        });

      menus.push({
        Parent: "inventory",
        Title: "Inventory List",
        Value: "inventorylist",
        Icon: "list",
        URL: "/home/inventory/inventorylist",
        URLData: { type: "list" },
      });

      if (!this.props.config.Stores.length > 1) {
        menus.push({
          Parent: "inventory",
          Title: "In Transit",
          Value: "intransit",
          Icon: "list",
          URL: "/home/inventory/intransit",
          URLData: { type: "intransit" },
        });
      }

      if (this.showModule("Vendors", false)) {
        menus.push({
          Parent: "inventory",
          Title: "Vendors",
          Value: "vendors",
          Icon: "vendors",
          URL: "/home/settings/vendors/",
          URLData: null,
          QuickAdd: true,
        });
      }
    }

    if (
      this.getVisibility("Customers", "read") &&
      this.props.user.user.Role === "Power User"
    ) {
      menus.push({ Title: "CRM", Value: "crm", Icon: "crm" });
      if (this.getVisibility("Customers", "read")) {
        menus.push({
          Parent: "crm",
          Title: "Customers",
          Value: "customers",
          Icon: "customer",
          URL: "/home/customer",
          URLData: null,
          QuickAdd: true,
        });
      }
    }

    if (this.getVisibility("Expenses", "read")) {
      menus.push({ Title: "Expense", Value: "expense", Icon: "expense" });
      menus.push({
        Parent: "expense",
        Title: "Expense List",
        Value: "expenselist",
        Icon: "expense",
        URL: "/home/expenselist",
        URLData: null,
      });
      menus.push({
        Parent: "expense",
        Title: "Account Management",
        Value: "accountmanagement",
        Icon: "accountmanagement",
        URL: "/home/expense/transactionlist",
        URLData: null,
      });
      if (this.showModule("ExpenseCategories")) {
        menus.push({
          Parent: "expense",
          Title: "Expense Categories",
          Value: "expensecategories",
          Icon: "expensecategories",
          URL: "/home/expensecategorieslist",
          URLData: null,
        });
      }
      menus.push({
        Parent: "expense",
        Title: "Account",
        Value: "account",
        Icon: "account",
        URL: "/home/expense/accounts",
        URLData: null,
      });
    }

    if (
      this.showModule("Attendance", false) ||
      this.showModule("Employees", false)
    ) {
      menus.push({ Title: "Staff", Value: "staff", Icon: "staff" });
    }

    if (this.showModule("Attendance", false)) {
      menus.push({
        Parent: "staff",
        Title: "Attendance",
        Value: "attendance",
        Icon: "attendance",
        URL: "/home/attendancelist",
        URLData: null,
      });
    }

    if (this.showModule("Employees", false)) {
      menus.push({
        Parent: "staff",
        Title: "Staff Details",
        Value: "staffdetails",
        Icon: "staff",
        URL: "/home/settings/employees/",
        URLData: null,
      });
    }

    //catalogue
    if (
      this.getVisibility("Products", "read") ||
      this.props.user.user.Role === "Store Owner" ||
      this.props.user.user.Role === "Store Manager"
    ) {
      menus.push({ Title: "Catalogue", Value: "catalogue", Icon: "catalogue" });

      if (
        this.showModule("Products", false) ||
        this.props.user.user.Role === "Store Owner" ||
        this.props.user.user.Role === "Store Manager"
      ) {
        menus.push({
          Parent: "catalogue",
          Title: "Product",
          Value: "pincodeproducts",
          Icon: "product",
          URL: "/home/product/pincode",
          URLData: null,
        });
        menus.push({
          Parent: "catalogue",
          Title: "Product - Quick Edit",
          Value: "productquickedit",
          Icon: "list",
          URL: "/home/product/pincodequickedit/",
          URLData: null,
        });
      }
      if (this.showModule("ProductCategories", false)) {
        menus.push({
          Parent: "catalogue",
          Title: "Category",
          Value: "category",
          Icon: "category",
          URL: "/home/catalogue/category",
          URLData: { type: actionTypes.PRODUCT_CATEGORY },
        });
        menus.push({
          Parent: "catalogue",
          Title: "Sub Category",
          Value: "subcategory",
          Icon: "subcategory",
          URL: "/home/catalogue/subcategory",
          URLData: { type: actionTypes.PRODUCT_SUB_CATEGORY },
        });
      }
      if (this.showModule("ProductBrands", false)) {
        menus.push({
          Parent: "catalogue",
          Title: "Brand",
          Value: "brand",
          Icon: "brand",
          URL: "/home/catalogue/brands",
          URLData: null,
        });
      }
      if (this.showModule("ProductTaxes", false)) {
        menus.push({
          Parent: "catalogue",
          Title: "Taxes",
          Value: "taxes",
          Icon: "taxes",
          URL: "/home/catalogue/taxes",
          URLData: null,
        });
      }
      if (
        this.showModule("ProductTags", false) ||
        this.showModule("Offers", false)
      ) {
        menus.push({
          Parent: "catalogue",
          Title: "Product Group",
          Value: "tags",
          Icon: "tags",
          URL: "/home/catalogue/tags",
          URLData: null,
        });
      }
      if (this.showModule("Offers", false)) {
        menus.push({
          Parent: "catalogue",
          Title: "Offers",
          Value: "offers",
          Icon: "offers",
          URL: "/home/catalogue/offer",
          URLData: null,
        });
      }
    }

    //setup
    if (
      this.props.registration.registration.EnablePosBillingForPincode &&
      this.props.user.user.Role === "Power User"
    ) {
      menus.push({ Title: "Setup", Value: "setup", Icon: "setup" });
      if (this.showModule("Registration", false)) {
        menus.push({
          Parent: "setup",
          Title: "Registration",
          Value: "registration",
          Icon: "registration",
          URL: "/home/settings/registration",
          URLData: null,
        });
      }
      if (this.showModule("Stores", false)) {
        menus.push({
          Parent: "setup",
          Title: "Stores",
          Value: "stores",
          Icon: "stores",
          URL: "/home/settings/stores",
          URLData: null,
        });
      }
      if (this.showModule("PaymentTypes", false)) {
        menus.push({
          Parent: "setup",
          Title: "Payment Types",
          Value: "paymenttypes",
          Icon: "paymenttypes",
          URL: "/home/settings/paymenttypes",
          URLData: null,
        });
      }
      if (this.showModule("CustomRoles", false)) {
        menus.push({
          Parent: "setup",
          Title: "Custom Roles",
          Value: "customroles",
          Icon: "adduser",
          URL: "/home/customroles",
          URLData: null,
        });
      }
      if (this.showModule("TransactionTypes", false)) {
        menus.push({
          Parent: "setup",
          Title: "Module Configuration",
          Value: "moduleconfiguration",
          Icon: "moduleconfiguration",
          URL: "/home/settings/moduleconfig",
          URLData: null,
        });
      }
      if (this.showModule("CustomFields", false)) {
        menus.push({
          Parent: "setup",
          Title: "Custom Fields",
          Value: "customfields",
          Icon: "customfields",
          URL: "/home/settings/customfields",
          URLData: null,
        });
      }
      menus.push({
        Parent: "setup",
        Title: "Additional Setting",
        Value: "addtionalsettings",
        Icon: "setup",
        URL: "/home/settings/addtionalsettings",
        URLData: null,
      });

      menus.push({
        Parent: "setup",
        Title: "Pincode Setting",
        Value: "pincodesettings",
        Icon: "setup",
        URL: "/home/settings/pincodeintegrationsettings",
        URLData: null,
      });
    }

    if (
      this.props.user.store &&
      this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
      this.props.user.store.NoOfDaysToExpire > 0 &&
      this.props.user.user.Role !== "Store Cashier" &&
      this.getVisibility("Reports", "read")
    ) {
      menus.push({ Title: "Reports", Value: "reports", Icon: "reports" });

      if (this.showModule("Sales Dashboard", false)) {
        if (
          this.props.registration.registration.hasOwnProperty("NewDashboard") &&
          this.props.registration.registration.NewDashboard
        ) {
          menus.push({
            Parent: "reports",
            Title: "Dashboard",
            Value: "dashboard",
            Icon: "dashboard",
            URL: "/home/reports/dashboard",
            URLData: null,
          });
        } else {
          menus.push({
            Parent: "reports",
            Title: "Dashboard",
            Value: "dashboard",
            Icon: "dashboard",
            URL: "/home",
            URLData: null,
          });
        }
      }
      if (
        this.props.user.user.Role !== "Store Cashier" &&
        this.props.getVisibility("Reports", "read")
      ) {
        menus.push({
          Parent: "reports",
          Title: "Report Options",
          Value: "reportoptions",
          Icon: "reports",
          URL: "/home/reports/allreport",
          URLData: null,
        });
      }
    }
    this.setState({ menus: menus });
  }

  loadMenus(transactionTypes) {
    if (this.props.config.WhiteLabel.Code === "PINCODE") {
      this.loadPincodeMenu(menus);
      return;
    }

    if (this.props.registration.registration.EnablePosBillingForPincode) {
      this.loadPincodeBillingMenus(transactionTypes);
      return;
    }

    let menus = [];
    let salesTransactionTypes = transactionTypes.filter(
      (f) => f.Module === "sales" && !f.IsDeleted
    );
    let inventoryTransactionTypes = transactionTypes.filter(
      (f) => f.Module === "inventory" && !f.IsDeleted
    );
    //sort as per sequence no
    salesTransactionTypes.sort(function(a, b) {
      let sequenceA = a.hasOwnProperty("SequenceNo") ? Number(a.SequenceNo) : 0;
      let sequenceb = b.hasOwnProperty("SequenceNo") ? Number(b.SequenceNo) : 0;
      return sequenceA - sequenceb;
    });
    inventoryTransactionTypes.sort(function(a, b) {
      let sequenceA = a.hasOwnProperty("SequenceNo") ? Number(a.SequenceNo) : 0;
      let sequenceb = b.hasOwnProperty("SequenceNo") ? Number(b.SequenceNo) : 0;
      return sequenceA - sequenceb;
    });

    if (this.props.registration.registration.PincodeIntegration) {
      menus.push({
        Title: "Online Order(s)",
        Value: "deliverylist",
        Icon: "onlineorder",
        URL: "/home/sales/deliverylist",
        URLData: { type: "list" },
      });
    }

    if (isMobileApp()) {
      menus.push({
        Title: "Home",
        Value: "home",
        Icon: "store",
        URL: "/home",
        URLData: {},
      });
    }

    if (this.getVisibility("Sales", "read")) {
      menus.push({ Title: "Sales", Value: "sales", Icon: "sales" });

      if (this.props.user.store.EnableTableOrder) {
        menus.push({
          Parent: "sales",
          Title: "Table Order",
          Value: "tableorder",
          Icon: "new",
          URL: "/home/tableorder",
          URLData: { type: "list" },
        });
      }

      //now load sub menu
      salesTransactionTypes
        .filter((e) => {
          if (
            this.props.user.store.hasOwnProperty("EnableTableOrder") &&
            this.props.user.store.EnableTableOrder
          ) {
            return false;
          }
          let accessibleToSpecificStores = false;
          if (
            e.hasOwnProperty("AccessibleToSpecificStores") &&
            e.AccessibleToSpecificStores &&
            e.AccessibleToSpecificStoresList.filter(
              (f) => f.StoreID === this.props.user.store.key
            ).length > 0
          ) {
            accessibleToSpecificStores = true;
          } else if (
            e.hasOwnProperty("AccessibleToSpecificStores") &&
            !e.AccessibleToSpecificStores
          ) {
            accessibleToSpecificStores = true;
          } else if (!e.hasOwnProperty("AccessibleToSpecificStores")) {
            accessibleToSpecificStores = true;
          }
          if (
            e.Module === "sales" &&
            !e.IsDeleted &&
            accessibleToSpecificStores
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((i) => {
          let showModule = false;
          if (
            this.props.user.user.Role === "Power User" ||
            this.props.user.user.Role === "Store Owner" ||
            this.props.user.user.Role === "Store Manager" ||
            this.props.user.user.Role === "Store Cashier"
          ) {
            showModule = this.showModule(i.TransactionType, true);
          } else {
            if (
              this.props.user.acl.filter(
                (e) => e.id === i.TransactionType && e.perms.read
              ).length > 0
            ) {
              showModule = true;
            }
          }
          if (this.props.registration.registration.PincodeIntegration) {
            if (
              this.props.registration.registration.hasOwnProperty(
                "PinocdeOrderTransactionTypeID"
              ) &&
              this.props.registration.registration
                .PinocdeOrderTransactionTypeID === i.key
            ) {
              showModule = false;
            }
          }
          if (showModule) {
            menus.push({
              Parent: "sales",
              Title: "New " + i.TransactionType,
              Value: i.TransactionType,
              Icon: "new",
              URL: "/home/sales/sales",
              URLData: { transactionType: i, type: actionTypes.SALE_NEW },
              QuickAdd: true,
            });
          }
        });

      menus.push({
        Parent: "sales",
        Title: "Sales History",
        Value: "saleshistory",
        Icon: "list",
        URL: "/home/sales/saleslist",
        URLData: { type: "list" },
      });

      if (
        this.props.registration &&
        this.props.registration.registration &&
        this.props.registration.registration.hasOwnProperty(
          "InventoryToSale"
        ) &&
        this.showModule("In Transit - Sales")
      ) {
        menus.push({
          Parent: "sales",
          Title: "In Transit",
          Value: "intransit",
          Icon: "list",
          URL: "/home/sales/intransit",
          URLData: { type: "list" },
        });
      }

      if (this.props.registration.registration.EnableInfinityAppOrders) {
        menus.push({
          Title: "Online Order(s)",
          Value: "deliverylist",
          Icon: "onlineorder",
          URL: "/home/sales/appolineorders",
          URLData: { type: "list" },
        });
      }

      if (this.props.registration.registration.Delivery && !isMobileApp()) {
        menus.push({
          Parent: "sales",
          Title: "Delivery List",
          Value: "deliverylist",
          Icon: "list",
          URL: "/home/sales/deliverylist",
          URLData: { type: "list" },
        });
      }

      if (
        this.props.user.store.hasOwnProperty("CashRegisterClosing") &&
        this.showModule("CashRegistersClosing")
      ) {
        menus.push({
          Parent: "sales",
          Title: "Cash Register Closing",
          Value: "cashregisterclosinglist",
          Icon: "cashregister",
          URL: "/home/sales/cashregisterclosinglist/",
          URLData: { type: "list" },
        });
      }

      if (
        this.props.registration.registration.EnablePackageTracker &&
        this.showModule("Packages")
      ) {
        menus.push({
          Parent: "sales",
          Title: "Packages",
          Value: "packages",
          Icon: "packages",
          URL: "/home/packages/packagelist",
          URLData: {},
        });
      }

      if (this.props.registration.registration.KOT && !isMobileApp()) {
        menus.push({
          Parent: "sales",
          Title: "KOT - Kitchen Display",
          Value: "kds",
          Icon: "kds",
          URL: "/home/KDS",
          URLData: {},
        });
        menus.push({
          Parent: "sales",
          Title: "KOT - Customer Display",
          Value: "kotcustomerdisplay",
          Icon: "kds",
          URL: "/home/kotcustomerdisplay",
          URLData: {},
        });
      }
    }

    if (this.getVisibility("Customers", "read")) {
      menus.push({ Title: "CRM", Value: "crm", Icon: "crm" });
      if (this.getVisibility("Customers", "read")) {
        menus.push({
          Parent: "crm",
          Title: "Customers",
          Value: "customers",
          Icon: "customer",
          URL: "/home/customer",
          URLData: null,
          QuickAdd: true,
        });
      }
      if (this.showModule("Campaigns")) {
        menus.push({
          Parent: "crm",
          Title: "Campaigns",
          Value: "campaigns",
          Icon: "campaign",
          URL: "/home/campaign",
          URLData: {},
        });
      }
      if (this.showModule("Memberships")) {
        menus.push({
          Parent: "crm",
          Title: "Memberships",
          Value: "membership",
          Icon: "membership",
          URL: "/home/membership",
          URLData: {},
        });
      }
      menus.push({
        Parent: "crm",
        Title: "Coupons",
        Value: "coupons",
        Icon: "coupon",
        URL: "/home/coupons",
        URLData: {},
      });

      menus.push({
        Parent: "crm",
        Title: "Message Template",
        Value: "promotionalsmstemplates",
        Icon: "sms",
        URL: "/home/settings/promotionalsmstemplates/",
        URLData: {},
      });
    }

    if (
      this.props.registration.registration.hasOwnProperty("LoyaltyEnabled") &&
      this.props.registration.registration.LoyaltyEnabled &&
      this.getVisibility("LoyaltyDashboard", "read")
    ) {
      menus.push({ Title: "Loyalty", Value: "loyalty", Icon: "loyalty" });
      menus.push({
        Parent: "loyalty",
        Title: "Loyalty Type",
        Value: "loyaltytype",
        Icon: "list",
        URL: "/home/loyalty/loyaltytype",
        URLData: null,
      });
      menus.push({
        Parent: "loyalty",
        Title: "Setup",
        Value: "loyaltysetup",
        Icon: "setup",
        URL: "/home/loyalty/setup",
        URLData: null,
      });

      ///home/loyalty/loyaltytype
      ///home/loyalty/setup
    }

    if (this.getVisibility("Inventory", "read")) {
      menus.push({ Title: "Inventory", Value: "inventory", Icon: "inventory" });

      inventoryTransactionTypes
        .filter((e) => {
          // console.log(e)
          let accessibleToSpecificStores = false;
          if (
            e.hasOwnProperty("AccessibleToSpecificStores") &&
            e.AccessibleToSpecificStores &&
            e.AccessibleToSpecificStoresList.filter(
              (f) => f.StoreID === this.props.user.store.key
            ).length > 0
          ) {
            accessibleToSpecificStores = true;
          } else if (
            e.hasOwnProperty("AccessibleToSpecificStores") &&
            !e.AccessibleToSpecificStores
          ) {
            accessibleToSpecificStores = true;
          } else if (!e.hasOwnProperty("AccessibleToSpecificStores")) {
            accessibleToSpecificStores = true;
          }
          if (
            e.Module === "inventory" &&
            !e.IsDeleted &&
            e.TransactionType !== "Transfer In" &&
            accessibleToSpecificStores
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((i) => {
          let showModule = false;
          if (
            this.props.user.user.Role === "Power User" ||
            this.props.user.user.Role === "Store Owner" ||
            this.props.user.user.Role === "Store Manager" ||
            this.props.user.user.Role === "Store Cashier"
          ) {
            showModule = this.showModule(i.TransactionType, true);
          } else {
            if (
              this.props.user.acl.filter(
                (e) => e.id === i.TransactionType && e.perms.read
              ).length > 0
            ) {
              // console.log('i.TransactionType', i.TransactionType);
              // console.log('perms', this.props.user.acl.filter(e=>e.id === i.TransactionType && e.perms.read));
              showModule = true;
            }
          }

          if (showModule) {
            menus.push({
              Parent: "inventory",
              Title: "New " + i.TransactionType,
              Value: i.TransactionType,
              Icon: "new",
              URL: "/home/inventory/inventory/",
              URLData: { transactionType: i, type: actionTypes.INVENTORY_NEW },
              QuickAdd: true,
            });
          }
        });
      menus.push({
        Parent: "inventory",
        Title: "Inventory List",
        Value: "inventorylist",
        Icon: "list",
        URL: "/home/inventory/inventorylist",
        URLData: { type: "list" },
      });
      if (!this.props.registration.registration.PincodeIntegration) {
        menus.push({
          Parent: "inventory",
          Title: "In Transit",
          Value: "intransit",
          Icon: "list",
          URL: "/home/inventory/intransit",
          URLData: { type: "intransit" },
        });
      }

      //check if approvl flow is enabled in transaction
      if (this.showModule("Vendors", false)) {
        menus.push({
          Parent: "inventory",
          Title: "Vendors",
          Value: "vendors",
          Icon: "vendors",
          URL: "/home/settings/vendors/",
          URLData: null,
          QuickAdd: true,
        });
      }
    }

    if (
      this.props.registration.registration &&
      this.props.registration.registration.EnableAppointment === true &&
      !isMobileApp()
    ) {
      menus.push({
        Title: "Appointment",
        Value: "appointment",
        Icon: "appointment",
        URL: "/home/appointment",
        URLData: null,
      });
    }

    if (this.getVisibility("Expenses", "read")) {
      menus.push({ Title: "Expense", Value: "expense", Icon: "expense" });
      menus.push({
        Parent: "expense",
        Title: "Expense List",
        Value: "expenselist",
        Icon: "expense",
        URL: "/home/expenselist",
        URLData: null,
      });
      // if (!this.props.registration.registration.PincodeIntegration) {
      menus.push({
        Parent: "expense",
        Title: "Account Management",
        Value: "accountmanagement",
        Icon: "accountmanagement",
        URL: "/home/expense/transactionlist",
        URLData: null,
      });
      // }

      if (this.showModule("ExpenseCategories")) {
        menus.push({
          Parent: "expense",
          Title: "Expense Categories",
          Value: "expensecategories",
          Icon: "expensecategories",
          URL: "/home/expensecategorieslist",
          URLData: null,
        });
      }
      menus.push({
        Parent: "expense",
        Title: "Account",
        Value: "account",
        Icon: "account",
        URL: "/home/expense/accounts",
        URLData: null,
      });
    }

    //staff
    if (
      this.showModule("Attendance", false) ||
      this.showModule("Employees", false)
    ) {
      menus.push({ Title: "Staff", Value: "staff", Icon: "staff" });
    }

    if (this.showModule("Attendance", false)) {
      menus.push({
        Parent: "staff",
        Title: "Attendance",
        Value: "attendance",
        Icon: "attendance",
        URL: "/home/attendancelist",
        URLData: null,
      });
    }

    if (this.showModule("Employees", false)) {
      menus.push({
        Parent: "staff",
        Title: "Staff Details",
        Value: "staffdetails",
        Icon: "staff",
        URL: "/home/settings/employees/",
        URLData: null,
      });
    }

    //online store
    if (
      this.props.user.store &&
      this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
      this.props.user.store.NoOfDaysToExpire > 0 &&
      this.props.user.user.Role === "Power User" &&
      !this.props.registration.registration.PincodeIntegration &&
      !isMobileApp()
    ) {
      menus.push({
        Title: "Online Store",
        Value: "onlinestore",
        Icon: "onlinestore",
      });
      menus.push({
        Parent: "onlinestore",
        Title: "Profile",
        Value: "onlineprofile",
        Icon: "onlineprofile",
        URL: "/home/omni/profile",
        URLData: null,
      });
      menus.push({
        Parent: "onlinestore",
        Title: "Menu",
        Value: "menubar",
        Icon: "menubar",
        URL: "/home/omni/menus",
        URLData: null,
      });
      menus.push({
        Parent: "onlinestore",
        Title: "Slider",
        Value: "onlinebanner",
        Icon: "onlinebanner",
        URL: "/home/slider/",
        URLData: null,
      });
      menus.push({
        Parent: "onlinestore",
        Title: "Home Sections",
        Value: "onlinehomesections",
        Icon: "onlinehomesections",
        URL: "/home/omni/homesections",
        URLData: null,
      });
      menus.push({
        Parent: "onlinestore",
        Title: "Product Detail Options",
        Value: "onlineproductdetail",
        Icon: "onlineproductdetail",
        URL: "/home/omni/productdetail",
        URLData: null,
      });
      // menus.push({Parent:"onlinestore",Title:"Checkout",Value:"onlinecheckout", Icon:"onlinestore", URL:"/home/omni/checkout", URLData:null})
      // menus.push({Parent:"onlinestore",Title:"Delivery/Packaging",Value:"onlineshipping", Icon:"delivery", URL:"/home/omni/shipping", URLData:null})
      menus.push({
        Parent: "onlinestore",
        Title: "Payment",
        Value: "onlinepayments",
        Icon: "expense",
        URL: "/home/omni/payment",
        URLData: null,
      });
      menus.push({
        Parent: "onlinestore",
        Title: "Pages",
        Value: "onlinepages",
        Icon: "onlinepages",
        URL: "/home/omni/pages",
        URLData: null,
      });
      menus.push({
        Parent: "onlinestore",
        Title: "Settings",
        Value: "onlinesettings",
        Icon: "setup",
        URL: "/home/omni/settings",
        URLData: null,
      });
      menus.push({
        Parent: "onlinestore",
        Title: "Payment Gateway Transactions",
        Value: "onlinepaymenttransactions",
        Icon: "list",
        URL: "/home/omni/onlinepaymenttransactions",
        URLData: null,
      });

      // menus.push({Parent:"onlinestore",Title:"Online orders",Value:"onlinesettings", Icon:"setup", URL:"/home/omni/onlineorders", URLData:null})
    }

    //catalogue
    if (
      this.getVisibility("Products", "read") ||
      (this.props.registration.registration.EnablePosBillingForPincode &&
        this.props.user.user.Role === "Store Owner")
    ) {
      menus.push({ Title: "Catalogue", Value: "catalogue", Icon: "catalogue" });

      if (
        this.showModule("Products", false) ||
        (this.props.registration.registration.EnablePosBillingForPincode &&
          this.props.user.user.Role === "Store Owner")
      ) {
        if (this.props.registration.registration.PincodeIntegration) {
          menus.push({
            Parent: "catalogue",
            Title: "Product",
            Value: "pincodeproducts",
            Icon: "product",
            URL: "/home/product/pincode",
            URLData: null,
          });
          menus.push({
            Parent: "catalogue",
            Title: "Product - Quick Edit",
            Value: "productquickedit",
            Icon: "list",
            URL: "/home/product/pincodequickedit/",
            URLData: null,
          });
        } else {
          menus.push({
            Parent: "catalogue",
            Title: "Product",
            Value: "product",
            Icon: "product",
            URL: "/home/product",
            URLData: null,
          });
        }
      }
      if (
        this.showModule("ProductCategories", false) ||
        (this.props.registration.registration.EnablePosBillingForPincode &&
          this.props.user.user.Role === "Store Owner")
      ) {
        menus.push({
          Parent: "catalogue",
          Title: "Category",
          Value: "category",
          Icon: "category",
          URL: "/home/catalogue/category",
          URLData: { type: actionTypes.PRODUCT_CATEGORY },
        });
        menus.push({
          Parent: "catalogue",
          Title: "Sub Category",
          Value: "subcategory",
          Icon: "subcategory",
          URL: "/home/catalogue/subcategory",
          URLData: { type: actionTypes.PRODUCT_SUB_CATEGORY },
        });
      }
      if (
        this.showModule("ProductBrands", false) ||
        (this.props.registration.registration.EnablePosBillingForPincode &&
          this.props.user.user.Role === "Store Owner")
      ) {
        menus.push({
          Parent: "catalogue",
          Title: "Brand",
          Value: "brand",
          Icon: "brand",
          URL: "/home/catalogue/brands",
          URLData: null,
        });
      }
      if (
        this.showModule("ProductTaxes", false) ||
        (this.props.registration.registration.EnablePosBillingForPincode &&
          this.props.user.user.Role === "Store Owner")
      ) {
        menus.push({
          Parent: "catalogue",
          Title: "Taxes",
          Value: "taxes",
          Icon: "taxes",
          URL: "/home/catalogue/taxes",
          URLData: null,
        });
      }
      if (this.showModule("Modifiers", false)) {
        menus.push({
          Parent: "catalogue",
          Title: "Modifiers",
          Value: "modifiers",
          Icon: "modifiers",
          URL: "/home/catalogue/modifiers",
          URLData: null,
        });
      }
      if (
        this.showModule("ProductTags", false) ||
        this.showModule("Offers", false)
      ) {
        menus.push({
          Parent: "catalogue",
          Title: "Product Group",
          Value: "tags",
          Icon: "tags",
          URL: "/home/catalogue/tags",
          URLData: null,
        });
      }
      if (this.showModule("Offers", false)) {
        menus.push({
          Parent: "catalogue",
          Title: "Offers",
          Value: "offers",
          Icon: "offers",
          URL: "/home/catalogue/offer",
          URLData: null,
        });
      }
      if (this.showModule("BOM", false)) {
        menus.push({
          Parent: "catalogue",
          Title: "BOM",
          Value: "bom",
          Icon: "bom",
          URL: "/home/catalogue/bom",
          URLData: null,
        });
      }
      if (
        this.props.user.store.hasOwnProperty("EnableFoodDeliveryPlatforms") &&
        this.props.user.store.EnableFoodDeliveryPlatforms
      ) {
        menus.push({
          Parent: "catalogue",
          Title: "Aggregator Products",
          Value: "aggregatorproducts",
          Icon: "aggregatorproducts",
          URL: "/home/catalogue/fooddeliveryplatformproducts",
          URLData: null,
        });
      }
    }

    //setup
    if (
      (this.getVisibility("Setup", "read") &&
        !this.props.registration.registration.EnablePosBillingForPincode) ||
      (this.props.registration.registration.EnablePosBillingForPincode &&
        this.props.user.user.Role === "Power User")
    ) {
      menus.push({ Title: "Setup", Value: "setup", Icon: "setup" });
      if (this.showModule("Registration", false)) {
        menus.push({
          Parent: "setup",
          Title: "Registration",
          Value: "registration",
          Icon: "registration",
          URL: "/home/settings/registration",
          URLData: null,
        });
      }
      if (this.showModule("Stores", false)) {
        menus.push({
          Parent: "setup",
          Title: "Stores",
          Value: "stores",
          Icon: "stores",
          URL: "/home/settings/stores",
          URLData: null,
        });
      }
      if (this.props.config.WhiteLabel.Code === "WRX") {
        menus.push({
          Parent: "setup",
          Title: "Sales Channel",
          Value: "saleschannel",
          Icon: "customfields",
          URL: "/home/saleschannel/",
          URLData: null,
        });
      }
      if (this.showModule("PaymentTypes", false)) {
        menus.push({
          Parent: "setup",
          Title: "Payment Types",
          Value: "paymenttypes",
          Icon: "paymenttypes",
          URL: "/home/settings/paymenttypes",
          URLData: null,
        });
      }
      if (
        this.props.user.store.hasOwnProperty("EnableTableOrder") &&
        this.props.user.store.EnableTableOrder
      ) {
        menus.push({
          Parent: "setup",
          Title: "Tables",
          Value: "tables",
          Icon: "tables",
          URL: "/home/settings/tables",
          URLData: null,
        });
      }
      if (this.showModule("CustomRoles", false)) {
        menus.push({
          Parent: "setup",
          Title: "Custom Roles",
          Value: "customroles",
          Icon: "adduser",
          URL: "/home/customroles",
          URLData: null,
        });
      }
      if (this.showModule("TransactionTypes", false)) {
        menus.push({
          Parent: "setup",
          Title: "Module Configuration",
          Value: "moduleconfiguration",
          Icon: "moduleconfiguration",
          URL: "/home/settings/moduleconfig",
          URLData: null,
        });
      }
      if (this.showModule("CustomFields", false)) {
        menus.push({
          Parent: "setup",
          Title: "Custom Fields",
          Value: "customfields",
          Icon: "customfields",
          URL: "/home/settings/customfields",
          URLData: null,
        });
      }
      menus.push({
        Parent: "setup",
        Title: "Transactional SMS Templates",
        Value: "transactionalsmstemplates",
        Icon: "sms",
        URL: "/home/settings/transactionalsmstemplates",
        URLData: null,
      });
      if (!this.props.registration.registration.PincodeIntegration) {
        menus.push({
          Parent: "setup",
          Title: "Additional Setting",
          Value: "addtionalsettings",
          Icon: "setup",
          URL: "/home/settings/addtionalsettings",
          URLData: null,
        });
      }
      if (this.props.registration.registration.PincodeIntegration) {
        menus.push({
          Parent: "setup",
          Title: "Pincode Setting",
          Value: "pincodesettings",
          Icon: "setup",
          URL: "/home/settings/pincodeintegrationsettings",
          URLData: null,
        });
      }

      if (this.props.registration.registration.key === "HM3hq6TxeFm38cdjcsF0") {
        menus.push({
          Parent: "setup",
          Title: "On Boarding",
          Value: "onboarding",
          Icon: "customfields",
          URL: "/home/settings/onBoarding",
          URLData: null,
        });
      }
    }

    if (
      this.props.user.store &&
      this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
      this.props.user.store.NoOfDaysToExpire > 0 &&
      this.props.user.user.Role !== "Store Cashier" &&
      this.getVisibility("Reports", "read")
    ) {
      menus.push({ Title: "Reports", Value: "reports", Icon: "reports" });

      if (this.showModule("Sales Dashboard", false)) {
        if (
          this.props.registration.registration.hasOwnProperty("NewDashboard") &&
          this.props.registration.registration.NewDashboard
        ) {
          menus.push({
            Parent: "reports",
            Title: "Dashboard",
            Value: "dashboard",
            Icon: "dashboard",
            URL: "/home/reports/dashboard",
            URLData: null,
          });
        } else {
          menus.push({
            Parent: "reports",
            Title: "Dashboard",
            Value: "dashboard",
            Icon: "dashboard",
            URL: "/home",
            URLData: null,
          });
        }
      }
      if (
        this.props.user.user.Role !== "Store Cashier" &&
        this.props.getVisibility("Reports", "read")
      ) {
        menus.push({
          Parent: "reports",
          Title: "Report Options",
          Value: "reportoptions",
          Icon: "reports",
          URL: "/home/reports/allreport",
          URLData: null,
        });
      }
    }
    this.setState({ menus: menus });
  }

  getVisibility(module, access) {
    let response = this.props.getVisibility(module, access);
    if (response === "inline") {
      return true;
    } else {
      //: "none"
      return false;
    }
  }

  showModule(module, forRole) {
    if (forRole) {
      if (
        this.props.user.user.hasOwnProperty("RoleKey") &&
        this.props.user.user.RoleKey !== ""
      ) {
        // console.log('forRole if', module)
        return this.props.getVisibility(module, "read");
      } else {
        // console.log('forRole else true', module)
        return true;
      }
    } else {
      return this.getVisibility(module, "read");
    }

    // return this.getVisibility(module, "read");
  }

  onOpenMenu(button) {
    this.setState({
      showMenu: true,
      menuCss: "menu-left menu-show-left",
      buttonSelected: button,
    });
  }

  onCloseMenu() {
    this.setState({
      showMenu: false,
      orientation: "",
      menuCss: "menu-left",
      buttonSelected: "",
    });

    // this.props.onCloseMenu();
  }

  onMenuClicked(menu, index) {
    if (menu.hasOwnProperty("URL") && menu.URL !== "") {
      this.props.history.push({ pathname: menu.URL, state: menu.URLData });
      this.onCloseMenu();
    }
    if (!menu.hasOwnProperty("Parent")) {
      if (this.state.selectedMenu === menu.Value) {
        this.setState({ selectedMenu: "" });
        this.props.setSelectedMenu("");
      } else {
        this.props.setSelectedMenu(menu.Value);
        this.setState({ selectedMenu: menu.Value });
      }
    } else {
      if (this.state.selectedSubMenu === menu.Value) {
        this.props.setSelectedSubMenu("");
        this.setState({ selectedSubMenu: "" });
      } else {
        this.props.setSelectedSubMenu(menu.Value);
        this.setState({ selectedSubMenu: menu.Value });
      }
    }
  }

  returnMenuItemClassName(menu, index) {
    if (this.props.config.SelectedMenu === menu.Value) {
      //it is selected
      //now check if it has child items or not
      if (this.state.menus.filter((f) => f.Parent === menu.Value).length > 0) {
        return "menu-item menu-item-selected menu-item-selected-child-items";
      } else {
        return "menu-item menu-item-selected";
      }
    } else {
      return "menu-item";
    }
  }

  renderMenuItem(menu, index) {
    //check if menu has child items
    return (
      <React.Fragment key={"menuitem_" + index}>
        <div
          className={this.returnMenuItemClassName(menu, index)}
          key={"menuitem_" + index}
          onClick={() => {
            this.onMenuClicked(menu, index);
          }}
        >
          {/* this.state.selectedMenu === menu.Value ? "menu-item menu-item-selected" : "menu-item" */}
          <Icon type={menu.Icon} />
          <div className="menu-item-text">{menu.Title}</div>
          {this.state.menus.filter((f) => f.Parent === menu.Value).length >
            0 && (
            <React.Fragment>
              {this.props.config.SelectedMenu !== menu.Value && (
                <Icon type={"rightarrow"} />
              )}
              {this.props.config.SelectedMenu === menu.Value && (
                <Icon type={"downarrow"} />
              )}
            </React.Fragment>
          )}
        </div>
        {this.state.menus.filter((f) => f.Parent === menu.Value).length > 0 &&
          this.props.config.SelectedMenu === menu.Value && (
            <div className="menu-item-child-container">
              {this.state.menus
                .filter((f) => f.Parent === menu.Value)
                .map((m, i) => (
                  <div
                    className={
                      this.props.config.SelectedSubMenu === m.Value
                        ? "menu-item menu-item-child menu-item-child-selected"
                        : "menu-item menu-item-child"
                    }
                    style={{ paddingLeft: "20px" }}
                    key={"menuitem_" + i}
                    onClick={() => {
                      this.onMenuClicked(m, i);
                    }}
                  >
                    <Icon type={m.Icon} />
                    <div className="menu-item-text">{m.Title}</div>
                  </div>
                ))}
            </div>
          )}
      </React.Fragment>
    );
  }

  logout() {
    this.props.signOutUser();
    auth.signOut().then(() => {
      // console.log('signout done')
      this.onCloseMenu();
      this.props.history.push({ pathname: "/" });
    });
  }

  renderUserWidget() {
    return (
      <React.Fragment>
        {this.props.user && !this.state.showUser && (
          <div className="menu-mobile-user">
            {this.props.config.WhiteLabel.Code !== "PINCODE" && (
              <>
                <Avatar text={this.props.user.user.Name} />
                <div>
                  <div className="">
                    <b>{this.props.user.user.Name}</b>
                  </div>
                  <div>{this.props.user.user.EmailID}</div>
                  <br />
                  {/* <br /> */}
                </div>
                {isMobileApp() && (
                  <div>
                    <i>Version: {this.props.config.Version}</i>
                  </div>
                )}
              </>
            )}

            {this.props.config.WhiteLabel.Code === "PINCODE" &&
              isMobileApp() &&
              this.props.user.store && (
                <>
                  <div className="">
                    <b>{this.props.user.user.Name}</b>
                  </div>
                  <div>{this.props.user.user.EmailID}</div>
                  <div>{this.props.user.store.StoreName}</div>
                  <div className="menu-onlineoffline-container">
                    <div className="header-online-switch">
                      {this.state.acceptOnlineOrdersLoading && (
                        <i className="fa fa-spinner fa-spin" />
                      )}
                      {!this.state.acceptOnlineOrdersLoading && (
                        <>
                          <span>
                            {this.props.user.store.AcceptOnlineOrders
                              ? "Online"
                              : "Offline"}
                          </span>
                          <Input
                            type="switch"
                            label=""
                            value={this.props.user.store.AcceptOnlineOrders}
                            disable={this.state.acceptOnlineOrdersLoading}
                            onChange={async (value) => {
                              // console.log("onChange called");
                              this.setState({
                                acceptOnlineOrdersLoading: true,
                              });
                              let store = this.props.user.store;
                              store.AcceptOnlineOrders = value;
                              await this.props.setStore(store);
                              await this.props.updateStore(
                                { AcceptOnlineOrders: value },
                                store.key,
                                value ? "Online" : "Offline"
                              );
                              this.setState({
                                acceptOnlineOrdersLoading: false,
                              });
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            {/* <br /> */}

            {/* <button className="menu-signout" onClick={this.logout}>Sign Out</button> */}
          </div>
        )}

        {/* {(this.props.user && this.state.showUser) && <React.Fragment>
                <div className="menu-overlay" onClick={() => this.setState({ showUser: false })} />
                <div className="user-menu">
                    <div className="list-row">
                        <Avatar text={this.props.user.user.Name} />
                        <div >
                            <div className=""><b>{this.props.user.user.Name}</b></div>
                            <div style={{ paddingBottom: "10px" }}>{this.props.user.user.EmailID}</div>
                            <a onClick={() => { this.setState({ showChangePasswordModal: true }) }}><u>Change password</u></a>
                            <br />

                        </div>
                    </div>
                    <br />
                    <button className="menu-signout" onClick={this.logout}>Sign Out</button>
                </div>
            </React.Fragment>} */}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showMenu && (
          <div className={"menu-overlay"} onClick={() => this.onCloseMenu()} />
        )}
        <div className={this.state.menuCss}>
          <div className="menu-content">
            {!isMobileApp() && (
              <div className="menu-logo">
                <Logo
                  logoOption={this.props.config.WhiteLabel.MenuLogoOption}
                  height="46px"
                  {...this.props}
                />
              </div>
            )}
            {isMobileApp() && this.renderUserWidget()}
            {this.state.menus
              .filter((f) => !f.hasOwnProperty("Parent"))
              .map((menu, index) => this.renderMenuItem(menu, index))}

            {isMobileApp() && (
              <>
                <div className="menu-logout-footer">
                  <Button
                    type="secondary"
                    text="Sign Out"
                    onClick={this.logout}
                  />
                </div>
                {/* <div>
                  <i>Version: {this.props.config.Version}</i>
                </div> */}
              </>
            )}
          </div>
          <div className="menu-close-button" onClick={() => this.onCloseMenu()}>
            <ImageButton type="remove" onClick={() => this.onCloseMenu()} />
          </div>
        </div>
        {this.props.user.store &&
          this.props.user.store.NoOfDaysToExpire &&
          this.props.user.store.NoOfDaysToExpire > 0 && (
            <div className="menu-button">
              <ImageButton
                type="menubar"
                onClick={() => this.onOpenMenu("menubar")}
              />
            </div>
          )}
      </React.Fragment>
    );
  }
}

export default Menu;
