import React, { Component } from "react";
import Window from "../2/Controls/Window";
import ReportHeader from "./Common/ReportHeader";
import ReportBody from "./Common/ReportBody";
import Input from "../2/Controls/Input";
import ReportButton from "./Common/ReportButton";
import firebase from "../../firebase";
import Spinner from "../Common/Spinner";
import * as constatnts from "../../Constatnts";
class PincodeReportedProducts extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      products: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.repotedProducts(0, 10000, "", "", "");
  }

  async repotedProducts(from, size, sku, barcode, productname) {
    let searchParameters = {
      track_total_hits: true,

      query: {
        bool: {
          must: [
            {
              match: {
                RegistrationID: this.props.registration.registration.key,
              },
            },
            {
              match: {
                IsDeleted: false,
              },
            },
            {
              exists: {
                field: "CustomField4",
              },
            },
            {
              script: {
                script: {
                  source: "doc['CustomField4.keyword'].value != ''",
                  lang: "painless",
                },
              },
            },
          ],
        },
      },

      size: size,
      from: from,
    };

    // if (sku.trim()) {
    //     searchParameters.query.bool.must.push({
    //         wildcard: {
    //             "ProductSKU.keyword": {
    //                 value: sku + "*",
    //             },
    //         },
    //     });
    // }
    // if (barcode.trim()) {
    //     searchParameters.query.bool.must.push({
    //         wildcard: {
    //             "BarCode.keyword": {
    //                 value: sku + "*",
    //             },
    //         },
    //     });
    // }
    // if (productname.trim()) {
    //     searchParameters.query.bool.must.push({
    //         wildcard: {
    //             "ProductName.keyword": {
    //                 value: productname + "*",
    //             },
    //         },
    //     });
    // }
    console.log("searchParameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getProducts");
    let result = await addMessage({ text: searchParameters });
    let sanitizedMessage = result.data.text;

    let products = [];

    let totalRecordCount = sanitizedMessage.hits.total.value;
    for (let i = 0; i < sanitizedMessage.hits.hits.length; i++) {
      let obj = sanitizedMessage.hits.hits[i];

      let Image =
        obj._source.Images && obj._source.Images.length > 0
          ? obj._source.Images[0].ImagePath
          : "";
      products.push({ key: obj._id, ...obj._source, Image: Image });
    }

    console.log("products", products);

    this.setState({
      products: products,
      totalItemsCount: totalRecordCount,
      loading: false,
    });
  }
  async exportProduct() {
    const productsData = this.state.products.map((item) => {
      const product = {
        Image: item.Image,
        SKU: item.SKU,
        BarCode: item.BarCode,
        Name: item.Name,
        MRP: item.MRP,
        RetailPrice: item.RetailPrice,
        "Catalogue Issue": item.CustomField4,
        "Catlogue Issue Remark": item.CustomField5,
      };
      return product;
    });

    const csvData = constatnts.objToCSV(productsData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "reportedProducts.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <ReportHeader
            title={"Reported Products"}
            showBack={true}
            onBack={() => {
              this.props.history.push({
                pathname: "/home/reports/allreport",
              });
            }}
          ></ReportHeader>
          <ReportBody>
            {/* <div className="row">
                            <div className="col-md-2">
                                <Input
                                    type="text"
                                    label="SKU"
                                    value={this.state.SKU}
                                    onChange={(value) => this.setState({ SKU: value })}
                                />
                            </div>
                            <div className="col-md-2">
                                <Input
                                    type="text"
                                    label="Barcode"
                                    value={this.state.barcode}
                                    onChange={(value) => this.setState({ barcode: value })}
                                />
                            </div>
                            <div className="col-md-2">
                                <Input
                                    type="text"
                                    label="Product name"
                                    value={this.state.productName}
                                    onChange={(value) => this.setState({ productName: value })}
                                />
                            </div>
                        </div> */}
            <div className="row">
              <div className="col-md-6 pull-left">
                <p>Total Count:{this.state.products.length}</p>
              </div>
              <div className="col-md-6 pull-right">
                {/* <ReportButton type="search" onClick={()=>}/> */}
                {/* <ReportButton type="clear" /> */}
                <ReportButton
                  type="export"
                  onClick={() => this.exportProduct()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.loading && <Spinner />}
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>SKU</th>
                      <th>Barcode</th>
                      <th>Name</th>
                      <th>MRP</th>
                      <th>Retail Price</th>
                      {/* <th>In Stock Qty</th> */}
                      <th>Catalogue Issue</th>
                      <th>Catalogue Issue Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products.map((m, index) => (
                      <tr key={"tdtr" + index}>
                        <td>
                          <img className="list-page-image" src={m.Image} />
                        </td>
                        <td>{m.SKU}</td>
                        <td>{m.BarCode}</td>
                        <td>{m.Name}</td>
                        <td className={"number"}>{m.MRP}</td>
                        <td style={{ textAlign: "right" }}>{m.RetailPrice}</td>
                        {/* <td>{m.InStockQty}</td> */}
                        <td>{m.CustomField4}</td>
                        <td>{m.CustomField5}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ReportBody>
        </section>
      </div>
    );
  }
}

export default PincodeReportedProducts;
