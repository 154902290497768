import * as actionTypes from "../actionTypes";
import axios from "axios";

export function getPincodeGlobalCatalogMasterData(type) {
  return (dispatch, getState) => {
    try {
      // console.log("getPincodeGlobalCatalogMasterData", type);

      axios
        .post(
          "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetPincodeGlobalCatalogMasterData",
          { type: type }
        )
        .then((res) => {
          // console.log(res);
          // return res.data;

          // console.log(JSON.stringify(res.data));
          if (type === "category") {
            let pincodeCategories = res.data.records;
            dispatch({
              type: actionTypes.SET_PINCODE_CATEGORIES,
              pincodeCategories,
            });
          } else if (type === "subcategory") {
            let pincodeSubCategories = res.data.records;
            dispatch({
              type: actionTypes.SET_PINCODE_SUB_CATEGORIES,
              pincodeSubCategories,
            });
          } else if (type === "brand") {
            let pincodeBrands = res.data.records;
            dispatch({
              type: actionTypes.SET_PINCODE_BRANDS,
              pincodeBrands,
            });
          } else if (type === "producttype") {
            let pincodeProductTypes = res.data.records;
            dispatch({
              type: actionTypes.SET_PINCODE_PRODUCT_TYPES,
              pincodeProductTypes,
            });
          } else if (type === "uom") {
            let pincodeUOMs = res.data.records;
            dispatch({
              type: actionTypes.SET_PINCODE_UOMS,
              pincodeUOMs,
            });
          } else if (type === "model") {
            let pincodeModels = res.data.records;
            dispatch({
              type: actionTypes.SET_PINCODE_MODELS,
              pincodeModels,
            });
          } else if (type === "packaging") {
            let pincodePackaging = res.data.records;
            dispatch({
              type: actionTypes.SET_PINCODE_PACKAGING,
              pincodePackaging,
            });
          } else if (type === "bundling") {
            let pincodeBundling = res.data.records;
            dispatch({
              type: actionTypes.SET_PINCODE_BUNDLING,
              pincodeBundling,
            });
          }
        })
        .catch((err) => {});

      // const res = await axios.post(
      //   "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetPincodeGlobalCatalogMasterData",
      //   { type: type }
      // );
    } catch (err) {
      console.log("getPincodeGlobalCatalogMasterData", err);
    }
  };
}
