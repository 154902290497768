import React, { Component } from "react";
import Loader from "../Loader";
import * as actionTypes from "../../actionTypes";
import Window from "../2/Controls/Window";
import Button from "../2/Controls/Button";
import "./ProductSelection.css";
class ProductSelection extends Component {
  constructor() {
    super();
    this.state = {};
    this.renderProductImage = this.renderProductImage.bind(this);
  }

  componentDidMount() {}

  renderProductImage(prodcut) {
    let image = "";
    if (prodcut.Images && prodcut.Images.length > 0) {
      image = prodcut.Images[0].ImagePath;
    }
    return (
      <div className="productquickedit-prodcut-image">
        {image !== "" && <img src={image} />}
      </div>
    );
  }

  render() {
    return (
      <Window
        header={"Please select product"}
        height={"300px"}
        width={"700px"}
        modal={true}
        processing={this.state.saving}
        onClose={this.props.onClose}
        onButtonClick={(buttonType) => {
          if (buttonType.toLowerCase() === "save") {
            this.onSave();
          } else {
            this.props.onClose();
          }
        }}
        buttons={[]}
      >
        <table className="table table-bordered table-striped delivery-list-table">
          <thead>
            <tr>
              <th></th>
              <th className="productselection-prodcut-product">Product</th>
              <th>MRP</th>
              <th>Retail Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.products
              .sort((a, b) => {
                const aMRP =
                  typeof a.MRP === "number" && !isNaN(a.MRP) ? a.MRP : Infinity; // Treat non-numeric or missing MRP as Infinity
                const bMRP =
                  typeof b.MRP === "number" && !isNaN(b.MRP) ? b.MRP : Infinity; // Treat non-numeric or missing MRP as Infinity

                return bMRP - aMRP; // Sort in ascending order
              })
              .map((p) => (
                <tr>
                  <td className="productselection-prodcut-image">
                    {this.renderProductImage(p)}
                  </td>
                  <td>
                    {p.Name}
                    <div>{p.SKU}</div>
                    {p.SKU !== p.BarCode && <div>{p.BarCode}</div>}
                  </td>
                  <td className="productselection-prodcut-price">{p.MRP}</td>
                  <td className="productselection-prodcut-price">
                    {p.RetailPrice}
                  </td>
                  <td>
                    <Button
                      type="primary"
                      text="Select"
                      onClick={() => this.props.onProductSelection(p)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Window>
    );
  }
}

export default ProductSelection;
