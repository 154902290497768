import React, { Component } from "react";
import Icon from "./Icon";
import Switch from "react-switch";
import CreatableSelect from "react-select";
import ImageButton from "./ImageButton";
import Dropzone from "react-dropzone";
import OtpInput from "react-otp-input";

import "./Input.css";
var AsyncTypeahead = require("react-bootstrap-typeahead").AsyncTypeahead;
var Typeahead = require("react-bootstrap-typeahead").Typeahead;

class Input extends Component {
  constructor() {
    super();
    this.state = {
      options: [],
      isProductTypeAheadLoading: false,
      productsTypeAheadOptions: [],
      productsTypeAheadSelected: [],
      queryText: "",
      HH: "",
      MM: "",
      AMPM: "",
      showPassword: false,
    };
    this.renderProductSearch = this.renderProductSearch.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onHHChange = this.onHHChange.bind(this);
    this.onMMChange = this.onMMChange.bind(this);
    this.onAMPMChange = this.onAMPMChange.bind(this);
    this.onDateEntryChange = this.onDateEntryChange.bind(this);
    this.returnFilterBy = this.returnFilterBy.bind(this);
    this.renderTypeAheadSelect = this.renderTypeAheadSelect.bind(this);
    this.renderTypeAheadSelection = this.renderTypeAheadSelection.bind(this);
    this.renderPassword = this.renderPassword.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.renderOTP = this.renderOTP.bind(this);
  }

  onHHChange(HH) {
    if (HH > 12) {
      return;
    }
    this.setState({ HH: HH });
    let time = { HH: HH, MM: this.props.value.MM, AMPM: this.props.value.AMPM };
    this.props.onChange(time);
  }

  onMMChange(MM) {
    if (MM > 60) {
      return;
    }
    this.setState({ MM: MM });
    let time = { HH: this.props.value.HH, MM: MM, AMPM: this.props.value.AMPM };
    this.props.onChange(time);
  }

  onAMPMChange(AMPM) {
    this.setState({ AMPM: AMPM });
    let time = { HH: this.props.value.HH, MM: this.props.value.MM, AMPM: AMPM };
    this.props.onChange(time);
  }

  onDateEntryChange(day, month, year) {
    let dateEntry = { Day: day, Month: month, Year: year };
    this.props.onChange(dateEntry);
  }

  componentDidMount() {
    if (this.props.type === "checkboxlist") {
      let options = [];
      this.props.children.map((option) => {
        let tmp = {
          value: option.props.value ? option.props.value : "",
          text: option.props.text ? option.props.text : "",
          selected: false,
        };
        // console.log(tmp);
        options.push(tmp);
      });
      this.setState({ options: options });
    }
  }

  onKeyDown(e) {
    if (e.key === "Enter") {
      // console.log('do validate');
      if (this.props.onEnter) {
        this.props.onEnter();
      }
    }
  }

  renderError() {
    return (
      <React.Fragment>
        {this.props.error && (
          <div className="error-msg">{this.props.error}</div>
        )}
      </React.Fragment>
    );
  }

  renderText() {
    return (
      <React.Fragment>
        <div style={{ position: "relative", marginBottom: "16px" }}>
          {this.props.label && <label>{this.props.label}</label>}
          <input
            type="text"
            className="form-control"
            placeholder={this.props.placeholder}
            value={this.props.value}
            onKeyDown={this.onKeyDown}
            disabled={this.props.disabled}
            onChange={(e) => {
              if (this.props.onChange) {
                this.props.onChange(e.target.value);
              }
            }}
          />
          {this.props.showButton && (
            <button
              className="btn btn-default btn-md"
              onClick={() => {
                if (this.props.onShowButtonClick) {
                  this.props.onShowButtonClick("");
                }
              }}
              style={{
                position: "absolute",
                right: "0px",
                top: "41px",
                transform: "translateY(-50%)",
                cursor: "pointer",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
              }}
            >
              {this.props.showButtonText}
            </button>
          )}
          {this.props.value && this.props.showRemoveButton && (
            <button
              onClick={() => {
                if (this.props.onRemove) {
                  this.props.onRemove("");
                }
              }}
              style={{
                position: "absolute",
                right: "10px",
                top: "41px",
                transform: "translateY(-50%)",
                border: "none",
                background: "transparent",
                cursor: "pointer",
                fontSize: "16px",
                color: "#aaa",
                fontWeight: "bold",
              }}
              aria-label="Clear input"
            >
              X
            </button>
          )}
          {this.renderError()}
        </div>
      </React.Fragment>
    );
  }

  renderTextArea() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <textarea
          type="text"
          rows="4"
          cols="50"
          className="form-control textarea"
          value={this.props.value}
          onChange={(e) => {
            if (this.props.onChange) {
              this.props.onChange(e.target.value);
            }
          }}
        />
        {this.renderError()}
      </React.Fragment>
    );
  }

  // renderNumber() {
  //   return (
  //     <React.Fragment>
  //       {this.props.label && <label>{this.props.label}</label>}
  //       <input
  //         type="number"
  //         ref={this.props.inputRef}
  //         placeholder={this.props.placeholder}
  //         disabled={this.props.disabled}
  //         className="form-control"
  //         value={this.props.value}
  //         onKeyDown={this.onKeyDown}
  //         onChange={(e) => {
  //           if (this.props.onChange) {
  //             this.props.onChange(e.target.value);
  //           }
  //         }}
  //       />
  //       {this.renderError()}
  //     </React.Fragment>
  //   );
  // }
  renderNumber() {
    return (
      <React.Fragment>
        <div style={{ position: "relative", marginBottom: "16px" }}>
          {this.props.label && <label>{this.props.label}</label>}
          <div className="input-with-suffix">
            <input
              type="number"
              ref={this.props.inputRef}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              className="form-control"
              value={this.props.value}
              onKeyDown={this.onKeyDown}
              onChange={(e) => {
                if (this.props.onChange) {
                  this.props.onChange(e.target.value);
                }
              }}
            />
            {this.props.suffix && (
              <span className="input-suffix">{this.props.suffix}</span>
            )}
          </div>
          {this.renderError()}
        </div>
      </React.Fragment>
    );
  }

  renderOTP() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        <div className="otp">
          <OtpInput
            value={this.props.value}
            onChange={(value) => {
              if (this.props.onChange) {
                this.props.onChange(value);
              }
            }}
            numInputs={this.props.noOfInputs ? this.props.noOfInputs : 4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        {this.renderError()}
      </React.Fragment>
    );
  }

  renderSelect() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <select
          className="form-control"
          value={this.props.value}
          disabled={this.props.disabled}
          onChange={(e) => {
            if (this.props.onChange) {
              this.props.onChange(e.target.value);
            }
          }}
        >
          {this.props.children}
        </select>
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderCheckBoxList() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        {this.renderError()}
        {this.state.options.map((o, index) => (
          <div id={"ctrl_" + index}>
            <input
              type="checkbox"
              checked={o.selected}
              onChange={() => {
                let options = this.state.options;
                options[index].selected = !options[index].selected;
                this.setState({ options: options });
                this.props.onChange(options);
              }}
            />{" "}
            {o.text}
          </div>
        ))}
      </React.Fragment>
    );
  }

  renderCheckbox() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        {this.renderError()}
        <input
          type="checkbox"
          checked={this.props.value}
          onChange={() => {
            if (this.props.onChange) {
              this.props.onChange(!this.props.value);
            }
          }}
        />{" "}
        {this.props.text}
      </React.Fragment>
    );
  }

  renderRadioList() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        {this.renderError()}
        {this.props.children.map((o, index) => (
          <div id={"ctrl_" + index}>
            <input
              type="radio"
              onChange={() => {
                if (this.props.onChange) {
                  this.props.onChange(o.props.text);
                }
              }}
              name={this.props.name}
            />{" "}
            {o.props.text}
          </div>
        ))}
      </React.Fragment>
    );
  }

  // renderRadio() {
  //   return <React.Fragment>
  //     <label>
  //       <input
  //         type="radio"
  //         value={this.props.value}
  //         checked={selectedOption === 'option1'}
  //         onChange={handleOptionChange}
  //       />
  //       Option 1
  //     </label>
  //   </React.Fragment>;
  // }

  renderMobileNo() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <div className="input-group">
          <div className="input-group-addon">
            <span className="input-group-text" id="basic-addon1">
              +91
            </span>
          </div>
          <input
            type="number"
            className="form-control"
            value={this.props.value}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              if (this.props.onChange) {
                this.props.onChange(e.target.value);
              }
            }}
            disabled={this.props.disabled}
            onKeyDown={this.onKeyDown}
          />
        </div>
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderEmailID() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <div className="input-group">
          <div className="input-group-addon">
            <span className="input-group-text">
              <i className="fa fa-envelope" />
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            value={this.props.value}
            onChange={(e) => {
              if (this.props.onChange) {
                this.props.onChange(e.target.value);
              }
            }}
            disabled={this.props.disabled}
            onKeyDown={this.onKeyDown}
          />
        </div>
        {this.renderError()}
      </React.Fragment>
    );
  }

  // renderLoginID(){
  //     return <React.Fragment>
  //         {this.props.label && <label>{this.props.label}</label>}
  //         <div className="form-group  has-feedback">
  //             <input type="text" className="form-control input-lg login-border-radius" placeholder={this.props.placeholder}
  //                 value={this.props.value}
  //                 onChange={e => this.props.onChange(e.target.value)} />
  //             <span className="fa fa-envelope form-control-feedback"></span>
  //         </div>
  //         {this.renderError()}
  //     </React.Fragment>
  // }

  renderLoginID() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <div className="input-rounded">
          <input
            type="text"
            placeholder={this.props.placeholder}
            value={this.props.value}
            onKeyDown={this.onKeyDown}
            onChange={(e) => {
              if (this.props.onChange) {
                this.props.onChange(e.target.value);
              }
            }}
          />
          {/* <span><Icon type="login"/></span> */}
        </div>
        {this.renderError()}
      </React.Fragment>
    );
  }

  // renderPassword(){
  //     return <React.Fragment>
  //     {this.props.label && <label>{this.props.label}</label>}
  //     <div className="input-rounded">
  //         <input type="password" placeholder={this.props.placeholder}
  //          onKeyDown={this.onKeyDown}
  //         value={this.props.value}
  //         onChange={(e) => {if(this.props.onChange){this.props.onChange(e.target.value)} }}/>
  //         {/* <span><Icon type="password"/></span> */}
  //     </div>
  //     {this.renderError()}
  // </React.Fragment>
  // }

  renderPassword() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        {!this.state.showPassword && (
          <div className="input-rounded">
            <input
              type="password"
              placeholder={this.props.placeholder}
              onKeyDown={this.onKeyDown}
              value={this.props.value}
              onChange={(e) => this.props.onChange(e.target.value)}
            />
            <span
              className="password"
              onClick={() => this.setState({ showPassword: true })}
            >
              <Icon type="showpassword" />
            </span>
          </div>
        )}
        {this.state.showPassword && (
          <div className="input-rounded">
            <input
              type="text"
              placeholder={this.props.placeholder}
              onKeyDown={this.onKeyDown}
              value={this.props.value}
              onChange={(e) => this.props.onChange(e.target.value)}
            />
            <span
              className="password"
              onClick={() => this.setState({ showPassword: false })}
            >
              <Icon type="hidepassword" />
            </span>
          </div>
        )}
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderSearch() {
    return (
      <div className="input-rounded">
        <input
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onKeyDown={this.onKeyDown}
          onChange={(e) => {
            if (this.props.onChange) {
              this.props.onChange(e.target.value);
            }
          }}
        />
        <span>
          <Icon type="search" />
        </span>
      </div>
    );
  }

  renderDate() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <input
          type="date"
          min={this.props.minDate}
          max={this.props.maxDate}
          className="form-control"
          placeholder={this.props.placeholder}
          onKeyDown={this.onKeyDown}
          value={this.props.value}
          onChange={(e) => {
            if (this.props.onChange) {
              this.props.onChange(e.target.value);
            }
          }}
        />
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderSwitch() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        <Switch
          checked={this.props.value}
          onChange={(e) => {
            if (this.props.onChange && !this.props.disabled) {
              this.props.onChange(!this.props.value);
            }
          }}
        />
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderProductSearch() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        <div className="input-group">
          {!this.state.toggleSearch && (
            <AsyncTypeahead
              //  ref={(ref) => this.typeaheadProductSearch = ref}
              allowNew={false}
              isLoading={this.props.processing}
              multiple={false}
              options={this.props.options}
              labelKey="Name"
              minLength={3}
              onSearch={(searchText) => this.props.onSearch(searchText)}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : "Start typing to search products"
              }
              filterBy={["Name", "SKU", "BarCode"]}
              dropup={false}
              highlightOnlyResult={true}
              selected={this.state.productsTypeAheadSelected}
              onChange={(selected) => {
                if (this.props.onChange) {
                  this.props.onChange(selected[0]);
                }
                this.setState({ productsTypeAheadSelected: [] });
              }}
              renderMenuItemChildren={(option, props) => (
                <div>
                  <div>
                    <b>{option.Name}</b>
                  </div>
                  <div>{option.SKU}</div>
                  {/* <div>{(option.hasOwnProperty('CustomField1') && option.CustomField1.trim() !== "" && this.state.productCustomField1DisplayName.trim() !== "") && <React.Fragment>{this.state.productCustomField1DisplayName}: {option.CustomField1}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField2') && option.CustomField2.trim() !== "" && this.state.productCustomField2DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField2DisplayName}: {option.CustomField2}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField3') && option.CustomField3.trim() !== "" && this.state.productCustomField3DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField3DisplayName}: {option.CustomField3}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField4') && option.CustomField4.trim() !== "" && this.state.productCustomField4DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField4DisplayName}: {option.CustomField4}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField5') && option.CustomField5.trim() !== "" && this.state.productCustomField5DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField5DisplayName}: {option.CustomField5}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField6') && option.CustomField6.trim() !== "" && this.state.productCustomField6DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField6DisplayName}: {option.CustomField6}</React.Fragment>}
                        </div> */}
                </div>
              )}
            />
          )}
          {this.state.toggleSearch && (
            <input
              type="text"
              onChange={(evt) => {
                this.setState({ queryText: evt.target.value });
              }}
              value={this.state.queryText}
              onKeyPress={() => {
                this.props.onScan(this.state.queryText);
              }}
              placeholder="Start scanning to search products"
              className="form-control"
              style={{ height: "33px", fontSize: "14px" }}
            />
          )}
          <div className="input-group-btn">
            <button
              type="button"
              onClick={() =>
                this.setState({ toggleSearch: !this.state.toggleSearch })
              }
              className="btn btn-default btn-flat btn-nocurve"
            >
              <i className="fa fa-exchange"></i>
            </button>
          </div>
          {this.props.showImportButton && (
            <div className="input-group-btn">
              <button
                type="button"
                onClick={() => {
                  this.props.onClick("import");
                }}
                className="btn btn-default btn-left-nocurve"
              >
                <i className="fa fa-upload"></i>Import Product
              </button>
            </div>
          )}
        </div>
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderTimePicker() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        <div className="timePicker">
          <div>
            <input
              type="number"
              onChange={(evt) => {
                if (this.props.onChange) {
                  this.onHHChange(evt.target.value);
                }
              }}
              value={this.props.value.HH}
              className="form-control"
              placeholder="HH"
            ></input>
          </div>
          <div className="timePicker-seperator">:</div>
          <div>
            <input
              type="number"
              className="form-control"
              onChange={(evt) => {
                if (this.props.onChange) {
                  this.onMMChange(evt.target.value);
                }
              }}
              value={this.props.value.MM}
              placeholder="MM"
            ></input>
          </div>
          <div className="timePicker-seperator">:</div>
          <div>
            <select
              className="form-control"
              onChange={(evt) => {
                if (this.props.onChange) {
                  this.onAMPMChange(evt.target.value);
                }
              }}
              value={this.props.value.AMPM}
            >
              <option value=""></option>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
          <div
            onClick={() => {
              let d = new Date();
              let hh = d
                .getHours()
                .toString()
                .padStart(2, "0");
              let mm = d
                .getMinutes()
                .toString()
                .padStart(2, "0");
              let ampm = "";
              if (Number(d.getHours()) > 12) {
                hh = Number(hh) - 12;
                hh = hh.toString().padStart(2, "0");
                ampm = "PM";
              } else {
                ampm = "AM";
              }
              let time = { HH: hh, MM: mm, AMPM: ampm };
              // console.log('d.getHours()',d.getHours())
              // console.log('time',time)
              this.props.onChange(time);
            }}
          >
            <Icon type="moduleconfiguration" />
          </div>
          {/* </div> */}
        </div>
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderDateEntry() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        <div className="timePicker">
          <div>
            <input
              type="number"
              onChange={(evt) => {
                if (this.props.onChange) {
                  this.onDateEntryChange(
                    evt.target.value,
                    this.props.value.Month,
                    this.props.value.Year
                  );
                }
              }}
              value={this.props.value.Day}
              placeholder="DD"
              className="form-control"
            ></input>
          </div>
          <div>
            <select
              className="form-control"
              placeholder="MMM"
              onChange={(evt) => {
                if (this.props.onChange) {
                  this.onDateEntryChange(
                    this.props.value.Day,
                    evt.target.value,
                    this.props.value.Year
                  );
                }
              }}
              value={this.props.value.Month}
            >
              <option></option>
              <option>Jan</option>
              <option>Feb</option>
              <option>Mar</option>
              <option>Apr</option>
              <option>May</option>
              <option>Jun</option>
              <option>Jul</option>
              <option>Aug</option>
              <option>Sep</option>
              <option>Oct</option>
              <option>Nov</option>
              <option>Dec</option>
            </select>
          </div>
          <div>
            <input
              type="number"
              className="form-control"
              placeholder="YYYY"
              onChange={(evt) => {
                if (this.props.onChange) {
                  this.onDateEntryChange(
                    this.props.value.Day,
                    this.props.value.Month,
                    evt.target.value
                  );
                }
              }}
              value={this.props.value.Year}
            ></input>
          </div>
        </div>
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderMultiSelect() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        <CreatableSelect
          defaultValue={this.props.value}
          options={this.props.options}
          onChange={(value) => {
            if (this.props.onChange) {
              this.props.onChange(value);
            }
          }}
          isMulti
        />
        {this.renderError()}
      </React.Fragment>
    );
  }

  returnFilterBy() {
    let filterColumns = [];
    this.props.columns.map((c) => {
      if (c.Filter) {
        filterColumns.push(c.Column);
      }
    });
    console.log("filterColumns", filterColumns);
    return filterColumns;
  }

  renderTypeAheadSelect() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        {!this.props.value && (
          <Typeahead
            allowNew={false}
            multiple={false}
            options={this.props.options}
            labelKey={this.props.labelKey}
            placeholder={this.props.placeholder}
            // filterBy={this.returnFilterBy}
            filterBy={this.props.filterBy}
            // bsSize={"large"}
            dropup={false}
            highlightOnlyResult={true}
            selected={this.props.value}
            onChange={(value) => {
              this.props.onChange(value);
            }}
            renderMenuItemChildren={(option, props) => (
              <div className="typeAheadSelect-serachitem">
                {this.props.columns
                  .filter((f) => f.SearchText)
                  .map((c) => (
                    <div className={c.Main ? "maintext" : "subtext"}>
                      {option[c.Column]}
                    </div>
                  ))}
              </div>
            )}
          />
        )}
        {this.props.value && (
          <div className="typeAheadSelect-selected">
            <div
              className="left w85"
              onClick={() => this.props.onClick("click")}
            >
              <div>
                {this.props.columns
                  .filter((f) => f.SelectedText)
                  .map((c) => (
                    <div className={c.Main ? "maintext" : "subtext"}>
                      {this.props.value[c.Column]}
                    </div>
                  ))}
              </div>
            </div>
            <div className="right w15">
              <ImageButton
                type="remove"
                onClick={() => this.props.onClick("remove")}
              />
            </div>
          </div>
        )}
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderTypeAheadSelection() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        {!this.props.selected && (
          <AsyncTypeahead
            ref={this.props.inputRef}
            allowNew={false}
            isLoading={this.props.processing}
            multiple={false}
            options={this.props.options}
            labelKey={this.props.labelKey}
            minLength={3}
            onSearch={(searchText) => this.props.onSearch(searchText)}
            placeholder={
              this.props.placeholder
                ? this.props.placeholder
                : "Start typing to search products"
            }
            filterBy={this.props.filterBy} //['Name', 'SKU', 'BarCode']
            dropup={false}
            highlightOnlyResult={true}
            selected={this.props.selected}
            onChange={(selected) => {
              if (this.props.onChange) {
                this.props.onChange(selected[0]);
              }
            }}
            renderMenuItemChildren={(option, props) =>
              this.props.renderMenuItemChildren(option, props)
            }
          />
        )}
        {this.props.selected && (
          <div className="typeAheadSelect-selected">
            <div
              className="left w85"
              onClick={() => this.props.onClick("click")}
            >
              <div>
                {this.props.columns
                  .filter((f) => f.SelectedText)
                  .map((c) => (
                    <div className={c.Main ? "maintext" : "subtext"}>
                      {this.props.selected[c.Column]}
                    </div>
                  ))}
              </div>
            </div>
            <div className="right w15">
              <ImageButton
                type="remove"
                onClick={() => this.props.onClick("remove")}
              />
            </div>
          </div>
        )}
        {this.renderError()}
      </React.Fragment>
    );
  }

  renderTypeAheadSearch() {
    return (
      <React.Fragment>
        {this.props.label && <div>{this.props.label}</div>}
        <AsyncTypeahead
          allowNew={false}
          isLoading={this.props.processing}
          multiple={false}
          options={this.props.options}
          labelKey={this.props.labelKey}
          minLength={3}
          onSearch={(searchText) => this.props.onSearch(searchText)}
          placeholder={
            this.props.placeholder
              ? this.props.placeholder
              : "Start typing to search products"
          }
          filterBy={this.props.filterBy} //['Name', 'SKU', 'BarCode']
          dropup={false}
          highlightOnlyResult={true}
          selected={this.props.selected}
          onChange={(selected) => {
            if (this.props.onChange) {
              this.props.onChange(selected[0]);
            }
          }}
          renderMenuItemChildren={(option, props) =>
            this.props.renderMenuItemChildren(option, props)
          }
        />
        {this.renderError()}
      </React.Fragment>
    );
  }

  async onDrop(acceptedFiles, rejectedFiles) {
    let images = this.props.value ? this.props.value : [];
    if (acceptedFiles.length > 0) {
      let maxImageCount = this.props.maxImageCount
        ? this.props.maxImageCount
        : 4;
      if (acceptedFiles.length + images.length > maxImageCount) {
        alert("Max no images uploaded");
        return;
      }
      for (let i = 0; i < acceptedFiles.length; i++) {
        let file = acceptedFiles[i];
        if (file.size / 1024 > 300) {
          alert("File size should not be more than 100 KB");
          return;
        }
      }
      this.setState({ imageLoading: true });

      for (let i = 0; i < acceptedFiles.length; i++) {
        let file = acceptedFiles[i];
        let fileName = +new Date().getTime() + file.name;
        let downloadURL = await this.props.uploadFileToServer(
          file,
          this.props.registration.registration.StorageFolder + "/Product",
          fileName
        );
        let bucket = "posible.in/";
        let folder =
          this.props.registration.registration.StorageFolder + "/Product/";
        let url =
          "https://storage.googleapis.com/" + bucket + folder + fileName;
        let image = {
          ImageName: fileName,
          ImageSize: file.size,
          ImagePath: url,
        };
        images.push(image);
      }
      this.setState({ imageLoading: false });
      console.log("images", images);
      this.props.onChange(images);
    } else {
      alert("No file selected");
    }
  }

  onRemoveImage(index) {
    let array = [...this.props.value];
    array.splice(index, 1);
    this.props.onChange(array);
  }

  renderImages() {
    return (
      <React.Fragment>
        {this.props.label && this.props.label !== "" && (
          <div className="row">
            <div className="col-md-12">{this.props.label}</div>
          </div>
        )}
        <div className="row">
          <div className="col-md-3">
            <Dropzone onDrop={(files) => this.onDrop(files)}>
              <div>
                Try dropping some files here, or click to select files to
                upload.
              </div>
            </Dropzone>
          </div>
          <div className="col-md-9">
            <ul className="mailbox-attachments clearfix">
              {this.props.value &&
                this.props.value.map((image, index) => (
                  <li>
                    <span className="mailbox-attachment-icon has-img">
                      <img src={image.ImagePath} alt="Attachment" />
                    </span>
                    <div className="mailbox-attachment-info">
                      <span className="mailbox-attachment-name">
                        {image.ImageName}
                      </span>
                      <span className="mailbox-attachment-size">
                        {Math.round(image.ImageSize / 1024)} KB
                        <a
                          onClick={() => this.onRemoveImage(index)}
                          className="btn btn-default btn-xs pull-right"
                        >
                          <i className="fa fa-trash"></i>
                        </a>
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderTypeAhead() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <Typeahead
          id="basic-typeahead-single"
          labelKey={this.props.labelKey}
          selected={this.props.selected}
          onChange={(selected) => {
            // console.log("renderTypeAhead", selected);
            if (this.props.onChange) {
              this.props.onChange(selected);
            }
          }}
          options={this.props.options ? this.props.options : []}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          disabled={this.props.disabled}
          clearButton
        />
        {this.renderError()}
      </React.Fragment>
    );
  }
  render() {
    return (
      <div className={this.props.noMargin ? "" : "input-ctrl"}>
        {this.props.type === "text" && this.renderText()}
        {this.props.type === "number" && this.renderNumber()}
        {this.props.type === "select" && this.renderSelect()}
        {this.props.type === "checkboxlist" && this.renderCheckBoxList()}
        {this.props.type === "radiolist" && this.renderRadioList()}
        {this.props.type === "textarea" && this.renderTextArea()}
        {this.props.type === "mobileno" && this.renderMobileNo()}
        {this.props.type === "emailid" && this.renderEmailID()}
        {this.props.type === "loginid" && this.renderLoginID()}
        {this.props.type === "password" && this.renderPassword()}
        {this.props.type === "search" && this.renderSearch()}
        {this.props.type === "date" && this.renderDate()}
        {this.props.type === "checkbox" && this.renderCheckbox()}
        {this.props.type === "switch" && this.renderSwitch()}
        {this.props.type === "productsearch" && this.renderProductSearch()}
        {this.props.type === "timepicker" && this.renderTimePicker()}
        {this.props.type === "dateentry" && this.renderDateEntry()}
        {this.props.type === "multiselect" && this.renderMultiSelect()}
        {this.props.type === "typeaheadselect" && this.renderTypeAheadSelect()}
        {this.props.type === "typeaheadselection" &&
          this.renderTypeAheadSelection()}
        {this.props.type === "typeaheadsearch" && this.renderTypeAheadSearch()}
        {this.props.type === "image" && this.renderImages()}
        {this.props.type === "otp" && this.renderOTP()}
        {this.props.type === "typeahead" && this.renderTypeAhead()}
      </div>
    );
  }
}

export default Input;
