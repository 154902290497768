import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import ReactHtmlParser from "react-html-parser";
import Input from "../2/Controls/Input";
import "./InvoiceDetail.css";
import firebase from "firebase";

class PincodeItemReport extends Component {
  constructor() {
    super();
    this.state = {
      invoices: [],
      stores: [],
      storeList: [],
      searchText: "",
      searchTransactionNo: "",
      searchTransactiontype: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      searchCustomerDetail: "",
      searchCashRegister: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      paginationStatus: "No records to display",
      exportCalled: false,
      loading: true,
    };
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.loadTransactionData = this.loadTransactionData.bind(this);
  }

  componentDidMount() {
    // this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });

    this.renderData(
      storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  async renderData(
    storeList,
    from,
    size,
    searchTransactionFromDate,
    searchTransactionToDate
  ) {
    let invoices = await this.loadTransactionData(
      storeList,
      from,
      size,
      searchTransactionFromDate,
      searchTransactionToDate
    );

    var col = [];
    for (var i = 0; i < invoices.length; i++) {
      for (var key in invoices[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }
    // console.log("col", col);
    var result = "<table class='table table-bordered'><thead><tr>";
    for (var i = 0; i < col.length; i++) {
      result += "<th>";
      result += col[i];
      result += "</th>";
    }
    result += "</tr></thead>";

    // ADD JSON DATA TO THE TABLE AS ROWS.
    result += "<tbody>";
    for (var i = 0; i < invoices.length; i++) {
      result += "<tr>";
      for (var j = 0; j < col.length; j++) {
        result += "<td>";
        result += invoices[i][col[j]] === undefined ? 0 : invoices[i][col[j]];
        result += "</td>";
      }
      result += "</tr>";
    }
    result += "</tbody>";
    result += "</table>";
    this.setState({ invoices: invoices, finalTable: result, loading: false });
  }

  async loadTransactionData(
    storeList,
    from,
    size,
    searchTransactionFromDate,
    searchTransactionToDate,
    exportData = false
  ) {
    this.setState({ loading: true });
    console.log("exportData", exportData);
    if (exportData) {
      size = 5000;
    }

    let totalItemsCount = 0;

    console.log(
      "PincodeItemReport searchTransactionFromDate",
      searchTransactionFromDate
    );
    console.log(
      "PincodeItemReport searchTransactionToDate",
      searchTransactionToDate
    );

    const fromDateStr = searchTransactionFromDate + "T00:00:00";
    // Create a Date object
    const fromDate = new Date(fromDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisFrom = fromDate.getTime();
    console.log(epochMillisFrom);

    // Define the date-time string
    const toDateStr = searchTransactionToDate + "T23:59:59";
    // Create a Date object
    const toDate = new Date(toDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisTo = toDate.getTime();
    console.log(epochMillisTo);

    let searchQuery = {
      track_total_hits: true,
      _source: [
        "Store.StoreName",
        "Store.StoreCode",
        "Store.key",
        "TransactionNo",
        "TransactionDate",
        "BillQty",
        "BillTaxAmount",
        "BillSubTotal",
        "BillDiscount",
        "BillAmount",
        "BillPaidAmount",
        "ReceivedPaymentAmount",
        "BalanceAmount",
        "SalesReturnTransactionNo",
        "CancellationReason",
        "BillChangeDue",
        "Payments",
        "DeliveryStatus",
        "ExternalTransactionNo",
        "CancellationBy",
        "DeliveryType",
        "DeliveryPersonName",
        "PincodeMerchantInvoiceAmount",
        "LineItems.Product.SKU",
        "LineItems.Product.Name",
        "LineItems.Product.PincodePackingQty",
        "LineItems.Product.PincodePackingUOM",
        // "LineItems.Product.BarCode",
        "LineItems.Qty",
        "LineItems.Product.key",
        "LineItems.RetailPrice",
        "LineItems.Amount",
        "ActionOn",
        "OrderLineItems",
      ],
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              term: {
                RegistrationID: this.props.registration.registration.key,
              },
            },
            {
              range: {
                TransactionDate: {
                  // lte: searchTransactionToDate,
                  // gte: searchTransactionFromDate,
                  // format: "yyyy-MM-dd",
                  lte: epochMillisTo,
                  gte: epochMillisFrom,
                },
              },
            },
          ],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              must: [],
              should: [],
            },
          },
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "desc",
          },
        },
      ],
    };
    for (let i = 0; i < storeList.length; i++) {
      const store = storeList[i];
      searchQuery.query.bool.should.push({
        term: {
          "Store.key.keyword": store.key,
        },
      });
    }
    // console.log("searchQuery", JSON.stringify(searchQuery));
    let invoices = [];
    let addMessage = firebase.functions().httpsCallable("scrollData");
    let sanitizedMessage = await addMessage({
      text: searchQuery,
      collection: "sales1",
      scrollID: "",
    }).then(function(result) {
      return result.data.text;
    });
    // console.log("sanitizedMessage", sanitizedMessage);
    totalItemsCount = sanitizedMessage.hits.total.value;

    sanitizedMessage.hits.hits.map((record, index) => {
      let data = record._source;

      for (let i = 0; i < data.LineItems.length; i++) {
        let lineItem = data.LineItems[i];
        let invoice = {
          "Store Name": data.Store.StoreName,
          "Store ID": data.Store.key,
          "Order ID": data.ExternalTransactionNo,
          "Order Date and Time": new Date(data.TransactionDate)
            .toString()
            .substring(0, 24),
          // Barcode: lineItem.Product.SKU,
          // "SKU Name": lineItem.Product.Name,
          // "Unit (Package Qty)": lineItem.Product.hasOwnProperty(
          //   "PincodePackingQty"
          // )
          //   ? lineItem.Product.PincodePackingQty
          //   : "",
          // UOM: lineItem.Product.hasOwnProperty("PincodePackingUOM")
          //   ? lineItem.Product.PincodePackingUOM
          //   : "",
          // "Item Selling Price": lineItem.RetailPrice,
          // "Qty Delivered": lineItem.Qty,
          // "Order Value Delivered": lineItem.Amount,
          // "Order Delivery Status": data.DeliveryStatus,
          // "Delivered Date and Time": "",
        };
        if (this.props.registration.registration.EnablePincodeSKU) {
          invoice["SKU"] = lineItem.Product.SKU;
          // invoice["Barcode"] = lineItem.Product.BarCode;
        } else {
          // invoice["Barcode"] = lineItem.Product.BarCode;
        }

        invoice["SKU Name"] = lineItem.Product.Name;
        invoice["Unit (Package Qty)"] = lineItem.Product.hasOwnProperty(
          "PincodePackingQty"
        )
          ? lineItem.Product.PincodePackingQty
          : "";
        invoice["UOM"] = lineItem.Product.hasOwnProperty("PincodePackingUOM")
          ? lineItem.Product.PincodePackingUOM
          : "";
        invoice["Item Selling Price"] = lineItem.RetailPrice;
        invoice["Qty Delivered"] = lineItem.Qty;
        invoice["Order Value Delivered"] = lineItem.Amount;
        invoice["Order Delivery Status"] = data.DeliveryStatus;
        invoice["Delivered Date and Time"] = "";
        if (
          data.DeliveryStatus === "Order-delivered" ||
          data.DeliveryStatus === "Order-picked-up"
        ) {
          invoice["Delivered Date and Time"] = new Date(data.ActionOn)
            .toString()
            .substring(0, 24);
        }
        invoices.push(invoice);
      }
      if (data.hasOwnProperty("OrderLineItems")) {
        console.log(JSON.stringify(data));
        for (let i = 0; i < data.OrderLineItems.length; i++) {
          if (
            data.LineItems.filter(
              (f) => data.OrderLineItems[i].ProductID == f.Product.key
            ).length === 0
          ) {
            let lineItem = data.OrderLineItems[i];
            let invoice = {
              "Store Name": data.Store.StoreName,
              "Store ID": data.Store.key,
              "Order ID": data.ExternalTransactionNo,
              "Order Date and Time": new Date(data.TransactionDate)
                .toString()
                .substring(0, 24),
            };
            if (this.props.registration.registration.EnablePincodeSKU) {
              invoice["SKU"] = lineItem.ProductSKU;
              invoice["Barcode"] = lineItem.ProductBarCode;
            } else {
              invoice["Barcode"] = lineItem.ProductBarCode;
            }

            invoice["SKU Name"] = lineItem.ProductName;
            invoice["Unit (Package Qty)"] = "";
            invoice["UOM"] = "";
            invoice["Item Selling Price"] = lineItem.RetailPrice;
            invoice["Qty Delivered"] = lineItem.Qty;
            invoice["Order Value Delivered"] = 0;
            invoice["Order Delivery Status"] = "Cancelled";
            invoice["Delivered Date and Time"] = "";
            invoices.push(invoice);
          }
        }
      }
    });

    if (exportData) {
      let total = sanitizedMessage.hits.total.value;
      // console.log("total,", total);
      let scrollID = sanitizedMessage._scroll_id;
      // console.log("scrollID", scrollID);
      for (let index = 1; index < total / size; index++) {
        let addMessage = firebase.functions().httpsCallable("scrollData");
        let loopResult = await addMessage({
          text: { scroll: "1m", scroll_id: scrollID },
        });
        let loopDatas = loopResult.data.text.hits.hits;
        loopDatas.map((record, index) => {
          let data = record._source;
          for (let i = 0; i < data.LineItems.length; i++) {
            let lineItem = data.LineItems[i];
            let invoice = {
              "Store Name": data.Store.StoreName,
              "Store ID": data.Store.key,
              "Order ID": data.ExternalTransactionNo,
              "Order Date and Time": new Date(data.TransactionDate)
                .toString()
                .substring(0, 24),
            };
            if (this.props.registration.registration.EnablePincodeSKU) {
              invoice["SKU"] = lineItem.Product.SKU;
              // invoice["Barcode"] = lineItem.Product.Barcode;
            } else {
              // invoice["Barcode"] = lineItem.Product.Barcode;
            }
            invoice["SKU Name"] = lineItem.Product.Name;
            invoice["Unit (Package Qty)"] = lineItem.Product.hasOwnProperty(
              "PincodePackingQty"
            )
              ? lineItem.Product.PincodePackingQty
              : "";
            invoice["UOM"] = lineItem.Product.hasOwnProperty(
              "PincodePackingUOM"
            )
              ? lineItem.Product.PincodePackingUOM
              : "";
            invoice["Item Selling Price"] = lineItem.RetailPrice;
            invoice["Qty Delivered"] = lineItem.Qty;
            invoice["Order Value Delivered"] = lineItem.Amount;
            invoice["Order Delivery Status"] = data.DeliveryStatus;
            invoice["Delivered Date and Time"] = "";
            if (
              data.DeliveryStatus === "Order-delivered" ||
              data.DeliveryStatus === "Order-picked-up"
            ) {
              invoice["Delivered Date and Time"] = new Date(data.ActionOn)
                .toString()
                .substring(0, 24);
            }
            invoices.push(invoice);
          }
        });
      }
    }

    // let result = await this.props.getBillWiseDetailReport(
    //   this.props.user.user.RegistrationID,
    //   storeList,
    //   from,
    //   size,
    //   "",
    //   "",
    //   searchTransactionFromDate,
    //   searchTransactionToDate,
    //   "",
    //   "",
    //   true
    // );

    // for (let i = 0; i < result.searchResult.length; i++) {
    //   // console.log("data", data);
    //   const data = result.searchResult[i];
    //   for (let j = 0; j < data.LineItems.length; j++) {
    //     let lineItem = data.LineItems[j];
    //     let invoice = {
    //       "Store Name": data.Store.StoreName,
    //       "Store ID": data.Store.key,
    //       "Order ID": data.ExternalTransactionNo,
    //       "Order Date and Time": new Date(data.TransactionDate)
    //         .toString()
    //         .substring(0, 24),
    //       Barcode: lineItem.Product.SKU,
    //       "SKU Name": lineItem.Product.Name,
    //       "Unit (Package Qty)": lineItem.Product.hasOwnProperty(
    //         "PincodePackingQty"
    //       )
    //         ? lineItem.Product.PincodePackingQty
    //         : "",
    //       UOM: lineItem.Product.hasOwnProperty("PincodePackingUOM")
    //         ? lineItem.Product.PincodePackingUOM
    //         : "",
    //       //   "Qty Ordered": lineItem.Qty,
    //       // "Qty Cancelled"
    //       "Item Selling Price": lineItem.RetailPrice,
    //       "Qty Delivered": lineItem.Qty,
    //       "Order Value Delivered": lineItem.Amount,
    //       // "Order Value Cancelled"
    //       "Order Delivery Status": data.DeliveryStatus,
    //     };
    //     invoices.push(invoice);
    //   }
    // }
    console.log("invoices", invoices);
    this.setState({
      totalItemsCount: totalItemsCount,
      loading: false,
    });
    return invoices;
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      storeList: storeList,
      storeID: this.props.user.store.key,
      recordFrom: 0,
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
    });

    this.renderData(
      storeList,
      0,
      this.state.itemsCountPerPage,
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0]
    );
  }

  async onExportClick() {
    let invoices = await this.loadTransactionData(
      this.state.storeList,
      0,
      10000,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      true
    );

    const csvData = constatnts.objToCSV(invoices);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "ItemReport.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({ searchFrom: 0, loading: true });
    this.renderData(
      this.state.storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  onStoreChange(value) {
    let storeList = [];
    //console.log("key", e.target.value);
    if (value != "") {
      storeList.push({ key: value });
    } else {
      for (let i = 0; i < this.state.stores.length; i++) {
        const store = this.state.stores[i];
        storeList.push({ key: store.key });
      }
    }
    //console.log("storeList", JSON.stringify(storeList));
    this.setState({ storeList: storeList, storeID: value });
  }

  handlePageChange(pageNumber) {
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.renderData(
      this.state.storeList,
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    // this.setState({ paginationStatus: paginationStatus });
    return paginationStatus;
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>Item Report</font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <Input
                        type="select"
                        label="Store"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        <option value="">All Stores</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </Input>
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="date"
                        label="Transaction From"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionFromDate: value,
                          })
                        }
                        value={this.state.searchTransactionFromDate}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="date"
                        label="Transaction To"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionToDate: value,
                          })
                        }
                        value={this.state.searchTransactionToDate}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      {ReactHtmlParser(this.state.finalTable)}
                    </div>
                  )}
                  {this.state.totalItemsCount > 0 && (
                    <div className="col-md-12">
                      <div className="pull-left" style={{ marginTop: "25px" }}>
                        {/* {this.state.paginationStatus} */}
                        {/* {this.showPaginationStatus()} */}
                      </div>
                      <div className="pull-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={2}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PincodeItemReport);
