import React, { Component } from "react";
import "./ReportBody.css";

class ReportBody extends Component {
  render() {
    return <div className="report-body">{this.props.children} </div>;
  }
}

export default ReportBody;
