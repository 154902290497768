import React, { Component } from "react";
import * as actionTypes from "../../../actionTypes";
import CreatableSelect from "react-select";
import { auth } from "../../../firebase";

import "../../Common/Common.css";
import Form from "../../2/Controls/Form";
import Input from "../../2/Controls/Input";
import Spinner from "../../Common/Spinner";
import Button from "../../2/Controls/Button";
import axios from "axios";

class EmployeeAccess extends Component {
  constructor() {
    super();
    this.state = {
      employee: {},
      employeeLogin: "",
      associatedStores: [],
      employeePassword: "",
      employeeConfirmPassword: "",
      employeeRole: "",
      employeeRoleKey: "",
      employeeAllStores: false,
      employeeSpecificStore: false,
      employeeLoginError: false,
      employeePasswordError: false,
      employeeConfirmPasswordError: false,
      employeeRoleError: false,
      employeeOptionError: false,
      employeeStoreError: false,
      action: "",
      key: "",
      stores: [],
      roles: [],
      saving: false,
      selectedStores: [],
      accessibleToSpecificIP: false,
      ipAddress: "",
      ipAddressError: "",
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onSaveEmployeeAccess = this.onSaveEmployeeAccess.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    let roles = [];
    let stores = [];
    let employeeLogin = "";
    let uid = "";
    let user = null;
    let employeeCode = "";
    let employeeName = "";
    let employeeRoleKey = "";
    let employeeRole = "";
    let selectedStores = [];
    let accessibleToSpecificIP = false;
    let ipAddress = "";
    // let selectedStores =[];
    roles = await this.props.getCustomRoles(
      this.props.user.user.RegistrationID
    );
    // this.setState({ roles: roles });
    let result = await this.props.getStores(
      this.props.user.user.RegistrationID,
      0,
      10000,
      "active",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    // console.log('result',result)
    // .then((result) => {
    let userStores = await this.props.getUserStores(
      this.props.user.user.RegistrationID,
      this.props.user.auth.uid
    );
    // .then((userStores) => {
    //  console.log("userStores", userStores);

    for (let index = 0; index < result.searchResult.length; index++) {
      const store = result.searchResult[index];
      for (let i = 0; i < userStores.length; i++) {
        if (userStores[i].key === store.key) {
          let obj = {
            value: store.key,
            label: store.StoreName,
          };
          stores.push(obj);
        }
      }
    }
    // })
    // });
    if (this.props.employee) {
      let employee = this.props.employee;
      employeeLogin = this.props.employee.EmailID;
      employeeCode = this.props.employee.EmployeeCode;
      employeeName =
        this.props.employee.EmployeeFirstName +
        " " +
        this.props.employee.EmployeeLastName;
      if (employee.hasOwnProperty("UID") && employee.UID !== "") {
        user = await this.props.getUserDetails(employee.UID);
        if (user) {
          uid = employee.UID;
          employeeLogin = user.EmailID;
          employeeRoleKey = user.Role;
          employeeRole = user.Role;
          accessibleToSpecificIP = user.hasOwnProperty("AccessibleToSpecificIP")
            ? user.AccessibleToSpecificIP
            : false;
          ipAddress = user.hasOwnProperty("IPAddress") ? user.IPAddress : "";
          user.Stores.map((s) => {
            selectedStores.push({
              value: s.StoreKey,
              label: s.StoreName,
            });
          });
        }
      }
    }
    // console.log('employee', this.props.employee)
    // console.log('stores',stores)
    // console.log('roles', roles)
    // console.log('selectedStores',selectedStores)
    // console.log('user', user)
    this.setState({
      stores: stores,
      roles: roles,
      employeeLogin: employeeLogin,
      employeeCode: employeeCode,
      employeeName: employeeName,
      employeeRoleKey: employeeRoleKey,
      employeeRole: employeeRole,
      selectedStores: selectedStores,
      user: user,
      uid: uid,
      accessibleToSpecificIP: accessibleToSpecificIP,
      ipAddress: ipAddress,
      loading: false,
    });
  }

  async onSaveEmployeeAccess() {
    this.setState({ saving: true });
    let employeeLoginError = "";
    let employeePasswordError = "";
    let employeeConfirmPasswordError = "";
    let employeeRoleError = "";
    let employeeStoreError = "";
    let ipAddressError = "";

    if (this.state.uid === "" && this.state.employeeLogin.trim() === "") {
      employeeLoginError = "Please provide login id";
    }
    if (this.state.uid === "" && this.state.employeePassword.trim() === "") {
      employeePasswordError = "Please provide password";
    }
    if (
      this.state.uid === "" &&
      this.state.employeeConfirmPassword.trim() === ""
    ) {
      employeeConfirmPasswordError = "Please provide confirm password";
    } else {
      if (
        this.state.uid === "" &&
        this.state.employeeConfirmPassword.trim() !==
          this.state.employeePassword.trim()
      ) {
        employeeConfirmPasswordError =
          "Password and confirm password do not match";
      }
    }

    if (this.state.employeeRoleKey.trim() === "") {
      employeeRoleError = "Please select role";
    }

    if (this.state.employeeRole.trim() !== "Power User") {
      if (this.state.associatedStores.length === 0) {
        employeeStoreError = "Please select store(s)";
      }
    }

    if (
      this.state.accessibleToSpecificIP &&
      this.state.ipAddress.trim() === ""
    ) {
      ipAddressError = "Please specify IP address";
    }

    // console.log('employeeLoginError',employeeLoginError);
    // console.log('employeePasswordError',employeePasswordError);
    // console.log('employeeConfirmPasswordError',employeeConfirmPasswordError)
    // console.log('employeeRoleError',employeeRoleError)
    // console.log('employeeStoreError',employeeStoreError);
    if (
      employeeLoginError !== "" ||
      employeePasswordError !== "" ||
      employeeConfirmPasswordError !== "" ||
      employeeRoleError !== "" ||
      employeeStoreError !== "" ||
      ipAddressError !== ""
    ) {
      this.setState({
        employeeLoginError: employeeLoginError,
        employeePasswordError: employeePasswordError,
        employeeConfirmPasswordError: employeeConfirmPasswordError,
        employeeRoleError: employeeRoleError,
        employeeStoreError: employeeStoreError,
        ipAddressError: ipAddressError,
        saving: false,
      });
      return;
    }

    let employeeRoleKey = "";
    if (
      this.state.employeeRoleKey !== "Power User" &&
      this.state.employeeRoleKey !== "Store Owner" &&
      this.state.employeeRoleKey !== "Store Manager" &&
      this.state.employeeRoleKey !== "Store Cashier"
    ) {
      employeeRoleKey = this.state.employeeRoleKey;
    }

    let stores = [];
    if (employeeRoleKey !== "Power User") {
      stores = this.state.associatedStores;
    }

    try {
      if (this.state.uid !== "") {
        let user = this.state.user;
        user.Role = this.state.employeeRole;
        user.RoleKey = employeeRoleKey;
        user.Stores = stores;
        user.AccessibleToSpecificIP = this.state.accessibleToSpecificIP;
        user.IPAddress = this.state.ipAddress;
        if (
          this.props.registration.registration.hasOwnProperty("PlanID") &&
          this.props.registration.registration.PlanID !== ""
        )
          user.PlanID = this.props.registration.registration.PlanID;

        this.props.updateUser(user).then(() => {
          this.props.getToastr("Access granted successfully!");
          this.setState({ saving: false });
          this.props.onSave();
        });
      } else {
        let result = await auth.createUserWithEmailAndPassword(
          this.state.employeeLogin,
          this.state.employeePassword
        );
        // console.log('result',result)
        const uid = result.user.uid;
        const employee = this.props.employee;
        const user = {};
        user.EmailID = this.state.employeeLogin;
        user.Name =
          employee.EmployeeFirstName.trim() +
          " " +
          employee.EmployeeLastName.trim();
        user.Name = user.Name.trim();
        user.RegistrationID = employee.RegistrationID;
        user.UID = uid;
        user.RoleKey = employeeRoleKey;
        user.Role = this.state.employeeRole;
        user.Stores = stores;
        user.AccessibleToSpecificIP = this.state.accessibleToSpecificIP;
        user.IPAddress = this.state.ipAddress;
        if (
          this.props.registration.registration.hasOwnProperty("PlanID") &&
          this.props.registration.registration.PlanID !== ""
        )
          user.PlanID = this.props.registration.registration.PlanID;
        // console.log('user',user)
        const idUser = await this.props.createUser(user);
        employee.UID = uid;
        // console.log('employee',employee)
        await this.props.updateEmployee(employee, employee.key);
        const response = await axios.post(
          "https://backend-o3dyulj4pa-el.a.run.app/v1/user/adduser",
          {
            userId: idUser.data,
            password: this.state.employeePassword,
          }
        );
        this.props.getToastr("Access granted successfully!");
        this.props.onSave();
        this.setState({ saving: false });
      }
    } catch (error) {
      // console.log(error)
      this.props.getToastr("Error:" + error);
      this.setState({ saving: false });
    }
    // this.props.onClose();
  }

  handleOnChange(storeList) {
    // console.log(JSON.stringify(storeList));
    let objList = [];
    this.setState.associatedStores = [];
    storeList.map((store) => {
      let obj = {
        StoreKey: store.value,
        StoreName: store.label,
      };
      objList.push(obj);
    });
    this.setState({ associatedStores: objList, selectedStores: storeList });
    // console.log("Associated Stores", JSON.stringify(this.state.associatedStores));
  }

  render() {
    return (
      <Form
        title="Assign Acccess"
        onClick={(action) => {
          if (action === "save") {
            this.onSaveEmployeeAccess();
          } else {
            this.props.onClose();
          }
        }}
        processing={this.state.saving}
      >
        {this.state.loading && <Spinner hideOverlay={true} />}
        {!this.state.loading && (
          <React.Fragment>
            <Input
              label="Employee Code"
              type="text"
              value={this.state.employeeCode}
              disabled={true}
            />
            <Input
              label="Employee Name"
              type="text"
              value={this.state.employeeName}
              disabled={true}
            />
            <Input
              label="Login Id"
              type="text"
              onChange={(value) =>
                this.setState({ employeeLogin: value, employeeLoginError: "" })
              }
              value={this.state.employeeLogin}
              error={this.state.employeeLoginError}
              disabled={this.state.uid !== "" ? true : false}
            />
            {this.state.uid === "" && (
              <Input
                type="password"
                label="Password"
                onChange={(value) =>
                  this.setState({
                    employeePassword: value,
                    employeePasswordError: "",
                  })
                }
                value={this.state.employeePassword}
                error={this.state.employeePasswordError}
              />
            )}
            {this.state.uid === "" && (
              <Input
                type="password"
                label="Confirm Password"
                onChange={(value) =>
                  this.setState({
                    employeeConfirmPassword: value,
                    employeeConfirmPasswordError: "",
                  })
                }
                value={this.state.employeeConfirmPassword}
                error={this.state.employeeConfirmPasswordError}
              />
            )}
            <Input
              type="select"
              label="Role"
              onChange={(value) => {
                let employeeRole = "";
                if (value !== "") {
                  if (
                    this.state.roles.filter((f) => f.key === value).length > 0
                  ) {
                    employeeRole = this.state.roles.filter(
                      (f) => f.key === value
                    )[0].Role;
                  } else {
                    employeeRole = value;
                  }
                }
                this.setState({
                  employeeRoleKey: value,
                  employeeRole: employeeRole,
                  employeeRoleError: "",
                });
              }}
              error={this.state.employeeRoleError}
              value={this.state.employeeRoleKey}
            >
              <option value="">Select Role</option>
              {this.props.user.user.Role === "Power User" && (
                <option value="Power User">Power User</option>
              )}
              <option value="Store Owner">Store Owner</option>
              <option value="Store Manager">Store Manager</option>
              <option value="Store Cashier">Store Cashier</option>
              {this.state.roles.map((role) => (
                <option key={role.key} value={role.key}>
                  {role.Role}
                </option>
              ))}
            </Input>
            {this.state.employeeRoleKey !== "Power User" &&
              this.state.employeeRoleKey !== "" && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Stores</label>
                      <CreatableSelect
                        options={this.state.stores}
                        value={this.state.selectedStores}
                        onChange={this.handleOnChange}
                        isMulti={true}
                      />
                      {this.state.employeeStoreError !== "" && (
                        <span className="help-block">
                          {this.state.employeeStoreError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            <Input
              type="switch"
              label="Allow system access from specific IP address"
              value={this.state.accessibleToSpecificIP}
              onChange={(value) =>
                this.setState({ accessibleToSpecificIP: value })
              }
            />
            {this.state.accessibleToSpecificIP && (
              <Input
                type="text"
                value={this.state.ipAddress}
                onChange={(value) => this.setState({ ipAddress: value })}
                error={this.state.ipAddressError}
              />
            )}
          </React.Fragment>
        )}
      </Form>
    );
  }
}
export default EmployeeAccess;
