import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../2/style.css";

class AllReportOptions extends Component {
  constructor() {
    super();
    this.state = {
      salesReports: [],
      inventoryreports: [],
      employeeReports: [],
      customerReports: [],
      vendorReports: [],
      expnseReports: [],
      loyaltyReports: [],
    };
  }

  loadPincodeReports() {
    let salesReports = [];
    salesReports.push({
      Report: "Order Report",
      Link: "/home/reports/sales/pincode/orderreport",
      State: { type: "report" },
    });
    salesReports.push({
      Report: "Item Report",
      Link: "/home/reports/sales/pincode/itemreport",
      State: { type: "report" },
    });
    if (
      this.props.registration.registration.key === "JbCXYlNPSt8XoqgX5nvm" ||
      this.props.registration.registration.key === "4kyrBQkxLZR5D0A3eKux" ||
      this.props.registration.registration.key === "KPLVQpkYE34hsM9zhtjk" ||
      this.props.registration.registration.key === "7hznWEWjUB6dPfYLCQyj" ||
      this.props.registration.registration.key === "trp6HCyvfWQxbUPXFsGM" ||
      this.props.registration.registration.key === "uHPANFUocT8XMDnjtEr4" ||
      this.props.registration.registration.key === "Ws4daVIcDrRVgsfK3fSf" ||
      this.props.registration.registration.key === "9Gz1FLaEQuelaitw4uXe" ||
      this.props.registration.registration.key === "MJBko82qp5N3AtAR73Ux" ||
      this.props.registration.registration.key === "7MR8EhP9hKVxU1teSLB4" ||
      this.props.registration.registration.key === "XNC8CcA4rdS0wyFaQfEz" ||
      this.props.registration.registration.key === "Njani9l6wA7EJYGmWGjR" ||
      this.props.registration.registration.key === "rtAxwvzVm3LkUtv0TPQm" ||
      this.props.registration.registration.key === "ToFNLPtQMmvdeoqFkkjF" ||
      this.props.registration.registration.key === "uHPANFUocT8XMDnjtEr4" ||
      this.props.registration.registration.key === "1RpFkiKniN4ulNGtFDYQ" ||
      this.props.registration.registration.key === "NoT4amt6ixBKILm4I8jF" ||
      this.props.registration.registration.key === "p39XGPeMb8RgDQGG8QgU" ||
      this.props.registration.registration.key === "m1FhORcls9dsPE0UtxNi" ||
      this.props.registration.registration.key === "jMOJSlPwHYiaPMWmsKhL" ||
      this.props.registration.registration.key === "wBOBvMXgyNuXEU0XDNfZ" ||
      this.props.registration.registration.key === "DkrUAD2Ym9ttneXCDfM0" ||
      this.props.registration.registration.key === "AP6nvkKK58aDPaPJy7w1"
    ) {
      salesReports.push({
        Report: "Customer Report",
        Link: "/home/reports/sales/pincode/customerreport",
        State: { type: "report" },
      });
    }

    this.setState({
      salesReports: salesReports,
    });
  }

  loadReportsPincodeBillingReports() {
    let salesReports = [];
    if (this.props.getVisibility("Daily Sales", "read") === "inline") {
      salesReports.push({
        Report: "Daily Sales",
        Link: "/home/reports/dailysales",
      });
    }
    // if (this.props.getVisibility("Monthly Sales", "read") === "inline") {
    //   salesReports.push({
    //     Report: "Monthly Sales",
    //     Link: "/home/reports/monthlysales",
    //   });
    // }
    // if (this.props.getVisibility("Top 50 Customers", "read") === "inline") {
    //   salesReports.push({
    //     Report: "Top N Customers",
    //     Link: "/home/reports/topcustomers",
    //   });
    // }
    // if (
    //   this.props.getVisibility("Payment Mode Wise Report", "read") === "inline"
    // ) {
    //   salesReports.push({
    //     Report: "Payment Mode Wise Report",
    //     Link: "/home/reports/paymentmodewise",
    //   });
    // }
    if (
      this.props.getVisibility(
        "Payment Mode Transaction Type Wise Report",
        "read"
      ) === "inline"
    ) {
      salesReports.push({
        Report: "Payment Mode Transaction Type Wise Report",
        Link: "/home/reports/paymentmodetransaction",
      });
    }
    if (this.props.getVisibility("Transaction Wise", "read") === "inline") {
      salesReports.push({
        Report: "Transaction Report",
        Link: "/home/reports/sales/transaction",
        State: { type: "report" },
      });
    }
    if (
      this.props.getVisibility("Invoice Detail Report", "read") === "inline"
    ) {
      salesReports.push({
        Report: "Transaction Detail Report",
        Link: "/home/reports/sales/transactiondetail",
      });
    }
    if (this.props.user.user.Role === "Power User") {
      if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
        salesReports.push({
          Report: "GSTR1 - Transaction Wise",
          Link: "/home/reports/gstr1transactionwise",
        });
      }
      if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
        salesReports.push({
          Report: "GSTR1 - Transaction Line Item Wise",
          Link: "/home/reports/gstr1",
        });
      }
      if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
        salesReports.push({
          Report: "GSTR1 - B2C",
          Link: "/home/reports/gstr1B2c",
        });
      }
      if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
        salesReports.push({
          Report: "GSTR1 - B2B",
          Link: "/home/reports/gstr1B2B",
        });
      }
      if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
        salesReports.push({
          Report: "HSN Wise Summary Report",
          Link: "/home/reports/hsnreport",
        });
      }
      if (
        this.props.getVisibility("Product Sold Report", "read") === "inline"
      ) {
        salesReports.push({
          Report: "Product Sold Report",
          Link: "/home/reports/itemsolddetail",
        });
      }
    }

    let inventoryreports = [];

    if (this.props.getVisibility("In Stock", "read") === "inline") {
      inventoryreports.push({
        Report: "In Stock",
        Link: "/home/reports/Instock",
      });
      inventoryreports.push({
        Report: "Not found in Global Catalog",
        Link: "/home/reports/notfoundingc",
      });

      inventoryreports.push({
        Report: "Reported Products",
        Link: "/home/reports/reportedProducts",
      });
    }
    // if (this.props.getVisibility("In Stock", "read") === "inline") {
    //   inventoryreports.push({
    //     Report: "Inventory History",
    //     Link: "/home/reports/inventoryhistory",
    //   });
    // }
    if (this.props.getVisibility("Min Qty Report", "read") === "inline") {
      inventoryreports.push({
        Report: "Min Qty Report",
        Link: "/home/reports/minqtyreport",
      });
    }

    let atleastOneInventoryTransactionAccessible = false;
    this.props.config.TransactionTypes.filter(
      (e) => e.Module === "inventory" && !e.IsDeleted
    ).map((e, index) => {
      if (this.props.getVisibility(e.TransactionType, "read")) {
        atleastOneInventoryTransactionAccessible = true;
        if (
          e.hasOwnProperty("TransactionMovement") &&
          e.TransactionMovement === "storetostore" &&
          e.TransactionType !== "Transfer Out"
        ) {
          inventoryreports.push({
            Report: e.TransactionType + " - Requested",
            Link: "/home/report/inventoryreports",
            State: { transactionType: e, type: "Requested" },
          });
          inventoryreports.push({
            Report: e.TransactionType + " - Received",
            Link: "/home/report/inventoryreports",
            State: { transactionType: e, type: "Received" },
          });
        } else {
          inventoryreports.push({
            Report: e.TransactionType,
            Link: "/home/report/inventoryreports",
            State: { transactionType: e },
          });
        }
      }
    });
    if (this.props.user.user.Role === "Power User") {
      if (this.props.getVisibility("GSTR2", "read") === "inline") {
        inventoryreports.push({
          Report: "GSTR 2 Report",
          Link: "/home/reports/gstr2",
        });
      }
    }

    // if (atleastOneInventoryTransactionAccessible) {
    //   inventoryreports.push({
    //     Report: "Batch Report",
    //     Link: "/home/reports/batchexpiryreport",
    //   });
    //   inventoryreports.push({
    //     Report: "Inventory Sales, Purchase & InStock Qty",
    //     Link: "/home/reports/inventorysalespurchaseinstock",
    //   });
    // }
    let employeeReports = [];
    let customerReports = [];
    let vendorReports = [];
    let expnseReports = [];
    let loyaltyReports = [];
    // if (this.props.getVisibility("Employee Insight", "read") === "inline") {
    //   employeeReports.push({
    //     Report: "Employee Insight",
    //     Link: "/home/reports/employeedashboard",
    //   });
    // }
    if (this.props.getVisibility("Attendance", "read") === "inline") {
      employeeReports.push({
        Report: "Attendance Report",
        Link: "/home/reports/attendancereport",
      });
    }
    // employeeReports.push({
    //   Report: "Employee Incentive",
    //   Link: "/home/reports/employeeincentive",
    // });

    // if (this.props.getVisibility("Membership Dashboard", "read") === "inline") {
    //   customerReports.push({
    //     Report: "Membership Dashboard",
    //     Link: "/home/reports/membershipdashboard",
    //   });
    // }
    // if (
    //   this.props.getVisibility("Customer Reference Report", "read") === "inline"
    // ) {
    //   customerReports.push({
    //     Report: "Customer Reference Report",
    //     Link: "/home/reports/customereferencedetail",
    //   });
    // }
    // if (
    //   this.props.getVisibility("Customer Wallet Ledger Report", "read") ===
    //   "inline"
    // ) {
    //   customerReports.push({
    //     Report: "Customer Wallet Ledger Report",
    //     Link: "/home/reports/customerwalletledger",
    //   });
    // }
    if (
      this.props.getVisibility("Customer OutStanding Ledger Report", "read") ===
      "inline"
    ) {
      customerReports.push({
        Report: "Customer Outstanding Ledger",
        Link: "/home/reports/customeroutstandingledger",
      });
    }
    // if (this.props.getVisibility("Membership Expiry", "read") === "inline") {
    //   customerReports.push({
    //     Report: "Membership Expiry",
    //     Link: "/home/reports/membershipexpiry",
    //   });
    // }

    vendorReports.push({
      Report: "Vendor Ledger",
      Link: "/home/reports/vendorledgerreport",
    });
    vendorReports.push({
      Report: "Vendor Due Amount",
      Link: "/home/reports/vendoroutstanding",
    });

    if (this.props.getVisibility("Expense List", "read") === "inline") {
      expnseReports.push({
        Report: "Expense List",
        Link: "/home/reports/expenseListreport",
      });
    }
    // if (
    //   this.props.getVisibility("Expense By Expense Category", "read") ===
    //   "inline"
    // ) {
    //   expnseReports.push({
    //     Report: "Expense By Expense Category",
    //     Link: "/home/reports/expenseByExpenseCategoryreport",
    //   });
    // }
    // if (
    //   this.props.getVisibility("Expense By Expense Category", "read") ===
    //   "inline"
    // ) {
    //   expnseReports.push({
    //     Report: "Expense By Party",
    //     Link: "/home/reports/expenseByPartyreport",
    //   });
    // }

    // if (
    //   this.props.registration.registration.hasOwnProperty("LoyaltyEnabled") &&
    //   this.props.registration.registration.LoyaltyEnabled
    // ) {
    //   if (this.props.getVisibility("LoyaltyDashboard", "read") === "inline") {
    //     loyaltyReports.push({
    //       Report: "Loyalty Dashboard",
    //       Link: "/home/reports/loyaltydashboard",
    //     });
    //   }
    //   if (this.props.getVisibility("LoyaltyCustomer", "read") === "inline") {
    //     loyaltyReports.push({
    //       Report: "Loyalty by Customer",
    //       Link: "/home/reports/loyaltycustomer",
    //     });
    //   }
    //   if (this.props.getVisibility("LoyaltyLedger", "read") === "inline") {
    //     loyaltyReports.push({
    //       Report: "Loyalty Ledger",
    //       Link: "/home/reports/loyaltyledger",
    //     });
    //   }
    // }

    this.setState({
      salesReports: salesReports,
      inventoryreports: inventoryreports,
      employeeReports: employeeReports,
      customerReports: customerReports,
      vendorReports: vendorReports,
      expnseReports: expnseReports,
      loyaltyReports: loyaltyReports,
    });
  }

  loadReports() {
    let salesReports = [];
    if (this.props.getVisibility("Daily Sales", "read") === "inline") {
      salesReports.push({
        Report: "Daily Sales",
        Link: "/home/reports/dailysales",
      });
    }
    if (this.props.getVisibility("Monthly Sales", "read") === "inline") {
      salesReports.push({
        Report: "Monthly Sales",
        Link: "/home/reports/monthlysales",
      });
    }
    if (this.props.getVisibility("Top 50 Customers", "read") === "inline") {
      salesReports.push({
        Report: "Top N Customers",
        Link: "/home/reports/topcustomers",
      });
    }
    if (
      this.props.getVisibility("Payment Mode Wise Report", "read") === "inline"
    ) {
      salesReports.push({
        Report: "Payment Mode Wise Report",
        Link: "/home/reports/paymentmodewise",
      });
    }
    if (
      this.props.getVisibility(
        "Payment Mode Transaction Type Wise Report",
        "read"
      ) === "inline"
    ) {
      salesReports.push({
        Report: "Payment Mode Transaction Type Wise Report",
        Link: "/home/reports/paymentmodetransaction",
      });
    }
    if (this.props.getVisibility("Transaction Wise", "read") === "inline") {
      salesReports.push({
        Report: "Transaction Report",
        Link: "/home/reports/sales/transaction",
        State: { type: "report" },
      });
    }
    // if (this.props.registration.registration.key === "gq9nD80i4HXXHKokX7cP") {

    if (
      this.props.getVisibility("Invoice Detail Report", "read") === "inline"
    ) {
      salesReports.push({
        Report: "Transaction Detail Report",
        Link: "/home/reports/sales/transactiondetail",
      });
    }
    // if(this.props.getVisibility("Invoice Detail Report", "read") === "inline" ){
    //     salesReports.push({Report:"Invoice Detail (With catalogue images)", Link:"/home/reports/invoicedetail2"})
    // }
    if (this.props.getVisibility("ProfitLossReport", "read") === "inline") {
      salesReports.push({
        Report: "Profit & Loss",
        Link: "/home/reports/profitlossreport",
      });
    }
    if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
      salesReports.push({
        Report: "GSTR1 - Transaction Wise",
        Link: "/home/reports/gstr1transactionwise",
      });
    }
    if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
      salesReports.push({
        Report: "GSTR1 - Transaction Line Item Wise",
        Link: "/home/reports/gstr1",
      });
    }
    if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
      salesReports.push({
        Report: "GSTR1 - B2C",
        Link: "/home/reports/gstr1B2c",
      });
    }
    if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
      salesReports.push({
        Report: "GSTR1 - B2B",
        Link: "/home/reports/gstr1B2B",
      });
    }
    if (this.props.getVisibility("GSTR 1 Report", "read") === "inline") {
      salesReports.push({
        Report: "HSN Wise Summary Report",
        Link: "/home/reports/hsnreport",
      });
    }
    if (this.props.getVisibility("Employee By Product", "read") === "inline") {
      salesReports.push({
        Report: "Employee By Product",
        Link: "/home/reports/employeeitemdetail",
      });
    }
    if (this.props.getVisibility("Product Sold Report", "read") === "inline") {
      salesReports.push({
        Report: "Product Sold Report",
        Link: "/home/reports/itemsolddetail",
      });
    }
    if (
      this.props.getVisibility("Product Type Wise Sold Report", "read") ===
      "inline"
    ) {
      salesReports.push({
        Report: "Product Type Wise Sold Report",
        Link: "/home/reports/producttypewise",
      });
    }
    if (
      this.props.getVisibility("Category Wise Sold Report", "read") === "inline"
    ) {
      salesReports.push({
        Report: "Category Wise Transaction Report",
        Link: "/home/reports/categorywisetransaction",
      });
    }
    if (
      this.props.getVisibility("Category Wise Sold Report", "read") === "inline"
    ) {
      salesReports.push({
        Report: "Category Wise Sold Report",
        Link: "/home/reports/categorywise",
      });
    }
    if (
      this.props.getVisibility("Sub Category Wise Sold Report", "read") ===
      "inline"
    ) {
      salesReports.push({
        Report: "Sub Category Wise Sold Report",
        Link: "/home/reports/subcategorywise",
      });
    }
    if (
      this.props.getVisibility("Brand Wise Sold Report", "read") === "inline"
    ) {
      salesReports.push({
        Report: "Brand Wise Sold Report",
        Link: "/home/reports/brandwise",
      });
    }
    if (this.props.getVisibility("OfferSummaryReport", "read") === "inline") {
      salesReports.push({
        Report: "Offer Report",
        Link: "/home/reports/offersummary",
      });
    }
    if (this.props.getVisibility("CouponSummaryReport", "read") === "inline") {
      salesReports.push({
        Report: "Coupon Report",
        Link: "/home/reports/couponsummary",
      });
    }

    let inventoryreports = [];

    if (this.props.getVisibility("In Stock", "read") === "inline") {
      inventoryreports.push({
        Report: "In Stock",
        Link: "/home/reports/Instock",
      });
    }
    if (this.props.getVisibility("Unique No", "read") === "inline") {
      inventoryreports.push({
        Report: "Unique No",
        Link: "/home/reports/uniqueno",
      });
    }
    if (this.props.getVisibility("In Stock", "read") === "inline") {
      inventoryreports.push({
        Report: "Inventory History",
        Link: "/home/reports/inventoryhistory",
      });
    }
    if (this.props.getVisibility("Product Order Report", "read") === "inline") {
      inventoryreports.push({
        Report: "Product Order Report",
        Link: "/home/reports/productorderreport",
      });
    }
    if (this.props.getVisibility("Min Qty Report", "read") === "inline") {
      inventoryreports.push({
        Report: "Min Qty Report",
        Link: "/home/reports/minqtyreport",
      });
    }
    if (this.props.getVisibility("Max Qty Report", "read") === "inline") {
      inventoryreports.push({
        Report: "Max Qty Report",
        Link: "/home/reports/maxqtyreport",
      });
    }

    let atleastOneInventoryTransactionAccessible = false;
    this.props.config.TransactionTypes.filter(
      (e) => e.Module === "inventory" && !e.IsDeleted
    ).map((e, index) => {
      if (this.props.getVisibility(e.TransactionType, "read")) {
        atleastOneInventoryTransactionAccessible = true;
        if (
          e.hasOwnProperty("TransactionMovement") &&
          e.TransactionMovement === "storetostore" &&
          e.TransactionType !== "Transfer Out"
        ) {
          inventoryreports.push({
            Report: e.TransactionType + " - Requested",
            Link: "/home/report/inventoryreports",
            State: { transactionType: e, type: "Requested" },
          });
          inventoryreports.push({
            Report: e.TransactionType + " - Received",
            Link: "/home/report/inventoryreports",
            State: { transactionType: e, type: "Received" },
          });
        } else {
          inventoryreports.push({
            Report: e.TransactionType,
            Link: "/home/report/inventoryreports",
            State: { transactionType: e },
          });
        }
      }
    });
    if (this.props.getVisibility("GSTR2", "read") === "inline") {
      inventoryreports.push({
        Report: "GSTR 2 Report",
        Link: "/home/reports/gstr2",
      });
    }
    if (atleastOneInventoryTransactionAccessible) {
      inventoryreports.push({
        Report: "Batch Report",
        Link: "/home/reports/batchexpiryreport",
      });
      inventoryreports.push({
        Report: "Inventory Sales, Purchase & InStock Qty",
        Link: "/home/reports/inventorysalespurchaseinstock",
      });
    }
    let employeeReports = [];
    let customerReports = [];
    let vendorReports = [];
    let expnseReports = [];
    let loyaltyReports = [];
    if (this.props.config.WhiteLabel.Code !== "PINCODE") {
      if (this.props.getVisibility("Employee Insight", "read") === "inline") {
        employeeReports.push({
          Report: "Employee Insight",
          Link: "/home/reports/employeedashboard",
        });
      }
      if (this.props.getVisibility("Attendance", "read") === "inline") {
        employeeReports.push({
          Report: "Attendance Report",
          Link: "/home/reports/attendancereport",
        });
      }
      employeeReports.push({
        Report: "Employee Incentive",
        Link: "/home/reports/employeeincentive",
      });

      if (
        this.props.getVisibility("Membership Dashboard", "read") === "inline"
      ) {
        customerReports.push({
          Report: "Membership Dashboard",
          Link: "/home/reports/membershipdashboard",
        });
      }
      if (
        this.props.getVisibility("Customer Reference Report", "read") ===
        "inline"
      ) {
        customerReports.push({
          Report: "Customer Reference Report",
          Link: "/home/reports/customereferencedetail",
        });
      }
      if (
        this.props.getVisibility("Customer Wallet Ledger Report", "read") ===
        "inline"
      ) {
        customerReports.push({
          Report: "Customer Wallet Ledger Report",
          Link: "/home/reports/customerwalletledger",
        });
      }
      if (
        this.props.getVisibility(
          "Customer OutStanding Ledger Report",
          "read"
        ) === "inline"
      ) {
        customerReports.push({
          Report: "Customer OutStanding Ledger Report",
          Link: "/home/reports/customeroutstandingledger",
        });
      }
      if (this.props.getVisibility("Membership Expiry", "read") === "inline") {
        customerReports.push({
          Report: "Membership Expiry",
          Link: "/home/reports/membershipexpiry",
        });
      }

      if (
        this.props.user.user.hasOwnProperty("RoleKey") &&
        this.props.user.user.RoleKey !== ""
      ) {
        if (this.props.getVisibility("Vendor Ledger", "read") === "inline") {
          vendorReports.push({
            Report: "Vendor Ledger",
            Link: "/home/reports/vendorledgerreport",
          });
        }
        if (
          this.props.getVisibility("Vendor Due Amount", "read") === "inline"
        ) {
          vendorReports.push({
            Report: "Vendor Due Amount",
            Link: "/home/reports/vendoroutstanding",
          });
        }
      } else {
        vendorReports.push({
          Report: "Vendor Ledger",
          Link: "/home/reports/vendorledgerreport",
        });
        vendorReports.push({
          Report: "Vendor Due Amount",
          Link: "/home/reports/vendoroutstanding",
        });
      }

      if (this.props.getVisibility("Expense List", "read") === "inline") {
        expnseReports.push({
          Report: "Expense List",
          Link: "/home/reports/expenseListreport",
        });
      }
      if (
        this.props.getVisibility("Expense By Expense Category", "read") ===
        "inline"
      ) {
        expnseReports.push({
          Report: "Expense By Expense Category",
          Link: "/home/reports/expenseByExpenseCategoryreport",
        });
      }
      if (
        this.props.getVisibility("Expense By Expense Category", "read") ===
        "inline"
      ) {
        expnseReports.push({
          Report: "Expense By Party",
          Link: "/home/reports/expenseByPartyreport",
        });
      }

      if (
        this.props.registration.registration.hasOwnProperty("LoyaltyEnabled") &&
        this.props.registration.registration.LoyaltyEnabled
      ) {
        if (this.props.getVisibility("LoyaltyDashboard", "read") === "inline") {
          loyaltyReports.push({
            Report: "Loyalty Dashboard",
            Link: "/home/reports/loyaltydashboard",
          });
        }
        if (this.props.getVisibility("LoyaltyCustomer", "read") === "inline") {
          loyaltyReports.push({
            Report: "Loyalty by Customer",
            Link: "/home/reports/loyaltycustomer",
          });
        }
        if (this.props.getVisibility("LoyaltyLedger", "read") === "inline") {
          loyaltyReports.push({
            Report: "Loyalty Ledger",
            Link: "/home/reports/loyaltyledger",
          });
        }
      }
    }

    // console.log('inventoryreports', inventoryreports)

    this.setState({
      salesReports: salesReports,
      inventoryreports: inventoryreports,
      employeeReports: employeeReports,
      customerReports: customerReports,
      vendorReports: vendorReports,
      expnseReports: expnseReports,
      loyaltyReports: loyaltyReports,
    });
  }

  componentDidMount() {
    if (this.props.config.WhiteLabel.Code === "PINCODE") {
      this.loadPincodeReports();
      return;
    }

    if (this.props.registration.registration.EnablePosBillingForPincode) {
      this.loadReportsPincodeBillingReports();
      return;
    }

    this.loadReports();
  }

  renderLink(s) {
    if (s.hasOwnProperty("State")) {
      // console.log('state found ', s)
      return (
        <Link to={{ pathname: s.Link, state: s.State }}>
          <span>{s.Report}</span>
        </Link>
      );
    } else {
      return (
        <Link to={s.Link}>
          <span>{s.Report}</span>
        </Link>
      );
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <font style={{ fontSize: "30px", marginBottom: "4px" }}>
                Reports
              </font>
            </div>
          </div>
          <div className="row">
            {this.state.salesReports.length > 0 && (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="box box-success">
                  <div className="box-header without-border">
                    <h3 className="box-title">Sales Reports</h3>
                  </div>
                  <div className="box-body-not-required">
                    {this.state.salesReports.map((s) => (
                      <div className="report-line-item">
                        {this.renderLink(s)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {this.state.inventoryreports.length > 0 && (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="box box-success">
                  <div className="box-header without-border">
                    <h3 className="box-title">Inventory Reports</h3>
                  </div>
                  <div className="box-body-not-required">
                    {this.state.inventoryreports.map((s) => (
                      <div className="report-line-item">
                        {this.renderLink(s)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {this.state.employeeReports.length > 0 && (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="box box-success">
                  <div className="box-header without-border">
                    <h3 className="box-title">Employee Reports</h3>
                  </div>
                  <div className="box-body-not-required">
                    {this.state.employeeReports.map((s) => (
                      <div className="report-line-item">
                        {this.renderLink(s)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {this.state.customerReports.length > 0 && (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="box box-success">
                  <div className="box-header without-border">
                    <h3 className="box-title">Customer Reports</h3>
                  </div>
                  <div className="box-body-not-required">
                    {this.state.customerReports.map((s) => (
                      <div className="report-line-item">
                        {this.renderLink(s)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {this.state.vendorReports.length > 0 && (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="box box-success">
                  <div className="box-header without-border">
                    <h3 className="box-title">Vendor Reports</h3>
                  </div>
                  <div className="box-body-not-required">
                    {this.state.vendorReports.map((s) => (
                      <div className="report-line-item">
                        {this.renderLink(s)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {this.state.expnseReports.length > 0 && (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="box box-success">
                  <div className="box-header without-border">
                    <h3 className="box-title">Expense Reports</h3>
                  </div>
                  <div className="box-body-not-required">
                    {this.state.expnseReports.map((s) => (
                      <div className="report-line-item">
                        <Link to={s.Link}>
                          <span>{s.Report}</span>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {this.state.loyaltyReports.length > 0 && (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="box box-success">
                  <div className="box-header without-border">
                    <h3 className="box-title">Loyalty Reports</h3>
                  </div>
                  <div className="box-body-not-required">
                    {this.state.loyaltyReports.map((s) => (
                      <div className="report-line-item">
                        <Link to={s.Link}>
                          <span>{s.Report}</span>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {this.props.config.WhiteLabel.Code === "WRX" && (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="box box-success">
                  <div className="box-header without-border">
                    <h3 className="box-title">Product Reports</h3>
                  </div>
                  <div className="box-body-not-required">
                    <div className="report-line-item">
                      <Link to={"productexport"}>
                        <span>Product Export</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default AllReportOptions;
