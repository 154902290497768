import React, { Component } from "react";
import Window from "../2/Controls/Window";
import { database } from "../../firebase";

class PincodeAPIlogs extends Component {
  constructor() {
    super();
    this.state = {
      showAPILogs: false,
      apiLogs: [],
    };
    this.loadAPILogs = this.loadAPILogs.bind(this);
  }

  componentDidMount() {}

  async loadAPILogs() {
    let apiLogs = await database
      .collection("PincodeApiLogs")
      .where("ProductID", "==", this.props.productID)
      .orderBy("ActtionOn", "desc")
      .limit(2000)
      .get()
      .then((querySnapshot) => {
        let apiLogs = [];
        querySnapshot.forEach((doc) => {
          apiLogs.push({ ...doc.data() });
        });
        console.log("apiLogs", apiLogs);
        return apiLogs;
      });

    this.setState({ apiLogs: apiLogs, showAPILogs: true });
  }

  render() {
    return (
      <React.Fragment>
        <a onClick={() => this.loadAPILogs()}>API Logs</a>

        {this.state.showAPILogs && (
          <Window
            header={"API Logs"}
            onClose={() => this.setState({ showAPILogs: false })}
            onButtonClick={(buttonType) => {
              this.setState({ showAPILogs: false });
            }}
            buttons={[]}
          >
            <div
              className="box-body scroll2"
              style={{ width: "auto", overflowX: "auto", height: "400px" }}
            >
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Date & time</th>
                    <th>Action Performed</th>
                    <th>Payload</th>
                    <th>Response</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.apiLogs.map((apiLog, index) => (
                    <tr key={index}>
                      <td>
                        {new Date(apiLog.ActtionOn.seconds * 1000)
                          .toString()
                          .substring(0, 24)}
                      </td>
                      <td>{apiLog.ActionBy}</td>
                      <td> {JSON.stringify(apiLog.Log)}</td>
                      <td>
                        {" "}
                        {apiLog.Response && (
                          <>{JSON.stringify(apiLog.Response)}</>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Window>
        )}
      </React.Fragment>
    );
  }
}

export default PincodeAPIlogs;
