import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { auth } from "../firebase";
import Window from "./2/Controls/Window";
import Input from "./2/Controls/Input";
import axios from "axios";
class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      employee: {},
      employeePassword: "",
      employeeConfirmPassword: "",
      employeePasswordError: "",
      employeeConfirmPasswordError: "",
      processing: false,
    };
    this.onChangePassword = this.onChangePassword.bind(this);
  }

  componentDidMount() {}

  async onChangePassword() {
    this.setState({ processing: true });
    let employeePasswordError = "";
    let employeeConfirmPasswordError = "";

    if (this.state.employeePassword.trim() === "") {
      employeePasswordError = "Please provide password";
    }
    if (this.state.employeeConfirmPassword.trim() === "") {
      employeeConfirmPasswordError = "Please provide password";
    }
    if (this.state.employeePassword.length < 6) {
      employeePasswordError =
        "Password should be at least 6 characters in length";
    }
    if (
      this.state.employeePassword.trim() !==
      this.state.employeeConfirmPassword.trim()
    ) {
      employeeConfirmPasswordError =
        "Password and confirm password do not match";
    }

    if (
      employeePasswordError.trim() !== "" ||
      employeeConfirmPasswordError.trim() !== ""
    ) {
      this.setState({
        employeePasswordError: employeePasswordError,
        employeeConfirmPasswordError: employeeConfirmPasswordError,
        processing: false,
      });
      return;
    }
    //else update pass
    // alert('onChangePassword', this.state.employeePassword)
    var user = auth.currentUser;
    user
      .updatePassword(this.state.employeePassword)
      .then(function() {
        // Update successful.
      })
      .catch(function(error) {
        // An error happened.
        // this.setState({processing:false});
        // return;
      });
    const userID = this.props.user.user.key ? this.props.user.user.key : "";
    // await this.updatePassword(userID,this.state.employeePassword);
    const response = await axios.post(
      "https://backend-o3dyulj4pa-el.a.run.app/v1/user/adduser",
      {
        userId: userID,
        password: this.state.employeePassword,
      }
    );
    this.props.getToastr("Password Changed Successfully");
    this.setState({ processing: false });
    this.props.onClose();
  }

  render() {
    return (
      <Window
        header={"Change Password"}
        height={"200px"}
        processing={this.state.processing}
        onClose={() => this.props.onClose()}
        onButtonClick={(buttonType) => {
          if (buttonType.toLowerCase() === "save") {
            this.onChangePassword();
          } else {
            this.props.onClose();
          }
        }}
        buttons={[
          { Type: "secondary", Text: "Cancel", Align: "left" },
          { Type: "primary", Text: "Save", Align: "right" },
        ]}
      >
        <Input
          type="password"
          label="Password"
          value={this.state.employeePassword}
          onChange={(value) => this.setState({ employeePassword: value })}
          error={this.state.employeePasswordError}
        />
        <Input
          type="password"
          label="Confirm Password"
          value={this.state.employeeConfirmPassword}
          onChange={(value) =>
            this.setState({ employeeConfirmPassword: value })
          }
          error={this.state.employeeConfirmPasswordError}
        />
      </Window>
    );
  }
}
export default withRouter(ChangePassword);
