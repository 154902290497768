import React, { Component } from "react";
import Window from "../2/Controls/Window";

class CancelSales extends React.Component {
  constructor() {
    super();
    this.state = {
      cancellationReason: "",
      key: "",
      saving: false,
    };
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    // console.log('salesID',this.props.salesID)
    this.setState({ key: this.props.salesID });
  }

  async onSave() {
    if (this.state.cancellationReason.trim() === "") {
      alert("Please provide reason for cancellation");
      return;
    }
    this.setState({ saving: true }); ////
    this.props.cancelSales(this.state.cancellationReason, this.state.key);
    this.props.getToastr("Transaction cancelled successfully");
    setTimeout(
      function() {
        //Start the timer
        this.setState({ saving: false });
        this.props.onSave();
      }.bind(this),
      3000
    );
  }

  render() {
    return (
      <Window
        header={"Cancel"}
        height={"300px"}
        modal={true}
        processing={this.state.saving}
        onClose={this.props.onClose}
        onButtonClick={(buttonType) => {
          if (buttonType.toLowerCase() === "save") {
            this.onSave();
          } else {
            this.props.onClose();
          }
        }}
        buttons={[
          { Type: "secondary", Text: "Cancel", Align: "left" },
          { Type: "primary", Text: "Save", Align: "right" },
        ]}
      >
        <textarea
          className="textarea"
          style={{ width: "100%", height: "150px" }}
          onChange={(evt) =>
            this.setState({ cancellationReason: evt.target.value })
          }
          value={this.state.cancellationReason}
        ></textarea>
      </Window>
    );
  }

  // render() {
  //     return (
  //         <div
  //             style={{
  //                 width: this.props.mobile ? "90%" : "300px",
  //                 margin: "auto",
  //             }}
  //         >
  //             <h3 style={{ marginTop: "0px" }}>Cancel Sales Invoice</h3>
  //             <div className="box-body">
  //                 <textarea
  //                     className="textarea"
  //                     style={{
  //                         width: this.props.isMobileApp() ? "90%" : "300px",
  //                         height: "200px",
  //                     }}
  //                     onChange={(evt) =>
  //                         this.setState({
  //                             cancellationReason: evt.target.value,
  //                         })
  //                     }
  //                     value={this.state.cancellationReason}
  //                 ></textarea>
  //             </div>
  //             <div className="box-footer">
  //                 <div>
  //                     <button
  //                         type="submit"
  //                         className="btn btn-md btn-flat btn-default btn-rounded"
  //                         onClick={this.props.onClose}
  //                     >
  //                         Cancel
  //                     </button>
  //                     <button
  //                         type="submit"
  //                         className="btn btn-flat btn-md btn-flat btn-success pull-right btn-rounded"
  //                         disabled={this.state.saving}
  //                         onClick={this.onSave}
  //                     >
  //                         Save
  //                     </button>
  //                 </div>
  //             </div>
  //         </div>
  //     );
  // }
}

export default CancelSales;
