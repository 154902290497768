import React, { Component } from "react";
import Input from "../2/Controls/Input";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";

class AddRegistrationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kotSelected: false,
      deliverySelected: false,
      globalCustomersSeleceted: false,
      productConsumptionSelected: false,
      sendInvoiceLinkSelected: false,
      sendInvoiceCancellationSmsSelected: false,
      cashRegisterClosingNotificationSelected: false,
      negativeProductQtyNotAllowedForBilling: false,
      showNumberPad: false,
      autoGenerateSKU: false,
      autoGenerateSKUPrefix: "",
      autoGenerateSKUPaddedZero: 0,
      autoGenerateSKURunningNo: 1,
      enableTCS: false,
      TCSPercentage: 0,
      kotGroup: false,
      // enableTableOrder:false,
      // tableOrderTransactionTypeID:'',
      enablePackageTracker: false,
      enableAppointment: false,
      smsTemplateEnabled: false,
      newDashboard: false,
      enableSaleToPurchase: false,
      nearingExpiryBatchToBeSelectedFirstValidation: false,
      showOtherStoreInStockAtBilling: false,
      autoUpdateCataloguePriceWithInventoryPrice: false,
      enableONDC: false,
      shopifyAdminAPIAccessToken: "",
      shopifyShopName: "",
      enableONDCShopify: false,
      ONDCTest: false,
      EInvoiceUserName: "",
      EInvoicePassword: "",
      services: [],
      enableExpenseTransactionWiseAdjustment: false,
    };
    this.onSave = this.onSave.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    // console.log('enableSaleToPurchase',enableSaleToPurchase)
  }

  async loadData() {
    let enableSaleToPurchase = false;
    if (
      this.props.registration.registration.hasOwnProperty(
        "SaleToPurchaseTransactionType"
      ) &&
      this.props.registration.registration.SaleToPurchaseTransactionType !== ""
    ) {
      enableSaleToPurchase = true;
    }

    //load services
    let services = await this.props.getProducts(
      this.props.registration.registration.key,
      0,
      10000,
      "",
      "",
      "services",
      "",
      "",
      "",
      "",
      "",
      "",
      false
    );

    console.log("services", services);

    this.setState({
      services: services.searchResult ? services.searchResult : [],
      kotSelected: this.props.registration.registration.hasOwnProperty("KOT")
        ? this.props.registration.registration.KOT
        : false,
      deliverySelected: this.props.registration.registration.hasOwnProperty(
        "Delivery"
      )
        ? this.props.registration.registration.Delivery
        : false,
      globalCustomersSeleceted: this.props.registration.registration.hasOwnProperty(
        "GlobalCustomer"
      )
        ? this.props.registration.registration.GlobalCustomer
        : false,
      productConsumptionSelected: this.props.registration.registration.hasOwnProperty(
        "ProductConsumption"
      )
        ? this.props.registration.registration.ProductConsumption
        : false,
      sendInvoiceLinkSelected: this.props.registration.registration.hasOwnProperty(
        "SendInvoiceLink"
      )
        ? this.props.registration.registration.SendInvoiceLink
        : false,
      sendInvoiceCancellationSmsSelected: this.props.registration.registration.hasOwnProperty(
        "SendInvoiceCancellationSMS"
      )
        ? this.props.registration.registration.SendInvoiceCancellationSMS
        : false,
      cashRegisterClosingNotificationSelected: this.props.registration.registration.hasOwnProperty(
        "CashRegisterClosingNotificationToStoreOwner"
      )
        ? this.props.registration.registration
            .CashRegisterClosingNotificationToStoreOwner
        : false,
      negativeProductQtyNotAllowedForBilling: this.props.registration.registration.hasOwnProperty(
        "NegativeProductQtyNotAllowedForBilling"
      )
        ? this.props.registration.registration
            .NegativeProductQtyNotAllowedForBilling
        : false,
      showNumberPad: this.props.registration.registration.hasOwnProperty(
        "ShowNumberPad"
      )
        ? this.props.registration.registration.ShowNumberPad
        : false,
      autoGenerateSKU: this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKU"
      )
        ? this.props.registration.registration.AutoGenerateSKU
        : false,
      autoGenerateSKUPrefix: this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKUPrefix"
      )
        ? this.props.registration.registration.AutoGenerateSKUPrefix
        : "",
      autoGenerateSKUPaddedZero: this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKUPaddedZero"
      )
        ? this.props.registration.registration.AutoGenerateSKUPaddedZero
        : 0,
      autoGenerateSKURunningNo: this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKURunningNo"
      )
        ? this.props.registration.registration.AutoGenerateSKURunningNo
        : 1,
      enableTCS: this.props.registration.registration.hasOwnProperty(
        "EnableTCS"
      )
        ? this.props.registration.registration.EnableTCS
        : false,
      TCSPercentage: this.props.registration.registration.hasOwnProperty(
        "TCSPercentage"
      )
        ? this.props.registration.registration.TCSPercentage
        : 0,
      // enableTableOrder:this.props.registration.registration.hasOwnProperty('EnableTableOrder') ? this.props.registration.registration.EnableTableOrder : false,
      // tableOrderTransactionTypeID:this.props.registration.registration.hasOwnProperty('TableOrderTransactionTypeID') ? this.props.registration.registration.TableOrderTransactionTypeID : 0,
      enablePackageTracker: this.props.registration.registration.hasOwnProperty(
        "EnablePackageTracker"
      )
        ? this.props.registration.registration.EnablePackageTracker
        : false,
      enableAppointment: this.props.registration.registration.hasOwnProperty(
        "EnableAppointment"
      )
        ? this.props.registration.registration.EnableAppointment
        : 0,
      kotGroup: this.props.registration.registration.hasOwnProperty("KOTGroup")
        ? this.props.registration.registration.KOTGroup
        : false,
      smsTemplateEnabled: this.props.registration.registration.hasOwnProperty(
        "SMSTemplateEnabled"
      )
        ? this.props.registration.registration.SMSTemplateEnabled
        : false,
      newDashboard: this.props.registration.registration.hasOwnProperty(
        "NewDashboard"
      )
        ? this.props.registration.registration.NewDashboard
        : false,
      enableSaleToPurchase: enableSaleToPurchase,
      enableSalesApproval: this.props.registration.registration.hasOwnProperty(
        "EnableSalesApproval"
      )
        ? this.props.registration.registration.EnableSalesApproval
        : false,
      enableInventoryApproval: this.props.registration.registration.hasOwnProperty(
        "EnableInventoryApproval"
      )
        ? this.props.registration.registration.EnableInventoryApproval
        : false,
      nearingExpiryBatchToBeSelectedFirstValidation: this.props.registration.registration.hasOwnProperty(
        "NearingExpiryBatchToBeSelectedFirstValidation"
      )
        ? this.props.registration.registration
            .NearingExpiryBatchToBeSelectedFirstValidation
        : false,
      showOtherStoreInStockAtBilling: this.props.registration.registration.hasOwnProperty(
        "ShowOtherStoreInStockAtBilling"
      )
        ? this.props.registration.registration.ShowOtherStoreInStockAtBilling
        : false,
      autoUpdateCataloguePriceWithInventoryPrice: this.props.registration.registration.hasOwnProperty(
        "AutoUpdateCataloguePriceWithInventoryPrice"
      )
        ? this.props.registration.registration
            .AutoUpdateCataloguePriceWithInventoryPrice
        : false,
      enableONDC: this.props.registration.registration.hasOwnProperty(
        "EnableONDC"
      )
        ? this.props.registration.registration.EnableONDC
        : false,
      shippingChargesServiceID: this.props.registration.registration.hasOwnProperty(
        "ShippingChargesServiceID"
      )
        ? this.props.registration.registration.ShippingChargesServiceID
        : false,
      enableONDCShopify: this.props.registration.registration.hasOwnProperty(
        "EnableONDCShopify"
      )
        ? this.props.registration.registration.EnableONDCShopify
        : false,
      shopifyShopName: this.props.registration.registration.hasOwnProperty(
        "ShopifyShopName"
      )
        ? this.props.registration.registration.ShopifyShopName
        : "",
      shopifyAdminAPIAccessToken: this.props.registration.registration.hasOwnProperty(
        "ShopifyAdminAPIAccessToken"
      )
        ? this.props.registration.registration.ShopifyAdminAPIAccessToken
        : "",
      ONDCTest: this.props.registration.registration.hasOwnProperty("ONDCTest")
        ? this.props.registration.registration.ONDCTest
        : false,
      EInvoiceUserName: this.props.registration.registration.hasOwnProperty(
        "EInvoiceUserName"
      )
        ? this.props.registration.registration.EInvoiceUserName
        : "",
      EInvoicePassword: this.props.registration.registration.hasOwnProperty(
        "EInvoicePassword"
      )
        ? this.props.registration.registration.EInvoicePassword
        : "",
      enableExpenseTransactionWiseAdjustment: this.props.registration.registration.hasOwnProperty(
        "EnableExpenseTransactionWiseAdjustment"
      )
        ? this.props.registration.registration
            .EnableExpenseTransactionWiseAdjustment
        : false,
    });
  }

  onSave() {
    const additionalSetting = {
      KOT: this.state.kotSelected,
      Delivery: this.state.deliverySelected,
      GlobalCustomer: this.state.globalCustomersSeleceted,
      ProductConsumption: this.state.productConsumptionSelected,
      SendInvoiceLink: this.state.sendInvoiceLinkSelected,
      SendInvoiceCancellationSMS: this.state.sendInvoiceCancellationSmsSelected,
      CashRegisterClosingNotificationToStoreOwner: this.state
        .cashRegisterClosingNotificationSelected,
      NegativeProductQtyNotAllowedForBilling: this.state
        .negativeProductQtyNotAllowedForBilling,
      ShowNumberPad: this.state.showNumberPad,
      AutoGenerateSKU: this.state.autoGenerateSKU,
      AutoGenerateSKUPrefix: this.state.autoGenerateSKU
        ? this.state.autoGenerateSKUPrefix
        : "",
      AutoGenerateSKUPaddedZero: this.state.autoGenerateSKUPaddedZero
        ? this.state.autoGenerateSKUPaddedZero
        : "",
      AutoGenerateSKURunningNo: this.state.autoGenerateSKU
        ? this.state.autoGenerateSKURunningNo
        : 0,
      EnableTCS: this.state.enableTCS,
      TCSPercentage: this.state.TCSPercentage,
      // EnableTableOrder: this.state.enableTableOrder,
      // TableOrderTransactionTypeID: this.state.tableOrderTransactionTypeID,
      EnablePackageTracker: this.state.enablePackageTracker,
      EnableAppointment: this.state.enableAppointment,
      KOTGroup: this.state.kotGroup,
      SMSTemplateEnabled: this.state.smsTemplateEnabled,
      NewDashboard: this.state.newDashboard,
      EnableSalesApproval: this.state.enableSalesApproval,
      EnableInventoryApproval: this.state.enableInventoryApproval,
      NearingExpiryBatchToBeSelectedFirstValidation: this.state
        .nearingExpiryBatchToBeSelectedFirstValidation,
      ShowOtherStoreInStockAtBilling: this.state.showOtherStoreInStockAtBilling,
      AutoUpdateCataloguePriceWithInventoryPrice: this.state
        .autoUpdateCataloguePriceWithInventoryPrice,
      EnableONDC: this.state.enableONDC,
      ShippingChargesServiceID: this.state.shippingChargesServiceID,
      EnableONDCShopify: this.state.enableONDCShopify,
      ShopifyShopName: this.state.shopifyShopName,
      ShopifyAdminAPIAccessToken: this.state.shopifyAdminAPIAccessToken,
      ONDCTest: this.state.ONDCTest,
      EInvoiceUserName: this.state.EInvoiceUserName,
      EInvoicePassword: this.state.EInvoicePassword,
      EnableExpenseTransactionWiseAdjustment: this.state
        .enableExpenseTransactionWiseAdjustment,
    };

    this.props
      .updateRegistrationGeneralDetails(
        this.props.registration.registration.key,
        additionalSetting
      )
      .then(() => {
        this.props.getToastr("Registration settings saved successfully");
        this.props.getRegistration(this.props.registration.registration.key);
        // this.props.onClose()
      });
  }

  render() {
    return (
      <Page
        title={"Additional Setting"}
        showSaveButton={true}
        showCancelButton={true}
        processing={this.state.saving}
        onClick={(action) => {
          if (action === "cancel") {
            this.loadData();
            this.props.history.push("/home");
          } else if (action === "save") {
            this.onSave();
          }
        }}
      >
        <PageSection title="Auto Generate SKU">
          <Input
            type="switch"
            label="Enable Auto Generate SKU"
            value={this.state.autoGenerateSKU}
            onChange={(value) => {
              this.setState({ autoGenerateSKU: value });
            }}
          />
          {this.state.autoGenerateSKU && (
            <div className="row">
              <div className="col-md-2">
                <Input
                  type="text"
                  label="Prefix"
                  value={this.state.autoGenerateSKUPrefix}
                  onChange={(value) => {
                    this.setState({ autoGenerateSKUPrefix: value });
                  }}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="text"
                  label="No of padded zeros"
                  value={this.state.autoGenerateSKUPaddedZero}
                  onChange={(value) => {
                    this.setState({ autoGenerateSKUPaddedZero: value });
                  }}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="text"
                  label="Next Running No"
                  value={this.state.autoGenerateSKURunningNo}
                  onChange={(value) => {
                    this.setState({ autoGenerateSKURunningNo: value });
                  }}
                />
              </div>
            </div>
          )}
        </PageSection>
        <PageSection title="Billing / Sales">
          <Input
            type="switch"
            label="Send Invoice Link"
            value={this.state.sendInvoiceLinkSelected}
            onChange={(value) => {
              this.setState({ sendInvoiceLinkSelected: value });
            }}
          />
          <Input
            type="switch"
            label="Send Invoice Cancellation SMS"
            value={this.state.sendInvoiceCancellationSmsSelected}
            onChange={(value) => {
              this.setState({ sendInvoiceCancellationSmsSelected: value });
            }}
          />
          <Input
            type="switch"
            label="Cash Register Closing Notification To Store Owner"
            value={this.state.cashRegisterClosingNotificationSelected}
            onChange={(value) => {
              this.setState({ cashRegisterClosingNotificationSelected: value });
            }}
          />
          <Input
            type="switch"
            label="During billing allow only batches that are expiring first to be selected"
            value={this.state.nearingExpiryBatchToBeSelectedFirstValidation}
            onChange={(value) => {
              this.setState({
                nearingExpiryBatchToBeSelectedFirstValidation: value,
              });
            }}
          />
          <Input
            type="switch"
            label="Show all store(s) in stock qty at the time of billing"
            value={this.state.showOtherStoreInStockAtBilling}
            onChange={(value) => {
              this.setState({ showOtherStoreInStockAtBilling: value });
            }}
          />
        </PageSection>
        <PageSection title="General Options">
          {/* <Input
                    type="switch"
                    label="Enable ONDC"
                    value={this.state.enableONDC}
                    onChange={(value) => { this.setState({ enableONDC: value }) }}
                />
                <Input
                    type="switch"
                    label="Enable Shopify for ONDC"
                    value={this.state.enableONDCShopify}
                    onChange={(value) => { this.setState({ enableONDCShopify: value})}}
                />
                {this.state.enableONDCShopify && <React.Fragment>
                    <Input
                    type="text"
                    label="Shopify Shop Name"
                    value={this.state.shopifyShopName}
                    onChange={(value) => { this.setState({ shopifyShopName: value })}}
                    />
                    <Input
                    type="text"
                    label="Shopify Admin API access token"
                    value={this.state.shopifyAdminAPIAccessToken}
                    onChange={(value) => { this.setState({ shopifyAdminAPIAccessToken: value })}}
                    />
                </React.Fragment>}
                {!this.state.enableONDCShopify && <Input type="select"  
                label="Service to which delivery charges will be mapped when sales transaction is created"
                value={this.state.shippingChargesServiceID}
                onChange={(value) =>this.setState({shippingChargesServiceIDError: "",shippingChargesServiceID:value,})}
                error={this.state.shippingChargesServiceIDError}
                >
                    <option value="">Please select</option>
                        {this.state.services.filter((f) => !f.IsDeleted && f.ProductTypeID === 3).map((service, index) => (
                            <option value={service.key} key={index}>
                                {service.Name}
                            </option>
                        ))}
                </Input>} */}

          {!this.props.registration.registration.EnablePosBillingForPincode && (
            <>
              <Input
                type="switch"
                label="Customize Dashboard"
                value={this.state.newDashboard}
                onChange={(value) => {
                  this.setState({ newDashboard: value });
                }}
              />
              <Input
                type="switch"
                label="Enable SMS Template"
                value={this.state.smsTemplateEnabled}
                onChange={(value) => {
                  this.setState({ smsTemplateEnabled: value });
                }}
              />
              <Input
                type="switch"
                label="Appointment"
                value={this.state.enableAppointment}
                onChange={(value) => {
                  this.setState({ enableAppointment: value });
                }}
              />
              <Input
                type="switch"
                label="Package Tracking"
                value={this.state.enablePackageTracker}
                onChange={(value) => {
                  this.setState({ enablePackageTracker: value });
                }}
              />
              <Input
                type="switch"
                label="KOT"
                value={this.state.kotSelected}
                onChange={(value) => {
                  this.setState({ kotSelected: value });
                }}
              />
              {this.state.kotSelected && (
                <Input
                  type="switch"
                  label="KOT Group"
                  value={this.state.kotGroup}
                  onChange={(value) => {
                    this.setState({ kotGroup: value });
                  }}
                />
              )}
              <Input
                type="switch"
                label="Delivery"
                value={this.state.deliverySelected}
                onChange={(value) => {
                  this.setState({ deliverySelected: value });
                }}
              />
              <Input
                type="switch"
                label="Product Consumption"
                value={this.state.productConsumptionSelected}
                onChange={(value) => {
                  this.setState({ productConsumptionSelected: value });
                }}
              />
              <Input
                type="switch"
                label="Tax Collected at Source (TCS)"
                value={this.state.enableTCS}
                onChange={(value) => {
                  this.setState({ enableTCS: value });
                }}
              />
              {this.state.enableTCS && (
                <Input
                  type="number"
                  label="TCS %"
                  value={this.state.TCSPercentage}
                  onChange={(value) => {
                    this.setState({ TCSPercentage: value });
                  }}
                />
              )}
            </>
          )}

          <Input
            type="switch"
            label="Global Customers"
            value={this.state.globalCustomersSeleceted}
            onChange={(value) => {
              this.setState({ globalCustomersSeleceted: value });
            }}
          />

          <Input
            type="switch"
            label="Auto update catalogue prices from inventory transactions"
            value={this.state.autoUpdateCataloguePriceWithInventoryPrice}
            onChange={(value) => {
              this.setState({
                autoUpdateCataloguePriceWithInventoryPrice: value,
              });
            }}
          />
          <Input
            type="switch"
            label="Expense - Allow transaction wise adjustment"
            value={this.state.enableExpenseTransactionWiseAdjustment}
            onChange={(value) => {
              this.setState({ enableExpenseTransactionWiseAdjustment: value });
            }}
          />
        </PageSection>
        {this.props.registration.registration.EInvoicing && (
          <PageSection title="E Invoice API Credentials">
            <div className="row">
              <div className="col-md-3">
                <Input
                  type="text"
                  label="E Invoice API User Name"
                  value={this.state.EInvoiceUserName}
                  onChange={(value) =>
                    this.setState({ EInvoiceUserName: value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Input
                  type="text"
                  label="E Invoice API Password"
                  value={this.state.EInvoicePassword}
                  onChange={(value) =>
                    this.setState({ EInvoicePassword: value })
                  }
                />
              </div>
            </div>
          </PageSection>
        )}
      </Page>
    );
  }
}

export default AddRegistrationSettings;
