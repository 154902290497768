import React, { Component } from "react";
import * as actionTypes from "../../actionTypes";
import VendorSearch from "./VendorSearch";
import Product from "../Catalogue/Product";
import Print from "../Sales/Print";
import ImportMaster from "../ImportMaster";
import UniqueNoInput from "./UniqueNoInput";
import UniqueNoSelection from "./UniqueNoSelection";
import BatchSelection from "./BatchSelection";
import Loader from "../Loader";
import AddEditVendor from "../Settings/Vendor/AddEditVendor";
import ProductVariants from "../Sales/ProductVariants";
import {
  getUOMs,
  checkDateFormat,
  roundUpTo2,
  showModule,
} from "../../CommonFunctions";
import Input from "../2/Controls/Input";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
import Window from "../2/Controls/Window";
import Grid from "../2/Controls/Grid";
import { isMobileApp } from "../../actions/Util";
import ImageButton from "../2/Controls/ImageButton";
import "./Inventory.css";
import Spinner from "../Common/Spinner";
import ProductSelection from "../Common/ProductSelection";
import ProductPincode from "../Catalogue/ProductPincode";
import { values } from "lodash";
import { searchPincodeGlobalCatalog } from "../../services/api/PincodeAPI";

var AsyncTypeahead = require("react-bootstrap-typeahead").AsyncTypeahead;

class Inventory extends Component {
  constructor() {
    super();
    this.state = {
      inventoryIssue: "",
      showProductSelection: false,
      showProductSelectionList: [],
      bulkPurchaseReturn: false,
      bulkPurchaseReturns: [],
      showVariants: false,
      selectedVariantProduct: null,
      key: "",
      transactionNo: "",
      transactionDate: new Date().toISOString().split("T")[0],
      editStore: null,
      transactionType: null,
      notes: "",
      lineItems: [],
      billQty: 0,
      billLineItemQty: 0,
      billFreeQty: 0,
      billSubTotal: 0,
      billAmount: 0,
      billTaxAmount: 0,
      billDiscount: 0,
      billRoundOff: 0,
      billAdditionalCharges: 0,
      billLineItemTotal: 0,
      taxSummaryList: [],
      HSNSummary: [],
      showLineItemModal: false,
      selectedLineItem: null,
      selectedLineItemIndex: -1,
      selectedProductID: "",
      placeOfSupply: "",
      refNo: "",
      selectedVendor: null,
      productsTypeAheadOptions: [],
      isProductTypeAheadLoading: false,
      productsTypeAheadSelected: [],
      showAddProduct: false,
      showInventory: true,
      saving: false,
      vendorError: "",
      noLineItemExistError: "",
      storeLocationError: "",
      parentTransactionNo: "",
      parentTransactionKey: "",
      editTransactionNo: "",
      editTransactionDate: null,
      storeLocations: [],
      storeLocationID: "",
      storeLocation: "",
      transactionDateError: "",
      toStoreError: "",
      toStoreID: "",
      toStore: "",
      toStoreList: [],
      print: false,
      printChecked: false,
      sendEmailChecked: false,
      sendSMSChecked: false,
      printData: null,
      selectedPringConfigID: "",
      selectedPrintConfigPrintDisplayName: "",
      showImport: false,
      showUniqueNoInput: false,
      showUniqueNoSelection: false,
      selectedProduct: null,
      toggleSearch: false,
      computeTax: true,
      billAdditionalDiscount: 0,
      showVendor: false,
      isMobileApp: false,
      vendorAction: actionTypes.VENDOR_NEW,
      productCustomField1DisplayName: "",
      productCustomField2DisplayName: "",
      productCustomField3DisplayName: "",
      productCustomField4DisplayName: "",
      productCustomField5DisplayName: "",
      productCustomField6DisplayName: "",
      converted: false,
      parentTransactions: [],
      vendorProducts: [],
      // fromLocation:"",
      // fromLocationID:"",
      fromLocationError: "",
      toLocationID: "",
      toLocationError: "",
      toLocationID: "",
      toLocation: "",
      showBatchsForSelection: false,
      lineItemBatchNo: "",
      lineItemBatchMRP: "",
      lineItemBatchRetailPrice: "",
      lineItemBatchExpDate: "",
      lineItemBatchMfgDate: "",
      batchNoError: "",
      batchMRPError: "",
      batchRetailPriceError: "",
      batchMfgDateError: "",
      batchExpDateError: "",
      inStockQtyNegativeErrors: [],
      inStockQtyNegativeError: "",
      uoms: [],
      editLineItems: [],
      showAddProductButton: false,
      parentTransactionProductIDs: [],
      showParkTransactionButton: false,
      childTransactionNo: "",
      childTransactionKey: "",
    };
    this.onProductSelected = this.onProductSelected.bind(this);
    this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this);
    this.onLineItemChange = this.onLineItemChange.bind(this);
    this.onLineItemPriceChange = this.onLineItemPriceChange.bind(this);
    this.onLineItemPriceChange = this.onLineItemPriceChange.bind(this);
    this.onRemoveLineItem = this.onRemoveLineItem.bind(this);
    this.onLineItemChangesDone = this.onLineItemChangesDone.bind(this);
    this.onSelectedLineItemQtyChange = this.onSelectedLineItemQtyChange.bind(
      this
    );
    this.onSelectedLineItemFreeQtyChange = this.onSelectedLineItemFreeQtyChange.bind(
      this
    );
    this.onSelectedLineItemPriceChange = this.onSelectedLineItemPriceChange.bind(
      this
    );
    this.onSelectedLineItemDiscountChange = this.onSelectedLineItemDiscountChange.bind(
      this
    );
    this.onSelectedLineItemDiscountAmountChange = this.onSelectedLineItemDiscountAmountChange.bind(
      this
    );
    this.onSelectedLineItemChargeTaxOnProductChange = this.onSelectedLineItemChargeTaxOnProductChange.bind(
      this
    );
    this.onSelectedLineItemTaxIncluxiveChange = this.onSelectedLineItemTaxIncluxiveChange.bind(
      this
    );
    this.onSelectedLineItemNotesChange = this.onSelectedLineItemNotesChange.bind(
      this
    );
    this.onLineItemSelected = this.onLineItemSelected.bind(this);
    this.onSelectedLineItemTaxGroupChange = this.onSelectedLineItemTaxGroupChange.bind(
      this
    );
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.getTransactioNo = this.getTransactioNo.bind(this);
    this.printFormatting = this.printFormatting.bind(this);
    this.onImportCompletion = this.onImportCompletion.bind(this);
    this.productSelected = this.productSelected.bind(this);
    this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this);
    this.onComputeTaxChange = this.onComputeTaxChange.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.onSaveAsDraft = this.onSaveAsDraft.bind(this);
    this.onSelectedLineItemBatchNoChange = this.onSelectedLineItemBatchNoChange.bind(
      this
    );
    this.onSelectedLineItemBatchMRPChange = this.onSelectedLineItemBatchMRPChange.bind(
      this
    );
    this.onSelectedLineItemBatchRetailPriceChange = this.onSelectedLineItemBatchRetailPriceChange.bind(
      this
    );
    this.onSelectedLineItemBatchMfgDateChange = this.onSelectedLineItemBatchMfgDateChange.bind(
      this
    );
    this.onSelectedLineItemBatchExpDateChange = this.onSelectedLineItemBatchExpDateChange.bind(
      this
    );
    this.checkIfNegativeQtyAllowed = this.checkIfNegativeQtyAllowed.bind(this);
    this.onStoreLocationChange = this.onStoreLocationChange.bind(this);
    this.onToStoreLocationChange = this.onToStoreLocationChange.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.productBarCodeSearch = this.productBarCodeSearch.bind(this);
    this.onLineItemFreeQtyChange = this.onLineItemFreeQtyChange.bind(this);
    this.onSaveBulkPurchaseReturn = this.onSaveBulkPurchaseReturn.bind(this);
    this.save = this.save.bind(this);
    this.calculateBillTotalSub = this.calculateBillTotalSub.bind(this);
    this.addVedorProductsToLineItems = this.addVedorProductsToLineItems.bind(
      this
    );
    this.renderSection1 = this.renderSection1.bind(this);
    this.renderSection2 = this.renderSection2.bind(this);
    this.renderSection3 = this.renderSection3.bind(this);
    this.onVendorChange = this.onVendorChange.bind(this);
    this.renderProductSearch = this.renderProductSearch.bind(this);
    this.renderSection3Totals = this.renderSection3Totals.bind(this);
    this.disableBatchDetailsEdit = this.disableBatchDetailsEdit.bind(this);
    this.disableQtyEdit = this.disableQtyEdit.bind(this);
    this.onProductScan = this.onProductScan.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });

    let uoms = getUOMs();
    let toggleSearch = false;
    let customField1DisplayName = "";
    let customField2DisplayName = "";
    let customField3DisplayName = "";
    let customField4DisplayName = "";
    let customField5DisplayName = "";
    let customField6DisplayName = "";
    let selectedPringConfigID = "";
    let storeLocations = [];
    let storeList = [];
    let showAddProductButton = false;
    let transactionType = null;

    if (this.props.user.store.hasOwnProperty("InventoryProductSearch")) {
      if (this.props.user.store.InventoryProductSearch === "2") {
        toggleSearch = true;
      } else {
        toggleSearch = false;
      }
    }

    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Product") {
        customField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "Custom Field 1"
            : customField.CustomField1Name.trim();
        customField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "Custom Field 2"
            : customField.CustomField2Name.trim();
        customField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "Custom Field 3"
            : customField.CustomField3Name.trim();
        customField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "Custom Field 4"
            : customField.CustomField4Name.trim();
        customField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "Custom Field 5"
            : customField.CustomField5Name.trim();
        customField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "Custom Field 6"
            : customField.CustomField6Name.trim();
      }
    });

    let printConfig = this.props.config.PrintConfigs.filter(
      (e) =>
        !e.IsDeleted &&
        e.TransactionTypeID === this.props.location.state.transactionType.key
    );
    if (printConfig.length > 0) {
      selectedPringConfigID = printConfig[0].key;
    }

    let storeLocationsTmp = [];
    storeLocationsTmp = await this.props.getStoreLocations(
      this.props.user.store.key
    );
    storeLocations = storeLocationsTmp.filter((f) => !f.IsDeleted);

    // console.log('uoms',uoms)

    if (showModule("Products", false, this.props)) {
      // console.log('show module called')
      showAddProductButton = true;
    }

    this.setState({
      uoms: uoms,
      toggleSearch: toggleSearch,
      productCustomField1DisplayName: customField1DisplayName,
      productCustomField2DisplayName: customField2DisplayName,
      productCustomField3DisplayName: customField3DisplayName,
      productCustomField4DisplayName: customField4DisplayName,
      productCustomField5DisplayName: customField5DisplayName,
      productCustomField6DisplayName: customField6DisplayName,
      storeLocations: storeLocations,
      storeLocationID: storeLocations[0].key,
      storeLocation:
        storeLocations[0].LocationCode + " " + storeLocations[0].LocationName,
      // toLocationID: storeLocations[0].key,
      // toLocation:storeLocations[0].LocationCode + ' ' + storeLocations[0].LocationName,

      selectedPringConfigID: selectedPringConfigID,
      transactionDate: new Date().toISOString().split("T")[0],
      showAddProductButton: showAddProductButton,
    });

    if (this.props.location.state.type === actionTypes.INVENTORY_NEW) {
      // let preCondition = await this.props.inventoryPreConditions(this.props.registration, this.props.user.store,this.props.location.state.transactionType);
      // console.log('this.props.registration.registration',this.props.registration.registration)
      let preCondition = await this.props.inventoryPreConditions(
        this.props.registration.registration,
        this.props.user.store,
        this.props.location.state.transactionType
      );

      if (!preCondition.Status) {
        alert(preCondition.Message);
        this.props.history.push({
          pathname: "/home/inventory/inventorylist/",
          state: { type: "list" },
        });
        return;
      }

      transactionType = this.props.location.state.transactionType;

      this.setState({
        key: "",
        // transactionType: this.props.location.state.transactionType,
        bulkPurchaseReturn:
          this.props.location.state.transactionType.TransactionType.toLowerCase() ===
          "bulk purchase return"
            ? true
            : false,
        showParkTransactionButton: true,
      });

      if (
        this.props.location.state.hasOwnProperty("inventory") &&
        this.props.location.state.inventory
      ) {
        let lineItems = this.props.location.state.inventory.LineItems;
        let parentTransactionProductIDs = [];
        for (let i = 0; i < lineItems.length; i++) {
          let l = lineItems[i];
          l = await this.onLineItemChange(
            l,
            this.props.location.state.inventory.PlaceOfSupply,
            true,
            this.props.location.state.transactionType
          );
          parentTransactionProductIDs.push({ key: l.Product.key });
        }
        this.calculateBillTotal(
          lineItems,
          0,
          0,
          0,
          this.props.location.state.transactionType
        );
        this.setState({
          parentTransactionToBeConverted: this.props.location.state.inventory,
          refNo: this.props.location.state.inventory.RefNo,
          lineItems: lineItems,
          selectedVendor: this.props.location.state.inventory.Vendor
            ? this.props.location.state.inventory.Vendor
            : null,
          toStore: null, //this.props.location.state.inventory.ToStore,
          toStoreID: "", //this.props.location.state.inventory.ToStoreID,
          converted: true,
          parentTransactionProductIDs: parentTransactionProductIDs,
          parentTransactions: this.props.location.state.inventory
            .ParentTransactions
            ? this.props.location.state.inventory.ParentTransactions
            : null,
        });
      } else if (
        this.props.location.state.transactionType.TransactionType.toLowerCase() ===
        "purchase return"
      ) {
        if (this.props.location.state.inventory) {
          let inventory = this.props.location.state.inventory;
          let storeKey = this.props.user.store.key;
          if (inventory.Store && inventory.Store.key !== "") {
            storeKey = inventory.Store.key;
          }
          storeLocationsTmp = await this.props.getStoreLocations(storeKey);
          storeLocations = storeLocationsTmp.filter((f) => !f.IsDeleted);
          // if(inventory.Store && inventory.Store.key !== this.props.user.store.key){
          //     alert("Purchase return store and logged in store are different. Please login to correct store");
          //     this.onCancel();
          //     return;
          // }
          this.setState({
            storeLocations: storeLocations,
            lineItems: inventory.LineItems,
            billQty: inventory.BillQty,
            billSubTotal: inventory.BillSubTotal,
            billAmount: inventory.BillAmount,
            billTaxAmount: inventory.BillTaxAmount,
            billDiscount: inventory.BillDiscount,
            taxSummaryList: inventory.TaxSummaryList,
            placeOfSupply: inventory.PlaceOfSupply,
            selectedVendor: inventory.Vendor,
            billLineItemTotal: inventory.hasOwnProperty("BillLineItemTotal")
              ? inventory.BillLineItemTotal
              : inventory.BillAmount,
            billRoundOff: inventory.hasOwnProperty("BillRoundOff")
              ? inventory.BillRoundOff
              : 0,
            billAdditionalCharges: inventory.hasOwnProperty(
              "BillAdditionalCharges"
            )
              ? inventory.BillAdditionalCharges
              : 0,
          });
        }
      } else {
        //check if
        transactionType = this.props.location.state.transactionType;
        this.setState({ transactionType: transactionType });
        if (transactionType.PrePopulateProducts) {
          if (
            transactionType.hasOwnProperty("ProductGroupID") &&
            transactionType.ProductGroupID !== ""
          ) {
            if (
              this.props.config.ProductTags.filter(
                (f) => f.key === transactionType.ProductGroupID
              ).length > 0
            ) {
              let productTag = this.props.config.ProductTags.filter(
                (f) => f.key === transactionType.ProductGroupID
              )[0];
              let products = productTag.Products;
              let lineItems = [];
              for (let i = 0; i < products.length; i++) {
                if (
                  this.props.config.Products.filter(
                    (f) => f.key === products[i].ProductID
                  ).length > 0
                ) {
                  let product = this.props.config.Products.filter(
                    (f) => f.key === products[i].ProductID
                  )[0];
                  if (product.ProductTypeID < 3) {
                    let lineItem = {
                      Qty: 0,
                      Notes: "",
                    };
                    if (
                      product.hasOwnProperty("PurchaseUOM") &&
                      product.PurchaseUOM !== ""
                    ) {
                      lineItem.UOM = product.PurchaseUOM;
                    } else if (
                      product.hasOwnProperty("UOM") &&
                      product.UOM !== ""
                    ) {
                      lineItem.UOM = product.UOM;
                    }
                    lineItem.LineItemQty = lineItem.Qty;
                    lineItem.FreeQty = 0;
                    lineItem.Product = product;
                    lineItem.SupplyPrice = product.SupplyPrice;
                    lineItem.SubTotal = 0;
                    lineItem.ChargeTaxOnProduct = false;
                    lineItem.PriceBasedTax = false;
                    lineItem.HSNSACCode = "";
                    lineItem.TaxInclusive = false;
                    lineItem.TaxGroup = "";
                    lineItem.TaxID = "";
                    lineItem.DiscountPer = 0;
                    lineItem.DiscountAmount = 0;
                    lineItem.SubTotalWithDiscount = 0;
                    lineItem.TaxableAmount = 0;
                    lineItem.TotalTax = 0;
                    lineItem.Amount = 0;
                    lineItems.push(lineItem);

                    // this.onProductSelected(product);
                  }
                }
              }
              this.onImportCompletion(lineItems);
            }
          }
        }
      }
    } else if (
      this.props.location.state.type === actionTypes.INVENTORY_PARKED
    ) {
      let inventory = this.props.location.state.inventory;

      let preCondition = await this.props.inventoryPreConditions(
        this.props.registration.registration,
        inventory.Store,
        inventory.TransactionType
      );
      if (!preCondition.Status) {
        alert(preCondition.Message);
        this.props.history.push({
          pathname: "/home/inventory/inventorylist/",
          state: { type: "list" },
        });
        return;
      }

      // if(inventory.Store && inventory.Store.key !== this.props.user.store.key){
      //     alert("Please login to correct store");
      //     this.onCancel();
      //     return;
      // }
      storeLocationsTmp = await this.props.getStoreLocations(
        inventory.Store.key
      );
      storeLocations = storeLocationsTmp.filter((f) => !f.IsDeleted);

      transactionType = inventory.TransactionType;

      this.setState({
        key: "",
        // transactionType: inventory.TransactionType,
        notes: inventory.Notes,
        billQty: inventory.BillQty,
        billLineItemQty: inventory.hasOwnProperty("BillLineItemQty")
          ? inventory.BillLineItemQty
          : 0,
        billFreeQty: inventory.hasOwnProperty("BillFreeQty")
          ? inventory.BillFreeQty
          : 0,
        editStore: inventory.Store,
        storeLocations: storeLocations,
        billSubTotal: inventory.BillSubTotal,
        billAmount: inventory.BillAmount,
        billTaxAmount: inventory.BillTaxAmount,
        billDiscount: inventory.BillDiscount,
        taxSummaryList: inventory.TaxSummaryList,
        placeOfSupply: inventory.PlaceOfSupply,
        transactionNo: inventory.TransactionNo,
        refNo: inventory.hasOwnProperty("RefNo") ? inventory.RefNo : "",
        selectedVendor: inventory.Vendor,
        storeLocationID: inventory.hasOwnProperty("StoreLocationID")
          ? inventory.StoreLocationID
          : "",
        storeLocation: inventory.hasOwnProperty("StoreLocation")
          ? inventory.StoreLocation
          : "",
        toStoreID: inventory.hasOwnProperty("ToStoreID")
          ? inventory.ToStoreID
          : "",
        toStore: inventory.hasOwnProperty("ToStore") ? inventory.ToStore : "",
        billLineItemTotal: inventory.hasOwnProperty("BillLineItemTotal")
          ? inventory.BillLineItemTotal
          : inventory.BillAmount,
        billRoundOff: inventory.hasOwnProperty("BillRoundOff")
          ? inventory.BillRoundOff
          : 0,
        billAdditionalCharges: inventory.hasOwnProperty("BillAdditionalCharges")
          ? inventory.BillAdditionalCharges
          : 0,
        billAdditionalDiscount: inventory.hasOwnProperty(
          "BillAdditionalDiscount"
        )
          ? inventory.BillAdditionalDiscount
          : 0,
        toLocation: inventory.hasOwnProperty("ToLocation")
          ? inventory.ToLocation
          : "",
        toLocationID: inventory.hasOwnProperty("ToLocationID")
          ? inventory.ToLocationID
          : "",
        lineItems: inventory.LineItems,
        parentTransactionNo: inventory.hasOwnProperty("ParentTransactionNo")
          ? inventory.ParentTransactionNo
          : "",
        parentTransactionKey: inventory.hasOwnProperty("ParentTransactionKey")
          ? inventory.ParentTransactionKey
          : "",
        parentTransactions: inventory.hasOwnProperty("ParentTransactions")
          ? inventory.ParentTransactions
          : [],
        childTransactionNo: inventory.hasOwnProperty("ChildTransactionNo")
          ? inventory.ChildTransactionNo
          : "",
        childTransactionKey: inventory.hasOwnProperty("ChildTransactionKey")
          ? inventory.ChildTransactionKey
          : "",
        showParkTransactionButton: true,
      });
    } else if (this.props.location.state.type === actionTypes.INVENTORY_EDIT) {
      let inventory = this.props.location.state.inventory;

      let preCondition = await this.props.inventoryPreConditions(
        this.props.registration.registration,
        inventory.Store,
        inventory.TransactionType
      );
      if (!preCondition.Status) {
        alert(preCondition.Message);
        this.props.history.push({
          pathname: "/home/inventory/inventorylist/",
          state: { type: "list" },
        });
        return;
      }

      // if(inventory.Store && inventory.Store.key !== this.props.user.store.key){
      //     alert("Please login to correct store");
      //     this.onCancel();
      //     return;
      // }
      let editLineItems = [];
      inventory.LineItems.map((m) => {
        editLineItems.push({ ...m });
      });

      storeLocationsTmp = await this.props.getStoreLocations(
        inventory.Store.key
      );
      storeLocations = storeLocationsTmp.filter((f) => !f.IsDeleted);

      transactionType = inventory.TransactionType;

      this.setState({
        key: this.props.location.state.key,
        // transactionType: inventory.TransactionType,
        transactionDate: new Date(inventory.TransactionDate.seconds * 1000)
          .toISOString()
          .split("T")[0],
        notes: inventory.Notes,
        billQty: inventory.BillQty,
        billLineItemQty: inventory.hasOwnProperty("BillLineItemQty")
          ? inventory.BillLineItemQty
          : 0,
        billFreeQty: inventory.hasOwnProperty("BillFreeQty")
          ? inventory.BillFreeQty
          : 0,
        editStore: inventory.Store,
        storeLocations: storeLocations,
        billSubTotal: inventory.BillSubTotal,
        billAmount: inventory.BillAmount,
        billTaxAmount: inventory.BillTaxAmount,
        billDiscount: inventory.BillDiscount,
        taxSummaryList: inventory.TaxSummaryList,
        placeOfSupply: inventory.PlaceOfSupply,
        transactionNo: inventory.TransactionNo,
        refNo: inventory.hasOwnProperty("RefNo") ? inventory.RefNo : "",
        selectedVendor: inventory.Vendor,
        storeLocationID: inventory.hasOwnProperty("StoreLocationID")
          ? inventory.StoreLocationID
          : "",
        storeLocation: inventory.hasOwnProperty("StoreLocation")
          ? inventory.StoreLocation
          : "",
        toStoreID: inventory.hasOwnProperty("ToStoreID")
          ? inventory.ToStoreID
          : "",
        toStore: inventory.hasOwnProperty("ToStore") ? inventory.ToStore : "",
        billLineItemTotal: inventory.hasOwnProperty("BillLineItemTotal")
          ? inventory.BillLineItemTotal
          : inventory.BillAmount,
        billRoundOff: inventory.hasOwnProperty("BillRoundOff")
          ? inventory.BillRoundOff
          : 0,
        billAdditionalCharges: inventory.hasOwnProperty("BillAdditionalCharges")
          ? inventory.BillAdditionalCharges
          : 0,
        billAdditionalDiscount: inventory.hasOwnProperty(
          "BillAdditionalDiscount"
        )
          ? inventory.BillAdditionalDiscount
          : 0,
        toLocation: inventory.hasOwnProperty("ToLocation")
          ? inventory.ToLocation
          : "",
        toLocationID: inventory.hasOwnProperty("ToLocationID")
          ? inventory.ToLocationID
          : "",
        lineItems: inventory.LineItems,
        editLineItems: editLineItems,
        showParkTransactionButton: false,
        parentTransactionNo: inventory.hasOwnProperty("ParentTransactionNo")
          ? inventory.ParentTransactionNo
          : "",
        parentTransactionKey: inventory.hasOwnProperty("ParentTransactionKey")
          ? inventory.ParentTransactionKey
          : "",
        parentTransactions: inventory.hasOwnProperty("ParentTransactions")
          ? inventory.ParentTransactions
          : [],
        childTransactionNo: inventory.hasOwnProperty("ChildTransactionNo")
          ? inventory.ChildTransactionNo
          : "",
        childTransactionKey: inventory.hasOwnProperty("ChildTransactionKey")
          ? inventory.ChildTransactionKey
          : "",
      });
      console.log("inventory", inventory);
    }

    let storeListResult = await this.props.getStores(
      this.props.user.user.RegistrationID,
      0,
      10000,
      "active",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    storeList = storeListResult.searchResult.filter(
      (s) => s.key !== this.props.user.store.key
    );
    if (
      transactionType.TransactionMovement === "storetostore" &&
      transactionType.hasOwnProperty("InventoryToStore") &&
      transactionType.InventoryToStore !== ""
    ) {
      console.log("to store selection", transactionType.InventoryToStore);
      storeList = storeListResult.searchResult.filter(
        (s) => s.key === transactionType.InventoryToStore
      );
    }

    this.setState({
      loading: false,
      toStoreList: storeList,
      transactionType: transactionType,
    });
  }

  async onStoreLocationChange(value) {
    let storeLocation = "";
    if (
      this.state.storeLocations.filter((e) => !e.IsDeleted && e.key === value)
        .length > 0
    ) {
      let storeLocationTmp = this.state.storeLocations.filter(
        (e) => !e.IsDeleted && e.key === value
      )[0];
      storeLocation =
        storeLocationTmp.LocationCode + " " + storeLocationTmp.LocationName;
    }
    this.setState({
      storeLocationID: value,
      storeLocation: storeLocation,
      storeLocationError: "",
    });
    if (this.state.storeLocationID !== value) {
      //refresh instock qty
      let lineItems = this.state.lineItems;
      for (let i = 0; i < lineItems.length; i++) {
        lineItems[i].InStockQty = await this.props.getProductInStockQty(
          this.props.user.store.key,
          value,
          lineItems[i].Product.key
        );
      }
      this.setState({ lineItems: lineItems });
    }
  }

  async onProductScan(code) {
    let result = await this.props.getProductsByBarCode(
      this.props.registration.registration.key,
      0,
      50,
      code
    );
    // console.log("result", result);
    let finalList = [];
    result.searchResult.map((m) => {
      if (m.SKU === code || m.BarCode === code) {
        finalList.push(m);
      }
    });
    if (finalList.length === 1) {
      let e = finalList[0];
      if (e.hasOwnProperty("StoreSpecificPricing")) {
        if (e.StoreSpecificPricing) {
          let storePrice = e.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );

          if (storePrice.length > 0) {
            e.RetailPrice = storePrice[0].RetailPrice;
            e.SupplyPrice = storePrice[0].SupplyPrice;
            e.Markup = storePrice[0].Markup;
            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            e.MRP = storePrice[0].MRP;
          }
        }
      }
      this.onProductSelected(e);
    } else if (finalList.length > 1) {
      for (let i = 0; i < finalList.length; i++) {
        let e = finalList[i];
        if (e.hasOwnProperty("StoreSpecificPricing")) {
          if (e.StoreSpecificPricing) {
            let storePrice = e.StoreSpecificPrices.filter(
              (f) => f.StoreID === this.props.user.store.key
            );

            if (storePrice.length > 0) {
              e.RetailPrice = storePrice[0].RetailPrice;
              e.SupplyPrice = storePrice[0].SupplyPrice;
              e.Markup = storePrice[0].Markup;
              e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                "WalletTopUpSameAsRetailPrice"
              )
                ? storePrice[0].WalletTopUpSameAsRetailPrice
                : false;
              e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                "WalletTopUpAmount"
              )
                ? storePrice[0].WalletTopUpAmount
                : 0;
              e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                "EditWalletTopUpAmount"
              )
                ? storePrice[0].EditWalletTopUpAmount
                : false;
              e.MRP = storePrice[0].MRP;
            }
          }
        }
        finalList[i] = e;
      }
      this.setState({
        showProductSelection: true,
        showProductSelectionList: finalList,
        queryText: "",
      });
      // return;
    } else {
      alert("No product found");
    }
    this.setState({ queryText: "" });
  }

  onToStoreLocationChange(value) {
    let storeLocation = "";
    if (
      this.state.storeLocations.filter((e) => !e.IsDeleted && e.key === value)
        .length > 0
    ) {
      let storeLocationTmp = this.state.storeLocations.filter(
        (e) => !e.IsDeleted && e.key === value
      )[0];
      storeLocation =
        storeLocationTmp.LocationCode + " " + storeLocationTmp.LocationName;
    }
    this.setState({
      toLocationID: value,
      toLocation: storeLocation,
      toLocationError: "",
    });
  }

  onStoreChange(value) {
    let placeOfSupply = "";
    let toStore = "";
    if (value !== "") {
      let tmp = this.state.toStoreList.filter((f) => f.key === value)[0];
      toStore = tmp.StoreCode + " " + tmp.StoreName;
      placeOfSupply = tmp.State;
    }
    this.setState({
      toStoreID: value,
      toStore: toStore,
      toStoreError: "",
      placeOfSupply: placeOfSupply, //this.state.toStoreList.filter((f) => f.key === e.target.value)[0].State
    });
    let lineItems = this.state.lineItems;
    for (let i = 0; i < lineItems.length; i++) {
      lineItems[i] = this.onLineItemChange(
        lineItems[i],
        placeOfSupply,
        true,
        this.state.transactionType
      );
    }
    this.setState({ lineItems: lineItems, computeTax: true });
    this.calculateBillTotal(
      lineItems,
      this.state.billRoundOff,
      this.state.billAdditionalCharges,
      this.state.billAdditionalDiscount,
      this.state.transactionType
    );
  }

  onProductBarCodeSearch(e) {
    if (e.key === "Enter") {
      this.productBarCodeSearch(this.state.queryText);
      this.setState({ queryText: "" });
    }
  }

  productBarCodeSearch(scanedCode) {
    var productList = this.props.config.Products.filter(
      (e) =>
        (e.SKU && e.SKU.toLowerCase() === scanedCode.toLowerCase()) ||
        (e.BarCode && e.BarCode.toLowerCase() === scanedCode.toLowerCase())
    );

    if (productList.length > 1) {
      console.log("more tha one product for selection");
      this.setState({
        showProductSelection: true,
        showProductSelectionList: productList,
      });
      return;
    }

    let e = productList[0];

    if (e) {
      if (e.hasOwnProperty("AccessibleToSelectedStores")) {
        if (e.AccessibleToSelectedStores) {
          let storePrice = e.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );
          if (storePrice.length > 0) {
            e.RetailPrice = storePrice[0].RetailPrice;
            e.SupplyPrice = storePrice[0].SupplyPrice;
            e.Markup = storePrice[0].Markup;
            e.MRP = storePrice[0].MRP;
            e.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
              ? storePrice[0].DiscountAmount
              : 0;
            e.DiscountPercentage = storePrice[0].hasOwnProperty(
              "DiscountPercentage"
            )
              ? storePrice[0].DiscountPercentage
              : 0;
            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
          }
        }
      }
      if (e.hasOwnProperty("StoreSpecificPricing")) {
        if (e.StoreSpecificPricing) {
          let storePrice = e.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );

          if (storePrice.length > 0) {
            e.RetailPrice = storePrice[0].RetailPrice;
            e.SupplyPrice = storePrice[0].SupplyPrice;
            e.Markup = storePrice[0].Markup;
            e.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
              ? storePrice[0].DiscountAmount
              : 0;
            e.DiscountPercentage = storePrice[0].hasOwnProperty(
              "DiscountPercentage"
            )
              ? storePrice[0].DiscountPercentage
              : 0;
            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            e.MRP = storePrice[0].MRP;
          }
        }
      }
      this.setState({ queryText: "" });
      this.onProductSelected(e);
    } else {
      this.props
        .getInwardUniqueList(0, 100, this.props.user.store.key, "", scanedCode)
        .then((result) => {
          if (result.searchResult && result.searchResult.length > 0) {
            let productID = result.searchResult[0].ProductID;
            let uniqueList = [];
            //check if thi product is already added
            if (
              this.state.lineItems.filter((f) => f.Product.key === productID)
                .length > 0
            ) {
              let l = this.state.lineItems.filter(
                (f) => f.Product.key === productID
              )[0];
              if (
                l.hasOwnProperty("UniqueNoList") &&
                l.UniqueNoList.filter(
                  (f) =>
                    f.UniqueNo1 === result.searchResult[0].UniqueNo1 &&
                    f.UniqueNo2 === result.searchResult[0].UniqueNo2 &&
                    f.UniqueNo3 === result.searchResult[0].UniqueNo3 &&
                    f.UniqueNo3 === result.searchResult[0].UniqueNo4
                ).length > 0
              ) {
                alert("Unique no already added to transaction");
                return;
              } else {
                //
                uniqueList = l.UniqueNoList;
              }
            }
            var productList = this.props.config.Products.filter(
              (e) => e.key === productID
            );
            var e = productList[0];
            if (e.hasOwnProperty("AccessibleToSelectedStores")) {
              if (e.AccessibleToSelectedStores) {
                let storePrice = e.StoreSpecificPrices.filter(
                  (f) => f.StoreID === this.props.user.store.key
                );
                if (storePrice.length > 0) {
                  e.RetailPrice = storePrice[0].RetailPrice;
                  e.SupplyPrice = storePrice[0].SupplyPrice;
                  e.Markup = storePrice[0].Markup;
                  e.MRP = storePrice[0].MRP;
                  e.DiscountAmount = storePrice[0].hasOwnProperty(
                    "DiscountAmount"
                  )
                    ? storePrice[0].DiscountAmount
                    : 0;
                  e.DiscountPercentage = storePrice[0].hasOwnProperty(
                    "DiscountPercentage"
                  )
                    ? storePrice[0].DiscountPercentage
                    : 0;
                  e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                    "WalletTopUpSameAsRetailPrice"
                  )
                    ? storePrice[0].WalletTopUpSameAsRetailPrice
                    : false;
                  e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                    "WalletTopUpAmount"
                  )
                    ? storePrice[0].WalletTopUpAmount
                    : 0;
                  e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                    "EditWalletTopUpAmount"
                  )
                    ? storePrice[0].EditWalletTopUpAmount
                    : false;
                }
              }
            }
            if (e.hasOwnProperty("StoreSpecificPricing")) {
              if (e.StoreSpecificPricing) {
                let storePrice = e.StoreSpecificPrices.filter(
                  (f) => f.StoreID === this.props.user.store.key
                );

                if (storePrice.length > 0) {
                  e.RetailPrice = storePrice[0].RetailPrice;
                  e.SupplyPrice = storePrice[0].SupplyPrice;
                  e.Markup = storePrice[0].Markup;
                  e.DiscountAmount = storePrice[0].hasOwnProperty(
                    "DiscountAmount"
                  )
                    ? storePrice[0].DiscountAmount
                    : 0;
                  e.DiscountPercentage = storePrice[0].hasOwnProperty(
                    "DiscountPercentage"
                  )
                    ? storePrice[0].DiscountPercentage
                    : 0;
                  e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                    "WalletTopUpSameAsRetailPrice"
                  )
                    ? storePrice[0].WalletTopUpSameAsRetailPrice
                    : false;
                  e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                    "WalletTopUpAmount"
                  )
                    ? storePrice[0].WalletTopUpAmount
                    : 0;
                  e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                    "EditWalletTopUpAmount"
                  )
                    ? storePrice[0].EditWalletTopUpAmount
                    : false;
                  e.MRP = storePrice[0].MRP;
                }
              }
            }
            uniqueList.push(result.searchResult[0]);
            this.productSelected(e, uniqueList);
          } else {
            this.setState({
              showAddProduct: true,
              showInventory: false,
              scanedCode: scanedCode,
            });
          }
        });
    }
  }

  onProductTypeAheadSearch = (query) => {
    let excludeUniqueNo =
      this.state.transactionType.TransactionType.toLowerCase() ===
      "stock update"
        ? true
        : false;
    this.setState({ isProductTypeAheadLoading: true });
    this.props
      .getProductsForInventorySearch(
        this.props.user.user.RegistrationID,
        query,
        excludeUniqueNo
      )
      .then((result) => {
        // console.log('onProductTypeAheadSearch result received', result)
        let productSearchResult = [];
        let rowCount = 0;
        for (let i = 0; i < result.searchResult.length; i++) {
          if (rowCount > 10) {
            break;
          }
          let e = result.searchResult[i];

          if (this.state.vendorProducts.length > 0) {
            if (
              this.state.vendorProducts.filter(
                (f) =>
                  f.ProductID === e.key &&
                  (f.StoreID === "" || f.StoreID == this.props.user.store.key)
              ).length > 0
            ) {
              productSearchResult.push(e);
              rowCount = rowCount + 1;
              continue;
            } else {
              continue;
            }
          }
          // if(e.)
          if (e.hasOwnProperty("AccessibleToSelectedStores")) {
            if (e.AccessibleToSelectedStores) {
              let storePrice = e.StoreSpecificPrices.filter(
                (f) => f.StoreID === this.props.user.store.key
              );
              if (storePrice.length > 0) {
                e.RetailPrice = storePrice[0].RetailPrice;
                e.SupplyPrice = storePrice[0].SupplyPrice;
                e.Markup = storePrice[0].Markup;
                e.MRP = storePrice[0].MRP;
                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                  "WalletTopUpSameAsRetailPrice"
                )
                  ? storePrice[0].WalletTopUpSameAsRetailPrice
                  : false;
                e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                  "WalletTopUpAmount"
                )
                  ? storePrice[0].WalletTopUpAmount
                  : 0;
                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                  "EditWalletTopUpAmount"
                )
                  ? storePrice[0].EditWalletTopUpAmount
                  : false;
                productSearchResult.push(e);
                rowCount = rowCount + 1;
              }
              continue;
            }
          }
          if (e.hasOwnProperty("StoreSpecificPricing")) {
            if (e.StoreSpecificPricing) {
              let storePrice = e.StoreSpecificPrices.filter(
                (f) => f.StoreID === this.props.user.store.key
              );

              if (storePrice.length > 0) {
                e.RetailPrice = storePrice[0].RetailPrice;
                e.SupplyPrice = storePrice[0].SupplyPrice;
                e.Markup = storePrice[0].Markup;
                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                  "WalletTopUpSameAsRetailPrice"
                )
                  ? storePrice[0].WalletTopUpSameAsRetailPrice
                  : false;
                e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                  "WalletTopUpAmount"
                )
                  ? storePrice[0].WalletTopUpAmount
                  : 0;
                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                  "EditWalletTopUpAmount"
                )
                  ? storePrice[0].EditWalletTopUpAmount
                  : false;
                e.MRP = storePrice[0].MRP;
              }
            }
          }
          rowCount = rowCount + 1;
          productSearchResult.push(e);
        }
        this.setState({ productsTypeAheadOptions: productSearchResult });
        this.setState({ isProductTypeAheadLoading: false });
        // console.log('onProductTypeAheadSearch end')
      });
  };

  async onProductSelected(product) {
    // console.log("onProductSelected.product", product);
    if (this.state.bulkPurchaseReturn && !this.state.selectedVendor) {
      alert("Please select vendor first");
      return;
    }

    //check if it is converted...if conveterd then check if the product belons to parent line items
    if (this.state.converted) {
      if (
        this.state.parentTransactionProductIDs.filter(
          (f) => f.key === product.key
        ).length === 0
      ) {
        ///product not part of parent transactions so can not be added
        alert(
          "Selected product not found in parent transaction. Please select another product."
        );
        return;
      }
    }
    if (
      this.props.config.Products.filter(
        (f) => f.ParentProductID === product.key
      ).length > 0
    ) {
      this.setState({
        showVariants: true,
        selectedVariantProduct: product,
        selectedLineItem: null,
      });
      return;
    }
    if (
      this.state.transactionType.UpdateStockChecked &&
      this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
        "outward" &&
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "unique no"
    ) {
      //give option to select unique id
      this.setState({ showUniqueNoSelection: true, selectedProduct: product });
      return;
    }

    if (
      this.state.transactionType.UpdateStockChecked &&
      this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
        "inward" &&
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "unique no"
    ) {
      //check if product already elected
      let lineItem = null;
      let lineItemIndex = -1;
      this.state.lineItems.map((l, index) => {
        if (l.Product.key === product.key) {
          //increase qty
          lineItem = l;
          lineItemIndex = index;
          // return
        }
      });
      this.setState({
        showUniqueNoInput: true,
        selectedProduct: product,
        selectedLineItem: lineItem,
      });
      return;
    }

    //batch
    if (
      this.state.transactionType.UpdateStockChecked &&
      this.state.transactionType.hasOwnProperty("UpdateStockInwardOrOutward") &&
      this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
        "outward" &&
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "batch tracking"
    ) {
      //show batch list
      this.setState({
        showBatchsForSelection: true,
        selectedProduct: product,
        selectedLineItem: null,
      });
      return;
    }

    if (
      this.state.transactionType.UpdateStockChecked &&
      this.state.transactionType.hasOwnProperty("UpdateStockInwardOrOutward") &&
      this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
        "inward" &&
      this.state.transactionType.TransactionType.toLowerCase() ===
        "stock update" &&
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "batch tracking"
    ) {
      //show batch list
      this.setState({
        showBatchsForSelection: true,
        selectedProduct: product,
        selectedLineItem: null,
      });
      return;
    }
    // console.log('onProductSelected completed')
    this.productSelected(product, null);
  }

  async productSelected(
    productPara,
    uniqueNoList,
    batch = null,
    varSelectedLineItemIndex
  ) {
    let lineItemIndex = -1;
    let lineItem = {};
    let selectedLineItemIndex = -1;
    let selectedLineItem = null;
    let supplyPrice = 0;
    let discount = 0;
    let showLineItemModal = false;
    let getInstockQty = true;
    let product = { ...productPara };
    let batchSupplyPrice = 0;
    let increaseQty = true; ///used if batch product from a converted transaction is getting adssgined a batch
    // //check if product is already added
    // console.log("batch", batch);
    this.state.lineItems.map((l, index) => {
      if (l.Product.key === product.key) {
        //increase qty
        lineItem = l;
        lineItemIndex = index;
        return;
      }
    });

    if (
      product.hasOwnProperty("InventoryType") &&
      this.state.transactionType.UpdateStockChecked &&
      this.state.transactionType.hasOwnProperty("UpdateStockInwardOrOutward") &&
      this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
        "inward" &&
      this.state.transactionType.TransactionType.toLowerCase() ===
        "stock update" &&
      product.InventoryType.toLowerCase() === "batch tracking"
    ) {
      if (!batch) {
        return;
      }
      lineItem = {};
      lineItem.BatchID = batch.key;
      lineItem.BatchNo = batch.BatchNo;
      lineItem.BatchMRP = batch.BatchMRP;
      lineItem.BatchRetailPrice = batch.BatchRetailPrice;
      lineItem.BatchMfgDate = batch.BatchMfgDate;
      lineItem.BatchExpDate = batch.BatchExpDate;
      lineItem.BatchMfgDateValue = batch.BatchMfgDateValue;
      lineItem.BatchExpDateValue = batch.BatchExpDateValue;
      lineItem.InStockQty = batch.Qty;
      if (product.BatchTrackingMRPRequired) {
        product.MRP = batch.BatchMRP;
        lineItem.RetailPrice = batch.BatchRetailPrice;
      }
      lineItemIndex = -1;
      showLineItemModal = false;
      getInstockQty = false;
    }

    if (
      product.hasOwnProperty("InventoryType") &&
      this.state.transactionType.UpdateStockChecked &&
      this.state.transactionType.hasOwnProperty("UpdateStockInwardOrOutward") &&
      this.state.transactionType.TransactionType.toLowerCase() !==
        "stock update" &&
      this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
        "inward" &&
      product.InventoryType.toLowerCase() === "batch tracking"
    ) {
      // console.log('batch tracking')
      lineItem = {};
      lineItem.BatchNo = "";
      lineItem.BatchMRP = 0;
      lineItem.BatchRetailPrice = 0;
      lineItem.BatchMfgDate = "";
      lineItem.BatchExpDate = "";
      lineItem.BatchMfgDateValue = null;
      lineItem.BatchExpDateValue = null;

      if (
        product.hasOwnProperty("BatchTrackingMRPRequired") &&
        product.BatchTrackingMRPRequired
      ) {
        lineItem.BatchMRP = product.MRP;
        lineItem.BatchRetailPrice = product.RetailPrice;
      }
      lineItemIndex = -1;
      showLineItemModal = true;
    }

    if (
      product.hasOwnProperty("InventoryType") &&
      this.state.transactionType.UpdateStockChecked &&
      this.state.transactionType.hasOwnProperty("UpdateStockInwardOrOutward") &&
      this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
        "outward" &&
      product.InventoryType.toLowerCase() === "batch tracking"
    ) {
      if (!batch) {
        return;
      }
      if (
        varSelectedLineItemIndex > -1 &&
        varSelectedLineItemIndex < this.state.lineItems.length
      ) {
        lineItemIndex = varSelectedLineItemIndex;
        lineItem = this.state.selectedLineItem;
        increaseQty = false;
        lineItem.Error = "";
        // console.log("lineitemindex found", lineItemIndex);
      } else {
        lineItemIndex = -1;
        lineItem = {};
        // console.log("lineitemindex not found", lineItemIndex);
      }

      lineItem.BatchID = batch.key;
      lineItem.BatchNo = batch.BatchNo;
      lineItem.BatchMRP = batch.BatchMRP;
      lineItem.BatchRetailPrice = batch.BatchRetailPrice;
      lineItem.BatchMfgDate = batch.BatchMfgDate;
      lineItem.BatchExpDate = batch.BatchExpDate;
      lineItem.BatchMfgDateValue = batch.BatchMfgDateValue;
      lineItem.BatchExpDateValue = batch.BatchExpDateValue;
      lineItem.InStockQty = batch.Qty;
      batchSupplyPrice = batch.hasOwnProperty("SupplyPrice")
        ? batch.SupplyPrice
        : 0;
      if (product.BatchTrackingMRPRequired) {
        product.MRP = batch.BatchMRP;
        lineItem.RetailPrice = batch.BatchRetailPrice;
      }
      showLineItemModal = false;
      getInstockQty = false;
    }

    if (this.state.vendorProducts.length > 0) {
      if (
        this.state.vendorProducts.filter(
          (f) =>
            f.ProductID === product.key &&
            f.StoreID == this.props.user.store.key
        ).length > 0
      ) {
        supplyPrice = this.state.vendorProducts.filter(
          (f) =>
            f.ProductID === product.key &&
            f.StoreID == this.props.user.store.key
        )[0].SupplyPrice;
        discount = this.state.vendorProducts.filter(
          (f) =>
            f.ProductID === product.key &&
            f.StoreID == this.props.user.store.key
        )[0].Discount;
      } else if (
        this.state.vendorProducts.filter(
          (f) => f.ProductID === product.key && f.StoreID === ""
        ).length > 0
      ) {
        supplyPrice = this.state.vendorProducts.filter(
          (f) => f.ProductID === product.key && f.StoreID === ""
        )[0].SupplyPrice;
        discount = this.state.vendorProducts.filter(
          (f) => f.ProductID === product.key && f.StoreID === ""
        )[0].Discount;
      }
    } else {
      if (batchSupplyPrice > 0) {
        supplyPrice = batchSupplyPrice;
      } else {
        supplyPrice = product.SupplyPrice;
      }
      discount = 0;
    }
    if (lineItemIndex === -1) {
      if (this.state.bulkPurchaseReturn) {
        let peList = await this.props.getPurchaseEntryForPurchaseReturn(
          this.props.user.store.key,
          this.state.selectedVendor.VendorCode,
          product.key
        );
        if (peList.length > 0) {
          lineItem.ParentTransactionNo = peList[0].TransactionNo;
          lineItem.ParentTransactionKey = peList[0].TransactionID;
          supplyPrice = peList[0].SupplyPrice;
          let bulkPurchaseReturns = this.state.bulkPurchaseReturns;
          if (
            bulkPurchaseReturns.filter(
              (f) => f.TransactionID === peList[0].TransactionID
            ).length === 0
          ) {
            bulkPurchaseReturns.push({
              TransactionNo: peList[0].TransactionNo,
              TransactionID: peList[0].TransactionID,
            });
            this.setState({ bulkPurchaseReturns: bulkPurchaseReturns });
          }
        } else {
          lineItem.ParentTransactionNo = "";
          lineItem.ParentTransactionKey = "";
        }
      }
      lineItem.LineItemQty = 1;
      lineItem.FreeQty = 0;
      lineItem.Qty = 1;
      lineItem.SupplyPrice = supplyPrice;
      if (product.hasOwnProperty("PurchaseUOM") && product.PurchaseUOM !== "") {
        lineItem.UOM = product.PurchaseUOM;
      } else if (product.hasOwnProperty("UOM") && product.UOM !== "") {
        lineItem.UOM = product.UOM;
      }
      lineItem.SubTotal = 0;
      lineItem.ChargeTaxOnProduct = product.ChargeTaxOnProduct;
      lineItem.PriceBasedTax = product.ChargeTaxOnProduct
        ? product.PriceBasedTax
        : false;
      lineItem.HSNSACCode = product.HSNSACCode;
      if (product.ChargeTaxOnProduct) {
        if (product.hasOwnProperty("TaxInclusivePurchase")) {
          lineItem.TaxInclusive = product.TaxInclusivePurchase;
        } else {
          lineItem.TaxInclusive = product.TaxInclusive;
        }
      } else {
        lineItem.TaxInclusive = false;
      }
      // lineItem.TaxInclusive = product.ChargeTaxOnProduct ? product.TaxInclusive : false
      lineItem.TaxGroup = product.TaxGroup;
      lineItem.TaxID = product.TaxID;
      lineItem.DiscountPer = 0;
      lineItem.DiscountAmount = discount;
      lineItem.SubTotalWithDiscount = 0;
      lineItem.TaxableAmount = 0;
      lineItem.TotalTax = 0;
      lineItem.Amount = 0;
      lineItem.Product = product;
      lineItem.Notes = "";
      if (uniqueNoList && uniqueNoList.length > 0) {
        lineItem.UniqueNoList = uniqueNoList;
        lineItem.LineItemQty = uniqueNoList.length;
        lineItem.Qty = lineItem.LineItemQty;
        lineItem.FreeQty = 0;
      }
    } else {
      if (uniqueNoList && uniqueNoList.length > 0) {
        lineItem.UniqueNoList = uniqueNoList;
        lineItem.LineItemQty = uniqueNoList.length;
        lineItem.Qty = lineItem.LineItemQty;
        lineItem.FreeQty = 0;
      } else {
        if (increaseQty) {
          lineItem.LineItemQty =
            Number(lineItem.LineItemQty) + 1 + Number(lineItem.FreeQty);
        } else {
          lineItem.LineItemQty =
            Number(lineItem.LineItemQty) + Number(lineItem.FreeQty);
        }
        lineItem.Qty = lineItem.LineItemQty;
      }
    }
    if (getInstockQty) {
      lineItem.InStockQty = 0;
      lineItem.InStockQty = await this.props.getProductInStockQty(
        this.props.user.store.key,
        this.state.storeLocationID,
        product.key
      );
    }
    // if(result && result.searchResult && result.searchResult.length > 0){
    //     lineItem.InStockQty = result.searchResult[0].InStockQty;
    //     console.log("lineItem.InStockQty",lineItem.InStockQty)
    // }

    // console.log("product", JSON.stringify(product));
    if (
      this.props.registration.registration.EnablePosBillingForPincode &&
      product.CustomField2 === "GC" &&
      product.CustomField4 === ""
    ) {
      //get code from gc
      let result = await searchPincodeGlobalCatalog(product.BarCode, "");
      // console.log("result", JSON.stringify(result));
      if (result && result.length > 0) {
        let gcprods = result.filter((f) => f.Code === product.BarCode);
        if (gcprods.length > 0 && gcprods[0].Type === "Barcode") {
          let productPackagingQtyGC = gcprods[0].Quantity;
          let productPackagingQtyUOM = gcprods[0].UOM;
          lineItem.ProductPackagingQtyCheck = true;
          lineItem.ProductPackagingQtyGC = productPackagingQtyGC;
          lineItem.ProductPackagingQty = "";
          lineItem.ProductPackagingQtyUOM = productPackagingQtyUOM;
          console.log("lineItem", JSON.stringify(lineItem));
        }
      }
    }

    lineItem = await this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    //lineItem.Error=""

    let lineItems = this.state.lineItems;
    if (lineItemIndex >= 0) {
      lineItems[lineItemIndex] = lineItem;
    } else {
      lineItems.unshift(lineItem);
      selectedLineItem = { ...lineItem };
      selectedLineItemIndex = 0; //2Dec
    }
    // console.log('selectedLineItemIndex', selectedLineItemIndex)

    this.setState({
      lineItems: lineItems,
      selectedLineItem: selectedLineItem,
      selectedLineItemIndex: selectedLineItemIndex,
      showLineItemModal: showLineItemModal,
      noLineItemExistError: "",
    });
    this.calculateBillTotal(
      lineItems,
      this.state.billRoundOff,
      this.state.billAdditionalCharges,
      this.state.billAdditionalDiscount,
      this.state.transactionType
    );
    // console.log('productSelected')
  }

  async onComputeTaxChange(computeTax) {
    let lineItems = this.state.lineItems;
    for (let i = 0; i < lineItems.length; i++) {
      lineItems[i] = this.onLineItemChange(
        lineItems[i],
        this.state.placeOfSupply,
        computeTax,
        this.state.transactionType
      );
    }
    this.setState({ lineItems: lineItems, computeTax: computeTax });
    this.calculateBillTotal(
      lineItems,
      this.state.billRoundOff,
      this.state.billAdditionalCharges,
      this.state.billAdditionalDiscount,
      this.state.transactionType
    );
  }

  onLineItemChange(
    lineItem,
    placeOfSupply,
    computeTax = true,
    transactionType
  ) {
    let includePriceAndTax = false;
    if (transactionType.hasOwnProperty("IncludePriceAndTax")) {
      includePriceAndTax = transactionType.IncludePriceAndTax;
    }
    if (!includePriceAndTax) {
      lineItem.SubTotal = 0;
      lineItem.ChargeTaxOnProduct = false;
      lineItem.TaxInclusive = false;
      lineItem.DiscountPer = 0;
      lineItem.DiscountAmount = 0;
      lineItem.SubTotalWithDiscount = 0;
      lineItem.TaxableAmount = 0;
      lineItem.TaxComponentAmount = [];
      lineItem.TotalTax = 0;
      lineItem.Amount = 0;
      return lineItem;
    }

    let subTotal = roundUpTo2(
      Number(lineItem.SupplyPrice) * Number(lineItem.LineItemQty)
    );
    let discountAmt = 0;
    let discountPer = 0;

    if (subTotal > 0) {
      if (
        Number(lineItem.DiscountAmount) > 0 &&
        Number(lineItem.DiscountPer) === 0
      ) {
        //calculate discount per based on discount amount

        discountPer = Number(
          ((Number(lineItem.DiscountAmount) * 100) / Number(subTotal)).toFixed(
            3
          )
        );
        discountAmt = roundUpTo2(lineItem.DiscountAmount);
      } else {
        discountPer = Number(lineItem.DiscountPer);
        discountAmt = roundUpTo2(
          (Number(subTotal) * Number(lineItem.DiscountPer)) / 100
        );
      }
    }

    let subTotalWithDiscount = roundUpTo2(
      Number(subTotal) - Number(discountAmt)
    );
    let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct;
    let priceBasedTax = lineItem.PriceBasedTax;
    let taxInclusive = lineItem.TaxInclusive;
    let taxableAmount = 0;
    let totalTax = 0;
    let amount = 0;
    let taxComponentAmount = [];
    let supplyPriceWithoutTax = 0; //TBD

    if (chargeTaxOnProduct && computeTax) {
      if (priceBasedTax) {
        let unitPriceAfterDiscount = roundUpTo2(
          Number(subTotalWithDiscount) / Number(lineItem.LineItemQty)
        );
        //console.log('unitPriceAfterDiscount',unitPriceAfterDiscount)
        //console.log('lineItem.Product.TaxPriceRange',lineItem.Product.TaxPriceRange)
        lineItem.Product.TaxPriceRange.map((t) => {
          if (
            Number(unitPriceAfterDiscount) >= Number(t.FromPrice) &&
            Number(unitPriceAfterDiscount) <= Number(t.ToPrice)
          ) {
            lineItem.TaxID = t.TaxID;
            lineItem.TaxGroup = t.TaxGroup;
            return;
          }
        });
      }
      let taxfound = false;
      let customerFromSameState = true; //TBD IMP IMP

      if (placeOfSupply !== "" && this.props.user.store.State !== "") {
        if (
          placeOfSupply.toLowerCase() !==
          this.props.user.store.State.toLowerCase()
        ) {
          customerFromSameState = false;
        }
      }
      this.props.config.ProductTaxes.filter(
        (t) => t.key === lineItem.TaxID.trim()
      ).map((taxGroup, index) => {
        taxfound = true;
        let taxAmount = 0;
        let taxComponentTemp = [];
        taxGroup.Taxes.map((t) => {
          if (
            customerFromSameState &&
            (t.TaxType === "CGST" || t.TaxType === "SGST")
          ) {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          } else if (!customerFromSameState && t.TaxType === "IGST") {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          } else if (
            t.TaxType !== "CGST" &&
            t.TaxType !== "IGST" &&
            t.TaxType !== "SGST"
          ) {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          }
        });

        if (taxInclusive) {
          //tax inclusive
          let totalTaxRate = 0;
          taxComponentTemp.map((t) => {
            totalTaxRate = roundUpTo2(
              Number(t.TaxPercentage) + Number(totalTaxRate)
            );
          });
          totalTax = roundUpTo2(
            (Number(subTotalWithDiscount) * Number(totalTaxRate)) /
              (100 + Number(totalTaxRate))
          );
          taxableAmount = roundUpTo2(
            Number(subTotalWithDiscount) - Number(totalTax)
          );
          amount = roundUpTo2(subTotalWithDiscount);
          taxComponentTemp.map((t) => {
            taxAmount = roundUpTo2(
              (Number(totalTax) *
                ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate))) /
                100
            );
            taxComponentAmount.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: taxAmount,
            });
          });
        } else {
          taxableAmount = subTotalWithDiscount;
          taxComponentTemp.map((t) => {
            taxAmount = roundUpTo2(
              (Number(taxableAmount) * Number(t.TaxPercentage)) / 100
            );
            totalTax = roundUpTo2(Number(totalTax) + Number(taxAmount));
            taxComponentAmount.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: taxAmount,
            });
          });
          amount = roundUpTo2(taxableAmount + totalTax);
        }
      });
      //tax is not found then
      if (!taxfound) {
        //alert("Tax not found for selected product. Please check item ")
        taxableAmount = 0;
        taxComponentAmount = [];
        totalTax = 0;
        amount = subTotalWithDiscount;
      }
    } else {
      taxableAmount = 0;
      taxComponentAmount = [];
      totalTax = 0;
      amount = subTotalWithDiscount;
    }
    // lineItem.Qty = qty
    // lineItem.RetailPrice = price
    let marginPercentage = 0;
    let profitPercentage = 0;

    if (lineItem.Product.MRP > 0 && amount > 0) {
      let cp = amount / lineItem.Qty;
      // console.log('cp',cp)
      marginPercentage = ((lineItem.Product.MRP - cp) / cp) * 100;
      marginPercentage = marginPercentage.toFixed(2);
      profitPercentage =
        ((lineItem.Product.MRP - cp) / lineItem.Product.MRP) * 100;
      profitPercentage = profitPercentage.toFixed(2);
    }
    lineItem.MarginPercentage = marginPercentage;
    lineItem.ProfitPercentage = profitPercentage;
    lineItem.SubTotal = roundUpTo2(subTotal);
    lineItem.ChargeTaxOnProduct = chargeTaxOnProduct;
    lineItem.TaxInclusive = taxInclusive;
    lineItem.DiscountPer = roundUpTo2(discountPer);
    lineItem.DiscountAmount = roundUpTo2(discountAmt);
    lineItem.SubTotalWithDiscount = roundUpTo2(subTotalWithDiscount);
    lineItem.TaxableAmount = roundUpTo2(taxableAmount);
    lineItem.TaxComponentAmount = taxComponentAmount;
    lineItem.TotalTax = roundUpTo2(totalTax);
    lineItem.Amount = roundUpTo2(amount);
    return lineItem;
  }

  async onRemoveLineItem(index) {
    //if it is edit and has unique no then check if the unique nos have not be untilized in some other transaciton
    if (
      this.state.lineItems[index].hasOwnProperty("UniqueNoList") &&
      this.state.lineItems[index].UniqueNoList.length > 0 &&
      this.state.key.trim() !== ""
    ) {
      let uniqueNoList = this.state.lineItems[index].UniqueNoList;
      for (let i = 0; i < uniqueNoList.length; i++) {
        let uniqueNo = null;
        uniqueNo = await this.props.getUniqueNoIDBasedOnTransaction(
          this.props.user.store.key,
          this.state.lineItems[index].Product.key,
          uniqueNoList[i].UniqueNo1,
          uniqueNoList[i].UniqueNo2,
          uniqueNoList[i].UniqueNo3,
          uniqueNoList[i].UniqueNo4
        );
        // console.log('uniqueNoID',uniqueNo)
        if (uniqueNo && uniqueNo.TransactionID !== this.state.key.trim()) {
          alert("Unique no already used. Cannot delete line item.");
          return;
        }
      }
    }
    let lineItems = this.state.lineItems;
    lineItems.splice(index, 1);
    this.calculateBillTotal(
      lineItems,
      this.state.billRoundOff,
      this.state.billAdditionalCharges,
      this.state.billAdditionalDiscount,
      this.state.transactionType
    );
    this.setState({
      selectedLineItem: null,
      selectedLineItemIndex: -1,
    });
  }

  onLineItemPriceChange(index, price) {
    let lineItems = this.state.lineItems;
    let lineItem = this.state.lineItems[index];
    lineItem.SupplyPrice = Number(price);
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    lineItems[index] = lineItem;
    this.setState({ lineItems: lineItems });
    this.calculateBillTotal(
      lineItems,
      this.state.billRoundOff,
      this.state.billAdditionalCharges,
      this.state.billAdditionalDiscount,
      this.state.transactionType
    );
  }

  onLineItemQtyChange(index, qty) {
    if (
      this.state.converted &&
      this.state.lineItems[index].hasOwnProperty(
        "ParentTransactionRemainingQty"
      ) &&
      this.state.lineItems[index].ParentTransactionRemainingQty
    ) {
      if (this.state.lineItems[index].ParentTransactionRemainingQty < qty) {
        return;
      }
    }
    let lineItems = this.state.lineItems;
    let lineItem = this.state.lineItems[index];
    lineItem.LineItemQty = Number(qty);
    lineItem.Qty = Number(lineItem.LineItemQty) + Number(lineItem.FreeQty);
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    lineItems[index] = lineItem;
    this.setState({ lineItems: lineItems });
    this.calculateBillTotal(
      lineItems,
      this.state.billRoundOff,
      this.state.billAdditionalCharges,
      this.state.billAdditionalDiscount,
      this.state.transactionType
    );
  }

  onLineItemFreeQtyChange(index, qty) {
    let lineItems = this.state.lineItems;
    let lineItem = this.state.lineItems[index];
    lineItem.FreeQty = Number(qty);
    lineItem.Qty = Number(lineItem.LineItemQty) + Number(lineItem.FreeQty);
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    lineItems[index] = lineItem;
    this.setState({ lineItems: lineItems });
    this.calculateBillTotal(
      lineItems,
      this.state.billRoundOff,
      this.state.billAdditionalCharges,
      this.state.billAdditionalDiscount,
      this.state.transactionType
    );
  }

  calculateBillTotal(
    lineItems,
    billRoundOff,
    billAdditionalCharges,
    billAdditionalDiscount,
    transactionType
  ) {
    let calculationResult = this.calculateBillTotalSub(
      lineItems,
      billRoundOff,
      billAdditionalCharges,
      billAdditionalDiscount,
      transactionType
    );
    this.setState({
      billQty: calculationResult.billQty,
      billLineItemQty: calculationResult.billLineItemQty,
      billFreeQty: calculationResult.billFreeQty,
      billSubTotal: calculationResult.billSubTotal,
      billAmount: calculationResult.billAmount,
      billTaxAmount: calculationResult.billTaxAmount,
      billDiscount: calculationResult.billDiscount,
      taxSummaryList: calculationResult.taxSummaryList,
      billLineItemTotal: calculationResult.billLineItemTotal,
      billRoundOff: calculationResult.billRoundOff,
      billAdditionalCharges: calculationResult.billAdditionalCharges,
      billAdditionalDiscount: calculationResult.billAdditionalDiscount,
    });
  }

  calculateBillTotalSub(
    lineItems,
    billRoundOff,
    billAdditionalCharges,
    billAdditionalDiscount,
    transactionType
  ) {
    let billQty = 0;
    let billLineItemQty = 0;
    let billFreeQty = 0;
    let billAmount = 0;
    let billSubTotal = 0;
    let billTaxAmount = 0;
    let billDiscount = 0;
    let taxSummaryList = [];
    let HSNSummary = [];
    let billLineItemTotal = 0;

    let includePriceAndTax = false;
    if (transactionType.hasOwnProperty("IncludePriceAndTax")) {
      includePriceAndTax = transactionType.IncludePriceAndTax;
    }
    // console.log('transactionType', transactionType)
    // console.log('includePriceAndTax', includePriceAndTax)
    if (!includePriceAndTax) {
      lineItems.map((lineItem) => {
        billQty = roundUpTo2(Number(billQty) + Number(lineItem.Qty));
        billLineItemQty = roundUpTo2(
          Number(billLineItemQty) + Number(lineItem.LineItemQty)
        );
        billFreeQty = roundUpTo2(
          Number(billFreeQty) + Number(lineItem.FreeQty)
        );
      });
      //create HSN codeand QTY summary
      for (let index = 0; index < lineItems.length; index++) {
        const lineItem = lineItems[index];
        if (lineItem.HSNSACCode.trim() !== "") {
          let indexFound = -1;
          HSNSummary.map((m, i) => {
            if (m.HSNSACCode.trim() === lineItem.HSNSACCode.trim()) {
              indexFound = i;
            }
          });
          if (indexFound === -1) {
            HSNSummary.push({
              HSNSACCode: lineItem.HSNSACCode.trim(),
              TotalQty: lineItem.Qty,
            });
          } else {
            HSNSummary[indexFound].TotalQty =
              roundUpTo2(Number(HSNSummary[indexFound].TotalQty)) +
              Number(lineItem.Qty);
          }
        }
      }

      return {
        billQty: billQty,
        billLineItemQty: billLineItemQty,
        billFreeQty: billFreeQty,
        billSubTotal: billSubTotal,
        billAmount: billAmount,
        billTaxAmount: billTaxAmount,
        billDiscount: billDiscount,
        taxSummaryList: taxSummaryList,
        billLineItemTotal: billLineItemTotal,
        HSNSummary: HSNSummary,
        billRoundOff: 0,
        billAdditionalCharges: 0,
        billAdditionalDiscount: 0,
      };
    }

    for (let index = 0; index < lineItems.length; index++) {
      const lineItem = lineItems[index];
      if (lineItem.TaxID != "") {
        let indexFound = -1;
        taxSummaryList.map((t, index) => {
          if (
            t.TaxID === lineItem.TaxID &&
            t.HSNSACCode === lineItem.HSNSACCode
          ) {
            let sameTaxComponent = true;
            t.TaxComponentAmount.map((a) => {
              if (
                lineItem.TaxComponentAmount.filter(
                  (f) => f.TaxName === a.TaxName
                ).length === 0
              ) {
                sameTaxComponent = false;
                return;
              }
            });
            if (sameTaxComponent) {
              indexFound = index;
              return;
            }
          }
        });
        if (indexFound < 0) {
          let taxSummary = {
            TaxID: lineItem.TaxID,
            TaxGroup: lineItem.TaxGroup,
            HSNSACCode: lineItem.HSNSACCode,
            TaxableAmount: roundUpTo2(lineItem.TaxableAmount),
            TotalTax: roundUpTo2(lineItem.TotalTax),
            TotalQty: roundUpTo2(lineItem.LineItemQty),
            TaxComponentAmount: [],
          };
          lineItem.TaxComponentAmount.map((a) => {
            taxSummary.TaxComponentAmount.push({ ...a });
          });
          taxSummaryList.push(taxSummary);
        } else {
          taxSummaryList[indexFound].TaxableAmount = roundUpTo2(
            Number(taxSummaryList[indexFound].TaxableAmount) +
              Number(lineItem.TaxableAmount)
          );
          taxSummaryList[indexFound].TotalTax = roundUpTo2(
            Number(taxSummaryList[indexFound].TotalTax) +
              Number(lineItem.TotalTax)
          );
          taxSummaryList[indexFound].TotalQty = roundUpTo2(
            Number(taxSummaryList[indexFound].TotalQty) +
              Number(lineItem.LineItemQty)
          );
          taxSummaryList[indexFound].TaxComponentAmount.map((t, index) => {
            t.TaxAmount = roundUpTo2(
              Number(t.TaxAmount) +
                Number(lineItem.TaxComponentAmount[index].TaxAmount)
            );
          });
        }
      }
      billQty = roundUpTo2(Number(billQty) + Number(lineItem.Qty));
      billLineItemQty = roundUpTo2(
        Number(billLineItemQty) + Number(lineItem.LineItemQty)
      );
      billFreeQty = roundUpTo2(Number(billFreeQty) + Number(lineItem.FreeQty));
      billSubTotal = roundUpTo2(
        Number(billSubTotal) + Number(lineItem.SubTotal)
      );
      billAmount = roundUpTo2(Number(billAmount) + Number(lineItem.Amount));
      billTaxAmount = roundUpTo2(
        Number(billTaxAmount) + Number(lineItem.TotalTax)
      );
      billDiscount = roundUpTo2(
        Number(billDiscount) + Number(lineItem.DiscountAmount)
      );
      billLineItemTotal = billAmount;
    }

    billAmount =
      billAmount +
      Number(billRoundOff) +
      Number(billAdditionalCharges) -
      Number(billAdditionalDiscount);
    return {
      billQty: billQty,
      billLineItemQty: billLineItemQty,
      billFreeQty: billFreeQty,
      billSubTotal: billSubTotal,
      billAmount: billAmount,
      billTaxAmount: billTaxAmount,
      billDiscount: billDiscount,
      taxSummaryList: taxSummaryList,
      billLineItemTotal: billLineItemTotal,
      billRoundOff: billRoundOff,
      billAdditionalCharges: billAdditionalCharges,
      billAdditionalDiscount: billAdditionalDiscount,
    };
  }

  onLineItemChangesDone() {
    let product = this.state.selectedLineItem.Product;
    let lineItem = this.state.selectedLineItem;
    let batchNoError = "";
    let batchMRPError = "";
    let batchRetailPriceError = "";
    let batchMfgDateError = "";
    let batchExpDateError = "";
    let batchExpDateValue = null;
    let batchMfgDateValue = null;
    let validMfgDate = false;
    let validExpDate = false;
    let productPackagingQtyError = "";

    // console.log('this.state.selectedLineItem', this.state.selectedLineItem)
    if (product.hasOwnProperty("InventoryType")) {
      if (
        product.InventoryType.toLowerCase() === "batch tracking" &&
        this.state.transactionType.UpdateStockChecked
      ) {
        if (
          product.BatchTrackingBatchNoRequired &&
          !product.BatchTrackingAutoBatchNoRequired &&
          this.state.selectedLineItem.BatchNo.trim() === ""
        ) {
          batchNoError = "Please provide Batch No";
        }
        if (
          product.BatchTrackingMRPRequired &&
          this.state.selectedLineItem.BatchMRP === ""
        ) {
          batchMRPError = "Please provide Batch MRP";
        }
        if (
          product.BatchTrackingMfgDateRequired &&
          this.state.selectedLineItem.BatchMfgDate.trim() === ""
        ) {
          batchMfgDateError = "Please provide Mfg Date";
        }
        if (
          product.BatchTrackingMfgDateRequired &&
          this.state.selectedLineItem.BatchMfgDate.trim() !== ""
        ) {
          batchMfgDateValue = checkDateFormat(
            this.state.selectedLineItem.BatchMfgDate,
            this.state.selectedLineItem.Product.BatchTrackingDateFormat,
            "mfg"
          );
          if (!batchMfgDateValue) {
            batchMfgDateError = "Invalid Mfg date format";
          } else {
            validMfgDate = true;
          }
        }
        if (
          product.BatchTrackingExpDateRequired &&
          this.state.selectedLineItem.BatchExpDate.trim() === ""
        ) {
          batchExpDateError = "Please provide Exp Date";
        }
        if (
          product.BatchTrackingExpDateRequired &&
          this.state.selectedLineItem.BatchExpDate.trim() !== ""
        ) {
          batchExpDateValue = checkDateFormat(
            this.state.selectedLineItem.BatchExpDate,
            this.state.selectedLineItem.Product.BatchTrackingDateFormat,
            "exp"
          );
          if (!batchExpDateValue) {
            batchExpDateError = "Invalid Exp date format";
          } else {
            validExpDate = true;
          }
        }
        if (
          validMfgDate &&
          validExpDate &&
          batchMfgDateValue > batchExpDateValue
        ) {
          batchExpDateError = "Mfg date can not be after Exp date";
        }
      }
    }
    if (
      lineItem.ProductPackagingQtyCheck &&
      lineItem.ProductPackagingQty == ""
    ) {
      productPackagingQtyError = "Please enter value";
    }
    if (
      batchNoError !== "" ||
      batchMRPError !== "" ||
      batchRetailPriceError !== "" ||
      batchMfgDateError !== "" ||
      batchExpDateError !== "" ||
      productPackagingQtyError !== ""
    ) {
      this.setState({
        batchNoError: batchNoError,
        batchMRPError: batchMRPError,
        batchRetailPriceError: batchRetailPriceError,
        batchMfgDateError: batchMfgDateError,
        batchExpDateError: batchExpDateError,
        productPackagingQtyError: productPackagingQtyError,
      });
      return;
    }
    let lineItems = this.state.lineItems;

    if (product.BatchTrackingMfgDateRequired) {
      lineItem.BatchMfgDateValue = batchMfgDateValue;
    }
    if (product.BatchTrackingExpDateRequired) {
      lineItem.BatchExpDateValue = batchExpDateValue;
    }
    lineItem.InventoryIssue = this.state.inventoryIssue;
    lineItem.Error = "";
    console.log("lineItem", lineItem);
    lineItems[this.state.selectedLineItemIndex] = lineItem;

    this.setState({ lineItems: lineItems, showLineItemModal: false });
    this.calculateBillTotal(
      lineItems,
      this.state.billRoundOff,
      this.state.billAdditionalCharges,
      this.state.billAdditionalDiscount,
      this.state.transactionType
    );
  }

  onLineItemSelected(lineItem, index) {
    let selectedLineItem = { ...lineItem };
    let showLineItemModal = true;
    let showBatchsForSelection = false;
    let selectedProduct = null;
    if (
      this.state.transactionType.hasOwnProperty("UpdateStockInwardOrOutward") &&
      this.state.transactionType.UpdateStockInwardOrOutward === "outward" &&
      selectedLineItem.Product.hasOwnProperty("InventoryType") &&
      selectedLineItem.Product.InventoryType.toLowerCase() === "batch tracking"
    ) {
      if (!selectedLineItem.hasOwnProperty("BatchID")) {
        showBatchsForSelection = true;
        showLineItemModal = false;
        selectedProduct = selectedLineItem.Product;
      } else if (
        selectedLineItem.hasOwnProperty("BatchID") &&
        selectedLineItem.BatchID === ""
      ) {
        showBatchsForSelection = true;
        showLineItemModal = false;
        selectedProduct = selectedLineItem.Product;
      }
    }

    this.setState({
      inventoryIssue: selectedLineItem.InventoryIssue
        ? selectedLineItem.InventoryIssue
        : "",
      selectedLineItem: selectedLineItem,
      selectedLineItemIndex: index,
      selectedItemEmployeeSelectionError: "",
      showLineItemModal: showLineItemModal,
      showBatchsForSelection: showBatchsForSelection,
      selectedProduct: selectedProduct,
    });
    console.log("onLineItemSelected", lineItem);
  }

  onSelectedLineItemBatchNoChange(batchNo) {
    let lineItem = this.state.selectedLineItem;
    lineItem.BatchNo = batchNo;
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemBatchMRPChange(mrp) {
    let lineItem = this.state.selectedLineItem;
    lineItem.BatchMRP = Number(mrp);
    lineItem.Product.MRP = Number(mrp);
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemBatchRetailPriceChange(retailPrice) {
    let lineItem = this.state.selectedLineItem;
    lineItem.BatchRetailPrice = Number(retailPrice);
    lineItem.Product.RetailPrice = Number(retailPrice);
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemBatchMfgDateChange(mfgDate) {
    let lineItem = this.state.selectedLineItem;
    lineItem.BatchMfgDate = mfgDate;
    this.setState({ selectedLineItem: lineItem, batchMfgDateError: "" });
  }

  onSelectedLineItemBatchExpDateChange(expDate) {
    let lineItem = this.state.selectedLineItem;
    lineItem.BatchExpDate = expDate;
    this.setState({ selectedLineItem: lineItem, batchExpDateError: "" });
  }

  onSelectedLineItemQtyChange(qty) {
    if (
      this.state.converted &&
      this.state.selectedLineItem.hasOwnProperty(
        "ParentTransactionRemainingQty"
      ) &&
      this.state.selectedLineItem.ParentTransactionRemainingQty
    ) {
      if (this.state.selectedLineItem.ParentTransactionRemainingQty < qty) {
        return;
      }
    }
    let lineItem = this.state.selectedLineItem;
    lineItem.LineItemQty = Number(qty);
    lineItem.Qty = Number(lineItem.LineItemQty) + Number(lineItem.FreeQty);
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemFreeQtyChange(qty) {
    let lineItem = this.state.selectedLineItem;
    lineItem.FreeQty = Number(qty);
    lineItem.Qty = Number(lineItem.LineItemQty) + Number(lineItem.FreeQty);
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemPriceChange(price) {
    let lineItem = this.state.selectedLineItem;
    lineItem.SupplyPrice = Number(price);
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemDiscountChange(discountPer) {
    let lineItem = this.state.selectedLineItem;
    lineItem.DiscountPer = Number(discountPer);
    lineItem.DiscountAmount = 0;
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemDiscountAmountChange(discountAmount) {
    let lineItem = this.state.selectedLineItem;
    lineItem.DiscountPer = 0;
    lineItem.DiscountAmount = Number(discountAmount);
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemChargeTaxOnProductChange() {
    let lineItem = this.state.selectedLineItem;
    lineItem.ChargeTaxOnProduct = !lineItem.ChargeTaxOnProduct;
    if (!lineItem.ChargeTaxOnProduct) {
      lineItem.TaxInclusive = false;
      lineItem.TaxGroup = "";
    }
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemTaxIncluxiveChange() {
    let lineItem = this.state.selectedLineItem;
    lineItem.TaxInclusive = !lineItem.TaxInclusive;
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemTaxGroupChange(taxID, taxGroup) {
    let lineItem = this.state.selectedLineItem;
    lineItem.TaxID = taxID;
    lineItem.TaxGroup = taxGroup;
    lineItem = this.onLineItemChange(
      lineItem,
      this.state.placeOfSupply,
      this.state.computeTax,
      this.state.transactionType
    );
    this.setState({ selectedLineItem: lineItem });
  }

  onSelectedLineItemNotesChange(notes) {
    let lineItem = this.state.selectedLineItem;
    lineItem.Notes = notes;
    this.setState({ selectedLineItem: lineItem });
  }

  async checkIfNegativeQtyAllowed(product, lineItem, lineItemAdded = true) {
    // console.log('product', product)
    let qty = lineItem.Qty;
    if (
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "uniqueno"
    ) {
      return true;
    }

    let negativeProductQtyNotAllowedForBilling = false;
    if (
      this.props.user.store.hasOwnProperty(
        "NegativeProductQtyNotAllowedForBilling"
      )
    ) {
      negativeProductQtyNotAllowedForBilling = this.props.user.store
        .NegativeProductQtyNotAllowedForBilling;
      // console.log('store NegativeProductQtyNotAllowedForBilling')
    } else {
      negativeProductQtyNotAllowedForBilling = this.props.registration
        .registration.NegativeProductQtyNotAllowedForBilling;
    }
    if (negativeProductQtyNotAllowedForBilling) {
      //now check if instock qty exists for this item
      let inStockQty = 0;
      if (
        product.hasOwnProperty("InventoryType") &&
        product.InventoryType.toLowerCase() === "batch tracking"
      ) {
        // console.log('batch tracking')
        inStockQty = lineItem.InStockQty;
      } else {
        // console.log('Normal')
        inStockQty = await this.props.getProductInStockQty(
          this.props.user.store.key,
          this.state.storeLocationID,
          product.key
        );
      }
      // console.log('inStockQty',inStockQty)
      //check if edit and the product exists previously then offset that qty
      // console.log('this.props.location.state.type', this.props.location.state.type)
      if (this.props.location.state.type === actionTypes.INVENTORY_EDIT) {
        if (this.state.editLineItems.length > 0) {
          let result = this.state.editLineItems.filter(
            (f) => f.Product.key === product.key
          );
          let qty = 0;
          for (let i = 0; i < result.length; i++) {
            qty = qty + Number(result[i].Qty);
          }
          // console.log('result',result)
          // console.log('qty', qty)
          inStockQty = inStockQty + qty;
        }
        // console.log('edit', inStockQty)
      }
      if (lineItemAdded) {
        if (inStockQty > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        // console.log('qty', qty)
        if (inStockQty - qty >= 0) {
          // console.log('true')
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  addVedorProductsToLineItems() {
    let vendorProducts = this.state.filteredVendorProducts.filter(
      (f) => f.Selected
    );
    let lineItems = this.state.lineItems;
    for (let i = 0; i < vendorProducts.length; i++) {
      if (
        lineItems.filter((f) => f.Product.key === vendorProducts[i].ProductID)
          .length === 0
      ) {
        //add product to lineitems
        if (
          this.props.config.Products.filter(
            (f) => f.key === vendorProducts[i].ProductID
          ).length > 0
        ) {
          let product = this.props.config.Products.filter(
            (f) => f.key === vendorProducts[i].ProductID
          )[0];
          product.SupplyPrice = vendorProducts[i].SupplyPrice;
          this.onProductSelected(product);
        } else {
          alert("Product not found");
        }
      }
    }
  }

  async onSaveBulkPurchaseReturn() {
    let bulkPurchaseReturns = this.state.bulkPurchaseReturns;
    for (let i = 0; i < bulkPurchaseReturns.length; i++) {
      let lineItems = this.state.lineItems.filter(
        (f) =>
          f.ParentTransactionID === bulkPurchaseReturns[i].ParentTransactionID
      );
      if (lineItems.length === 0) {
        alert(
          "No lineitems found for PE " + bulkPurchaseReturns[i].TransactionNo
        );
        return;
      }
      let calculationResult = this.calculateBillTotalSub(lineItems);
      let inventory = {
        TransactionType: this.state.transactionType,
        Vendor: this.state.selectedVendor,
        RegistrationID: this.props.user.user.RegistrationID,
        Store: this.props.user.store,
        LineItems: lineItems,
        BillQty: this.state.billQty,
        BillLineItemQty: this.state.billLineItemQty,
        BillFreeQty: 0,
        BillSubTotal: this.state.billSubTotal,
        BillDiscount: this.state.billDiscount,
        BillTaxAmount: this.state.billTaxAmount,
        BillAmount: this.state.billAmount,
        ParentTransactionNo: bulkPurchaseReturns[i].ParentTransactionNo,
        ParentTransactionKey: bulkPurchaseReturns[i].ParentTransactionID,
        Notes: this.state.notes,
        TaxSummaryList: this.state.taxSummaryList,
        HSNSummary: this.state.HSNSummary,
        PlaceOfSupply: this.state.placeOfSupply,
        StoreLocation: this.state.storeLocation,
        StoreLocationID: this.state.storeLocationID,
        RefNo: this.state.refNo,
        BackEndUpdate: false,
        Status: "Completed",
        ToStoreID: this.state.toStoreID,
        ToStore: this.state.toStore,
        ChildTransactionNo: "",
        ChildTransactionKey: "",
        BillLineItemTotal: this.state.billLineItemTotal,
        BillRoundOff: this.state.billRoundOff,
        BillAdditionalCharges: this.state.billAdditionalCharges,
        BillAdditionalDiscount: this.state.billAdditionalDiscount,
        Converted: this.state.converted,
        ParentTransactions: this.state.parentTransactions,
        // FromStoreLocation: this.state.fromLocation,
        // FromLocationID: this.state.fromLocationID,
        ToLocation: this.state.toLocation,
        ToLocationID: this.state.toLocationID,
      };
      this.save(inventory);
    }
  }

  async onSave() {
    this.setState({ saving: true });
    let vendorError = "";
    let storeLocationError = "";
    let noLineItemExistError = "";
    let transactionDateError = "";
    let toStoreError = "";
    let fromLocationError = "";
    let toLocationError = "";
    // console.log('this.state.storeLocationID',this.state.storeLocationID)
    if (this.state.transactionDate.trim() === "") {
      transactionDateError = "Please select transaction date";
    }
    if (
      this.state.transactionType.TransactionMovement === "vendortostore" ||
      this.state.transactionType.TransactionMovement === "storetovendor"
    ) {
      if (!this.state.selectedVendor) {
        vendorError = "Please select vendor";
      }
      if (this.state.storeLocationID.trim() === "") {
        storeLocationError = "Please select store location";
      }
    }
    if (this.state.transactionType.TransactionMovement === "store") {
      if (this.state.storeLocationID.trim() === "") {
        storeLocationError = "Please select store location";
      }
    }
    if (this.state.transactionType.TransactionMovement === "storetostore") {
      if (this.state.storeLocationID.trim() === "") {
        storeLocationError = "Please select store location";
      }
      if (this.state.toStoreID.trim() === "") {
        toStoreError = "Please select to store";
      }
    }
    if (this.state.lineItems.length === 0) {
      noLineItemExistError = "Please select items";
    }
    if (
      this.state.transactionType.TransactionMovement ===
      "storelocationtostorelocation"
    ) {
      if (this.state.storeLocationID.trim() === "") {
        storeLocationError = "Please select location";
      }
      if (this.state.toLocationID.trim() === "") {
        toLocationError = "Please select location";
      }
      if (
        this.state.storeLocationID.trim() !== "" &&
        this.state.toLocationID.trim() !== "" &&
        this.state.storeLocationID.trim() === this.state.toLocationID.trim()
      ) {
        storeLocationError = "From location can not be same as to location";
      }
    }
    //check if batch details are provided

    let batchErrorFound = false;
    let lineItemError = false;
    let lineItems = this.state.lineItems;
    if (
      this.state.transactionType.hasOwnProperty("UpdateStockInwardOrOutward")
    ) {
      for (let i = 0; i < lineItems.length; i++) {
        let lineItem = lineItems[i];
        let product = lineItem.Product;
        if (
          product.hasOwnProperty("InventoryType") &&
          product.InventoryType.toLowerCase() === "batch tracking" &&
          product.ProductTypeID < 3 &&
          this.state.transactionType.UpdateStockChecked
        ) {
          if (
            this.state.transactionType.UpdateStockInwardOrOutward === "inward"
          ) {
            let batchError = "";
            if (
              product.BatchTrackingBatchNoRequired &&
              !product.BatchTrackingAutoBatchNoRequired &&
              lineItem.BatchNo.trim() === ""
            ) {
              batchError = batchError + "Please provide Batch No. ";
            }
            if (product.BatchTrackingMRPRequired && lineItem.BatchMRP === "") {
              batchError = batchError + "Please provide Batch MRP. ";
            }
            if (
              product.BatchTrackingMfgDateRequired &&
              lineItem.BatchMfgDate.trim() === ""
            ) {
              batchError = batchError + "Please provide Mfg Date. ";
            }
            if (
              product.BatchTrackingExpDateRequired &&
              lineItem.BatchExpDate.trim() === ""
            ) {
              batchError = batchError + "Please provide Exp Date. ";
            }
            if (batchError.trim() !== "") {
              batchErrorFound = true;
              lineItems[i].Error = batchError;
            }
          } else if (
            this.state.transactionType.UpdateStockInwardOrOutward === "outward"
          ) {
            if (!lineItems[i].hasOwnProperty("BatchID")) {
              batchErrorFound = true;
              lineItems[i].Error = "Please select batch";
            } else if (
              lineItems[i].hasOwnProperty("BatchID") &&
              lineItems[i].BatchID === ""
            ) {
              batchErrorFound = true;
              lineItems[i].Error = "Please select batch";
            }
          }
        }
        if (
          this.props.registration.registration.EnablePosBillingForPincode &&
          lineItem.ProductPackagingQtyCheck
        ) {
          if (lineItem.ProductPackagingQty.toString().trim() === "") {
            lineItems[i].Error =
              "Please provide product grammage/weight/volume";
            lineItemError = true;
          }
        }
      }
    }
    console.log("lineItemError", lineItemError);
    if (
      vendorError !== "" ||
      noLineItemExistError !== "" ||
      storeLocationError !== "" ||
      toStoreError !== "" ||
      toLocationError !== "" ||
      batchErrorFound ||
      lineItemError
    ) {
      this.setState({
        // showError: true,
        vendorError: vendorError,
        noLineItemExistError: noLineItemExistError,
        toLocationError: toLocationError,
        toStoreError: toStoreError,
        saving: false,
        lineItems: lineItems,
      });
      return;
    }
    //now check for negative billing qty
    if (this.state.transactionType.UpdateStockInwardOrOutward === "outward") {
      let lineItems = this.state.lineItems;
      let inStockQtyNegativeErrors = [];
      for (let h = 0; h < lineItems.length; h++) {
        let allow = await this.checkIfNegativeQtyAllowed(
          lineItems[h].Product,
          lineItems[h],
          false
        );
        if (!allow) {
          inStockQtyNegativeErrors.push({ Error: lineItems[h].Product.Name });
        }
      }
      if (inStockQtyNegativeErrors.length > 0) {
        this.setState({
          showError: true,
          saving: false,
          inStockQtyNegativeErrors: inStockQtyNegativeErrors,
          inStockQtyNegativeError:
            "Following product(s) can not be selected for billing as in stock quantity is less than zero.",
        });
        return;
      } else {
        this.setState({
          showError: false,
          inStockQtyNegativeErrors: inStockQtyNegativeErrors,
          inStockQtyNegativeError: "",
        });
      }
    }

    if (this.state.bulkPurchaseReturn) {
      this.onSaveBulkPurchaseReturn();
      return;
    }

    //

    let status = "Completed";
    let transactionType = this.state.transactionType;
    if (
      transactionType.hasOwnProperty("EnableApproval") &&
      transactionType.EnableApproval
    ) {
      // console.log('Approval Flow');
      let firstApprover = "";
      let secondApprover = "";
      if (
        transactionType.hasOwnProperty("FirstApprover") &&
        transactionType.FirstApprover !== ""
      ) {
        firstApprover = transactionType.FirstApprover;
      }
      if (
        transactionType.hasOwnProperty("SecondApprover") &&
        transactionType.SecondApprover !== ""
      ) {
        secondApprover = transactionType.SecondApprover;
      }
      //check if current user is part of first user or second user

      if (firstApprover !== "") {
        if (
          firstApprover === this.props.user.user.Role ||
          firstApprover === this.props.user.user.RoleKey
        ) {
          status =
            secondApprover !== "" ? "Pending Final Approval" : "Completed";
        } else {
          status = "Pending First Approval";
        }
      }
      if (secondApprover !== "") {
        if (
          secondApprover === this.props.user.user.Role ||
          secondApprover === this.props.user.user.RoleKey
        ) {
          status = "Completed";
        }
      }
    }
    // console.log('status', status)

    // let lineItems = this.state.lineItems;
    lineItems.map((l) => (l.Product.StoreSpecificQtys = []));
    let inventory = {
      TransactionType: this.state.transactionType,
      Vendor: this.state.selectedVendor,
      RegistrationID: this.props.user.user.RegistrationID,
      Store: this.props.user.store,
      LineItems: lineItems, //this.state.lineItems,
      BillQty: this.state.billQty,
      BillLineItemQty: this.state.billLineItemQty,
      BillFreeQty: this.state.billFreeQty,
      BillSubTotal: this.state.billSubTotal,
      BillDiscount: this.state.billDiscount,
      BillTaxAmount: this.state.billTaxAmount,
      BillAmount: this.state.billAmount,
      ParentTransactionNo: this.state.parentTransactionNo,
      ParentTransactionKey: this.state.parentTransactionKey,
      Notes: this.state.notes,
      TaxSummaryList: this.state.taxSummaryList,
      HSNSummary: this.state.HSNSummary,
      PlaceOfSupply: this.state.placeOfSupply,
      StoreLocation: this.state.storeLocation,
      StoreLocationID: this.state.storeLocationID,
      RefNo: this.state.refNo,
      BackEndUpdate: false,
      Status: status,
      ToStoreID: this.state.toStoreID,
      ToStore: this.state.toStore,
      ChildTransactionNo: this.state.childTransactionNo,
      ChildTransactionKey: this.state.childTransactionKey,
      BillLineItemTotal: this.state.billLineItemTotal,
      BillRoundOff: this.state.billRoundOff,
      BillAdditionalCharges: this.state.billAdditionalCharges,
      BillAdditionalDiscount: this.state.billAdditionalDiscount,
      Converted: this.state.converted,
      ParentTransactions: this.state.parentTransactions,
      // FromLocation: this.state.fromLocation,
      // FromLocationID: this.state.fromLocationID,
      ToLocation: this.state.toLocation,
      ToLocationID: this.state.toLocationID,
    };
    // console.log(
    //   " this.state.childTransactionKey",
    //   this.state.childTransactionKey
    // );
    // console.log("inventory", inventory);
    this.save(inventory);
  }

  async save(inventory) {
    // console.log('inventory',inventory);
    if (this.state.selectedVendor && this.state.selectedVendor.EmailID !== "") {
      inventory.EmailID = this.state.selectedVendor.EmailID;
    }
    // console.log('inventory',inventory)
    // if (this.props.location.state.type === actionTypes.INVENTORY_NEW) {
    if (this.state.key === "") {
      let transactionNo = this.getTransactioNo(this.state.transactionType);
      let nextRunningNo = await this.props.getInventoryRunningNo(
        inventory.Store.key,
        inventory.TransactionType.key
      );
      transactionNo = transactionNo + nextRunningNo;
      inventory.TransactionNo = transactionNo;
      inventory.TransactionDate = new Date(this.state.transactionDate);

      let inventoryToSale = await this.props.getInventoryToSale();
      if (inventoryToSale) {
        for (let index = 0; index < inventoryToSale.length; index++) {
          const obj = inventoryToSale[index];
          if (
            obj.InventoryTransactionTypeID === inventory.TransactionType.key &&
            inventory.Vendor.hasOwnProperty("VendorStoreID") &&
            inventory.Vendor.VendorStoreID !== ""
          ) {
            inventory.Status = "Request Raised";
          }
        }
      }

      // console.log('inventory', inventory)
      this.props.addInventory(inventory).then(async (key) => {
        // this.props.getToastr(
        //   "Inventory #" + transactionNo + " saved successfully"
        // );
        //1jun19 pawan
        this.props.updateInventoryRunningNo(
          inventory.Store.key,
          inventory.TransactionType.key,
          nextRunningNo
        );

        //now reporrt product gc
        if (this.props.registration.registration.EnablePosBillingForPincode) {
          inventory.LineItems.map(async (l) => {
            if (l.ProductPackagingQtyCheck)
              if (
                Number(l.ProductPackagingQty) < Number(l.ProductPackagingQtyGC)
              ) {
                //report gc issue
                await this.props.updateProduct(
                  {
                    CustomField4: "Wrong grammage/weight/volume",
                    CustomField5: l.ProductPackagingQty,
                  },
                  l.Product.key
                );
              }
          });
        }
        if (this.state.printChecked || this.state.sendEmailChecked) {
          // let inventoryPrint = await this.printFormatting({ ...inventory });
          // console.log("inventoryPrint", JSON.stringify(inventoryPrint));
          // // let inventoryPrint = { ...inventory };
          // // await this.printFormatting(inventoryPrint);

          // let printData = {
          //   Inventory: inventoryPrint,
          //   PrintConfig: this.props.config.PrintConfigs.filter(
          //     (e) => e.key === this.state.selectedPringConfigID
          //   )[0],
          // };
          // // console.log("printData", JSON.stringify(printData));
          // this.setState({
          //   print: true,
          //   showInventory: false,
          //   inventoryPrint: inventoryPrint,
          //   printData: printData,
          // });

          if (
            inventory.TransactionType.TransactionMovement === "storetostore"
          ) {
            //get stores
            inventory.ToStore = await this.props.getStoreDetails(
              inventory.ToStoreID
            );
          }
          if (
            inventory.TransactionType.TransactionMovement === "store" &&
            inventory.TransactionType.TransactionType === "Transfer In"
          ) {
            //get stores
            if (
              inventory.hasOwnProperty("FromStoreID") &&
              inventory.FromStoreID !== ""
            ) {
              inventory.FromStore = await this.props.getStoreDetails(
                inventory.FromStoreID
              );
              // console.log("FromStore", inventory.FromStore);
            }
          }

          let inventoryPrint = { ...inventory };
          await this.printFormatting(inventoryPrint);
          let printData = {
            Inventory: inventoryPrint,
            PrintConfig: this.props.config.PrintConfigs.filter(
              (e) => e.key === this.state.selectedPringConfigID
            )[0],
          };
          this.setState({
            print: true,
            printChecked: true,
            showInventory: false,
            printData: printData,
          }); //TBDpawan
          return;
        }
        setTimeout(
          function() {
            //Start the timer
            this.setState({ saving: false });
            // this.props.history.push({ pathname: "/home/sales/sales/", state: { transactionType: this.state.transactionType, type: actionTypes.SALE_NEW } })
            // if (this.props.isMobileApp()) {
            //     this.props.history.push({ pathname: '/home/inventory/inventoryhistory' })
            // }
            // else {
            this.props.history.push({
              pathname: "/home/inventory/inventorylist/",
              state: { type: "list" },
            });
            // }
          }.bind(this),
          2000
        );
      });
    } else {
      inventory.TransactionNo = this.state.transactionNo;
      inventory.TransactionDate = new Date(this.state.transactionDate);
      //31may//pawan
      inventory.Store = this.state.editStore;
      this.props.updateInventory(inventory, this.state.key).then((key) => {
        this.props.getToastr(
          "Inventory #" + inventory.TransactionNo + " updated successfully"
        );
        setTimeout(
          function() {
            this.setState({ saving: false });

            this.props.history.push({
              pathname: "/home/inventory/inventorylist/",
              state: { type: "list" },
            });
          }.bind(this),
          2000
        );
      });
    }
  }

  async onSaveAsDraft() {
    let inventory = {
      TransactionType: this.state.transactionType,
      Vendor: this.state.selectedVendor,
      RegistrationID: this.props.user.user.RegistrationID,
      Store: this.props.user.store,
      LineItems: this.state.lineItems,
      BillQty: this.state.billQty,
      BillFreeQty: this.state.billFreeQty,
      BillLineItemQty: this.state.billLineItemQty,
      BillSubTotal: this.state.billSubTotal,
      BillDiscount: this.state.billDiscount,
      BillTaxAmount: this.state.billTaxAmount,
      BillAmount: this.state.billAmount,
      ParentTransactionNo: this.state.parentTransactionNo,
      ParentTransactionKey: this.state.parentTransactionKey,
      Notes: this.state.notes,
      TaxSummaryList: this.state.taxSummaryList,
      HSNSummary: this.state.HSNSummary,
      PlaceOfSupply: this.state.placeOfSupply,
      StoreLocation: this.state.storeLocation,
      StoreLocationID: this.state.storeLocationID,
      RefNo: this.state.refNo,
      BackEndUpdate: false,
      Status: "",
      ToStoreID: this.state.toStoreID,
      ToStore: this.state.toStore,
      ChildTransactionNo: "",
      ChildTransactionKey: "",
      BillLineItemTotal: this.state.billLineItemTotal,
      BillRoundOff: this.state.billRoundOff,
      BillAdditionalCharges: this.state.billAdditionalCharges,
      BillAdditionalDiscount: this.state.billAdditionalDiscount,
    };
    // console.log('inventory', inventory)
    await this.props.saveDraftTransaction(inventory, "Inventory", "");
    // .then(() => {
    this.props.getToastr("Transaction saved as draft successfully");
    this.onCancel();
    // })
  }

  async printFormatting(inventory) {
    //get to store details

    // sale.TransactionDateShort = (new Date(sale.TransactionDate)).toISOString().split('T')[0]
    // sale.TransactionDate = sale.TransactionDate.toString().substring(0, 24)

    // inventory.TransactionDate = new Date(
    //   inventory.TransactionDate.seconds * 1000
    // )
    //   .toString()
    //   .substring(0, 24);
    // inventory.TransactionDateShort = inventory.TransactionDate.substring(0, 15); //(new Date(inventory.TransactionDate.seconds * 1000)).toISOString().split('T')[0]

    inventory.TransactionDate = new Date(inventory.TransactionDate)
      .toISOString()
      .split("T")[0];

    inventory.TransactionDateShort = inventory.TransactionDate;
    // inventory.TransactionDate = inventory.TransactionDate.toString().substring(
    //   0,
    //   24
    // );

    inventory.AmountInWords = this.props.convertNumberToWords(
      inventory.BillAmount
    );
    inventory.LineItems.map((lineItem, index) => {
      inventory.LineItems[index].SrNo = index + 1;
    });
    let printTaxComponents = [];
    let billTaxableAmount = 0;
    inventory.LineItems.map((lineItem, index) => {
      if (lineItem.hasOwnProperty("TaxableAmount")) {
        billTaxableAmount =
          Number(billTaxableAmount) + Number(lineItem.TaxableAmount);
      }
      if (lineItem.TaxID != "") {
        lineItem.TaxComponentAmount.map((t) => {
          let indexFound = -1;
          printTaxComponents.map((pt, ptIndex) => {
            if (t.TaxType === pt.TaxType) {
              indexFound = ptIndex;
              return;
            }
          });
          if (indexFound < 0) {
            printTaxComponents.push({
              TaxType: t.TaxType,
              TaxAmount: roundUpTo2(Number(t.TaxAmount)),
            });
          } else {
            printTaxComponents[indexFound].TaxAmount = roundUpTo2(
              Number(printTaxComponents[indexFound].TaxAmount) +
                Number(t.TaxAmount)
            );
          }
        });
      }
    });
    inventory.PrintTaxComponents = printTaxComponents;
    inventory.BillTaxableAmount = billTaxableAmount;
    inventory.LineItems.map((lineItem, index) => {
      for (var i = 0; i < printTaxComponents.length; i++) {
        if (lineItem.TaxComponentAmount.length < i + 1) {
          lineItem.TaxComponentAmount.push({
            TaxName: "",
            TaxAmount: 0,
            TaxPercentage: "",
            TaxType: "",
          });
        }
      }
    });
    // console.log("printformatting", inventory);
    // return inventory;
  }

  getTransactioNo(transactionType) {
    let transactionNo = "";
    transactionType.NumberingFormat.map((parameters) => {
      let para = "";
      if (parameters.Option === "text") {
        para = parameters.Value;
      } else if (parameters.Option === "storecode") {
        para = this.props.user.store.StoreCode;
      }
      // else if (parameters.Option == "locationcode") {
      //     para = this.props.user.cashRegister.LocationCode
      // }
      else if (parameters.Option === "calendardmonthyear") {
        let d = new Date();
        let a = d.getFullYear() + "";
        para = d.getMonth() + 1 + a.substring(2, 4);
        if (para.length < 3) {
          para = "0" + para;
        }
      } else if (parameters.Option === "fiscalyear") {
        let d = new Date();
        para = d.getFullYear() + "";
      } else if (parameters.Option === "calendarmonth") {
        let d = new Date();
        let a = d.getMonth() + 1 + "";
        if (a.length < 2) {
          para = "0" + a;
        }
      } else if (parameters.Option === "calendaryear") {
        let d = new Date();
        para = d.getFullYear() + "";
      }
      transactionNo = transactionNo + para + parameters.Separator;
    });
    return transactionNo;
  }

  onCancel() {
    // this.props.history.push('/home/inventory/inventorylist')
    // console.log('onCancel')

    // if (this.props.isMobileApp()) {
    //     this.props.history.push({ pathname: '/home/inventory/inventoryhistory/' })
    // }
    // else {
    this.props.history.push({
      pathname: "/home/inventory/inventorylist/",
      state: { type: "list" },
    });
    // }
  }

  async onImportCompletion(importedRcords) {
    // console.log("importedRcords", importedRcords);
    let products = [];
    let productsWithInStock = [];

    if (importedRcords.length > 501) {
      alert("No of rows more than 500");
      this.setState({ showImport: false });
    } else {
      for (let index = 0; index < importedRcords.length; index++) {
        const lineItem = importedRcords[index];
        products.push(lineItem.Product.key);
      }

      let result = await this.props.getInStockQtyForProducts(
        this.props.user.store.key,
        this.state.storeLocationID,
        products
      );
      productsWithInStock = result.searchResult;
      console.log("this.props.user.store.key", this.props.user.store.key);
      console.log("this.state.storeLocationID", this.state.storeLocationID);
      console.log("productsWithInStock", productsWithInStock);
      for (let index = 0; index < productsWithInStock.length; index++) {
        const product = productsWithInStock[index];

        for (let j = 0; j < importedRcords.length; j++) {
          if (importedRcords[j].Product.key === product.key) {
            importedRcords[j].InStockQty = product.InStockQty;
          }
        }
      }

      if (this.state.transactionType.IncludePriceAndTax) {
        importedRcords.map((lineItem, index) => {
          importedRcords[index] = this.onLineItemChange(
            lineItem,
            this.state.placeOfSupply,
            this.state.computeTax,
            this.state.transactionType
          );
        });
      }
      // else {
      //     this.setState({ showImport: false, lineItems: importedRcords })
      //     this.calculateBillTotal(importedRcords, this.state.billRoundOff, this.state.billAdditionalCharges, this.state.billAdditionalDiscount, this.state.transactionType)
      // }
      importedRcords.map((lineItem, index) => {
        if (!importedRcords[index].hasOwnProperty("BatchNo")) {
          importedRcords[index].BatchNo = "";
        }
        if (!importedRcords[index].hasOwnProperty("BatchMRP")) {
          importedRcords[index].BatchMRP = "";
        }

        if (!importedRcords[index].hasOwnProperty("BatchExpDate")) {
          importedRcords[index].BatchExpDate = "";
        }

        if (!importedRcords[index].hasOwnProperty("BatchMfgDate")) {
          importedRcords[index].BatchMfgDate = "";
        }
      });
      this.setState({ showImport: false, lineItems: importedRcords });
      this.calculateBillTotal(
        importedRcords,
        this.state.billRoundOff,
        this.state.billAdditionalCharges,
        this.state.billAdditionalDiscount,
        this.state.transactionType
      );

      console.log("importedRcords", importedRcords);
    }
  }

  handleScan(e) {
    e.preventDefault();
    if (!window.cordova) {
      return;
    }
    window.cloudSky.zBar.scan(
      {
        text_title: "Scan Barcode", // Android only
        text_instructions: "Please point your camera at the Barcode.", // Android only
      },
      (barcode) => {
        this.productBarCodeSearch(barcode);
      },
      (error) => {
        alert("Scanning failed: " + error);
      }
    );
  }

  onVendorChange(vendor) {
    console.log("vendor", vendor);
    let vendorProducts = [];
    if (this.state.converted) {
      return false;
    }
    let placeOfSupply = "";
    let vendorNew = null;
    if (vendor) {
      vendorNew = {
        key: vendor.key,
        VendorCode: vendor.VendorCode,
        VendorFirstName: vendor.VendorFirstName,
        VendorLastName: vendor.VendorLastName,
        EmailID: vendor.EmailID,
        PhoneNo: vendor.PhoneNo,
        Address1: vendor.Address1,
        Address2: vendor.Address2,
        City: vendor.City,
        State: vendor.hasOwnProperty("State") ? vendor.State : "",
        StateCode: vendor.hasOwnProperty("StateCode") ? vendor.StateCode : "",
        PostalCode: vendor.PostalCode,
        Country: vendor.Country,
        CustomField1: vendor.CustomField1,
        CustomField2: vendor.CustomField1,
        CustomField3: vendor.CustomField1,
        CustomField4: vendor.CustomField1,
        CustomField5: vendor.CustomField1,
        CustomField6: vendor.CustomField1,
        StoreID: vendor.StoreID,
        RegistrationID: vendor.RegistrationID,
        IsDeleted: vendor.IsDeleted,
        VendorStoreID: vendor.hasOwnProperty("VendorStoreID")
          ? vendor.VendorStoreID
          : "",
      };
      vendorProducts = vendor.hasOwnProperty("VendorProducts")
        ? vendor.VendorProducts
        : [];
      // console.log('vendorProducts',vendorProducts)
      placeOfSupply = vendor.State ? vendor.State.trim() : "";
      if (vendorProducts.length > 0) {
        this.setState({ lineItems: [], vendorProducts: vendorProducts });
      }
    } else {
      if (this.state.vendorProducts.length > 0) {
        this.setState({ lineItems: [], vendorProducts: [] });
      }
    }
    console.log("placeOfSupply", placeOfSupply);
    if (this.state.placeOfSupply.trim() !== placeOfSupply) {
      //recalculate taxes
      let lineItems = [];
      this.state.lineItems.map((l) => {
        let lineItem = l;
        lineItem = this.onLineItemChange(
          lineItem,
          placeOfSupply,
          this.state.computeTax,
          this.state.transactionType
        );
        lineItems.push(lineItem);
      });
      this.setState({ lineItems: lineItems, vendorProducts: vendorProducts });
      this.calculateBillTotal(
        lineItems,
        this.state.billRoundOff,
        this.state.billAdditionalCharges,
        this.state.billAdditionalDiscount,
        this.state.transactionType
      );
    }
    this.setState({
      selectedVendor: vendorNew,
      placeOfSupply: placeOfSupply,
      vendorError: "",
    });
  }

  renderProductSearch() {
    return (
      <React.Fragment>
        {this.state.transactionType.TransactionType !== "Transfer In" && (
          <div
            className={
              this.state.noLineItemExistError !== ""
                ? "form-group has-error"
                : "form-group "
            }
          >
            <div className="input-group">
              {!this.state.toggleSearch && (
                <AsyncTypeahead
                  id="prodSearch"
                  autoFocus={true}
                  allowNew={false}
                  isLoading={this.state.isProductTypeAheadLoading}
                  multiple={false}
                  options={this.state.productsTypeAheadOptions}
                  labelKey="Name"
                  minLength={3}
                  onSearch={this.onProductTypeAheadSearch}
                  placeholder="Start typing to search products"
                  filterBy={["Name", "SKU", "BarCode"]}
                  // autoFocus={true}
                  dropup={false}
                  highlightOnlyResult={true}
                  selected={this.state.productsTypeAheadSelected}
                  onChange={(selected) => {
                    this.onProductSelected(selected[0]);
                    this.setState({ productsTypeAheadSelected: [] });
                  }}
                  renderMenuItemChildren={(option, props) => (
                    <div>
                      <div>
                        <b>{option.Name}</b>
                      </div>
                      <div>{option.SKU}</div>
                    </div>
                  )}
                />
              )}
              {/* {this.state.toggleSearch && (
                <input
                  type="text"
                  onChange={(evt) => {
                    this.setState({ queryText: evt.target.value });
                  }}
                  value={this.state.queryText}
                  onKeyPress={this.onProductBarCodeSearch} //
                  placeholder="Start scanning to search products"
                  className="form-control"
                  style={{ height: "33px", fontSize: "14px" }}
                />
              )} */}
              {this.state.toggleSearch && (
                <input
                  type="text"
                  ref="refProductScan"
                  onChange={(evt) => {
                    this.setState({ queryText: evt.target.value });
                  }}
                  value={this.state.queryText}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.onProductScan(this.state.queryText);
                    }
                  }}
                  placeholder="Start scanning to search products"
                  className="form-control"
                  style={{ height: "33px", fontSize: "14px" }}
                />
              )}
              <div className="input-group-btn">
                <button
                  type="button"
                  onClick={() =>
                    this.setState({ toggleSearch: !this.state.toggleSearch })
                  }
                  className="btn btn-default btn-flat btn-nocurve"
                >
                  <i className="fa fa-exchange"></i>
                </button>
                {this.state.showAddProductButton && (
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        showAddProduct: true,
                        showInventory: false,
                        scanedCode: "",
                      })
                    }
                    className="btn btn-default btn-flat btn-nocurve"
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ showImport: true });
                  }}
                  className="btn btn-default btn-flat"
                >
                  <i className="fa fa-upload"></i>Import Product
                </button>
              </div>
            </div>
            {this.state.noLineItemExistError !== "" && (
              <span className="help-block">
                {this.state.noLineItemExistError}
              </span>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  renderSection1() {
    return (
      <div className="row">
        <div className="col-md-2 col-xs-12">
          <Input
            type="date"
            label="Date"
            onChange={(value) =>
              this.setState({
                transactionDate: value,
                transactionDateError: "",
              })
            }
            value={this.state.transactionDate}
            error={this.state.transactionDateError}
          />
        </div>
        {(this.state.transactionType.TransactionMovement == "vendortostore" ||
          this.state.transactionType.TransactionMovement ==
            "storetovendor") && (
          <div className="col-md-3 col-xs-12">
            <div
              className={
                this.state.vendorError !== ""
                  ? "form-group has-error"
                  : "form-group "
              }
            >
              <label>Vendor</label>
              <VendorSearch
                {...this.props}
                vendor={this.state.selectedVendor}
                error={this.state.partyError}
                onEditVendor={() =>
                  this.setState({
                    showInventory: false,
                    showVendor: true,
                    vendorAction: actionTypes.VENDOR_EDIT,
                  })
                }
                onAddVendor={() =>
                  this.setState({
                    showInventory: false,
                    showVendor: true,
                    vendorAction: actionTypes.VENDOR_NEW,
                  })
                }
                onChange={(vendor) => {
                  this.onVendorChange(vendor);
                }}
              />
              {this.state.vendorError !== "" && (
                <span className="help-block">{this.state.vendorError}</span>
              )}
            </div>
          </div>
        )}
        {(this.state.transactionType.TransactionMovement == "vendortostore" ||
          this.state.transactionType.TransactionMovement == "storetovendor" ||
          this.state.transactionType.TransactionMovement == "store" ||
          this.state.transactionType.TransactionMovement == "storetostore" ||
          this.state.transactionType.TransactionMovement ==
            "storelocationtostorelocation") && (
          <div className="col-md-2 col-xs-12">
            <Input
              type="select"
              label={
                this.state.transactionType.TransactionMovement ==
                "storelocationtostorelocation"
                  ? "From Location"
                  : "Store Location"
              }
              value={this.state.storeLocationID}
              onChange={(value) => {
                this.onStoreLocationChange(value);
              }}
              error={this.state.storeLocationError}
            >
              <option value="">Select Store Location</option>
              {this.state.storeLocations
                .filter((e) => !e.IsDeleted)
                .map((storeLocation) => (
                  <option key={storeLocation.key} value={storeLocation.key}>
                    {storeLocation.LocationCode} {storeLocation.LocationName}
                  </option>
                ))}
            </Input>
          </div>
        )}
        {this.state.transactionType.TransactionMovement ==
          "storelocationtostorelocation" && (
          <div className="col-md-2 col-xs-12">
            <Input
              type="select"
              label="To Location"
              value={this.state.toLocationID}
              onChange={(value) => {
                this.onToStoreLocationChange(value);
              }}
              error={this.state.toLocationError}
            >
              <option value="">Select Store Location</option>
              {this.state.storeLocations
                .filter((e) => !e.IsDeleted)
                .map((storeLocation) => (
                  <option key={storeLocation.key} value={storeLocation.key}>
                    {storeLocation.LocationCode} {storeLocation.LocationName}
                  </option>
                ))}
            </Input>
          </div>
        )}
        <div className="col-md-2 col-xs-12">
          <Input
            type="text"
            label="Ref No"
            onChange={(value) => this.setState({ refNo: value })}
            value={this.state.refNo}
          />
        </div>
        {this.state.transactionType.TransactionMovement == "storetostore" && (
          <div className="col-md-2 col-xs-12">
            <Input
              type="select"
              label="To Store"
              value={this.state.toStoreID}
              onChange={(value) => {
                this.onStoreChange(value);
              }}
              error={this.state.toStoreError}
            >
              <option value="">Select Store </option>
              {this.state.toStoreList.map((store) => (
                <option value={store.key}>
                  {store.StoreCode} {store.StoreName}
                </option>
              ))}
            </Input>
          </div>
        )}
      </div>
    );
  }

  renderSection2() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-5">{this.renderProductSearch()} </div>
          {this.state.transactionType.PrePopulateProducts && (
            <div className="col-md-2">
              <button
                className="btn btn-default btn-flat"
                onClick={() => {
                  let newLineItem = [];
                  let tmp = this.state.lineItems;
                  for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].Qty !== 0) {
                      newLineItem.push(tmp[i]);
                    }
                  }
                  this.setState({ lineItems: newLineItem });
                  this.calculateBillTotal(
                    newLineItem,
                    this.state.billRoundOff,
                    this.state.billAdditionalCharges,
                    this.state.billAdditionalDiscount,
                    this.state.transactionType
                  );
                }}
              >
                Remove 0 qty products
              </button>
            </div>
          )}
          <div className="col-md-2">
            {this.state.vendorProducts.length > 0 && (
              <button
                className="btn btn-default btn-flat"
                onClick={() => {
                  this.setState({
                    showVendorProductsForSelection: true,
                    filteredVendorProducts: this.state.vendorProducts,
                  });
                }}
              >
                Add {this.state.vendorProducts.length} products
              </button>
            )}
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          {this.state.bulkPurchaseReturns.length > 0 && (
            <div className="col-md-12">
              {this.state.bulkPurchaseReturns.map((m) => (
                <div className="col-md-12">{m.TransactionNo}</div>
              ))}
            </div>
          )}
          <div className="col-md-12">
            {this.state.transactionType && (
              <React.Fragment>
                {isMobileApp() && (
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th style={{ width: "10px" }}>#</th>
                        <th>Product</th>
                        <th style={{ width: "56px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.lineItems.map((lineItem, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div>
                              {/* <a onClick={() => { this.onLineItemSelected(lineItem, index) }}> */}
                              <div>{lineItem.Product.Name}</div>
                              <div className="sales-sub-text">
                                SKU - {lineItem.Product.SKU}
                              </div>
                              {lineItem.Product.BarCode !== "" && (
                                <div className="sales-sub-text">
                                  Barcode - {lineItem.Product.BarCode}{" "}
                                </div>
                              )}
                              {lineItem.HSNSACCode !== "" && (
                                <div className="sales-sub-text">
                                  HSNSACCode - {lineItem.HSNSACCode}{" "}
                                </div>
                              )}

                              {/* </a> */}
                            </div>
                            <div>MRP: {lineItem.Product.MRP}</div>
                            {this.state.transactionType.IncludePriceAndTax && (
                              <div>Supply Price: {lineItem.SupplyPrice}</div>
                            )}
                            {(this.state.transactionType.TransactionType ===
                              "Transfer In" ||
                              (this.props.location.state.inventory &&
                                this.props.location.state.inventory.hasOwnProperty(
                                  "SaleToPurchase"
                                ))) && (
                              <div>
                                Dispatched Qty: {lineItem.DispatchedQty}
                              </div>
                            )}
                            {/* non unique no products*/}
                            <div>Qty: {lineItem.LineItemQty}</div>
                            {this.state.transactionType.UpdateStockChecked &&
                              lineItem.Product.hasOwnProperty(
                                "InventoryType"
                              ) &&
                              lineItem.Product.InventoryType.toLowerCase() ===
                                "unique no" && (
                                <div>
                                  <button
                                    type="submit"
                                    style={{ width: "100%" }}
                                    className="btn btn-md btn-default btn-flat pull-right"
                                    onClick={() =>
                                      this.setState({
                                        showUniqueNoInput: true,
                                        selectedLineItem: lineItem,
                                        selectedLineItemIndex: index,
                                        selectedProduct: lineItem.Product,
                                      })
                                    }
                                  >
                                    {lineItem.Product.UniqueLabel.trim() !== ""
                                      ? lineItem.Product.UniqueLabel
                                      : "Unique No"}
                                  </button>
                                </div>
                              )}
                            {this.state.transactionType.UpdateStockChecked &&
                              (!lineItem.Product.hasOwnProperty(
                                "InventoryType"
                              ) ||
                                (lineItem.Product.hasOwnProperty(
                                  "InventoryType"
                                ) &&
                                  lineItem.Product.InventoryType.toLowerCase() !==
                                    "unique no")) && (
                                <div>Free Qty: {lineItem.FreeQty}</div>
                              )}
                            <div>UOM:{lineItem.UOM} </div>
                            <div>In Stock Qty: {lineItem.InStockQty}</div>
                            {this.state.transactionType.IncludePriceAndTax && (
                              <div>Sub Total :{lineItem.SubTotal}</div>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <div> Discount: {lineItem.DiscountAmount}</div>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <div>
                                {lineItem.TaxGroup}
                                {lineItem.TaxComponentAmount &&
                                  lineItem.TaxComponentAmount.map((t) => (
                                    <div className="sales-sub-text">
                                      {t.TaxName} {t.TaxPercentage}%:{" "}
                                      {t.TaxAmount}
                                    </div>
                                  ))}
                              </div>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <div>Total Tax: {lineItem.TotalTax}</div>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <div>
                                Margin %:
                                {lineItem.hasOwnProperty("MarginPercentage")
                                  ? lineItem.MarginPercentage
                                  : 0}
                              </div>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <div>
                                Profit %:{" "}
                                {lineItem.hasOwnProperty("ProfitPercentage")
                                  ? lineItem.ProfitPercentage
                                  : 0}
                              </div>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <div>Amount: {lineItem.Amount}</div>
                            )}
                            {!this.props.registration.registration
                              .EnablePosBillingForPincode && (
                              <div className="sales-sub-text">
                                {lineItem.Product.hasOwnProperty(
                                  "CustomField1"
                                ) &&
                                  lineItem.Product.CustomField1.trim() !== "" &&
                                  this.state.productCustomField1DisplayName.trim() !==
                                    "" && (
                                    <React.Fragment>
                                      {
                                        this.state
                                          .productCustomField1DisplayName
                                      }
                                      : {lineItem.Product.CustomField1}
                                    </React.Fragment>
                                  )}
                                {lineItem.Product.hasOwnProperty(
                                  "CustomField2"
                                ) &&
                                  lineItem.Product.CustomField2.trim() !== "" &&
                                  this.state.productCustomField2DisplayName.trim() !==
                                    "" && (
                                    <React.Fragment>
                                      ,{" "}
                                      {
                                        this.state
                                          .productCustomField2DisplayName
                                      }
                                      : {lineItem.Product.CustomField2}
                                    </React.Fragment>
                                  )}
                                {lineItem.Product.hasOwnProperty(
                                  "CustomField3"
                                ) &&
                                  lineItem.Product.CustomField3.trim() !== "" &&
                                  this.state.productCustomField3DisplayName.trim() !==
                                    "" && (
                                    <React.Fragment>
                                      ,{" "}
                                      {
                                        this.state
                                          .productCustomField3DisplayName
                                      }
                                      : {lineItem.Product.CustomField3}
                                    </React.Fragment>
                                  )}
                                {lineItem.Product.hasOwnProperty(
                                  "CustomField4"
                                ) &&
                                  lineItem.Product.CustomField4.trim() !== "" &&
                                  this.state.productCustomField4DisplayName.trim() !==
                                    "" && (
                                    <React.Fragment>
                                      ,{" "}
                                      {
                                        this.state
                                          .productCustomField4DisplayName
                                      }
                                      : {lineItem.Product.CustomField4}
                                    </React.Fragment>
                                  )}
                                {lineItem.Product.hasOwnProperty(
                                  "CustomField5"
                                ) &&
                                  lineItem.Product.CustomField5.trim() !== "" &&
                                  this.state.productCustomField5DisplayName.trim() !==
                                    "" && (
                                    <React.Fragment>
                                      ,{" "}
                                      {
                                        this.state
                                          .productCustomField5DisplayName
                                      }
                                      : {lineItem.Product.CustomField5}
                                    </React.Fragment>
                                  )}
                                {lineItem.Product.hasOwnProperty(
                                  "CustomField6"
                                ) &&
                                  lineItem.Product.CustomField6.trim() !== "" &&
                                  this.state.productCustomField6DisplayName.trim() !==
                                    "" && (
                                    <React.Fragment>
                                      ,{" "}
                                      {
                                        this.state
                                          .productCustomField6DisplayName
                                      }
                                      : {lineItem.Product.CustomField6}
                                    </React.Fragment>
                                  )}
                              </div>
                            )}
                            {lineItem.BatchNo && (
                              <div className="sales-sub-text">
                                Batch No: {lineItem.BatchNo}
                              </div>
                            )}
                            {lineItem.BatchMRP && (
                              <div className="sales-sub-text">
                                Batch MRP: {lineItem.BatchMRP}
                              </div>
                            )}
                            {lineItem.BatchMfgDate && (
                              <div className="sales-sub-text">
                                Batch Mfg: {lineItem.BatchMfgDate}
                              </div>
                            )}
                            {lineItem.BatchExpDate && (
                              <div className="sales-sub-text">
                                Batch Exp: {lineItem.BatchExpDate}
                              </div>
                            )}
                            {lineItem.Notes !== "" && (
                              <div className="sales-sub-text">
                                Notes - {lineItem.Notes}{" "}
                              </div>
                            )}
                            {lineItem.Error !== "" && (
                              <span className="help-block">
                                {lineItem.Error}
                              </span>
                            )}
                            {this.state.bulkPurchaseReturn && (
                              <div>
                                Purchase Entry:{" "}
                                {lineItem.ParentTransactionNo === ""
                                  ? "Not found"
                                  : lineItem.ParentTransactionNo}
                              </div>
                            )}
                          </td>
                          <td>
                            {this.state.transactionType.TransactionType !==
                              "Transfer In" && (
                              <React.Fragment>
                                <ImageButton
                                  type="edit"
                                  label=""
                                  onClick={() =>
                                    this.onLineItemSelected(lineItem, index)
                                  }
                                />
                                <ImageButton
                                  type="delete"
                                  label=""
                                  onClick={() => this.onRemoveLineItem(index)}
                                />
                                {/* <a onClick={() => this.onRemoveLineItem(index)}><i className="fa fa-lg fa-trash-o pull-right" style={{ margin: "10px" }}></i></a> */}
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {!isMobileApp() && (
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th style={{ width: "10px" }}>#</th>
                        <th>Product</th>
                        <th style={{ width: "100px" }}>MRP / Retail Price</th>
                        {this.state.transactionType.IncludePriceAndTax && (
                          <th style={{ width: "200px" }}>Supply Price</th>
                        )}
                        {(this.state.transactionType.TransactionType ===
                          "Transfer In" ||
                          (this.props.location.state.inventory &&
                            this.props.location.state.inventory.hasOwnProperty(
                              "SaleToPurchase"
                            ))) && (
                          <th style={{ width: "100px" }}>Dispatched Qty</th>
                        )}
                        <th style={{ width: "120px", textAlign: "right" }}>
                          Qty
                        </th>
                        {this.state.transactionType.UpdateStockChecked &&
                          !this.state.transactionType.HideFreeQty && (
                            <th style={{ width: "90px" }}>Free Qty</th>
                          )}
                        {this.state.transactionType.UpdateStockChecked &&
                          !this.state.transactionType.HideFreeQty && (
                            <th style={{ width: "120px" }}>Total Qty</th>
                          )}
                        <th style={{ width: "90px" }}>Unit</th>
                        <th style={{ width: "80px", textAlign: "right" }}>
                          In Stock
                        </th>
                        {this.state.transactionType.IncludePriceAndTax && (
                          <th style={{ width: "100px" }}>Sub Total</th>
                        )}
                        {this.state.transactionType.IncludePriceAndTax && (
                          <th style={{ width: "50px" }}>Dis</th>
                        )}
                        {this.state.transactionType.IncludePriceAndTax && (
                          <th style={{ width: "100px" }}>Tax</th>
                        )}
                        {this.state.transactionType.IncludePriceAndTax && (
                          <th style={{ width: "100px" }}>Tax Amt</th>
                        )}
                        {this.state.transactionType.IncludePriceAndTax && (
                          <th style={{ width: "100px" }}>Margin %</th>
                        )}
                        {this.state.transactionType.IncludePriceAndTax && (
                          <th style={{ width: "100px" }}>Profit %</th>
                        )}
                        {this.state.transactionType.IncludePriceAndTax && (
                          <th style={{ width: "100px" }}>Total</th>
                        )}
                        {this.state.transactionType.TransactionType !==
                          "Transfer In" && <th style={{ width: "40px" }}></th>}
                      </tr>
                    </thead>
                    {!this.state.loading && (
                      <tbody>
                        {this.state.lineItems.map((lineItem, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div
                                className={
                                  lineItem.Error && lineItem.Error !== ""
                                    ? "form-group has-error"
                                    : "form-group "
                                }
                              >
                                <div className="product-with-image">
                                  {lineItem.Product.Images &&
                                    lineItem.Product.Images.length > 0 && (
                                      <div>
                                        <img
                                          className="list-page-image"
                                          src={
                                            lineItem.Product.Images[0].ImagePath
                                          }
                                        />
                                      </div>
                                    )}
                                  <div>
                                    <a
                                      onClick={() => {
                                        this.onLineItemSelected(
                                          lineItem,
                                          index
                                        );
                                      }}
                                    >
                                      {lineItem.Product.Name}
                                      <div className="sales-sub-text">
                                        SKU - {lineItem.Product.SKU}
                                      </div>
                                      {lineItem.Product.BarCode !== "" && (
                                        <div className="sales-sub-text">
                                          Barcode - {lineItem.Product.BarCode}{" "}
                                        </div>
                                      )}

                                      {lineItem.Error !== "" && (
                                        <span className="help-block">
                                          {lineItem.Error}
                                        </span>
                                      )}
                                      {this.state.bulkPurchaseReturn && (
                                        <div>
                                          Purchase Entry:{" "}
                                          {lineItem.ParentTransactionNo === ""
                                            ? "Not found"
                                            : lineItem.ParentTransactionNo}
                                        </div>
                                      )}
                                      {lineItem.InventoryIssue &&
                                        lineItem.InventoryIssue !== "" && (
                                          <div className="badge bg-lg bg-red">
                                            Issue - {lineItem.InventoryIssue}{" "}
                                          </div>
                                        )}
                                    </a>
                                    {lineItem.HSNSACCode !== "" && (
                                      <div className="sales-sub-text">
                                        HSNSACCode - {lineItem.HSNSACCode}{" "}
                                      </div>
                                    )}
                                    {!this.props.registration.registration
                                      .EnablePosBillingForPincode && (
                                      <div className="sales-sub-text">
                                        {lineItem.Product.hasOwnProperty(
                                          "CustomField1"
                                        ) &&
                                          lineItem.Product.CustomField1.toString().trim() !==
                                            "" &&
                                          this.state.productCustomField1DisplayName.trim() !==
                                            "" && (
                                            <React.Fragment>
                                              {
                                                this.state
                                                  .productCustomField1DisplayName
                                              }
                                              : {lineItem.Product.CustomField1}
                                            </React.Fragment>
                                          )}
                                        {lineItem.Product.hasOwnProperty(
                                          "CustomField2"
                                        ) &&
                                          lineItem.Product.CustomField2.toString().trim() !==
                                            "" &&
                                          this.state.productCustomField2DisplayName.trim() !==
                                            "" && (
                                            <React.Fragment>
                                              ,{" "}
                                              {
                                                this.state
                                                  .productCustomField2DisplayName
                                              }
                                              : {lineItem.Product.CustomField2}
                                            </React.Fragment>
                                          )}
                                        {lineItem.Product.hasOwnProperty(
                                          "CustomField3"
                                        ) &&
                                          lineItem.Product.CustomField3.toString().trim() !==
                                            "" &&
                                          this.state.productCustomField3DisplayName.trim() !==
                                            "" && (
                                            <React.Fragment>
                                              ,{" "}
                                              {
                                                this.state
                                                  .productCustomField3DisplayName
                                              }
                                              : {lineItem.Product.CustomField3}
                                            </React.Fragment>
                                          )}
                                        {lineItem.Product.hasOwnProperty(
                                          "CustomField4"
                                        ) &&
                                          lineItem.Product.CustomField4.toString().trim() !==
                                            "" &&
                                          this.state.productCustomField4DisplayName.trim() !==
                                            "" && (
                                            <React.Fragment>
                                              ,{" "}
                                              {
                                                this.state
                                                  .productCustomField4DisplayName
                                              }
                                              : {lineItem.Product.CustomField4}
                                            </React.Fragment>
                                          )}
                                        {lineItem.Product.hasOwnProperty(
                                          "CustomField5"
                                        ) &&
                                          lineItem.Product.CustomField5.toString().trim() !==
                                            "" &&
                                          this.state.productCustomField5DisplayName.trim() !==
                                            "" && (
                                            <React.Fragment>
                                              ,{" "}
                                              {
                                                this.state
                                                  .productCustomField5DisplayName
                                              }
                                              : {lineItem.Product.CustomField5}
                                            </React.Fragment>
                                          )}
                                        {lineItem.Product.hasOwnProperty(
                                          "CustomField6"
                                        ) &&
                                          lineItem.Product.CustomField6.toString().trim() !==
                                            "" &&
                                          this.state.productCustomField6DisplayName.trim() !==
                                            "" && (
                                            <React.Fragment>
                                              ,{" "}
                                              {
                                                this.state
                                                  .productCustomField6DisplayName
                                              }
                                              : {lineItem.Product.CustomField6}
                                            </React.Fragment>
                                          )}
                                      </div>
                                    )}
                                    {lineItem.BatchNo && (
                                      <div className="sales-sub-text">
                                        Batch No: {lineItem.BatchNo}
                                      </div>
                                    )}
                                    {lineItem.BatchMRP && (
                                      <div className="sales-sub-text">
                                        Batch MRP: {lineItem.BatchMRP}
                                      </div>
                                    )}
                                    {lineItem.BatchMfgDate && (
                                      <div className="sales-sub-text">
                                        Batch Mfg: {lineItem.BatchMfgDate}
                                      </div>
                                    )}
                                    {lineItem.BatchExpDate && (
                                      <div className="sales-sub-text">
                                        Batch Exp: {lineItem.BatchExpDate}
                                      </div>
                                    )}
                                    {lineItem.Notes !== "" && (
                                      <div className="sales-sub-text">
                                        Notes - {lineItem.Notes}{" "}
                                      </div>
                                    )}
                                    {lineItem.ProductPackagingQtyCheck &&
                                      lineItem.ProductPackagingQtyGC >
                                        lineItem.ProductPackagingQty &&
                                      lineItem.ProductPackagingQty !== "" && (
                                        <div className="error-msg">
                                          Wrong grammage/weight/volume
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              className="number"
                              style={{ textAlign: "right" }}
                            >
                              {lineItem.Product.MRP} /{" "}
                              {lineItem.Product.RetailPrice}
                              {this.props.registration.registration
                                .EnablePosBillingForPincode && (
                                <div>
                                  <a
                                    onClick={() => {
                                      console.log("lineItem.Product", lineItem);
                                      this.setState({
                                        newMRP: true,
                                        selectedProductID: lineItem.Product.key,
                                        selectedLineItemIndex: index,
                                        showAddProduct: true,
                                        // showInventory: false,
                                      });
                                    }}
                                  >
                                    New MRP
                                  </a>
                                </div>
                              )}
                            </td>
                            {this.state.transactionType.IncludePriceAndTax && (
                              <td style={{ textAlign: "right" }}>
                                {/* {this.state.transactionType.TransactionType !== 'Transfer In' && */}
                                {!this.disableQtyEdit(lineItem) && (
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(evt) =>
                                      this.onLineItemPriceChange(
                                        index,
                                        evt.target.value
                                      )
                                    }
                                    disabled={!lineItem.Product.AllowPriceEdit}
                                    // onKeyPress={this.handleKeyPress}
                                    value={lineItem.SupplyPrice}
                                  />
                                )}
                                {/* {this.state.transactionType.TransactionType === 'Transfer In' &&  */}
                                {this.disableQtyEdit(lineItem) &&
                                  lineItem.SupplyPrice}
                              </td>
                            )}
                            {(this.state.transactionType.TransactionType ===
                              "Transfer In" ||
                              (this.props.location.state.inventory &&
                                this.props.location.state.inventory.hasOwnProperty(
                                  "SaleToPurchase"
                                ))) && (
                              <td
                                style={{ width: "100px", textAlign: "right" }}
                              >
                                {lineItem.DispatchedQty}
                              </td>
                            )}
                            <td style={{ textAlign: "right" }}>
                              {/* non unique no products*/}
                              {(!lineItem.Product.hasOwnProperty(
                                "InventoryType"
                              ) ||
                                (lineItem.Product.hasOwnProperty(
                                  "InventoryType"
                                ) &&
                                  lineItem.Product.InventoryType.toLowerCase() !==
                                    "unique no")) && (
                                <React.Fragment>
                                  {/* {this.state.transactionType.TransactionType !== 'Transfer In' && */}
                                  {!this.disableQtyEdit(lineItem) && (
                                    <input
                                      type="number"
                                      className="form-control"
                                      onChange={(evt) =>
                                        this.onLineItemQtyChange(
                                          index,
                                          evt.target.value
                                        )
                                      }
                                      value={lineItem.LineItemQty}
                                    />
                                  )}

                                  {/* {this.state.transactionType.TransactionType === 'Transfer In' &&  */}
                                  {this.disableQtyEdit(lineItem) &&
                                    lineItem.LineItemQty}
                                </React.Fragment>
                              )}

                              {this.state.transactionType.UpdateStockChecked &&
                                lineItem.Product.hasOwnProperty(
                                  "InventoryType"
                                ) &&
                                lineItem.Product.InventoryType.toLowerCase() ===
                                  "unique no" && (
                                  <React.Fragment>
                                    <div>{lineItem.LineItemQty}</div>
                                    {/* {this.state.transactionType.TransactionType !== 'Transfer In' &&  */}
                                    {!this.disableQtyEdit(lineItem) && (
                                      <div>
                                        <button
                                          type="submit"
                                          style={{ width: "100%" }}
                                          className="btn btn-md btn-default btn-flat pull-right"
                                          onClick={() =>
                                            this.setState({
                                              showUniqueNoInput: true,
                                              selectedLineItem: lineItem,
                                              selectedLineItemIndex: index,
                                              selectedProduct: lineItem.Product,
                                            })
                                          }
                                        >
                                          {lineItem.Product.UniqueLabel.trim() !==
                                          ""
                                            ? lineItem.Product.UniqueLabel
                                            : "Unique No"}
                                        </button>
                                      </div>
                                    )}
                                  </React.Fragment>
                                )}
                            </td>
                            {this.state.transactionType.UpdateStockChecked &&
                              !this.state.transactionType.HideFreeQty && (
                                <td style={{ textAlign: "right" }}>
                                  {(!lineItem.Product.hasOwnProperty(
                                    "InventoryType"
                                  ) ||
                                    (lineItem.Product.hasOwnProperty(
                                      "InventoryType"
                                    ) &&
                                      lineItem.Product.InventoryType.toLowerCase() !==
                                        "unique no")) && (
                                    <React.Fragment>
                                      {/* {this.state.transactionType.TransactionType !== 'Transfer In' &&  */}
                                      {!this.disableQtyEdit(lineItem) && (
                                        <input
                                          type="number"
                                          className="form-control"
                                          onChange={(evt) =>
                                            this.onLineItemFreeQtyChange(
                                              index,
                                              evt.target.value
                                            )
                                          }
                                          value={lineItem.FreeQty}
                                        />
                                      )}

                                      {/* {this.state.transactionType.TransactionType === 'Transfer In' &&  */}
                                      {this.disableQtyEdit(lineItem) &&
                                        lineItem.FreeQty}
                                    </React.Fragment>
                                  )}
                                </td>
                              )}
                            {this.state.transactionType.UpdateStockChecked &&
                              !this.state.transactionType.HideFreeQty && (
                                <td style={{ textAlign: "right" }}>
                                  {/* <input type="number" className="form-control" value={lineItem.Qty} /> */}
                                  {lineItem.Qty}
                                </td>
                              )}
                            <td>
                              {lineItem.Product.UOM &&
                                lineItem.Product.UOM !== "" && (
                                  <select
                                    className="form-control"
                                    value={lineItem.UOM}
                                    onChange={(e) => {
                                      let lineItems = this.state.lineItems;
                                      let supplyPrice = lineItem.SupplyPrice;
                                      if (
                                        e.target.value !== "" &&
                                        lineItem.Product.SecondaryUOMs &&
                                        lineItem.Product.SecondaryUOMs.length >
                                          0
                                      ) {
                                        ///current selected uom to
                                        let uoms = [];
                                        lineItem.Product.SecondaryUOMs.map(
                                          (m) => uoms.push(m)
                                        );
                                        uoms.push({
                                          SecondaryUOMValue:
                                            lineItem.Product.UOM,
                                          Conversion: 1,
                                        });

                                        let currentConversionVlaue = 0;
                                        let tmp1 = uoms.filter(
                                          (f) =>
                                            f.SecondaryUOMValue === lineItem.UOM
                                        );
                                        if (tmp1.length > 0) {
                                          currentConversionVlaue =
                                            tmp1[0].Conversion;
                                        }
                                        let supplyPriceForPurchaseUOM =
                                          supplyPrice / currentConversionVlaue;

                                        ///let new uom to
                                        let tmp = uoms.filter(
                                          (f) =>
                                            f.SecondaryUOMValue ===
                                            e.target.value
                                        );
                                        if (tmp.length > 0) {
                                          let conversionValue =
                                            tmp[0].Conversion; ///conversion value with respect to primary UOM
                                          supplyPrice =
                                            supplyPriceForPurchaseUOM *
                                            conversionValue;
                                        }
                                      }
                                      lineItems[index].UOM = e.target.value;
                                      lineItems[
                                        index
                                      ].SupplyPrice = supplyPrice;

                                      this.setState({ lineItems: lineItems });
                                      this.onLineItemPriceChange(
                                        index,
                                        supplyPrice
                                      );
                                    }}
                                  >
                                    <option value={lineItem.Product.UOM}>
                                      {this.state.uoms.filter(
                                        (f) => f.value === lineItem.Product.UOM
                                      ).length > 0
                                        ? this.state.uoms.filter(
                                            (f) =>
                                              f.value === lineItem.Product.UOM
                                          )[0].text
                                        : ""}
                                    </option>
                                    {lineItem.Product.hasOwnProperty(
                                      "SecondaryUOMs"
                                    ) &&
                                      lineItem.Product.SecondaryUOMs.length >
                                        0 && (
                                        <React.Fragment>
                                          {" "}
                                          {lineItem.Product.SecondaryUOMs.map(
                                            (t) => (
                                              <option
                                                value={t.SecondaryUOMValue}
                                              >
                                                {t.SecondaryUOM}
                                              </option>
                                            )
                                          )}
                                        </React.Fragment>
                                      )}
                                  </select>
                                )}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {lineItem.InStockQty}
                            </td>
                            {this.state.transactionType.IncludePriceAndTax && (
                              <td style={{ textAlign: "right" }}>
                                {lineItem.SubTotal}
                              </td>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <td style={{ textAlign: "right" }}>
                                {lineItem.DiscountAmount}
                                <div className="sales-sub-text">
                                  {lineItem.DiscountPer} %
                                </div>
                              </td>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <td>
                                {lineItem.TaxGroup}
                                {lineItem.TaxComponentAmount &&
                                  lineItem.TaxComponentAmount.map((t) => (
                                    <div className="sales-sub-text">
                                      {t.TaxName} {t.TaxPercentage}%:
                                      {t.TaxAmount}
                                    </div>
                                  ))}
                              </td>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <td style={{ textAlign: "right" }}>
                                {lineItem.TotalTax}
                              </td>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <td style={{ textAlign: "right" }}>
                                {lineItem.hasOwnProperty("MarginPercentage")
                                  ? lineItem.MarginPercentage
                                  : 0}
                              </td>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <td style={{ textAlign: "right" }}>
                                {lineItem.hasOwnProperty("ProfitPercentage")
                                  ? lineItem.ProfitPercentage
                                  : 0}
                              </td>
                            )}
                            {this.state.transactionType.IncludePriceAndTax && (
                              <td style={{ textAlign: "right" }}>
                                {lineItem.Amount}
                              </td>
                            )}
                            {this.state.transactionType.TransactionType !==
                              "Transfer In" && (
                              <td>
                                <a onClick={() => this.onRemoveLineItem(index)}>
                                  <i
                                    className="fa fa-lg fa-trash-o pull-right"
                                    style={{ margin: "10px" }}
                                  ></i>
                                </a>
                              </td>
                            )}
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          {this.state.transactionType.IncludePriceAndTax && (
                            <td></td>
                          )}
                          {(this.state.transactionType.TransactionType ===
                            "Transfer In" ||
                            (this.props.location.state.inventory &&
                              this.props.location.state.inventory.hasOwnProperty(
                                "SaleToPurchase"
                              ))) && <td></td>}
                          <td style={{ textAlign: "right" }}>
                            <b>{this.state.billLineItemQty}</b>
                          </td>
                          {this.state.transactionType.UpdateStockChecked &&
                            !this.state.transactionType.HideFreeQty && (
                              <td style={{ textAlign: "right" }}>
                                <b>{this.state.billFreeQty}</b>
                              </td>
                            )}
                          {this.state.transactionType.UpdateStockChecked &&
                            !this.state.transactionType.HideFreeQty && (
                              <td style={{ textAlign: "right" }}>
                                <b>{this.state.billQty}</b>
                              </td>
                            )}
                          <td></td>
                          <td></td>
                          {this.state.transactionType.IncludePriceAndTax && (
                            <td style={{ textAlign: "right" }}>
                              <b>{this.state.billSubTotal}</b>
                            </td>
                          )}
                          {this.state.transactionType.IncludePriceAndTax && (
                            <td style={{ textAlign: "right" }}>
                              <b>{this.state.billDiscount}</b>
                            </td>
                          )}
                          {this.state.transactionType.IncludePriceAndTax && (
                            <td></td>
                          )}
                          {this.state.transactionType.IncludePriceAndTax && (
                            <td style={{ textAlign: "right" }}>
                              <b>{this.state.billTaxAmount}</b>
                            </td>
                          )}
                          {this.state.transactionType.IncludePriceAndTax && (
                            <td style={{ textAlign: "right" }}></td>
                          )}
                          {this.state.transactionType.IncludePriceAndTax && (
                            <td style={{ textAlign: "right" }}></td>
                          )}
                          {this.state.transactionType.IncludePriceAndTax && (
                            <td style={{ textAlign: "right" }}>
                              <b>{this.state.billLineItemTotal}</b>
                            </td>
                          )}
                          {this.state.transactionType.TransactionType !==
                            "Transfer In" && <td></td>}
                        </tr>
                      </tbody>
                    )}
                  </table>
                )}
              </React.Fragment>
            )}

            {this.state.loading && <Loader show={this.state.loading} />}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSection3Totals() {
    return (
      <React.Fragment>
        {this.state.transactionType &&
          this.state.transactionType.IncludePriceAndTax && (
            <div className="col-md-3 col-xs-12 pull-right">
              {!this.state.bulkPurchaseReturn && (
                <div className="inventory-totals">
                  <div className="right w50 inventory-totals-label">
                    Additional Discount:
                  </div>
                  <div className="right w50">
                    <input
                      type="number"
                      style={{ textAlign: "right" }}
                      className="form-control"
                      onChange={(evt) =>
                        this.calculateBillTotal(
                          this.state.lineItems,
                          this.state.billRoundOff,
                          this.state.billAdditionalCharges,
                          evt.target.value,
                          this.state.transactionType
                        )
                      }
                      value={this.state.billAdditionalDiscount}
                    />
                  </div>
                </div>
              )}
              {!this.state.bulkPurchaseReturn && (
                <div className="inventory-totals">
                  <div className="right w50 inventory-totals-label">
                    Additional Charges:
                  </div>
                  <div className="right w50">
                    <input
                      type="number"
                      style={{ textAlign: "right" }}
                      className="form-control"
                      onChange={(evt) =>
                        this.calculateBillTotal(
                          this.state.lineItems,
                          this.state.billRoundOff,
                          evt.target.value,
                          this.state.billAdditionalDiscount,
                          this.state.transactionType
                        )
                      }
                      value={this.state.billAdditionalCharges}
                    />
                  </div>
                </div>
              )}
              {!this.state.bulkPurchaseReturn && (
                <div className="inventory-totals">
                  <div className="right w50 inventory-totals-label">
                    Round Off:
                  </div>
                  <div className="right w50">
                    <input
                      type="number"
                      style={{ textAlign: "right" }}
                      className="form-control"
                      onChange={(evt) =>
                        this.calculateBillTotal(
                          this.state.lineItems,
                          evt.target.value,
                          this.state.billAdditionalCharges,
                          this.state.billAdditionalDiscount,
                          this.state.transactionType
                        )
                      }
                      value={this.state.billRoundOff}
                    />
                  </div>
                </div>
              )}
              <div className="inventory-totals">
                <div className="right w50 inventory-totals-label">
                  <b>Total Amount:</b>
                </div>
                <div className="right w50">
                  <input
                    type="number"
                    style={{ textAlign: "right" }}
                    className="form-control"
                    value={this.state.billAmount}
                  />
                </div>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  }

  renderSection3() {
    const styleEditor = {
      width: "100%",
      height: "150px",
      font_size: "14px",
      line_height: "18px",
      border: "1px solid #dddddd",
      padding: "10px",
    };

    return (
      <div className="row">
        {/* {(this.state.transactionType && this.props.isMobileApp() && this.state.transactionType.IncludePriceAndTax) && <div className="col-xs-12">
            <div className="col-xs-6">Add Discount:</div>
            <div className="col-xs-6"><input type="number"
                className="form-control"
                onChange={(evt) => this.calculateBillTotal(this.state.lineItems, this.state.billRoundOff, this.state.billAdditionalCharges, evt.target.value, this.state.transactionType)}
                value={this.state.billAdditionalDiscount}
            />
            </div>
            <div className="col-xs-6">Add Charges:</div>
            <div className="col-xs-6"><input type="number"
                className="form-control"
                onChange={(evt) => this.calculateBillTotal(this.state.lineItems, this.state.billRoundOff, evt.target.value, this.state.billAdditionalDiscount, this.state.transactionType)}
                value={this.state.billAdditionalCharges}
            />
            </div>
            <div className="col-xs-6">Round Off:</div>
            <div className="col-xs-6"><input type="number"
                className="form-control"
                onChange={(evt) => this.calculateBillTotal(this.state.lineItems, evt.target.value, this.state.billAdditionalCharges, this.state.billAdditionalDiscount, this.state.transactionType)}
                value={this.state.billRoundOff}
            />
            </div>
            <div className="col-xs-6"><b>Total Amt:</b></div>
            <div className="col-xs-6"><b>{this.state.billAmount}</b></div>
        </div>} */}

        {isMobileApp() && this.renderSection3Totals()}

        <div className="col-md-4 col-xs-12">
          <div className="form-group">
            <label>Notes</label>
            <textarea
              className="textarea"
              style={styleEditor}
              onChange={(evt) => this.setState({ notes: evt.target.value })}
              value={this.state.notes}
            ></textarea>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          {this.state.transactionType &&
            this.state.transactionType.IncludePriceAndTax && (
              <div className="form-group">
                <label>Tax Summary</label>
                {this.state.taxSummaryList && (
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>HSN</th>
                        <th>Qty</th>
                        <th>Taxable Amount</th>
                        <th>Tax</th>
                        <th>Tax Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.taxSummaryList.map((t) => (
                        <tr>
                          <td>{t.HSNSACCode}</td>
                          <td style={{ textAlign: "right" }}>
                            {t.hasOwnProperty("TotalQty") ? t.TotalQty : 0}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t.TaxableAmount}
                          </td>
                          <td>{t.TaxGroup}</td>
                          <td style={{ textAlign: "right" }}>{t.TotalTax}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          {!this.state.transactionType.IncludePriceAndTax && (
            <div className="form-group">
              <label>HSN Summary</label>
              {this.state.HSNSummary && (
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>HSN</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.HSNSummary.map((t) => (
                      <tr>
                        <td>{t.HSNSACCode}</td>
                        <td style={{ textAlign: "right" }}>
                          {t.hasOwnProperty("TotalQty") ? t.TotalQty : 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>

        {!isMobileApp() && this.renderSection3Totals()}
      </div>
    );
  }

  disableBatchDetailsEdit() {
    let disable = false;
    if (
      this.state.transactionType.UpdateStockChecked &&
      this.state.transactionType.hasOwnProperty("UpdateStockInwardOrOutward") &&
      this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
        "outward"
    ) {
      //show batch list
      disable = true;
    }
    return disable;
  }

  disableQtyEdit(lineItem) {
    let disable = false;
    if (
      lineItem &&
      lineItem.Product.hasOwnProperty("InventoryType") &&
      lineItem.Product.InventoryType.toLowerCase() === "unique no"
    ) {
      disable = true;
    }
    if (
      this.state.transactionType.TransactionType.toLowerCase() ===
        "transfer in" &&
      !this.state.transactionType.TransferInAllowEdit
    ) {
      disable = true;
    }
    // console.log('disable',disable)
    return disable;
  }

  renderLineItemWindow() {
    return (
      <React.Fragment>
        {this.state.showLineItemModal && (
          <Window
            header={
              this.state.selectedLineItem.Product.SKU +
              "-" +
              this.state.selectedLineItem.Product.Name
            }
            width={"700px"}
            height={"400px"}
            modal={false}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showLineItemModal: false })}
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "ok") {
                this.onLineItemChangesDone();
              } else {
                this.setState({ showLineItemModal: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            {// this.state.transactionType.TransactionType.toLowerCase() !==
            //   "stock update" &&
            this.props.registration.registration.EnablePosBillingForPincode &&
              this.state.selectedLineItem.Product.CustomField2 === "GC" &&
              this.state.selectedLineItem.Product.CustomField4 === "" && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="gcissue-info">
                      If the product is incorrect, featuring a different image,
                      an MRP that varies, and an alternate packaging quantity.
                      <br />
                      <a
                        onClick={() => {
                          this.setState({
                            selectedProductID: this.state.selectedLineItem
                              .Product.key,
                            showAddProduct: true,
                            showInventory: false,
                          });
                        }}
                      >
                        Report issue
                      </a>
                    </div>
                  </div>
                </div>
              )}
            {// this.state.transactionType.TransactionType.toLowerCase() ===
            //   "stock update" &&
            this.props.registration.registration.EnablePosBillingForPincode && (
              <>
                <div className="row">
                  <div className="col-md-3 inventory-product-info">
                    {this.state.selectedLineItem.Product.Images &&
                      this.state.selectedLineItem.Product.Images.length > 0 && (
                        <img
                          className="list-page-image"
                          src={
                            this.state.selectedLineItem.Product.Images[0]
                              .ImagePath
                          }
                        />
                      )}
                  </div>
                  <div className="col-md-9">
                    <div style={{ marginBottom: "5px" }}>
                      {this.state.selectedLineItem.Product.Name}
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      SKU: {this.state.selectedLineItem.Product.SKU}
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      Bar Code: {this.state.selectedLineItem.Product.BarCode}
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      MRP: {this.state.selectedLineItem.Product.MRP}
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      {"Retail Price: "}
                      {this.state.selectedLineItem.Product.RetailPrice}
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      In Stock Qty: {this.state.selectedLineItem.InStockQty}
                    </div>
                  </div>
                </div>
                {this.state.selectedLineItem.ProductPackagingQtyCheck && (
                  <div className="row">
                    <div className="col-md-4">
                      <Input
                        suffix={
                          this.state.selectedLineItem.ProductPackagingQtyUOM
                        }
                        type="number"
                        label="Product grammage/weight/volume"
                        value={this.state.selectedLineItem.ProductPackagingQty}
                        onChange={(value) => {
                          let selectedLineItem = this.state.selectedLineItem;
                          selectedLineItem.ProductPackagingQty = value;
                          this.setState({
                            selectedLineItem: selectedLineItem,
                            productPackagingQtyError: "",
                          });
                        }}
                        error={this.state.productPackagingQtyError}
                      />
                    </div>

                    {this.state.selectedLineItem.ProductPackagingQty !== "" &&
                      Number(this.state.selectedLineItem.ProductPackagingQty) <
                        Number(
                          this.state.selectedLineItem.ProductPackagingQtyGC
                        ) && (
                        // <div className="row">
                        <div className="col-md-12">
                          <div className="error-msg">
                            This grammage is lower than the product's grammage
                            in the catalogue. This will be automatically
                            reported for catalogue correction.
                          </div>
                        </div>
                        // </div>
                      )}

                    {this.state.selectedLineItem.ProductPackagingQty !== "" &&
                      Number(this.state.selectedLineItem.ProductPackagingQty) >
                        Number(
                          this.state.selectedLineItem.ProductPackagingQtyGC
                        ) && (
                        // <div className="row">
                        <div className="col-md-12">
                          <div className="error-msg">
                            This grammage is higher than the product's grammage
                            in the catalogue.
                          </div>
                        </div>
                        // </div>
                      )}

                    {/* <div className="col-md-2 pull-left">
                        {this.state.selectedLineItem.ProductPackagingQtyUOM}
                      </div> */}
                  </div>
                )}
              </>
            )}
            <div>
              {this.state.selectedLineItem.Product.hasOwnProperty(
                "InventoryType"
              ) &&
                this.state.selectedLineItem.Product.InventoryType.toLowerCase() ===
                  "batch tracking" &&
                this.state.transactionType.UpdateStockChecked && (
                  <div className="row">
                    {this.state.selectedLineItem.Product
                      .BatchTrackingBatchNoRequired && (
                      <div className="col-md-2 col-xs-6">
                        <div
                          className={
                            this.state.batchNoError !== ""
                              ? "form-group has-error"
                              : "form-group "
                          }
                        >
                          <label>Batch No</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(evt) =>
                              this.onSelectedLineItemBatchNoChange(
                                evt.target.value
                              )
                            }
                            value={
                              this.state.selectedLineItem.Product
                                .BatchTrackingAutoBatchNoRequired
                                ? "Auto Generated"
                                : this.state.selectedLineItem.BatchNo
                            }
                            onKeyPress={this.handleKeyPress}
                            disabled={
                              this.state.selectedLineItem.Product
                                .BatchTrackingAutoBatchNoRequired ||
                              this.props.location.state.type ===
                                actionTypes.INVENTORY_EDIT ||
                              this.disableBatchDetailsEdit()
                            }
                          />
                          {this.state.batchNoError !== "" && (
                            <span className="help-block">
                              {this.state.batchNoError}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.selectedLineItem.Product
                      .BatchTrackingMRPRequired && (
                      <div className="col-md-2 col-xs-6">
                        <div
                          className={
                            this.state.batchMRPError !== ""
                              ? "form-group has-error"
                              : "form-group "
                          }
                        >
                          <label>MRP</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(evt) =>
                              this.onSelectedLineItemBatchMRPChange(
                                evt.target.value
                              )
                            }
                            value={this.state.selectedLineItem.BatchMRP}
                            disabled={
                              this.props.location.state.type ===
                                actionTypes.INVENTORY_EDIT ||
                              this.disableBatchDetailsEdit()
                            }
                          />
                          {this.state.batchMRPError !== "" && (
                            <span className="help-block">
                              {this.state.batchMRPError}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.selectedLineItem.Product
                      .BatchTrackingMRPRequired && (
                      <div className="col-md-2 col-xs-6">
                        <div
                          className={
                            this.state.batchRetailPriceError !== ""
                              ? "form-group has-error"
                              : "form-group "
                          }
                        >
                          <label>Retail Price</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(evt) =>
                              this.onSelectedLineItemBatchRetailPriceChange(
                                evt.target.value
                              )
                            }
                            value={this.state.selectedLineItem.BatchRetailPrice}
                            disabled={
                              this.props.location.state.type ===
                                actionTypes.INVENTORY_EDIT ||
                              this.disableBatchDetailsEdit()
                            }
                          />
                          {this.state.batchRetailPriceError !== "" && (
                            <span className="help-block">
                              {this.state.batchRetailPriceError}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.selectedLineItem.Product
                      .BatchTrackingMfgDateRequired && (
                      <div className="col-md-3 col-xs-6">
                        <div
                          className={
                            this.state.batchMfgDateError !== ""
                              ? "form-group has-error"
                              : "form-group "
                          }
                        >
                          <label>Mfg Date</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(evt) =>
                              this.onSelectedLineItemBatchMfgDateChange(
                                evt.target.value
                              )
                            }
                            value={this.state.selectedLineItem.BatchMfgDate}
                            placeholder={
                              this.state.selectedLineItem.Product
                                .BatchTrackingDateFormat
                            }
                            disabled={
                              this.props.location.state.type ===
                                actionTypes.INVENTORY_EDIT ||
                              this.disableBatchDetailsEdit()
                            }
                          />
                          {this.state.batchMfgDateError !== "" && (
                            <span className="help-block">
                              {this.state.batchMfgDateError}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.selectedLineItem.Product
                      .BatchTrackingExpDateRequired && (
                      <div className="col-md-3 col-xs-6">
                        <div
                          className={
                            this.state.batchExpDateError !== ""
                              ? "form-group has-error"
                              : "form-group "
                          }
                        >
                          <label>Exp Date</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(evt) =>
                              this.onSelectedLineItemBatchExpDateChange(
                                evt.target.value
                              )
                            }
                            value={this.state.selectedLineItem.BatchExpDate}
                            placeholder={
                              this.state.selectedLineItem.Product
                                .BatchTrackingDateFormat
                            }
                            disabled={
                              this.props.location.state.type ===
                                actionTypes.INVENTORY_EDIT ||
                              this.disableBatchDetailsEdit()
                            }
                          />
                          {this.state.batchExpDateError !== "" && (
                            <span className="help-block">
                              {this.state.batchExpDateError}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              <div className="row">
                <div className="col-md-2 col-xs-6">
                  <div className="form-group">
                    <label>Qty</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={(evt) =>
                        this.onSelectedLineItemQtyChange(evt.target.value)
                      }
                      value={this.state.selectedLineItem.LineItemQty}
                      onKeyPress={this.handleKeyPress}
                      disabled={this.disableQtyEdit(
                        this.state.selectedLineItem
                      )}
                    />
                  </div>
                </div>
                {this.state.selectedLineItem.Product.hasOwnProperty(
                  "SecondaryUOMs"
                ) &&
                  this.state.selectedLineItem.Product.SecondaryUOMs.length >
                    0 &&
                  this.state.selectedLineItem.Product.UOM !== "" && (
                    <div className="col-md-3 col-xs-6">
                      <div className="form-group">
                        <label>UOM</label>
                        <select
                          className="form-control"
                          value={this.state.selectedLineItem.UOM}
                          onChange={(e) => {
                            let lineItem = this.state.selectedLineItem;
                            lineItem.UOM = e.target.value;
                            this.setState({
                              selectedLineItem: lineItem,
                            });
                          }}
                        >
                          <option
                            value={this.state.selectedLineItem.Product.UOM}
                          >
                            {this.state.uoms.filter(
                              (f) =>
                                f.value ===
                                this.state.selectedLineItem.Product.UOM
                            ).length > 0
                              ? this.state.uoms.filter(
                                  (f) =>
                                    f.value ===
                                    this.state.selectedLineItem.Product.UOM
                                )[0].text
                              : ""}
                          </option>
                          {this.state.selectedLineItem.Product.SecondaryUOMs.map(
                            (t) => (
                              <option value={t.SecondaryUOMValue}>
                                {t.SecondaryUOM}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  )}
                {this.state.transactionType.UpdateStockChecked &&
                  !this.state.transactionType.HideFreeQty && (
                    <div className="col-md-2 col-xs-6">
                      <div className="form-group">
                        <label>Free Qty</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(evt) =>
                            this.onSelectedLineItemFreeQtyChange(
                              evt.target.value
                            )
                          }
                          value={this.state.selectedLineItem.FreeQty}
                          onKeyPress={this.handleKeyPress}
                          // disabled={this.state.selectedLineItem.Product.hasOwnProperty('InventoryType') && this.state.selectedLineItem.Product.InventoryType.toLowerCase() === "unique no" ? true : false}
                          disabled={this.disableQtyEdit(
                            this.state.selectedLineItem
                          )}
                        />
                      </div>
                    </div>
                  )}
                {this.state.transactionType.UpdateStockChecked &&
                  !this.state.transactionType.HideFreeQty && (
                    <div className="col-md-2 col-xs-6">
                      <div className="form-group">
                        <label>Total Qty</label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.selectedLineItem.Qty}
                          onKeyPress={this.handleKeyPress}
                          disabled={true}
                        />
                      </div>
                    </div>
                  )}
                {this.state.transactionType.IncludePriceAndTax && (
                  <React.Fragment>
                    <div className="col-md-3 col-xs-6">
                      <div className="form-group">
                        <label>Supply Price</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(evt) =>
                            this.onSelectedLineItemPriceChange(evt.target.value)
                          }
                          value={this.state.selectedLineItem.SupplyPrice}
                          disabled={
                            !this.state.selectedLineItem.Product.AllowPriceEdit
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-xs-6">
                      <div className="form-group">
                        <label>Disc %</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(evt) =>
                            this.onSelectedLineItemDiscountChange(
                              evt.target.value
                            )
                          }
                          value={this.state.selectedLineItem.DiscountPer}
                          disabled={
                            !this.state.selectedLineItem.Product.AllowDiscount
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-xs-6">
                      <div className="form-group">
                        <label>Dis Amt</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(evt) =>
                            this.onSelectedLineItemDiscountAmountChange(
                              evt.target.value
                            )
                          }
                          value={this.state.selectedLineItem.DiscountAmount}
                          disabled={
                            !this.state.selectedLineItem.Product.AllowDiscount
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-6">
                      <div className="form-group">
                        <label>Sub Total</label>
                        <input
                          type="number"
                          className="form-control"
                          disabled={true}
                          value={
                            this.state.selectedLineItem.SubTotalWithDiscount
                          }
                        />
                      </div>
                    </div>{" "}
                  </React.Fragment>
                )}
              </div>
              {this.state.transactionType.IncludePriceAndTax && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          onChange={() =>
                            this.onSelectedLineItemChargeTaxOnProductChange()
                          }
                          defaultChecked={
                            this.state.selectedLineItem.ChargeTaxOnProduct
                          }
                          disabled={
                            !this.state.selectedLineItem.Product.AllowTaxChange
                          }
                        />
                        <label>Charge taxes on this product</label>
                      </div>
                    </div>
                    {this.state.selectedLineItem.ChargeTaxOnProduct && (
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            onChange={() =>
                              this.onSelectedLineItemTaxIncluxiveChange()
                            }
                            defaultChecked={
                              this.state.selectedLineItem.TaxInclusive
                            }
                            disabled={
                              !this.state.selectedLineItem.Product
                                .AllowTaxChange
                            }
                          />
                          <label for="taxInclusive">Tax inclusive</label>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.state.selectedLineItem.ChargeTaxOnProduct && (
                    <div className="row">
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>HSN/SAC Code</label>
                          <input
                            type="text"
                            className="form-control"
                            onKeyPress={this.handleKeyPress}
                            onChange={(evt) => {
                              let lineItem = this.state.selectedLineItem;
                              lineItem.HSNSACCode = evt.target.value;
                              this.setState({ selectedLineItem: lineItem });
                            }}
                            value={this.state.selectedLineItem.HSNSACCode}
                            disabled={
                              !this.state.selectedLineItem.Product
                                .AllowTaxChange
                            }
                            placeholder="HSN/SAC code"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label for="tax">Tax </label>
                          <select
                            className="form-control"
                            value={this.state.selectedLineItem.TaxID}
                            onChange={(evt) =>
                              this.onSelectedLineItemTaxGroupChange(
                                evt.target.value,
                                evt.target.options[evt.target.selectedIndex]
                                  .text
                              )
                            }
                            disabled={
                              !this.state.selectedLineItem.Product
                                .AllowTaxChange
                            }
                          >
                            <option value="">Select Tax</option>
                            {this.props.config.ProductTaxes.filter(
                              (f) => f.IsDeleted === false
                            ).map((t, index) => (
                              <option value={t.key}>{t.TaxGroup}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.selectedLineItem.ChargeTaxOnProduct && (
                    <div className="row">
                      {this.state.selectedLineItem.TaxComponentAmount.map(
                        (t) => (
                          <div className="col-md-3 col-xs-6">
                            <div className="form-group">
                              <label>
                                {t.TaxName} {t.TaxPercentage}%
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={t.TaxAmount}
                                disabled={true}
                              ></input>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-4 col-xs-6">
                      <div className="form-group">
                        <label> Total Amount</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={true}
                          value={this.state.selectedLineItem.Amount}
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-6"></div>
                  </div>
                </React.Fragment>
              )}
              {/* {this.state.transactionType.TransactionType.toLowerCase() ===
                "stock update" &&
                this.props.registration.registration.PincodeIntegration && (
                  <div className="row">
                    <div className="col-md-4">
                      <Input
                        label="Issue"
                        type="select"
                        onChange={(value) =>
                          this.setState({ inventoryIssue: value })
                        }
                        value={this.state.inventoryIssue}
                      >
                        <option value={""}>Select</option>
                        <option value={"Wrong Product"}>
                          {"Wrong Product"}
                        </option>
                        <option value={"Wrong Product Image"}>
                          {"Wrong Product Image"}
                        </option>
                      </Input>
                    </div>
                  </div>
                )} */}
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group">
                    <label>Notes</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(evt) =>
                        this.onSelectedLineItemNotesChange(evt.target.value)
                      }
                      value={this.state.selectedLineItem.Notes}
                      onKeyPress={this.handleKeyPress}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Window>
        )}
      </React.Fragment>
    );

    //     return <Modal open={this.state.showLineItemModal} onClose={() => this.setState({ showLineItemModal: false })} center>
    //     {this.state.selectedLineItem && <div style={{ width: this.props.isMobileApp() ? "300px" : "700px" }}>
    //         <h3 style={{ marginTop: "0px" }}>{this.state.selectedLineItem.Product.SKU} - {this.state.selectedLineItem.Product.Name}</h3>

    //         <div className="box-footer">
    //             <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.setState({ showLineItemModal: false })} >Cancel</button>
    //             <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={() => this.onLineItemChangesDone()}>OK</button>
    //         </div>
    //     </div>}
    // </Modal>
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading && (
          <Spinner text="Loading inventory. Please wait..." />
        )}
        {this.state.showInventory && this.state.transactionType && (
          <div>
            <Page
              {...this.props}
              title={
                this.props.location.state.type === actionTypes.INVENTORY_NEW
                  ? this.state.transactionType.TransactionType + "- New"
                  : this.state.transactionType.TransactionType + " - Edit"
              }
              showSaveButton={true}
              showCancelButton={true}
              showParkTransactionButton={this.state.showParkTransactionButton}
              showPrintFormats={true}
              printEnabled={this.state.printChecked}
              onPrintOptionClicked={(value) => {
                this.setState({ printChecked: value });
              }}
              emailEnabled={this.state.sendEmailChecked}
              onEmailOptionClicked={(value) => {
                this.setState({ sendEmailChecked: value });
              }}
              transactionTypeID={this.state.transactionType.key}
              onPrintFormatChange={(value) => {
                this.setState({ selectedPringConfigID: value });
              }}
              printFormat={this.state.selectedPringConfigID}
              processing={this.state.saving}
              onClick={(action) => {
                if (action === "cancel") {
                  this.onCancel();
                } else if (action === "save") {
                  this.onSave();
                } else if (action === "parktransaction") {
                  this.onSaveAsDraft();
                }
              }}
            >
              <PageSection title="">
                {this.renderSection1()}
                {this.renderSection2()}
                {this.renderSection3()}
              </PageSection>
            </Page>

            {this.renderLineItemWindow()}
          </div>
        )}

        {this.state.showProductSelection && (
          <ProductSelection
            products={this.state.showProductSelectionList}
            onProductSelection={(product) => {
              this.productSelected(product);
              this.setState({ showProductSelection: false });
            }}
            onClose={() => this.setState({ showProductSelection: false })}
          />
        )}

        {this.state.showVendorProductsForSelection && (
          <Window
            header="Select Vendor Products"
            width={"700px"}
            height={"400px"}
            modal={false}
            // processing={this.state.deleting}
            onClose={() =>
              this.setState({ showVendorProductsForSelection: false })
            }
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "ok") {
                this.addVedorProductsToLineItems();
                this.setState({ showVendorProductsForSelection: false });
              } else {
                this.setState({ showVendorProductsForSelection: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            <Input
              type="search"
              placeholder="Search by product name, SKU"
              onChange={(value) => {
                let filteredVendorProducts = this.state.vendorProducts.filter(
                  (f) =>
                    f.ProductSKU.toLowerCase().search(value.toLowerCase()) >
                      -1 ||
                    f.ProductName.toLowerCase().search(value.toLowerCase()) > -1
                );
                this.setState({
                  filteredVendorProducts: filteredVendorProducts,
                });
              }}
            />
            <Grid
              columns={[
                { Name: "", Type: "checkbox" },
                {
                  Name: "SKU",
                  Type: "field",
                  Columns: [{ Column: "ProductSKU" }],
                },
                {
                  Name: "Product",
                  Type: "field",
                  Columns: [{ Column: "ProductName" }],
                },
                {
                  Name: "Discount",
                  Type: "field",
                  Columns: [{ Column: "Discount", Type: "number" }],
                },
                {
                  Name: "Supply Price",
                  Type: "field",
                  Columns: [{ Column: "SupplyPrice", Type: "number" }],
                },
              ]}
              data={this.state.filteredVendorProducts}
              onChange={(index, record) => {
                let filteredVendorProducts = this.state.filteredVendorProducts;
                filteredVendorProducts[index] = record;
                this.setState({
                  filteredVendorProducts: filteredVendorProducts,
                });
              }}
              selectAll={this.state.selectAll}
              onSelectAll={(selected) => {
                let filteredVendorProducts = this.state.filteredVendorProducts;
                filteredVendorProducts.map((s) => (s.Selected = selected));
                this.setState({
                  filteredVendorProducts: filteredVendorProducts,
                  selectAll: selected,
                });
              }}
            />
          </Window>
        )}

        {this.state.showVendor && (
          <AddEditVendor
            {...this.props}
            action={this.state.vendorAction}
            mobile={isMobileApp()}
            source="inventory"
            vendor={this.state.selectedVendor}
            onSaveVendor={(vendor) => {
              this.setState({
                selectedVendor: vendor,
                showInventory: true,
                showVendor: false,
              });
            }}
            onCancel={() => {
              this.setState({ showInventory: true, showVendor: false });
            }}
          />
        )}
        {/* <Modal open={this.state.showError} onClose={() => this.setState({ showError: false })} center> */}
        {this.state.showError && (
          <Window
            header="Error"
            modal={true}
            onClose={() => this.setState({ showError: false })}
            onButtonClick={(buttonType) => {
              this.setState({ showError: false });
            }}
            buttons={[{ Type: "primary", Text: "OK", Align: "right" }]}
          >
            {/* <div style={{ width: "300px" }}>
                    <h3 style={{ marginTop: "0px" }}>Error</h3>
                    <div className="box-body"> */}
            <ul>
              {this.state.inStockQtyNegativeError !== "" && (
                <li>{this.state.inStockQtyNegativeError}</li>
              )}
              {this.state.inStockQtyNegativeErrors.map((m) => (
                <li>{m.Error}</li>
              ))}
            </ul>
            {/*</div>
                     <div className="box-footer">
                        <button type="submit" className="btn btn-flat btn-md btn-danger pull-right"
                            onClick={() => this.setState({ showError: false })}>OK</button>
                    </div>
                </div> */}
          </Window>
        )}
        {/* </Modal> */}
        {/* <Modal open={this.state.showUniqueNoInput}
                onClose={() => this.setState({ showUniqueNoInput: false })} center> */}
        {this.state.showUniqueNoInput && (
          <UniqueNoInput
            {...this.props}
            mobile={isMobileApp()}
            transactionID={this.state.key}
            product={this.state.selectedProduct}
            lineItem={this.state.selectedLineItem}
            mode={
              this.state.transactionType &&
              this.state.transactionType.hasOwnProperty(
                "UpdateStockInwardOrOutward"
              ) &&
              this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
                "inward" &&
              this.state.transactionType.TransactionType !== "Transfer In" &&
              this.state.key.trim() === ""
                ? "add"
                : this.state.transactionType &&
                  this.state.transactionType.hasOwnProperty(
                    "UpdateStockInwardOrOutward"
                  ) &&
                  this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
                    "inward" &&
                  this.state.transactionType.TransactionType !==
                    "Transfer In" &&
                  this.state.key.trim() !== ""
                ? "edit"
                : this.state.transactionType &&
                  this.state.transactionType.TransactionType === "Transfer In"
                ? "view"
                : "remove"
            }
            onOK={(uniqueNoList) => {
              this.productSelected(this.state.selectedProduct, uniqueNoList);
              this.setState({ showUniqueNoInput: false });
            }}
            onClose={() => this.setState({ showUniqueNoInput: false })}
          />
        )}
        {this.state.showUniqueNoSelection && (
          <UniqueNoSelection
            {...this.props}
            mobile={isMobileApp()}
            product={this.state.selectedProduct}
            lineItem={this.state.selectedLineItem}
            lineItems={this.state.lineItems}
            onOK={(uniqueNoList) => {
              this.productSelected(this.state.selectedProduct, uniqueNoList);
              this.setState({
                showUniqueNoSelection: false,
                selectedLineItem: null,
                selectedLineItem: null,
              });
            }}
            onClose={() => this.setState({ showUniqueNoSelection: false })}
          />
        )}

        {this.state.showBatchsForSelection && (
          <BatchSelection
            {...this.props}
            mobile={isMobileApp()}
            product={this.state.selectedProduct}
            storeLocationID={this.state.storeLocationID}
            lineItems={this.state.lineItems}
            onOK={async (batches) => {
              for (let i = 0; i < batches.length; i++) {
                let batch = batches[i];
                let selectedLineItemIndex = -1;
                if (i === 0 && this.state.selectedLineItem) {
                  selectedLineItemIndex = this.state.selectedLineItemIndex;
                }
                // console.log('selectedLineItemIndex', selectedLineItemIndex)
                await this.productSelected(
                  this.state.selectedProduct,
                  [],
                  batch,
                  selectedLineItemIndex
                );
              }
              this.setState({
                showBatchsForSelection: false,
                selectedLineItem: null,
              });
            }}
            onClose={() => this.setState({ showBatchsForSelection: false })}
          />
        )}

        {/* {this.state.transactionType && <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                <div style={modalWidth}> */}
        {this.state.showImport && (
          <ImportMaster
            {...this.props}
            showWindow={true}
            importType={
              this.state.transactionType.IncludePriceAndTax
                ? "inventoryPrice"
                : "inventoryQty"
            }
            // inventoryInWardOrOutward={this.state.transactionType.TransactionType.toLowerCase() === "stock update" ? "" : this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase()}
            inventoryInWardOrOutward={this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase()}
            showBatchImport={
              this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
              "inward"
            }
            showUniqueNoImport={
              this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ===
                "inward" &&
              this.state.transactionType.TransactionType.toLowerCase() !==
                "stock update"
                ? true
                : false
            }
            onClose={() => this.setState({ showImport: false })}
            onImportCompletion={(records) => this.onImportCompletion(records)}
          />
        )}
        {/* </div>
            </Modal>} */}
        {this.state.showAddProduct &&
          !this.props.registration.registration.EnablePosBillingForPincode && (
            <Product
              {...this.props}
              mobile={isMobileApp()}
              searchBarcode={this.state.scanedCode}
              calledFromInvoice={true}
              onSave={(product) => {
                this.setState({ showAddProduct: false, showInventory: true });
                this.onProductSelected(product);
              }}
              onClose={() =>
                this.setState({ showAddProduct: false, showInventory: true })
              }
            />
          )}
        {this.state.showAddProduct &&
          this.props.registration.registration.EnablePosBillingForPincode && (
            <ProductPincode
              newMRP={this.state.newMRP}
              selectedProductID={this.state.selectedProductID}
              {...this.props}
              mobile={isMobileApp()}
              searchBarcode={this.state.scanedCode}
              calledFromInvoice={true}
              onSave={(product) => {
                if (this.state.newMRP) {
                  let lineItems = this.state.lineItems;
                  lineItems[this.state.selectedLineItemIndex].Product = product;
                  lineItems[this.state.selectedLineItemIndex].InStockQty = 0;
                  this.setState({
                    lineItems: lineItems,
                    selectedLineItemIndex: -1,
                  });
                } else {
                  // console.log(
                  //   "this.state.selectedLineItemIndex",
                  //   this.state.selectedLineItemIndex
                  // );
                  // console.log(
                  //   "this.state.selectedProductID",
                  //   this.state.selectedProductID
                  // );
                  if (
                    this.state.selectedLineItemIndex >= 0 &&
                    this.state.selectedProductID !== ""
                  ) {
                    let lineItems = this.state.lineItems;
                    lineItems[
                      this.state.selectedLineItemIndex
                    ].Product = product;
                    this.setState({
                      lineItems: lineItems,
                      showLineItemModal: false,
                      // selectedProduct: product,
                    });
                  } else {
                    this.onProductSelected(product);
                  }
                }
                this.setState({
                  showAddProduct: false,
                  showInventory: true,
                  newMRP: false,
                  selectedProductID: "",
                });
              }}
              onClose={() => {
                this.setState({
                  showAddProduct: false,
                  showInventory: true,
                  newMRP: false,
                });
                if (this.state.newMRP) {
                  this.setState({ selectedLineItemIndex: -1 });
                }
              }}
            />
          )}

        {this.state.print && (
          <div>
            <Print
              {...this.props}
              printChecked={this.state.printChecked}
              sendEmailChecked={this.state.sendEmailChecked}
              sendSMSChecked={this.state.sendSMSChecked}
              printData={this.state.printData}
              onClose={() => {
                this.setState({ print: false, showInventory: true });
                this.props.history.push({
                  pathname: "/home/inventory/inventorylist/",
                  state: { type: "list" },
                });
              }}
            />
          </div>
        )}
        {/* <Modal open={this.state.showVariants} onClose={() => this.setState({ showVariants: false })} center> */}
        {this.state.showVariants && (
          <ProductVariants
            {...this.props}
            mobile={isMobileApp()}
            selectedVariantProduct={this.state.selectedVariantProduct}
            onOK={async (product) => {
              await this.productSelected(product, []);
              this.setState({
                showVariants: false,
                selectedLineItem: null,
                selectedVariantProduct: null,
              });
            }}
            onClose={() => this.setState({ showVariants: false })}
          />
        )}
        {/* </Modal> */}
      </React.Fragment>
    );
  }
}

export default Inventory;
