import React, { Component } from "react";
import Window from "../2/Controls/Window";
import ReportHeader from "./Common/ReportHeader";
import ReportBody from "./Common/ReportBody";
import Input from "../2/Controls/Input";
import ReportButton from "./Common/ReportButton";
import firebase from "../../firebase";
import Spinner from "../Common/Spinner";
import * as constants from "../../Constatnts";

class PincodeReportManual extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      products: [],
      totalItemsCount: 0,
      currentPage: 1,
      pageSize: 50,
      sku: "",
      barcode: "",
      productName: "",
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.fetchReportedProducts();
  }

  async fetchReportedProducts(page = 1) {
    const { sku, barcode, productName, pageSize } = this.state;
    const storeID = this.props.user.store.key;
    const from = (page - 1) * pageSize;

    const searchParameters = {
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              term: {
                "StoreSpecificPrices.StoreID.keyword": {
                  value: storeID,
                },
              },
            },
            {
              term: {
                "StoreSpecificPrices.OutOfStock": true,
              },
            },
          ],
          should: [
            {
              term: {
                "StoreSpecificPrices.OutOfStockReason.keyword": "",
              },
            },
            {
              bool: {
                must_not: {
                  exists: {
                    field: "StoreSpecificPrices.OutOfStockReason",
                  },
                },
              },
            },
          ],
          minimum_should_match: 1,
        },
      },
      size: pageSize,
      from,
    };

    if (sku.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "SKU.keyword": {
            value: `${sku}*`,
          },
        },
      });
    }

    if (barcode.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "BarCode.keyword": {
            value: `${barcode}*`,
          },
        },
      });
    }

    if (productName.trim()) {
      searchParameters.query.bool.must.push({
        wildcard: {
          "Name.keyword": {
            value: `${productName}*`,
          },
        },
      });
    }

    console.log("Search Parameters:", JSON.stringify(searchParameters));

    // const fetchInventory = firebase.functions().httpsCallable("getInventoryInStock");
    // const result = await fetchInventory({ text: searchParameters });
    let addMessage = firebase.functions().httpsCallable("getProducts");
    let result = await addMessage({ text: searchParameters });
    const responseData = result.data.text;

    const inventory = [];
    const productIDs = [];
    const totalRecordCount = responseData.hits.total.value;
    console.log("totalRecordCount", totalRecordCount);

    for (let i = 0; i < responseData.hits.hits.length; i++) {
      let obj = responseData.hits.hits[i];
      // if(obj._source.BarCode.length>8){
      inventory.push({ key: obj._id, ...obj._source });
      ///  productIDs.push(obj._source.ProductID);
      // }
    }

    // responseData.hits.hits.forEach((item) => {
    //   const source = item._source;
    //   // if (source.BarCode.length > 8) {
    //     inventory.push({ key: item._id, ...source });
    //     productIDs.push(source.ProductID);
    //   // }
    // });
    console.log("inventory", inventory.length, productIDs);
    // const res1 = await this.props.getProductsByProductIDs(productIDs);
    // const productList = res1.searchResult;

    let products = [];
    for (let index = 0; index < inventory.length; index++) {
      const data = inventory[index];
      let product = {
        key: data.key,
        StoreName: data.StoreName,
        StoreLocation: data.Location,
        Brand: data.Brand,
        Category: data.Category,
        SubCategory: data.SubCategory,
        ProductSKU: data.ProductSKU,
        BarCode: data.BarCode,
        ProductID: data.ProductID,
        ProductName: data.ProductName,
        MRP: 0,
        RetailPrice: 0,
        InStockQty: Number(data.InStockQty ? data.InStockQty : 0).toFixed(2),
      };

      // if (productList.filter((p) => p.key === data.ProductID).length > 0) {
      //   let objProd = productList.filter((p) => p.key === data.ProductID)[0];
      //   //console.log("objProd",objProd)
      //   product.Image =
      //     objProd.images && objProd.images.length > 0
      //       ? objProd.images[0].ImagePath
      //       : "";
      //   let mrp = objProd.mrp;
      //   let retailPrice = objProd.retailprice;
      //   if (
      //     objProd.storeSpecificPrices.filter((f) => f.StoreID === storeID) > 0
      //   ) {
      //     let ssp = objProd.StoreSpecificPrices.filter(
      //       (f) => f.StoreID === storeID
      //     )[0];
      //     mrp = ssp.MRP;
      //     retailPrice = ssp.RetailPrice;
      //   }
      //   product.MRP = mrp;
      //   product.RetailPrice = retailPrice;
      //   product.Brand = objProd.brand;
      //   product.Category = objProd.category;
      //   product.SubCategory = objProd.sub_category;
      //   product.ProductName = objProd.product;
      //   product.CatalogueIssue = objProd.customField4;
      //   product.CatalogueIssueRemark = objProd.customField5
      // }
      products.push(product);
    }
    console.log("products", products);

    // const products = inventory.map((data) => {
    //   const product = {
    //     key: data.key,
    //     StoreName: data.StoreName,
    //     StoreLocation: data.Location,
    //     Brand: data.Brand,
    //     Category: data.Category,
    //     SubCategory: data.SubCategory,
    //     ProductSKU: data.ProductSKU,
    //     BarCode: data.BarCode,
    //     ProductID: data.ProductID,
    //     ProductName: data.ProductName,
    //     MRP: 0,
    //     RetailPrice: 0,
    //     InStockQty: Number(data.InStockQty || 0).toFixed(2),
    //   };

    //   const matchedProduct = productList.find((p) => p.key === data.ProductID);
    //   if (matchedProduct) {
    //     // product.Image = matchedProduct.images?.[0]?.ImagePath || "";
    //     product.Image =
    //     matchedProduct.images && matchedProduct.images.length > 0
    //       ? matchedProduct.images[0].ImagePath
    //       : "";
    //     product.MRP = matchedProduct.mrp;
    //     product.RetailPrice = matchedProduct.retailprice;
    //     product.Brand = matchedProduct.brand;
    //     product.Category = matchedProduct.category;
    //     product.SubCategory = matchedProduct.sub_category;
    //     product.ProductName = matchedProduct.product;
    //     product.CatalogueIssue = matchedProduct.customField4;
    //     product.CatalogueIssueRemark = matchedProduct.customField5;
    //   }

    //   return product;
    // });

    this.setState({
      products,
      totalItemsCount: totalRecordCount,
      currentPage: page,
      loading: false,
    });
  }

  async exportProduct() {
    const productsData = this.state.products.map((item) => ({
      Image: item.Image,
      SKU: item.ProductSKU,
      BarCode: item.BarCode,
      Name: item.ProductName,
      Category: item.Category,
      SubCategory: item.SubCategory,
      Brand: item.Brand,
      MRP: item.MRP,
      RetailPrice: item.RetailPrice,
      "In Stock Qty": item.InStockQty,
    }));

    const csvData = constants.objToCSV(productsData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "notfoundinglobalcatalog.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  handleSearchChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handlePageChange = async (page) => {
    this.setState({ currentPage: page });
    await this.fetchReportedProducts(page);
  };

  render() {
    // const { products, loading, totalItemsCount, currentPage, pageSize } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content">
          <ReportHeader
            title="Report Manual"
            showBack={true}
            onBack={() =>
              this.props.history.push({ pathname: "/home/reports/allreport" })
            }
          />
          <ReportBody>
            <div className="row">
              <div className="col-md-2">
                <Input
                  type="text"
                  label="SKU"
                  value={this.state.sku}
                  onChange={(value) => this.handleSearchChange("sku", value)}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="text"
                  label="Barcode"
                  value={this.state.barcode}
                  onChange={(value) =>
                    this.handleSearchChange("barcode", value)
                  }
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="text"
                  label="Product name"
                  value={this.state.productName}
                  onChange={(value) =>
                    this.handleSearchChange("productName", value)
                  }
                />
              </div>
              <div className="col-md-6 pull-right">
                <ReportButton
                  type="export"
                  onClick={() => this.exportProduct()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.loading && <Spinner />}
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>SKU</th>
                      <th>Barcode</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>SubCategory</th>
                      <th>Brand</th>
                      <th>MRP</th>
                      <th>Retail Price</th>
                      <th>In Stock Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products.map((m, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            className="list-page-image"
                            src={m.Image}
                            alt="Product"
                          />
                        </td>
                        <td>{m.ProductSKU}</td>
                        <td>{m.BarCode}</td>
                        <td>{m.ProductName}</td>
                        <td>{m.Category}</td>
                        <td>{m.SubCategory}</td>
                        <td>{m.Brand}</td>
                        <td>{m.MRP}</td>
                        <td>{m.RetailPrice}</td>
                        <td>{m.InStockQty}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ReportBody>
        </section>
      </div>
    );
  }
}

export default PincodeReportManual;
